import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICiudad } from '../models/ciudad';
import { ICliente } from '../models/cliente';
import { IConfiguracionUnidad } from '../models/confUnidad';
import { IProyecto } from '../models/proyecto';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UnidadService } from '../services/unidad.service';
import { Router } from '@angular/router';

import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { RutaService } from '../services/ruta.service';
import { IRuta } from '../models/ruta';
import { ClienteService } from '../services/cliente.service';
import { CiudadService } from '../services/ciudad.service';
import { ProyectoService } from '../services/proyecto.service';
import { ConfunidadService } from '../services/confunidad.service';
import { ITipoTransporte } from '../models/tipotransporte';
import { TipotransporteService } from '../services/tipotransporte.service';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
import { ISituacion } from '../models/situacion';
import { IRutaEspecial } from '../models/ruta_especial';

@Component({
  selector: 'app-rutas',
  templateUrl: './rutas.component.html',
  styleUrls: ['./rutas.component.scss']
})
export class RutasComponent implements OnInit {
  lstConfUnidad: IConfiguracionUnidad[] = [];
  selectedConfUnidad: IConfiguracionUnidad = {
    clave_configuracionUnidad: 0,
    nombre: ''
  };
  lstProyectos: IProyecto[] = [];
  selectedProyecto: IProyecto = {
    id_proyecto: 0,
    nombre: '',
    fec_registro: ''
  };
  lstCiudades: ICiudad[] = [];
  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };
  lstClientes: ICliente[] = [];
  selectedCliente: ICliente = {
    id_cliente: 0,
    nombre: '',
    fec_registro: '',
    clv_activo: 0
  };

  lstRutas: IRuta[] = [];
  lstTransporte: ITipoTransporte[] = [];
  selectedTransporte: ITipoTransporte = {
    clave_tipoTransporte: 0,
    nombre: '',
    fecha_Registro: ''
  };

  REGEX_CARACTERES: any = /^(?=(?:.*[A-Za-z]){2})(?=(?:.*[0-9]){2})(?=(?:.*[@$?¡\-_]){1})\S{6,9}$/;
  /*Formulario*/
  formularioRuta: FormGroup = new FormGroup({
    ruta: new FormControl('', []),
    personal_encargado: new FormControl('', [
      Validators.required
    ]),
    selectedCiudad: new FormControl(0, [
      Validators.required
    ]),
    selectedProyecto: new FormControl(-1, [
      Validators.required
    ]),
    origen: new FormControl('', [
      Validators.required
    ]),
    destino: new FormControl('', [
      Validators.required
    ]),
    selectedTransporte: new FormControl(0, [
      Validators.required
    ]),
    selectedCliente: new FormControl(0, [
      Validators.required
    ]),
    clv_foranea: new FormControl(0, [Validators.required]),
    imp_viaticos_driver: new FormControl(0),
    imp_viaticos_hospedaje: new FormControl(0),
    imp_viaticos_auxiliar: new FormControl(0),
    clv_especial: new FormControl(0, [Validators.required]),
    clave_configuracionUnidad: new FormControl(0, [Validators.required]),
    complemento_ruta: new FormControl('', []),
    fec_registro: new FormControl('', [Validators.required]),
    imp_tienda_adicional: new FormControl(0, [Validators.required]),
    num_folio_proyecto: new FormControl('', [Validators.required, Validators.pattern(this.REGEX_CARACTERES)]),
    clv_devolucion:new FormControl(0,[Validators.required]),
    clv_tarifa:new FormControl(1, [Validators.required])
  });
  saveForm!: FormGroup;
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  lstTarifa = [{
    clv_tarifa: 1,
    desc_tarifa: 'SPOT'
  },
  {
    clv_tarifa: 2,
    desc_tarifa: 'DEDICADA'
  },
  {
    clv_tarifa: 3,
    desc_tarifa: 'EXTRAORDINARIA'
  }
];
  //Registro
  user: ICredencialUsuario = {};
  //Ruta Foranea
  lstSituacion: ISituacion[] = [{ id: 0, nombre: 'NO' }, { id: 1, nombre: 'SI' }];
  dsb_foranea: boolean = false;
  //Ruta Especial
  lstTipoRuta: IRutaEspecial[] = [{ id: 0, nombre: 'NORMAL' }, { id: 1, nombre: 'ESPECIAL' }];
  fecha_dia: any = null;
  constructor(private unidadService: UnidadService, private messageService: MessageService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder, private rutaService: RutaService,
    private clienteService: ClienteService,
    private ciudadService: CiudadService,
    private proyectoService: ProyectoService,
    private confUnidadService: ConfunidadService,
    private tipoTransporteService: TipotransporteService,
    private auth: AuthService) {
    this.cargarInformacionUsuario();
    this.formularioRuta.controls['clv_foranea'].valueChanges.subscribe(data => {
      if (data !== null) {
        if (data == 1) {
          this.dsb_foranea = true;
          this.formularioRuta.controls['imp_viaticos_driver'].setValue(0);
          this.formularioRuta.controls['imp_viaticos_hospedaje'].setValue(0);
          this.formularioRuta.controls['imp_viaticos_auxiliar'].setValue(0);
        } else {
          this.dsb_foranea = false;
          this.formularioRuta.controls['imp_viaticos_driver'].setValue(0);
          this.formularioRuta.controls['imp_viaticos_hospedaje'].setValue(0);
          this.formularioRuta.controls['imp_viaticos_auxiliar'].setValue(0);
        }
      }
    });
  }
  ngOnInit(): void {
    //this.getListaDeRutas();
    this.getListaDeClientes();
    this.getListaDeCiudades();
    this.getListaDeProyectos();
    this.getListaDeConfUnidad();
    this.getListaDeTipoTransporte();
    this.inicializarFechas();
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    //Formatear fecha de Inicio
    this.formularioRuta.controls['fec_registro'].setValue(this.fecha_dia);
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  getListaDeRutas() {
    this.rutaService.listarRutas().subscribe((resp) => {
      this.lstRutas = resp;
    });
  }
  getListaDeClientes() {
    this.formularioRuta.controls['selectedCliente'].disable();
    this.clienteService.getListadoClientes().subscribe((resp) => {
      this.lstClientes = resp;
      this.formularioRuta.controls['selectedCliente'].enable();
    },
      (error) => {
        this.formularioRuta.controls['selectedCliente'].disable();
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Listado de Clientes>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  getListaDeCiudades() {
    this.formularioRuta.controls['selectedCiudad'].disable();
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudades = resp;
      this.formularioRuta.controls['selectedCiudad'].enable();
    },
      (error) => {
        this.formularioRuta.controls['selectedCiudad'].disable();
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Listado de Ciudades>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  getListaDeProyectos() {
    this.formularioRuta.controls['selectedProyecto'].disable();
    this.proyectoService.getListadoProyectos().subscribe((resp) => {
      this.lstProyectos = resp;
      this.formularioRuta.controls['selectedProyecto'].enable();
    },
      (error) => {
        this.formularioRuta.controls['selectedProyecto'].disable();
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Listado de Proyectos>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  getListaDeConfUnidad() {
    this.confUnidadService.getListadoConfUnidades().subscribe((resp) => {
      this.lstConfUnidad = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Conf. de Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  getListaDeTipoTransporte() {
    this.formularioRuta.controls['selectedTransporte'].disable();
    this.tipoTransporteService.getListadoTipoTransporte().subscribe((resp) => {
      this.lstTransporte = resp;
      this.formularioRuta.controls['selectedTransporte'].enable();
    },
      (error) => {
        this.formularioRuta.controls['selectedTransporte'].disable();
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Tipo de Transporte>', detail: 'Contacte al Administrador del Sitio' });
      });
  }


  cancelar() {
    //this.formularioRuta.reset();
    this.resetearFormulario();
    this.formularioRuta.controls['fec_registro'].setValue(this.fecha_dia);
    this.formularioRuta.controls['complemento_ruta'].setValue('');
    this.dsb_foranea = false;
  }

  resetearFormulario() {
    this.formularioRuta.controls['ruta'].setValue('');
    this.formularioRuta.controls['personal_encargado'].setValue('');
    this.formularioRuta.controls['selectedCiudad'].setValue(0);
    this.formularioRuta.controls['selectedProyecto'].setValue(-1);
    this.formularioRuta.controls['origen'].setValue('');
    this.formularioRuta.controls['destino'].setValue('');
    this.formularioRuta.controls['selectedTransporte'].setValue(0);
    this.formularioRuta.controls['selectedCliente'].setValue(0);
    this.formularioRuta.controls['clv_foranea'].setValue(0);
    this.formularioRuta.controls['imp_viaticos_driver'].setValue(0);
    this.formularioRuta.controls['imp_viaticos_hospedaje'].setValue(0);
    this.formularioRuta.controls['imp_viaticos_auxiliar'].setValue(0);
    this.formularioRuta.controls['clv_especial'].setValue(0);
    this.formularioRuta.controls['clave_configuracionUnidad'].setValue(0);
    this.formularioRuta.controls['complemento_ruta'].setValue('');
    this.formularioRuta.controls['fec_registro'].setValue('');
    this.formularioRuta.controls['imp_tienda_adicional'].setValue(0);
    this.formularioRuta.controls['num_folio_proyecto'].setValue('');
  }

  validarFormulario() {

  }

  submit() {

    //Se valida informacion de Formulario
    let ruta_enviar: IRuta = {
      clave_ruta: 0,
      idx: 0,
      desc_ruta: '',
      complemento_ruta: this.formularioRuta.get('complemento_ruta')?.value.toUpperCase(),
      clave_configuracionUnidad: this.formularioRuta.get('clave_configuracionUnidad')?.value,
      id_proyecto: this.formularioRuta.get('selectedProyecto')?.value,
      id_ciudad: this.formularioRuta.get('selectedCiudad')?.value,
      id_cliente: this.formularioRuta.get('selectedCliente')?.value,
      desc_origen: typeof this.formularioRuta.get('origen')?.value === 'object' ? '' : this.formularioRuta.get('origen')?.value.toUpperCase(),
      desc_destino: typeof this.formularioRuta.get('destino')?.value === 'object' ? '' : this.formularioRuta.get('destino')?.value.toUpperCase(),
      desc_ciudad: '',
      desc_cliente: '',
      fec_registro: this.formularioRuta.get('fec_registro')?.value,
      clv_disponible: 1,
      fecha_registro_especial: this.formularioRuta.get('clv_especial')?.value == 0 ? '1900-01-01' : this.formularioRuta.get('fec_registro')?.value,
      clv_especial: this.formularioRuta.get('clv_especial')?.value,
      nombre_responsable: typeof this.formularioRuta.get('personal_encargado')?.value === 'object' ? '' : this.formularioRuta.get('personal_encargado')?.value.toUpperCase(),
      id_tipo_transporte: this.formularioRuta.get('selectedTransporte')?.value,
      desc_proyecto: '',
      desc_tipo_transporte: '',
      clv_foranea: this.formularioRuta.get('clv_foranea')?.value,
      imp_viaticos_driver: this.formularioRuta.get('imp_viaticos_driver')?.value == null ? 0 : this.formularioRuta.get('imp_viaticos_driver')?.value,
      imp_viaticos_hospedaje: this.formularioRuta.get('imp_viaticos_hospedaje')?.value == null ? 0 : this.formularioRuta.get('imp_viaticos_hospedaje')?.value,
      imp_viaticos_auxiliar: this.formularioRuta.get('imp_viaticos_auxiliar')?.value == null ? 0 : this.formularioRuta.get('imp_viaticos_auxiliar')?.value,
      imp_tienda_adicional: this.formularioRuta.get('imp_tienda_adicional')?.value == '' || this.formularioRuta.get('imp_tienda_adicional')?.value == null ? 0 : this.formularioRuta.get('imp_tienda_adicional')?.value,
      num_folio_proyecto: this.formularioRuta.get('num_folio_proyecto')?.value == '' || this.formularioRuta.get('num_folio_proyecto')?.value == null ? '' : this.formularioRuta.get('num_folio_proyecto')?.value,
      clv_devolucion:this.formularioRuta.get('clv_devolucion')?.value == null ? 0 : this.formularioRuta.get('clv_devolucion')?.value,
      clv_tarifa:this.formularioRuta.get('clv_tarifa')?.value==null ? 1:this.formularioRuta.get('clv_tarifa')?.value
    };

    this.rutaService.saveRutaNormal(1, ruta_enviar).subscribe((resp) => {
      setTimeout(() => {
        this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
        this.limpiar();
      }, 800);
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error en <Ruta Normal>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  limpiar() {
    //this.formularioRuta.reset();
    this.resetearFormulario();
    this.dsb_foranea = false;
    this.inicializarFechas();
    this.formularioRuta.controls['fec_registro'].setValue(this.fecha_dia);
    this.formularioRuta.controls['complemento_ruta'].setValue('');
    this.formularioRuta.controls['clv_tarifa'].setValue(1);
    this.formularioRuta.controls['clv_especial'].setValue(0);
  }

  redireccionarTodas() {
    this.router.navigateByUrl("/rutas");
  }
}
