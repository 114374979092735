import { CiudadService } from './../services/ciudad.service';
import { TabuladorService } from './../services/tabulador.service';
import { ConfunidadService } from './../services/confunidad.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { ProyectoService } from '../services/proyecto.service';
import { IProyecto } from '../models/proyecto';
import { IConfiguracionUnidad } from '../models/confUnidad';
import { ClienteService } from '../services/cliente.service';
import { ICliente } from '../models/cliente';
import { IServicioBrindado } from '../models/serviciobrindado';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPuestochofer } from '../models/puestochofer';
import { ITabuladoReestructura } from '../models/tabulado_reestructura';
import { ICiudad } from '../models/ciudad';
import { IDataExtensionRuta } from '../models/data_extension_ruta';
import { IrptSalariosAutorizados } from '../models/rptsalarios';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-salarios',
  templateUrl: './salarios.component.html',
  styleUrls: ['./salarios.component.scss']
})
export class SalariosComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  @ViewChild('dt2') dt2!: Table;
  anio: any = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  dspDialogo: boolean = false;
  dspDialogoExtra: boolean = false;
  texto_operacion: string = '';
  nombre_registro: string = '';
  //
  lstTabuladoN: ITabuladoReestructura[] = [];
  lstTabuladoNReporte: ITabuladoReestructura[] = [];
  //
  loading: boolean = false;
  //Catalogos
  lstCiudad: ICiudad[] = [];
  lstProyectos: IProyecto[] = [];
  lstConfUnidad: IConfiguracionUnidad[] = [];
  lstClientes: ICliente[] = [];
  lstServicioBrindado: IServicioBrindado[] = [];
  lstServicioBrindado_form: IServicioBrindado[] = [];
  lstPuestosChofer: IPuestochofer[] = [];
  clv_operacion: number = 0;
  data_extension: IDataExtensionRuta = {
    desc_ruta: '',
    imp_apoyo_entregas: 0,
    imp_apoyo_maniobra: 0,
    imp_ext_est_sueldo_diver: 0,
    imp_ext_est_sueldo_auxiliar: 0,
    imp_ext_est_alimento_diver: 0,
    imp_ext_est_alimento_auxiliar: 0,
    imp_hospedaje: 0,
    num_dias_hospedaje:0
  };
  //reporte
  loading_reporte1: boolean = false;
  anioReporte1: any;
  dbsReportes: boolean = false;
  lstReporteSalarios: IrptSalariosAutorizados[] = [];
  constructor(private ciudadService: CiudadService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private proyectoService: ProyectoService,
    private confunidadService: ConfunidadService,
    private tabuladorService: TabuladorService) {
  }
  ngOnInit(): void {
    this.inicializarFechas();
    this.cargarListadodeCiudades();
    this.cargarListadoDeProyectos();
    this.cargarListadoDeConfUnidad();
    this.cargarListadodeServicioBrindado();
    this.cargarListadodePuestos();
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.anioReporte1 = getYear;
  }

  filtrarListadoSubEstatus() {
    if (this.lstServicioBrindado_form.length > 0) {
      this.lstServicioBrindado_form.map((valor) => {
        if (valor.id_servicio == 5 || valor.id_servicio == 6) {
          valor.disabled = true;
        } else {
          valor.disabled = false;
        }
      });
    }
  }


  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }


  cargarInformacionTabulado(num_anio: any) {
    this.loading = true;
    if (typeof num_anio == 'object') {
      num_anio = num_anio.toLocaleDateString('es-MX', { year: 'numeric' });
    }
    if (typeof num_anio === "string") {
      num_anio = num_anio;
    }
    if (num_anio == null || typeof num_anio == 'undefined') {
      let mensaje = 'Seleccione un Año para mostrar Información';
      this.mostrarDialogoInformativoSinInformacion(mensaje);
    } else {
      this.tabuladorService.getListadoTabuladoReestructura(num_anio).subscribe((resp) => {
        this.lstTabuladoN = resp;
        setTimeout(() => {
          this.loading = false;
        },
          600);
        if (this.lstTabuladoN.length == 0) {
          let mensaje = 'No existe información del Año a Consultar.';
          this.mostrarDialogoInformativoSinInformacion(mensaje);
        }
      }, (error) => {
        this.loading = false;
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Tabulado>', detail: 'Contacte al Administrador del Sitio' });
      });
    }
  }

  cargarListadodeServicioBrindado() {
    this.lstServicioBrindado = [
      {
        "idx": 1,
        "id_servicio": 1,
        "nom_servicio": "Asistencia Pagada",
        "id_tipo_transporte": 1,
        "id_tipo_puesto": 0,
        "clv_asignado_manual": 0,
        "clv_activo": 1
      },
      {
        "idx": 5,
        "id_servicio": 5,
        "nom_servicio": "Cancelada",
        "id_tipo_transporte": 1,
        "id_tipo_puesto": 1,
        "clv_asignado_manual": 0,
        "clv_activo": 1
      },
      {
        "idx": 3,
        "id_servicio": 3,
        "nom_servicio": "Capacitación",
        "id_tipo_transporte": 1,
        "id_tipo_puesto": 2,
        "clv_asignado_manual": 0,
        "clv_activo": 1
      },
      {
        "idx": 4,
        "id_servicio": 4,
        "nom_servicio": "Certificación",
        "id_tipo_transporte": 1,
        "id_tipo_puesto": 1,
        "clv_asignado_manual": 0,
        "clv_activo": 1
      },
      {
        "idx": 7,
        "id_servicio": 8,
        "nom_servicio": "Operacion",
        "id_tipo_transporte": 3,
        "id_tipo_puesto": 0,
        "clv_asignado_manual": 1,
        "clv_activo": 1
      },
      {
        "idx": 6,
        "id_servicio": 6,
        "nom_servicio": "Rescate de Unidad",
        "id_tipo_transporte": 1,
        "id_tipo_puesto": 1,
        "clv_asignado_manual": 0,
        "clv_activo": 1
      },
      {
        "idx": 2,
        "id_servicio": 2,
        "nom_servicio": "Traslado de Unidad",
        "id_tipo_transporte": 1,
        "id_tipo_puesto": 2,
        "clv_asignado_manual": 1,
        "clv_activo": 1
      }
    ];
  }

  cargarListadodePuestos() {
    this.lstPuestosChofer = [
      {
        id: 1,
        nombre: 'DRIVER',
        descripcion_Puesto: ''
      },
      {
        id: 2,
        nombre: 'AUXILIAR',
        descripcion_Puesto: ''
      },
      {
        id: 3,
        nombre: 'BACK UP',
        descripcion_Puesto: ''
      }
    ];
  }

  cargarListadodeCiudades() {
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp;
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Ciudades>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargarListadoDeProyectos() {
    this.proyectoService.getListadoProyectos().subscribe((resp) => {
      this.lstProyectos = resp;
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Proyectos>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargarListadoDeConfUnidad() {
    this.confunidadService.getListadoConfUnidades().subscribe((resp) => {
      this.lstConfUnidad = resp;
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Conf.Unidad>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  obtenerInformacionCiudad(id_ciudad_HM: number): ICiudad {
    let ciudad: ICiudad = {
      id: 0,
      nombre: '',
      id_ciudad_principal: 0,
      clave_tipoTransporte: 0,
      clave_Estado: ''
    };
    this.lstCiudad.map((dato) => {
      if (dato.id == id_ciudad_HM) {
        ciudad = dato;
      }
    });
    return ciudad;
  }


  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal2($event: any, stringVal: any) {
    this.dt2.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  mostrarModalExtension(data: ITabuladoReestructura) {
    this.texto_operacion = 'Información del Tabulador';
    this.data_extension = {
      desc_ruta: data.desc_ruta,
      imp_apoyo_entregas: data.imp_apoyo_entregas,
      imp_apoyo_maniobra: data.imp_apoyo_maniobra,
      imp_ext_est_sueldo_diver: data.imp_ext_est_sueldo_diver,
      imp_ext_est_sueldo_auxiliar: data.imp_ext_est_sueldo_auxiliar,
      imp_ext_est_alimento_diver: data.imp_ext_est_alimento_diver,
      imp_ext_est_alimento_auxiliar: data.imp_ext_est_alimento_auxiliar,
      imp_hospedaje: data.imp_hospedaje,
      num_dias_mas: data.num_dias_mas,
      num_dias_hospedaje: data.num_dias_hospedaje
    };
    this.dspDialogoExtra = true;
  }

  cancelarMostrarExtension() {
    this.texto_operacion = '';
    this.data_extension = {
      desc_ruta: '',
      imp_apoyo_entregas: 0,
      imp_apoyo_maniobra: 0,
      imp_ext_est_sueldo_diver: 0,
      imp_ext_est_sueldo_auxiliar: 0,
      imp_ext_est_alimento_diver: 0,
      imp_ext_est_alimento_auxiliar: 0,
      imp_hospedaje: 0,
      num_dias_mas: 0,
      num_dias_hospedaje:0
    }
    this.dspDialogoExtra = false;
  }

  abrirModalReportes() {
    this.dbsReportes = true;
  }

  generarReporte1() {
    this.loading_reporte1 = true;
     if ((this.anioReporte1 === 'undefined' || this.anioReporte1 == null)) {
       this.mostrarMensajeDeAlerta();
       //this.mostrarDialogoInformativoSinInformacion('Mes y Año deben seleccionarse para consultar informacion.');
       this.loading_reporte1 = false;
     } else {
       let anio_cast: any = 0;
       if (this.anioReporte1 instanceof Date) {
         // will execute
         anio_cast = this.anioReporte1.getFullYear();
       } else {
         anio_cast = this.anioReporte1;
       }
       this.cargarInformacionDelMes(anio_cast);
     }
   }

   mostrarMensajeDeAlerta() {
    this.loading = false;
    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'info',
      summary: 'Falta Año',
      detail: 'Año debe seleccionarse para consultar informacion.'
    })
  }

  cancelarMensajeConfirmacion(){
    this.messageService.clear('confirm');
  }

  confirmarMensaje(){
    this.messageService.clear('confirm');
  }

   cargarInformacionDelMes(num_anio:number) {
    this.lstReporteSalarios = [];
    this.tabuladorService.getListadoTabuladoReestructura(num_anio).subscribe((resp) => {
      this.lstTabuladoNReporte = resp;
        if (!this.lstTabuladoNReporte.length || typeof this.lstTabuladoNReporte === 'undefined') {
          this.loading_reporte1 = false;
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
        } else {
          this.asignarArregloParaExportarRecursos(this.lstTabuladoNReporte);
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.lstReporteSalarios);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });
            this.saveAsExcelFile(excelBuffer, "TabuladorAutorizado" + num_anio);
          });
        }
        setTimeout(() => {
          this.loading_reporte1 = false;
        },
          600);
    }, (error) => {
      this.loading_reporte1 = false;
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Tabulado>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cancelarModalReportes() {
    this.dbsReportes = false;
  }

  asignarArregloParaExportarRecursos(data: ITabuladoReestructura[]) {
    data.map((valor) => {
      this.lstReporteSalarios.push({
        ClaveRuta: valor.id_servicio==8? valor.id_ruta : valor.id_servicio,
        Servicio:valor.desc_servicio,
        NombreRuta: valor.id_servicio==8? valor.desc_ruta:'',
        CiudadOperacion: valor.desc_ciudad_HM,
        NumeroDias: valor.num_dias_mas,
        SueldoDriver: valor.num_dias_mas <= 1 ? valor.imp_sueldo_diver : valor.imp_ext_est_sueldo_diver,
        SueldoAuxiliar: valor.num_dias_mas <= 1 ? valor.imp_sueldo_auxiliar : valor.imp_ext_est_sueldo_auxiliar,
        AlimentoDriver: valor.num_dias_mas <= 1 ? valor.imp_alimento_diver : valor.imp_ext_est_alimento_diver,
        AlimentoAuxiliar: valor.num_dias_mas <= 1 ? valor.imp_alimento_auxiliar : valor.imp_ext_est_alimento_auxiliar,
        DiasHospedaje: valor.num_dias_hospedaje,
        Hospedaje: valor.imp_hospedaje,
        ApoyoPorEntrega: valor.imp_apoyo_entregas,
        ApoyoManiobras: valor.imp_apoyo_maniobra,
        Comentarios: valor.observacion,
        Estatus: valor.clv_activo==0 ? 'INACTIVO': 'ACTIVO'
      });
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_AlDia_" + dateFormat+ EXCEL_EXTENSION);
  }

}
