import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IRuta } from '../models/ruta';
import { RutaService } from '../services/ruta.service';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClienteService } from '../services/cliente.service';
import { CiudadService } from '../services/ciudad.service';
import { ProyectoService } from '../services/proyecto.service';
import { TipotransporteService } from '../services/tipotransporte.service';
import { IProyecto } from '../models/proyecto';
import { ICiudad } from '../models/ciudad';
import { ICliente } from '../models/cliente';
import { ITipoTransporte } from '../models/tipotransporte';
import { IResultadoGeneral } from '../models/resultadogeneral';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
import { IEstatus } from '../models/estatus';
import { ISituacion } from '../models/situacion';
import { ConfunidadService } from '../services/confunidad.service';
import { IConfiguracionUnidad } from '../models/confUnidad';
import { IRutaEspecial } from '../models/ruta_especial';
import { IDataGuardarFolio } from '../models/data_folio';
import { IMes } from '../models/mes';
@Component({
  selector: 'app-lstrutas',
  templateUrl: './lstrutas.component.html',
  styleUrls: ['./lstrutas.component.scss']
})
export class LstrutasComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  lstRutas: IRuta[] = [];
  loading: boolean = false;
  dspEditarRuta: boolean = false;
  REGEX_CARACTERES: any = /^(?=(?:.*[A-Za-z]){2})(?=(?:.*[0-9]){2})(?=(?:.*[@$?¡\-_]){1})\S{6,9}$/;
  rutaForm: FormGroup = new FormGroup({
    ruta: new FormControl('', []),
    personal_encargado: new FormControl('', [
      Validators.required
    ]),
    selectedCiudad: new FormControl(0, [
      Validators.required
    ]),
    selectedProyecto: new FormControl(-1, [
      Validators.required
    ]),
    origen: new FormControl('', [
      Validators.required
    ]),
    destino: new FormControl('', [
      Validators.required
    ]),
    selectedTransporte: new FormControl(0, [
      Validators.required
    ]),
    selectedCliente: new FormControl(0, [
      Validators.required
    ]),
    clv_foranea: new FormControl(0, [Validators.required]),
    imp_viaticos_driver: new FormControl(0),
    imp_viaticos_hospedaje: new FormControl(0),
    imp_viaticos_auxiliar: new FormControl(0),
    clv_especial: new FormControl(0, [Validators.required]),
    clave_configuracionUnidad: new FormControl(0, [Validators.required]),
    complemento_ruta: new FormControl('', []),
    fec_registro: new FormControl({ value: '', disabled: true }, [Validators.required]),
    imp_tienda_adicional: new FormControl(0, [Validators.required]),
    num_folio_proyecto: new FormControl({ value: '', disabled: true }, [Validators.pattern(this.REGEX_CARACTERES)]),
    clv_devolucion: new FormControl(0, [Validators.required]),
    clv_tarifa:new FormControl(1, [Validators.required])

  });

  foliosForm: FormGroup = new FormGroup({
    idx: new FormControl(0, [Validators.required]),
    id_ruta: new FormControl({ value: 0, disabled: true }, [Validators.required]),
    num_folio_proyecto: new FormControl('',[Validators.required, Validators.pattern(this.REGEX_CARACTERES)]),
    num_mes: new FormControl(0, [Validators.required]),
    num_anio: new FormControl(0, [Validators.required])
  });

  lstProyectos: IProyecto[] = [];
  lstCiudades: ICiudad[] = [];
  lstClientes: ICliente[] = [];
  lstTransporte: ITipoTransporte[] = [];
  lstConfUnidad: IConfiguracionUnidad[] = [];

  lstTarifa = [{
      clv_tarifa: 1,
      desc_tarifa: 'SPOT'
    },
    {
      clv_tarifa: 2,
      desc_tarifa: 'DEDICADA'
    },
    {
      clv_tarifa: 3,
      desc_tarifa: 'EXTRAORDINARIA'
    }
  ];
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];
  selectedMes: IMes = {
    num_mes: -1,
    nombre: ""
  }

  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  }
  anio: any;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }
  anioReporte1: any;
  folioDescRuta: number = 0;

  dspDesativarRuta: boolean = false;
  dsbAgregarFolio : boolean = false;
  desc_ruta: string = '';
  DesarutaForm!: FormGroup;
  rutaOperacion: IRuta = {
    clave_ruta: 0,
    idx: 0,
    desc_ruta: '',
    clave_configuracionUnidad: 0,
    id_proyecto: 0,
    id_ciudad: 0,
    id_cliente: 0,
    id_tipo_transporte: 0,
    desc_origen: '',
    desc_destino: '',
    desc_ciudad: '',
    desc_cliente: '',
    fec_registro: '',
    clv_disponible: 0,
    fecha_registro_especial: '',
    clv_especial: 0,
    nombre_responsable: '',
    desc_proyecto: '',
    desc_tipo_transporte: '',
    clv_foranea: 0,
    imp_viaticos_driver: 0,
    imp_viaticos_hospedaje: 0,
    imp_viaticos_auxiliar: 0,
    complemento_ruta: '',
    imp_tienda_adicional: 0,
    num_folio_proyecto: '',
    clv_devolucion: 0,
    clv_tarifa: 1,

  };
  folioActivRuta: IRuta = this.rutaOperacion;
  /*Colores*/
  color_rojo_especial: string = '#FF0000';
  color_gris_deshabilitadas: string = '#D9D9D9';
  num_operacion: number = 0;
  //Registro
  user: ICredencialUsuario = {};
  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'ACTIVO'
  },
  {
    id: 0,
    nombre: 'INACTIVO'
  }];
  lstEstatusEspecial: IEstatus[] = [{
    id: 1,
    nombre: 'ESPECIAL'
  },
  {
    id: 0,
    nombre: 'NORMAL'
  }];

  lstForanea: IEstatus[] = [{
    id: 1,
    nombre: 'FORANEA'
  },
  {
    id: 0,
    nombre: 'LOCAL'
  }];
  //FORANEA
  lstSituacion: ISituacion[] = [{ id: 0, nombre: 'NO' }, { id: 1, nombre: 'SI' }];
  dsb_foranea: boolean = false;
  //Ruta Especial
  lstTipoRuta: IRutaEspecial[] = [{ id: 0, nombre: 'NORMAL' }, { id: 1, nombre: 'ESPECIAL' }];
  constructor(private rutaService: RutaService,
    private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private clienteService: ClienteService,
    private ciudadService: CiudadService,
    private proyectoService: ProyectoService,
    private tipoTransporteService: TipotransporteService,
    private auth: AuthService,
    private confUnidadService: ConfunidadService) {
    this.cargarInformacionUsuario();
   // this.inicializarFechas();
    this.rutaForm.controls['clv_foranea'].valueChanges.subscribe(data => {
      if (data !== null) {
        if (data == 1) {
          this.dsb_foranea = true;
        } else {
          this.dsb_foranea = false;
          this.rutaForm.controls['imp_viaticos_driver'].setValue(0);
          this.rutaForm.controls['imp_viaticos_hospedaje'].setValue(0);
          this.rutaForm.controls['imp_viaticos_auxiliar'].setValue(0);
        }
      }
    });
  }
  ngOnInit(): void {
    this.getListaDeRutas();
    this.getListaDeClientes();
    this.getListaDeCiudades();
    this.getListaDeProyectos();
    this.getListaDeConfUnidad();
    this.getListaDeTipoTransporte();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  getListaDeRutas() {
    this.loading = true;
    this.rutaService.listarRutas().subscribe((resp) => {
      this.lstRutas = resp;
      setTimeout(() => {
        this.loading = false;
      },
        800);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de RUTAS');
        this.loading = false;
      });
  }

  /*redireccionarNueva(num_operacion:number) {
    this.num_operacion=num_operacion;
  }*/

  redireccionarNueva() {
    this.router.navigateByUrl("/ruta");
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }





//metodo para validaar si existe

//-------------hacer un get que reciba la info  o implemntarlo al  que esta ctualmente

deshabilitarTodos(){
  this.foliosForm.get('num_mes')!.disable();
  this.foliosForm.get('num_anio')!.disable();
  this.foliosForm.get('num_folio_proyecto')!.enable();
}


  validaNumOperacion(ruta: IRuta){
    this.inicializarFechas();
    this.foliosForm.controls['idx'].setValue(0);
    this.foliosForm.controls['num_mes'].setValue(this.selectedMes.num_mes);
    this.foliosForm.controls['num_anio'].setValue(this.anio);
    this.foliosForm.controls['id_ruta'].setValue(ruta.clave_ruta);
    this.foliosForm.controls['num_folio_proyecto'].setValue('');
    this.deshabilitarTodos();
    this.desc_ruta = ruta.desc_ruta;
    this.dsbAgregarFolio = true;
    this.folioDescRuta = 1;

  }

  confirmarDesactivar(clave_ruta: number, ruta: IRuta) {

    let estatus_original = ruta.clv_disponible;
    let texto_desactivar: string = ruta.clv_disponible == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = ruta.clv_disponible == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> la Ruta <b>' + ruta.desc_ruta + '</b> ?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
       // ruta.clv_disponible = ruta.clv_disponible == 1 ? 0 : 1;
        this.guardarInformacionDesactivarRuta(ruta);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            ruta.clv_disponible = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Proceso Cancelado' });
            break;
          case ConfirmEventType.CANCEL:
            ruta.clv_disponible = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Proceso Cancelado' });
            break;
        }
      }
    });

  }



  guardarInformacionDesactivarRuta(ruta: IRuta) {
    //Se manda llamar el servicio
    this.rutaService.DesaRutaNormal(4, ruta).subscribe((resp) => {
      this.resultado = resp;

      if(resp[0].clv_estatus == 0){

        this.validaNumOperacion(ruta);
        this.folioActivRuta = ruta;
      }else{ setTimeout(() => {
        this.getListaDeRutas();
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Actualización exitosa', detail: 'La informacion se actualizo de forma correcta' });
      },
        800);
      }
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Desactivar Ruta>', detail: 'Contacte al Administrador del Sitio' });
    });
  }


  guardarInformacionRuta() {

    //Se valida informacion de Formulario
    let ruta_enviar: IRuta = {
      clave_ruta: this.rutaOperacion.clave_ruta,
      idx: this.rutaOperacion.idx,
      desc_ruta: '',
      clave_configuracionUnidad: this.rutaForm.get('clave_configuracionUnidad')?.value,
      id_proyecto: this.rutaForm.get('selectedProyecto')?.value,
      id_ciudad: this.rutaForm.get('selectedCiudad')?.value,
      id_cliente: this.rutaForm.get('selectedCliente')?.value,
      desc_origen: this.rutaForm.get('origen')?.value.toUpperCase(),
      desc_destino: this.rutaForm.get('destino')?.value.toUpperCase(),
      desc_ciudad: '',
      desc_cliente: '',
      fec_registro: '1900-01-01',
      clv_disponible: this.rutaOperacion.clv_disponible,
      fecha_registro_especial: '1900-01-01',
      clv_especial: this.rutaForm.get('clv_especial')?.value,
      nombre_responsable: this.rutaForm.get('personal_encargado')?.value.toUpperCase(),
      id_tipo_transporte: this.rutaForm.get('selectedTransporte')?.value,
      desc_proyecto: '',
      desc_tipo_transporte: '',
      clv_foranea: this.rutaForm.get('clv_foranea')?.value,
      imp_viaticos_driver: this.rutaForm.get('imp_viaticos_driver')?.value == null ? 0 : this.rutaForm.get('imp_viaticos_driver')?.value,
      imp_viaticos_hospedaje: this.rutaForm.get('imp_viaticos_hospedaje')?.value == null ? 0 : this.rutaForm.get('imp_viaticos_hospedaje')?.value,
      imp_viaticos_auxiliar: this.rutaForm.get('imp_viaticos_auxiliar')?.value == null ? 0 : this.rutaForm.get('imp_viaticos_auxiliar')?.value,
      complemento_ruta: this.rutaForm.get('complemento_ruta')?.value == '' || this.rutaForm.get('complemento_ruta')?.value == null ? '' : this.rutaForm.get('complemento_ruta')?.value.toUpperCase(),
      imp_tienda_adicional: this.rutaForm.get('imp_tienda_adicional')?.value == '' || this.rutaForm.get('imp_tienda_adicional')?.value == null ? 0 : this.rutaForm.get('imp_tienda_adicional')?.value,
      num_folio_proyecto: this.rutaForm.get('num_folio_proyecto')?.value == '' || this.rutaForm.get('num_folio_proyecto')?.value == null ? '' : this.rutaForm.get('num_folio_proyecto')?.value,
      clv_devolucion: this.rutaForm.get('clv_devolucion')?.value == null ? 0 : this.rutaForm.get('clv_devolucion')?.value,
      clv_tarifa: this.rutaForm.get('clv_tarifa')?.value== null ? 1: this.rutaForm.get('clv_tarifa')?.value
    };

    //Se manda llamar el servicio
    this.rutaService.editRutaNormal(3, ruta_enviar).subscribe((resp) => {
      this.resultado = resp;
      setTimeout(() => {
        this.getListaDeRutas();
        this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
        this.cancelarMostrarAgregarRuta();
      }, 800);
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error en <Edicion Ruta>', detail: 'Contacte al Administrador del Sitio' });
    });
  }



  cancelarMostrarAgregarRuta() {
    this.rutaOperacion = {
      clave_ruta: 0,
      idx: 0,
      desc_ruta: '',
      clave_configuracionUnidad: 0,
      id_proyecto: 0,
      id_ciudad: 0,
      id_cliente: 0,
      id_tipo_transporte: 0,
      desc_origen: '',
      desc_destino: '',
      desc_ciudad: '',
      desc_cliente: '',
      fec_registro: '',
      clv_disponible: 0,
      fecha_registro_especial: '',
      clv_especial: 0,
      nombre_responsable: '',
      desc_proyecto: '',
      desc_tipo_transporte: '',
      clv_foranea: 0,
      imp_viaticos_driver: 0,
      imp_viaticos_hospedaje: 0,
      imp_viaticos_auxiliar: 0,
      complemento_ruta: '',
      imp_tienda_adicional: 0,
      num_folio_proyecto: '',
      clv_devolucion: 0,
      clv_tarifa: 1

    };
    this.dspEditarRuta = false;
    //Reiniciar formulario ruta especial
    this.rutaForm.reset();
    this.rutaForm.controls['complemento_ruta'].setValue('');
    this.resetearFormulario();
    this.desc_ruta = '';
    this.dsbAgregarFolio = false;
    this.folioDescRuta = 0 ;
  }


  editarInformacionRuta(num_operacion: number, ruta: IRuta) {

    this.num_operacion = num_operacion;
    this.rutaOperacion = ruta;
    this.rutaForm.controls['ruta'].setValue(ruta.desc_ruta);
    this.rutaForm.controls['complemento_ruta'].setValue(ruta.complemento_ruta);
    this.rutaForm.controls['personal_encargado'].setValue(ruta.nombre_responsable);
    this.rutaForm.controls['selectedProyecto'].setValue(ruta.id_proyecto);
    this.rutaForm.controls['selectedCiudad'].setValue(ruta.id_ciudad);
    this.rutaForm.controls['selectedCliente'].setValue(ruta.id_cliente);
    this.rutaForm.controls['selectedTransporte'].setValue(ruta.id_tipo_transporte);
    this.rutaForm.controls['origen'].setValue(ruta.desc_origen);
    this.rutaForm.controls['destino'].setValue(ruta.desc_destino);
    this.rutaForm.controls['clv_foranea'].setValue(ruta.clv_foranea);
    this.rutaForm.controls['clv_especial'].setValue(ruta.clv_especial);
    this.rutaForm.controls['imp_viaticos_driver'].setValue(ruta.imp_viaticos_driver);
    this.rutaForm.controls['imp_viaticos_hospedaje'].setValue(ruta.imp_viaticos_hospedaje);
    this.rutaForm.controls['imp_viaticos_auxiliar'].setValue(ruta.imp_viaticos_auxiliar);
    this.rutaForm.controls['clave_configuracionUnidad'].setValue(ruta.clave_configuracionUnidad);
    this.rutaForm.controls['fec_registro'].setValue(ruta.fec_registro.substring(0, 10));
    this.rutaForm.controls['imp_tienda_adicional'].setValue(ruta.imp_tienda_adicional);
    this.rutaForm.controls['num_folio_proyecto'].setValue(ruta.num_folio_proyecto);
    this.rutaForm.controls['clv_devolucion'].setValue(ruta.clv_devolucion);
    //agregar tarifa
    this.rutaForm.controls['clv_tarifa'].setValue(ruta.clv_tarifa );
    setTimeout(() => {
      this.dspEditarRuta = true;
    }, 300);
  }


  getListaDeConfUnidad() {
    this.confUnidadService.getListadoConfUnidades().subscribe((resp) => {
      this.lstConfUnidad = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Conf. de Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }



  getListaDeClientes() {
    this.rutaForm.controls['selectedCliente'].disable();
    this.clienteService.getListadoClientes().subscribe((resp) => {
      this.lstClientes = resp;
      this.rutaForm.controls['selectedCliente'].enable();
    },
      (error) => {
        this.rutaForm.controls['selectedCliente'].disable();
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Clientes>', detail: 'Contacte al Administrador del Sitio' });
      });
  }


  getListaDeCiudades() {
    this.rutaForm.controls['selectedCiudad'].disable();
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudades = resp;
      this.rutaForm.controls['selectedCiudad'].enable();
    },
      (error) => {
        this.rutaForm.controls['selectedCiudad'].disable();
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Ciudades>', detail: 'Contacte al Administrador del Sitio' });
      });
  }


  getListaDeProyectos() {
    this.rutaForm.controls['selectedProyecto'].disable();
    this.proyectoService.getListadoProyectos().subscribe((resp) => {
      this.lstProyectos = resp;
      this.rutaForm.controls['selectedProyecto'].enable();
    },
      (error) => {
        this.rutaForm.controls['selectedProyecto'].disable();
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Proyectos>', detail: 'Contacte al Administrador del Sitio' });
      });
  }


  getListaDeTipoTransporte() {
    this.rutaForm.controls['selectedTransporte'].disable();
    this.tipoTransporteService.getListadoTipoTransporte().subscribe((resp) => {
      this.lstTransporte = resp;
      this.rutaForm.controls['selectedTransporte'].enable();
    },
      (error) => {
        this.rutaForm.controls['selectedTransporte'].disable();
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Tipo de Transporte>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  /*DESACTIVAR RUTA*/
  guardarInformacionDesactivarRuta2() {

  }

  exportarExcel() {
    if (!this.lstRutas.length || typeof this.lstRutas === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    } else {
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.lstRutas);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        this.saveAsExcelFile(excelBuffer, "rutas");
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);

  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }







//------------------------------------------------------------------------------
  guardarInformacionFolio() {

    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var fecha_registro_momento = getYear + "-" + getMonth + "-" + getDay;
    let data_enviar : IDataGuardarFolio ={
      idx: 0,
      num_folio_proyecto: this.foliosForm.get('num_folio_proyecto')?.value,
      id_ruta: this.foliosForm.get('id_ruta')?.value,
      cod_usuario: this.user.cod!,
      num_mes: this.foliosForm.get('num_mes')?.value,
      num_anio: this.foliosForm.get('num_anio')?.value,
      fecha_registro: fecha_registro_momento
    };
    this.rutaService.guardarFolioRuta(data_enviar).subscribe((resp)=> {
      let rst_estatus: IResultadoGeneral = {
        clv_estatus: 0,
        messageDetails: ''
      };
      rst_estatus = resp;
      if(rst_estatus.clv_estatus==208){
        this.mostrarDialogoInformativo(rst_estatus.messageDetails);
      } else {
        setTimeout(() => {
          this.getListaDeRutas();
          this.cancelarMostrarAgregarRuta();
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
        },
          800);


      }
    },
    (error)=> {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Guardar Folio del Mes>', detail: 'Contacte al Administrador del Sitio' });
    },
  ()=>{
    if(this.folioDescRuta == 1 ){

      this.guardarInformacionDesactivarRuta(this.folioActivRuta);

    }


  });
  }


  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
  resetearFormulario(){
    this.foliosForm.controls['idx'].setValue(0);
    this.foliosForm.controls['id_ruta'].setValue(0);
    this.foliosForm.controls['num_mes'].setValue(0);
    this.foliosForm.controls['num_anio'].setValue(0);
    this.foliosForm.controls['num_folio_proyecto'].setValue('');
  }

  // habilitarEdicionFolios() {
  //   this.foliosForm.get('num_mes')!.enable();
  //   this.foliosForm.get('num_anio')!.enable();
  //   this.foliosForm.get('num_folio_proyecto')!.enable();
    //this.habilitarTodos();
    //this.habilitar_edicion_costo = false;
  //}

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMes.nombre = mes.nombre;
        this.selectedMes.num_mes = mes.num_mes;
        //
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anio = getYear;
    this.anioReporte1 = getYear;
  }
}
