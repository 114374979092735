import { Component, OnInit } from '@angular/core';
import { IProyeccion } from '../models/proyeccion';

import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { ProyeccionService } from '../services/proyeccion.service';
import { DatePipe } from '@angular/common';
import { IPaoDia } from '../models/paodia';
import { IColumnas } from '../models/columnas';
import { IModelDia } from '../models/modeldia';
import { IProyeccionOriginal } from '../models/proyeccionoriginal';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IRuta } from '../models/ruta';
import { RutaService } from '../services/ruta.service';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IProyecto } from '../models/proyecto';
import { ICiudad } from '../models/ciudad';
import { ICliente } from '../models/cliente';
import { ITipoTransporte } from '../models/tipotransporte';
import { ClienteService } from '../services/cliente.service';
import { CiudadService } from '../services/ciudad.service';
import { ProyectoService } from '../services/proyecto.service';
import { TipotransporteService } from '../services/tipotransporte.service';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
@Component({
  selector: 'app-proyeccion',
  templateUrl: './proyeccion.component.html',
  styleUrls: ['./proyeccion.component.scss']
})
export class ProyeccionComponent implements OnInit {
  lstProyeccion: IProyeccion[] = [];
  lstClonado: IProyeccion[] = [];
  fecha: Date = new Date();
  dias = [
    'Dom',
    'Lun',
    'Mar',
    'Mie',
    'Jue',
    'Vie',
    'Sab',
  ];
  meses = ['','Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiemnbre',
    'Octubre',
    'Noviembre',
    'Diciembre'];
  mes: string = '';

  /*Columnas Dinamicas*/
 /*Columnas Dinamicas*/
 cols: IColumnas[] =[{
  field: '',
  header: '',
  dia: 1,
  mes:0
},
{
field: '',
header: '',
dia: 2,
mes:0
},
{
field: '',
header: '',
dia: 3,
mes:0
},
{
field: '',
header: '',
dia: 4,
mes:0
},
{
field: '',
header: '',
dia: 5,
mes:0
},
{
field: '',
header: '',
dia: 6,
mes:0
},
{
field: '',
header: '',
dia: 7,
mes:0
},
{
field: '',
header: '',
dia: 8,
mes:0
},
{
field: '',
header: '',
dia: 9,
mes:0
},
{
field: '',
header: '',
dia: 10,
mes:0
},
{
field: '',
header: '',
dia: 11,
mes:0
},
{
field: '',
header: '',
dia: 12,
mes:0
},
{
field: '',
header: '',
dia: 13,
mes:0
},
{
field: '',
header: '',
dia: 14,
mes:0
},
{
field: '',
header: '',
dia: 15,
mes:0
},
{
field: '',
header: '',
dia: 16,
mes:0
},
{
field: '',
header: '',
dia: 17,
mes:0
},
{
field: '',
header: '',
dia: 18,
mes:0
},
{
field: '',
header: '',
dia: 19,
mes:0
},
{
field: '',
header: '',
dia: 20,
mes:0
},
{
field: '',
header: '',
dia: 21,
mes:0
},
{
field: '',
header: '',
dia: 22,
mes:0
},
{
field: '',
header: '',
dia: 23,
mes:0
},
{
field: '',
header: '',
dia: 24,
mes:0
},
{
field: '',
header: '',
dia: 25,
mes:0
},
{
field: '',
header: '',
dia: 26,
mes:0
},
{
field: '',
header: '',
dia: 27,
mes:0
},
{
field: '',
header: '',
dia: 28,
mes:0
},
{
field: '',
header: '',
dia: 29,
mes:0
},
{
field: '',
header: '',
dia: 30,
mes:0
}];
  PAO_estimacion_dias: IPaoDia[] = [];
  mes_inicio_local:number=0;
  anio_actual:number=0;
  /*Edicion de Informacion */
  dspEditar: boolean = false;
  model2: IPaoDia = {
    id_ruta: 0,
    id_proyecto: 0,
    id_ciudad: 0,
    id_cliente: 0,
    anio: 0,
    mes: 0,
    dia_estimacion: {
      dia: 0,
      num_unidades_ligero: 0,
      num_unidades_pesado: 0,
    }
  };
  model: IModelDia = {
    id_registro:0,
    dia: 0,
    num_unidades_ligero: 0,
    num_unidades_pesado: 0
  };

  /*Fechas*/
  fecha_inicio_filtro: any = null;
  fecha_fin_filtro: any = null;
  fecha_principal : any = new Date().toLocaleDateString('es-MX',{ year: 'numeric', month: '2-digit', day: '2-digit' }).substring(0,10);
  mostrarbtnFiltro: boolean = false;
  dspmostrarFechaDia: boolean = false;
  fecha_dia_editable: any ='';
  nombre_ruta_Editable:string = '';
  @ViewChild('dt1') dt!: Table;

  /*Modal Agregar*/
  dspAgregarRuta:boolean=false;
  texto_operacion:string = 'Agregar Ruta';
  rutaForm: FormGroup = new FormGroup({
    ruta: new FormControl('', [
      Validators.required
    ]),
    personal_encargado: new FormControl('', [
      Validators.required
    ]),
    selectedCiudad: new FormControl(null, [
      Validators.required
    ]),
    selectedProyecto: new FormControl(null, [
      Validators.required
    ]),
    origen: new FormControl('', [
      Validators.required
    ]),
    destino: new FormControl('', [
      Validators.required
    ]),
    selectedTransporte: new FormControl(null, [
      Validators.required
    ]),
    selectedCliente: new FormControl(null, [
      Validators.required
    ]),
  });
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  }
  lstProyectos: IProyecto[] = [];
  lstCiudades: ICiudad[] = [];
  lstClientes: ICliente[] = [];
  lstTransporte: ITipoTransporte[] = [];
  loading:boolean=false;
  //Registro
  user: ICredencialUsuario = {};
  constructor(private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private proyeccionService: ProyeccionService,
    private datePipe: DatePipe,
    private rutaService: RutaService,
    private clienteService: ClienteService,
    private ciudadService: CiudadService,
    private proyectoService: ProyectoService,
    private tipoTransporteService: TipotransporteService,
    private auth: AuthService) {
      this.cargarInformacionUsuario();
  }
  ngOnInit(): void {
    //this.cargarListadodeProyeccion();
    //this.cargarFechaDelMes();
    this.inicializarFechasFormateadas();
  }
  cargarInformacionUsuario(){
    this.user = this.auth.user;
  }

  inicializarFechasFormateadas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;

    let num_mes:number = +getMonth;
    //Nombre de mes
    this.mes = this.meses[num_mes];

    //asignacion inicial
    this.fecha_inicio_filtro = dateFormat;
    this.fecha_fin_filtro = dateFormat;
  }

  getListaDeClientes() {
    this.clienteService.getListadoClientes().subscribe((resp) => {
      this.lstClientes = resp;
    },
    (error)=> {
      this.messageService.add({ severity: 'error', summary: 'Error en <Consultar Clientes>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  getListaDeCiudades() {
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudades = resp;
    },
    (error)=> {
      this.messageService.add({ severity: 'error', summary: 'Error en <Consultar Ciudades>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  getListaDeProyectos() {
    this.proyectoService.getListadoProyectos().subscribe((resp) => {
      this.lstProyectos = resp;
    },
    (error)=> {
      this.messageService.add({ severity: 'error', summary: 'Error en <Consultar Proyectos>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  getListaDeTipoTransporte() {
    this.tipoTransporteService.getListadoTipoTransporte().subscribe((resp) => {
      this.lstTransporte = resp;
    },
    (error)=> {
      this.messageService.add({ severity: 'error', summary: 'Error en <Consultar Transporte>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargarFechaDelMes() {
    this.mes = this.meses[this.fecha.getMonth()];
    /*this.cols = [
      { field: 'dia01', header: 'Mie [01]' },
      { field: 'dia02', header: 'Jue [02]' },
      { field: 'dia03', header: 'Vie [03]' },
      { field: 'dia04', header: 'Sab [04]' },
      { field: 'dia05', header: 'Dom [05]' },
      { field: 'dia06', header: 'Lun [06]' },
      { field: 'dia07', header: 'Mar [07]' }
    ];*/
  }

  FiltrarFechasyRuta(){
    //Inicio
    let antes_fecha_inicio_filtro: string = this.fecha_inicio_filtro;
    antes_fecha_inicio_filtro.replace("-", "/");
    let prueba1:string = antes_fecha_inicio_filtro.replace("-", "/");
    let prueba2:string = prueba1.replace("-", "/");
    let prueba3:string = prueba2.replace("-", "/");
    let fecha_ini_cast = new Date(prueba3);
    let mes_inicio = fecha_ini_cast.getMonth()+1;
    this.mes_inicio_local=mes_inicio;
    //fin
    let antes_fecha_fin_filtro: string = this.fecha_fin_filtro;
    antes_fecha_inicio_filtro.replace("-", "/");
    let prueba_fin1:string = antes_fecha_fin_filtro.replace("-", "/");
    let prueba_fin2:string = prueba_fin1.replace("-", "/");
    let prueba_fin3:string = prueba_fin2.replace("-", "/");
    let fecha_fin_cast = new Date(prueba_fin3);
    let mes_final = fecha_fin_cast.getMonth()+1;

    this.anio_actual =fecha_ini_cast.getFullYear();
    //Se obtiene el mes de inicio y mes fin para ver si son del mismo mes.

    //Nombre de mes
    this.mes = this.meses[fecha_ini_cast.getMonth()+1];

    /*Validar Fechas*/
    if(this.fecha_fin_filtro<this.fecha_inicio_filtro){
      this.confirmationService.confirm({
        message: 'Fecha Final debe ser Mayor o Igual que Fecha de Inicio',
        header: 'Informativo',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.fecha_fin_filtro = null;
        },
        reject: (type:any) => {
          this.fecha_fin_filtro = null;
        }
    });
    } else if(mes_inicio!=mes_final){
      this.confirmationService.confirm({
        message: 'Rango de Fechas deben ser del mismo MES',
        header: 'Informativo',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.fecha_fin_filtro = null;
        },
        reject: (type:any) => {
          this.fecha_fin_filtro = null;
        }
    });
    } else {
      if(this.fecha_inicio_filtro!=null && this.fecha_fin_filtro!=null){
       //Consultar las Columnas
      this.cargarInformaciondeColumnasFechaFiltro(1);
      }
    }
  }

  cargarInformaciondeColumnasFechaFiltro(num_opcion:number){
    this.loading=true;
    this.proyeccionService.getListadoColumnasRangoFecha(num_opcion,this.fecha_inicio_filtro,this.fecha_fin_filtro).subscribe((resp) => {
      this.cols=resp;
      this.cols = [...this.cols];
      //Llenar Informacion de la Tabla
      this.cargarListadodeProyeccion();
    },
    (error)=>{
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Informacion de Columnas');
    });
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario:string){
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
      },
      key: "sinInformacionDialog"
  });
  }



  cancelarMostrarAgregarRuta(){
    this.dspAgregarRuta=false;
  }


  MostrarInformacionPlaneacionDetalle(id_registro:number,data: IProyeccion, nombre_de_columna: number,fecha:string) {
    //Inicializar Modelo
    this.inicializarModeloDias();
    this.model.id_registro = id_registro;
    this.dspEditar = true;
    this.fecha_dia_editable = fecha.substring(0,10);
    this.nombre_ruta_Editable = data.nombre_ruta;
    this.dspmostrarFechaDia = true;
  }



  realizarActualizacion() {
    const pos = this.lstProyeccion.findIndex(data => (data.id === this.model.id_registro));
    /*Sacar el totalizado */
    let num_total:number = (this.model.num_unidades_ligero + this.model.num_unidades_pesado);
    //Mandar Llamar El servicio actualizar diaN= Cat_Proyeccion_PAO, y Movimientos Mov_Pao_Diario

    //Falta obtener el nombre de columna de un objeto
    this.inicializarModeloDias();
  }

  cargarListadodeProyeccion() {
    let num_opcion: number = 1, mes: number = this.mes_inicio_local, anio: number = this.anio_actual;
    this.proyeccionService.getListadoProyeccionpao(num_opcion, mes,anio).subscribe((resp) => {
      this.lstProyeccion = resp;
      setTimeout(() => {
        this.loading=false;
      }, 1000);
      this.mostrarbtnFiltro = true;
    },
    (error)=> {
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Informacion de Rutas y Programado(PAO)');
    });
  }

inicializarModeloDias(){
  this.model = {id_registro:0,
    dia: 0,
    num_unidades_ligero: 0,
    num_unidades_pesado: 0};
}
  calcularMenuDiasSemanas() {

  }

  agregarRuta() {
    this.rutaForm.reset();
    this.getListaDeClientes();
    this.getListaDeCiudades();
    this.getListaDeProyectos();
    //this.getListaDeConfUnidad();
    this.getListaDeTipoTransporte();
    setTimeout(() => {
      this.dspAgregarRuta = true;
    }, 300);
  }

  onRowEditInit(product: IProyeccion) {
    //this.lstClonado[product.id] = {...product};
  }

  onRowEditSave(product: IProyeccion) {
    let filtro: IProyeccionOriginal = {
      id: product.id,
      id_ruta: product.id_ruta,
      anio: product.anio,
      mes: product.mes,
      dia01: product.dia01,
      dia02: product.dia02,
      dia03: product.dia03,
      dia04: product.dia04,
      dia05: product.dia05,
      dia06: product.dia06,
      dia07: product.dia07,
      dia08: product.dia08,
      dia09: product.dia09,
      dia10: product.dia10,
      dia11: product.dia11,
      dia12: product.dia12,
      dia13: product.dia13,
      dia14: product.dia14,
      dia15: product.dia15,
      dia16: product.dia16,
      dia17: product.dia17,
      dia18: product.dia18,
      dia19: product.dia19,
      dia20: product.dia20,
      dia21: product.dia21,
      dia22: product.dia22,
      dia23: product.dia23,
      dia24: product.dia24,
      dia25: product.dia25,
      dia26: product.dia26,
      dia27: product.dia27,
      dia28: product.dia28,
      dia29: product.dia29,
      dia30: product.dia30,
      dia31: product.dia31
    };
    this.proyeccionService.editPaoPorRegistro(product.id,filtro).subscribe((resp) => {
      this.messageService.add({key:'tl',severity:'success', summary: 'Success', detail:'PAO fue actualizado'});
      //FALTA VOLVER A RECARGAR
      this.cargarInformaciondeColumnasFechaFiltro(1);
    },
    (error)=> {
      this.messageService.add({severity:'error', summary: 'Error', detail:'Error en <guardar PAO>. Contacte al Administrador.'});
    });
  }

  onRowEditCancel(product: IProyeccion, index: number) {

  }

  Limpiar(){
    this.inicializarFechasFormateadas();
    this.cols =[];
    this.lstProyeccion=[];
    this.mostrarbtnFiltro = false;
    this.loading=false;
    //Se limpian los filtros de las tablas
    this.limpiarFiltrosTabla();
  }

  limpiarFiltrosTabla(){
    this.dt.clear();
  }

  clear(table: Table) {
    table.clear();
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }




}
