<p-toast position="top-right" key="msj"></p-toast>
<p-toast position="top-center" key="confirm" (onClose)="cancelarMensajeConfirmacion()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-6">
                    <button type="button" pButton (click)="cancelarMensajeConfirmacion()" label="Cancelar" class="p-button-secondary"></button>
                </div>
                <div class="col-6">
                    <button type="button" pButton (click)="confirmarMensaje()" label="Aceptar" class="p-button-success"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 100%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-map-marker mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">Gestión de Folios de Rutas</header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <button type="button" class="btn btn-success" style="width: 100%;"
                                    (click)="abrirModalReportes()" pTooltip="Abrir Ventana" tooltipPosition="left">
                                    Reportes <i class="pi pi-file-excel"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="lstRutas" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="480px"
                                responsiveLayout="scroll" [globalFilterFields]="['desc_ruta','desc_origen','desc_destino','desc_ciudad','desc_cliente','desc_proyecto','clave_ruta',
                                'nombre_responsable']" [paginator]="true" [rows]="50"
                                [rowsPerPageOptions]="[25,50,75,100,150]" [showCurrentPageReport]="true"
                                [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [loading]="loading" sortField="desc_ciudad">
                                <ng-template pTemplate="caption">
                                    <div class="flex justify-content-end">
                                        <p-columnFilter field="clv_disponible" matchMode="equals" [showMenu]="false">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="lstEstatus"
                                                    optionLabel="nombre" optionValue="id"
                                                    (onChange)="filter($event.value)" placeholder="Filtrar por Estatus"
                                                    [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar Nombre Ruta,Clave,Ciudad" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="clave_ruta" class="text-center">#Ruta <p-sortIcon
                                                field="clave_ruta"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="desc_ruta">Nombre de Ruta <p-sortIcon
                                                field="desc_ruta"></p-sortIcon></th>
                                        <th pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                                                field="desc_ciudad"></p-sortIcon></th>
                                        <th pSortableColumn="desc_proyecto">Servicio<p-sortIcon
                                                field="desc_proyecto"></p-sortIcon></th>
                                        <th pSortableColumn="desc_cliente">Cliente<p-sortIcon
                                                field="desc_cliente"></p-sortIcon></th>
                                        <th pSortableColumn="nombre_responsable">Responsable
                                            <p-sortIcon field="nombre_responsable"></p-sortIcon>
                                        </th>
                                        <th style="width: 150px;"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-ruta>
                                    <tr [ngClass]="{'row-accessories': ruta.clv_disponible ===0}">
                                        <td [ngClass]="{'col-clave':true}">
                                            {{ruta.clave_ruta}}
                                        </td>
                                        <td [ngClass]="{'col-header':true,'col-header-sp': ruta.clv_especial==1}">
                                            {{ruta.desc_ruta}}
                                        </td>
                                        <td>{{ruta.desc_ciudad}}</td>
                                        <td>{{ruta.desc_proyecto}}</td>
                                        <td>{{ruta.desc_cliente}}</td>
                                        <td class="texto_responsable">{{ruta.nombre_responsable}}</td>
                                        <td style="width: 150px;">
                                            <div class="d-flex justify-content-around">
                                                <p-button styleClass="p-button-sm" icon="pi pi-plus"
                                                    pTooltip="Agregar Folio" tooltipPosition="left"
                                                    (click)="mostrarModalAgregarFolio(ruta);"></p-button>
                                                <p-button styleClass="p-button-help p-button-sm" icon="pi pi-cog"
                                                    pTooltip="Folios de Proyecto de Ruta" tooltipPosition="left"
                                                    (click)="gestionarFoliosDeRuta(ruta);"></p-button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <!--<ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-between">
                                        Un total de {{lstRutas ? lstRutas.length : 0 }} Registros.
                                    </div>
                                </ng-template>-->
                            </p-table>
                        </div>
                        <div class="row mt-3 d-flex justify-content-center">
                            <div class="col-6">
                                <div class="mt-3">
                                    <p-colorPicker [(ngModel)]="color_rojo_especial" [disabled]="true"></p-colorPicker>
                                    <p class="text-left informativo" style="display: inline;">Rutas Especiales</p>
                                </div>
                                <div class="mt-3 mb-2">
                                    <p-colorPicker [(ngModel)]="color_gris_deshabilitadas"
                                        [disabled]="true"></p-colorPicker>
                                    <p class="text-left informativo" style="display: inline;">Ruta INACTIVA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--REPORTES-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
            [style]="{width: '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModalReportes()">
            <ng-template pTemplate="header">
                Reportes
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Folio de Proyecto Mensual de Ruta (Mes y Año)</p>
                        </div>
                        <div class="col-md-3">
                            <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*" autoWidth="false"
                                [style]="{'width':'100%'}" [showClear]="true" [(ngModel)]="selectedMesReporte1"
                                tooltip="Es el Mes a consultar" tooltipPosition="left"></p-dropdown>
                            <hr class="my-0">
                        </div>
                        <div class="col-md-3">
                            <p-calendar [(ngModel)]="anioReporte1" [showIcon]="true" view="year" dateFormat="yy"
                                [style]="{'width':'100%'}" placeholder="Seleccione un año*"></p-calendar>
                            <hr class="my-0">
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="generarReporte1()">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-2 text-center">
                            <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte"></i>
                        </div>
                    </div>
                    <hr class="style-two">
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModalReportes();"></button>
            </ng-template>
        </p-dialog>
        <!--AGREGAR DE FOLIOS DE PROYECTO POR RUTA-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbAgregarHerramientas"
            [style]="{width: '50vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModalAgregarFolio()">
            <ng-template pTemplate="header">
                Agregar Folio de Proyecto Mensual- Ruta
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="d-flex align-items-center texto-valido">Ruta:&nbsp;{{desc_ruta}}</p>
                        </div>
                    </div>
                    <form [formGroup]="foliosForm" (ngSubmit)="guardarInformacionFolio()">
                        <span class="title-light mt-3">Información del Folio<i class="pi pi-cog"
                                style="size: 2rem;"></i>
                        </span>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Mes</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*"
                                            autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                                            formControlName="num_mes" tooltip="Es el Mes a registrar"
                                            tooltipPosition="left" optionValue="num_mes"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Año</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <p-calendar formControlName="num_anio" [showIcon]="true" view="year"
                                            dateFormat="yy" [style]="{'width':'100%'}" placeholder="Seleccione un año*"
                                            pTooltip="Seleccione o ingrese el año"></p-calendar>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Folio del Proyecto</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="string" class="form-control sinborde" pTooltip="Folio"
                                            tooltipPosition="top" formControlName="num_folio_proyecto"
                                            style="text-transform:uppercase;">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-4">
                                <p *ngIf="!foliosForm.valid" class="texto-invalido text-right">
                                    <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario
                                        Proporcionar La Informaci&oacute;n Obligatoria(*)</i>
                                </p>
                                <p *ngIf="foliosForm.valid" class="texto-valido text-right">
                                    <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                                        Completa</i>
                                </p>
                            </div>
                            <div class="col-2">
                                <button type="submit" class="btn btn-success w-100"
                                    [disabled]="!foliosForm.valid || habilitar_edicion_costo">
                                    <span class="text-white">Aceptar</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-md-3">
                            <button class="btn btn-warning w-100" (click)="habilitarEdicionFolios();"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModalAgregarFolio();"></button>
            </ng-template>
        </p-dialog>
        <!--HISTORICO DE FOLIOS DE PROYECTO POR RUTA-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbgestionHerramientas"
            [style]="{width: '85vw','height':'630px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModalHistoricoFolios()">
            <ng-template pTemplate="header">
                Gestión de Folios de Proyecto Mensual- Ruta
            </ng-template>
            <ng-template pTemplate="content">
                 <!--TABLA HISTORICO DE FOLIOS-->
                 <div class="row mt-3">
                    <div class="col-md-12">
                        <p-table dataKey="id"  [value]="lstHistorico" [tableStyle]="{ 'min-width': '50rem' }"
                            styleClass="p-datatable-sm" [loading]="loadinglst" [scrollable]="true" scrollHeight="380px">
                            <ng-template pTemplate="caption">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="d-flex align-items-center texto-valido">
                                            Ruta:&nbsp;{{desc_ruta}}
                                        </p>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Mes</th>
                                    <th>Año</th>
                                    <th>Folio</th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data let-index="rowIndex">
                                <tr [pEditableRow]="data">
                                    <td class="color-numeconomico">{{ data.nom_mes }}</td>
                                    <td class="color-sombreado">{{ data.num_anio }}</td>
                                    <td class="color-resaltado">
                                        {{ data.num_folio_proyecto | uppercase }}
                                    <td>
                                        <div class="flex align-items-center justify-content-center gap-2">
                                            <button *ngIf="clv_operacion==3" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                                                (click)="mostrarModalEditarFolio(data)" class="p-button-rounded"
                                                pTooltip="Admin-Editar Folio Mensual"></button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModalHistoricoFolios();"></button>
            </ng-template>
        </p-dialog>
        <!--EDITAR DE FOLIOS DE PROYECTO POR RUTA - ADMIN-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbAgregarHerramientasEditar"
            [style]="{width: '50vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModalEditarFolio()">
            <ng-template pTemplate="header">
                Editar Folio de Proyecto Mensual- Ruta
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="d-flex align-items-center texto-valido">Ruta:&nbsp;{{desc_ruta}}</p>
                        </div>
                    </div>
                    <form [formGroup]="foliosForm" (ngSubmit)="guardarInformacionFolioEditar()">
                        <span class="title-light mt-3">Información del Folio<i class="pi pi-cog"
                                style="size: 2rem;"></i>
                        </span>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Mes</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*"
                                            autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                                            formControlName="num_mes" tooltip="Es el Mes a registrar"
                                            tooltipPosition="left" optionValue="num_mes"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Año</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="num_anio"
                                            style="text-transform:uppercase; font-weight: bold; color: brown;"
                                            pTooltip="Año">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Folio del Proyecto</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="string" class="form-control sinborde" pTooltip="Folio"
                                            tooltipPosition="top" formControlName="num_folio_proyecto"
                                            style="text-transform:uppercase;">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-4">
                                <p *ngIf="!foliosForm.valid" class="texto-invalido text-right">
                                    <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario
                                        Proporcionar La Informaci&oacute;n Obligatoria(*)</i>
                                </p>
                                <p *ngIf="foliosForm.valid" class="texto-valido text-right">
                                    <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                                        Completa</i>
                                </p>
                            </div>
                            <div class="col-2">
                                <button type="submit" class="btn btn-success w-100"
                                    [disabled]="!foliosForm.valid || habilitar_edicion_costo">
                                    <span class="text-white">Aceptar</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-md-3">
                            <button class="btn btn-warning w-100" (click)="habilitarEdicionFolios_historico();"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModalEditarFolio();"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>