<p-toast [style]="{marginTop: '80px'}" position="top-right" key="msj"></p-toast>

<div class="container">
    <!--<div class="row d-flex justify-content-around">
        <div class="col-3">
            <header><i class="pi pi-truck mr-3" style="font-size: 2rem; color: #f3aa0c;"></i>Unidad Transporte</header>
        </div>
        <div class="col-3">
            <button class="btn btn-info form-control" (click)="redireccionarListadoUnidades();">
                <i class="pi pi-arrow-right  mr-2"></i>
                <span>Ver todas las Unidades</span>
            </button>
        </div>
    </div>-->
    <div class="row">
        <div class="col-md-12">
            <div class="card card-shadow sinborde">
                <div class="card-header sinborde" style="background: none; ">
                    <div class="numberCircle rounded">
                        <i class="pi pi-truck mt-3" style="font-size: 2rem; color: #fff;"></i>
                    </div>
                    <header style="left: 11%; top: 5%;">Unidad Transporte</header>
                    <div class="row d-flex justify-content-end">
                        <div class="col-2">
                            <button type="button" class="btn btn-success form-control"
                                (click)="redireccionarNueva();" pTooltip="Redireccion a Todas las Unidades">
                                ver Todas <i class="pi pi-directions"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <!--<div class="card-header card-header-icon">
                    <i class="pi pi-truck material-icons" style="font-size: 2rem; color: #fff;"></i>
                    <header>Unidad Transporte</header>
                </div>-->
                <div class="card-content">
                    <form class="form_unidad">
                        <div>
                            <span class="title-light">Datos Principales <i class="pi pi-cog"
                                    style="size: 2rem;"></i></span>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstTransporte" optionLabel="nombre" autoWidth="false"
                                                [style]="{'width':'100%'}" [(ngModel)]="selectedTransporte"
                                                [ngModelOptions]="{standalone: true}"
                                                placeholder="Seleccione Tipo Transporte*"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstConfUnidad" optionLabel="nombre"
                                                [(ngModel)]="selectedConfUnidad" [ngModelOptions]="{standalone: true}"
                                                autoWidth="false" [style]="{'width':'100%'}"
                                                placeholder="Seleccione Unidad*"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstTipoUnidad" optionLabel="nombre"
                                                [(ngModel)]="selectedTipoUnidad" [ngModelOptions]="{standalone: true}"
                                                autoWidth="false" [style]="{'width':'100%'}"
                                                placeholder="Seleccione TipoUnidad*"
                                                (ngModelChange)="validarCambioTipoUnidad()"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                            <input type="text" class="form-control sinborde ml-2"
                                                placeholder="   Numero Económico*" required>
                                        </div>
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                            <input type="text" class="form-control sinborde" placeholder="Placa*"
                                                required>

                                        </div>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstTipoVerificacion" autoWidth="false"
                                                [style]="{'width':'100%'}" [(ngModel)]="selectedTipoVerificacion"
                                                placeholder="Seleccione Tipo Verificacion*" optionLabel="nombre"
                                                [showClear]="true" [ngModelOptions]="{standalone: true}"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstVerificaciones" autoWidth="false"
                                                [style]="{'width':'100%'}" [(ngModel)]="selectedVerificacion"
                                                placeholder="Seleccione Verificacion*" optionLabel="nombre"
                                                [showClear]="true" [ngModelOptions]="{standalone: true}"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2" *ngIf="selectedTipoUnidad.clave_tipoUnidad==2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstproveedor" optionLabel="nombre"
                                                [(ngModel)]="selectedProveedor" [ngModelOptions]="{standalone: true}"
                                                (onChange)="seleccionarProveedor()" autoWidth="false"
                                                [style]="{'width':'100%'}" placeholder="Seleccione Proveedor*"
                                                [showClear]="true"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <label class="label-header">Fecha Inicio*</label>
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha Inicio de Renta"
                                                tooltipPosition="top">
                                            <hr class="my-0">
                                        </div>
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <label class="label-header">Fecha Fin</label>
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha fin de Renta" tooltipPosition="top">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span class="title-light">Generales y Caracteristicas <i class="pi pi-check-square"
                                    style="size: 2rem;"></i></span>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde"
                                            placeholder="Tarjeta de Circulación" required>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="VIN" required>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Marca" required>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Modelo" required>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Año" required>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Color" required>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Poliza de Seguro"
                                            required>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex justify-content-around">
                                        <label class="label-header">Fecha Inicio*</label>
                                        <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha Inicio de Poliza" tooltipPosition="top">
                                    </div>
                                    <div class="col-sm-6 d-flex justify-content-around">
                                        <label class="label-header">Fecha Fin*</label>
                                        <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha Fin de Poliza" tooltipPosition="top">
                                    </div>

                                    <hr class="my-0">
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Aseguradora"
                                            required>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Permiso Carga"
                                            required>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Tipo de Caja"
                                            required>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstCombustible" autoWidth="false"
                                            [style]="{'width':'100%'}" [(ngModel)]="selectedCombustible"
                                            placeholder="Seleccione Tipo de Combustible" optionLabel="nombre"
                                            [showClear]="true" [ngModelOptions]="{standalone: true}"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstCiudades" autoWidth="false" [style]="{'width':'100%'}"
                                            [(ngModel)]="selectedCiudadDestino" placeholder="Seleccione Ciudad Destino"
                                            optionLabel="nombre" [showClear]="true"
                                            [ngModelOptions]="{standalone: true}"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstCiudades" autoWidth="false" [style]="{'width':'100%'}"
                                            [(ngModel)]="selectedCiudadOrigen" placeholder="Seleccione Ciudad Origen"
                                            optionLabel="nombre" [showClear]="true"
                                            [ngModelOptions]="{standalone: true}"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span class="title-light">Medidas de Seguridad <i class="pi pi-lock"
                                    style="size: 2rem;"></i></span>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1"><span class="input-group-addon"> <i
                                                    class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                            </span></div>
                                        <div class="col-sm-11">
                                            <input type="text" placeholder="Numero GPS" class="form-control sinborde">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1"><span class="input-group-addon"> <i
                                                    class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                            </span></div>
                                        <div class="col-sm-11">
                                            <input type="text" placeholder="Nombre GPS" class="form-control sinborde">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-1"><span class="input-group-addon"> <i
                                                    class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                            </span></div>
                                        <div class="col-sm-11">
                                            <input type="text" placeholder="#Camara" class="form-control sinborde">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-1"><span class="input-group-addon"> <i
                                                    class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                            </span></div>
                                        <div class="col-sm-11">
                                            <input type="text" placeholder="#Boton Panico"
                                                class="form-control sinborde">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-1"><span class="input-group-addon"> <i
                                                    class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                            </span></div>
                                        <div class="col-sm-11">
                                            <input type="text" placeholder="#Numero Inmovilizador"
                                                class="form-control sinborde">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-end">
                                <div class="col-2">
                                    <button class="btn btn-warning form-control">
                                        <span class="text-white">Limpiar</span>
                                        <i class="pi pi-times text-white mr-2"></i>
                                    </button>
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary form-control" (click)="validarFormulario()">
                                        <span class="text-white">Guardar</span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div> <!--End row form-->

</div>