<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
  rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 100%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-map-marker mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">Listado de Rutas</header>
            <div class="row d-flex justify-content-end">
              <div class="col-2">
                <button type="button" class="btn btn-primary form-control" (click)="redireccionarNueva();"
                  pTooltip="ir a Nueva Ruta" tooltipPosition="top">
                  Agregar <i class="pi pi-plus-circle"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="row mt-3">
              <p-table #dt1 [value]="lstRutas" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm"
                [scrollable]="true" scrollHeight="480px" responsiveLayout="scroll" [globalFilterFields]="['desc_ruta','desc_origen','desc_destino','desc_ciudad','desc_cliente','desc_proyecto','clave_ruta',
                                'nombre_responsable']" [paginator]="true" [rows]="50"
                [rowsPerPageOptions]="[25,50,75,100,150]" [showCurrentPageReport]="true" [resizableColumns]="false"
                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading"
                sortField="desc_ciudad">
                <ng-template pTemplate="caption">
                  <div class="flex justify-content-start mb-3">
                    <button type="button" pButton pRipple class="p-button-success ml-5"
                      pTooltip="Exportar Catalogo a EXCEL" tooltipPosition="right" (click)="exportarExcel();">
                      <i class="pi pi-file-excel">Exportar</i>
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                        <p-columnFilter field="clv_disponible" matchMode="equals" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="lstEstatus" optionLabel="nombre" optionValue="id"
                                    (onChange)="filter($event.value)" placeholder="Filtrar por Estatus" [showClear]="true">
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                    <div class="col-md-3">
                        <p-columnFilter field="clv_especial" matchMode="equals" [showMenu]="false"
                            tooltip="Filtrar las Unidades ACTIVAS/INACTIVAS" tooltipPosition="top">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="lstEstatusEspecial" optionLabel="nombre"
                                    optionValue="id" (onChange)="filter($event.value)" placeholder="Filtrar por ESPECIAL"
                                    [showClear]="true">
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                    <div class="col-md-3">
                        <p-columnFilter field="clv_foranea" matchMode="equals" [showMenu]="false"
                            tooltip="Filtrar las Unidades FORANEAS/LOCALES" tooltipPosition="top">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="lstForanea" optionLabel="nombre" optionValue="id"
                                    (onChange)="filter($event.value)" placeholder="Filtrar por FORANEA" [showClear]="true">
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                    <!-- checar el tamaño del imput -->
                    <div class="col-md-auto">
                       <span class="p-input-icon-left">
                          <i class="pi pi-search" style="margin-left: 5px;"></i>
                          <input pInputText  type="text"
                            (input)="applyFilterGlobal($event, 'contains')"
                             placeholder="Buscar"
                            style="margin-left: 5px;" />
                        </span>
                    </div>
                </div>




                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="clave_ruta" class="text-center">#Ruta <p-sortIcon
                        field="clave_ruta"></p-sortIcon>
                    </th>
                    <th pSortableColumn="desc_ruta">Nombre de Ruta <p-sortIcon field="desc_ruta"></p-sortIcon></th>
                    <th pSortableColumn="fecha_registro_especial_cast">Fecha Especial<p-sortIcon
                        field="fecha_registro_especial_cast"></p-sortIcon>
                    </th>
                    <th>Origen</th>
                    <th>Destino</th>
                    <th pSortableColumn="desc_ciudad">Ciudad<p-sortIcon field="desc_ciudad"></p-sortIcon></th>
                    <th pSortableColumn="desc_proyecto">Servicio<p-sortIcon field="desc_proyecto"></p-sortIcon></th>
                    <th pSortableColumn="desc_cliente">Cliente<p-sortIcon field="desc_cliente"></p-sortIcon></th>
                    <th pSortableColumn="nombre_responsable">Responsable
                      <p-sortIcon field="nombre_responsable"></p-sortIcon>
                    </th>
                    <th style="width: 150px;"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ruta>
                  <tr [ngClass]="{'row-accessories': ruta.clv_disponible ===0}">
                    <td [ngClass]="{'col-clave':true}">
                      {{ruta.clave_ruta}}
                    </td>
                    <td [ngClass]="{'col-header':true,'col-header-sp': ruta.clv_especial==1}">
                      {{ruta.desc_ruta}}
                    </td>
                    <td class="texto_responsable" [ngClass]="{'col-header-sp': ruta.clv_especial==1}">
                      <p *ngIf="ruta.clv_especial==1" style="display: inline;"
                        pTooltip="Fecha de Operacion de la Ruta Especial" tooltipPosition="top">
                        {{ruta.fecha_registro_especial_cast}}</p>
                    </td>
                    <td>{{ruta.desc_origen}}</td>
                    <td>{{ruta.desc_destino}}</td>
                    <td>{{ruta.desc_ciudad}}</td>
                    <td>{{ruta.desc_proyecto}}</td>
                    <td>{{ruta.desc_cliente}}</td>
                    <td class="texto_responsable">{{ruta.nombre_responsable}}</td>
                    <td style="width: 150px;">
                      <div class="d-flex justify-content-around">
                        <button class="btn btn-warning" pTooltip="Editar Informacion de Ruta" tooltipPosition="left"
                          (click)="editarInformacionRuta(2,ruta)">
                          <i class="pi pi-pencil"></i>
                        </button>
                        <button class="btn btn-info" pTooltip="Cambiar Estatus de Ruta" tooltipPosition="left"
                          (click)="confirmarDesactivar(ruta.clave_ruta,ruta);">
                          <i class="pi pi-delete-left"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <!--<ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-between">
                                        Un total de {{lstRutas ? lstRutas.length : 0 }} Registros.
                                    </div>
                                </ng-template>-->
              </p-table>
            </div>
            <div class="row mt-3 d-flex justify-content-center">
              <div class="col-6">
                <div class="mt-3">
                  <p-colorPicker [(ngModel)]="color_rojo_especial" [disabled]="true"></p-colorPicker>
                  <p class="text-left informativo" style="display: inline;">Rutas Especiales</p>
                </div>
                <div class="mt-3 mb-2">
                  <p-colorPicker [(ngModel)]="color_gris_deshabilitadas" [disabled]="true"></p-colorPicker>
                  <p class="text-left informativo" style="display: inline;">Ruta INACTIVA</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- ITZAEL -->
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspEditarRuta"
    [style]="{width: '85vw','height':'700px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
    <ng-template pTemplate="header">
      Edici&oacute;n de Ruta
    </ng-template>
    <ng-template pTemplate="content">
      <div class="mt-5">
        <div class="card-content card-shadow sinborde">
          <form class="form_ruta" [formGroup]="rutaForm" (ngSubmit)="guardarInformacionRuta()">
            <div class="ml-1">
              <span class="title-light">Informacion de la Ruta <i class="pi pi-cog" style="size: 2rem;"></i></span>
              <div class="row mt-3">
                <div class="form-group col-md-3">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <p-dropdown [options]="lstTipoRuta" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="SELECCIONE TIPO DE RUTA*" formControlName="clv_especial" optionLabel="nombre"
                        [showClear]="true" optionValue="id" tooltip="Ruta Normal o Especial"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <!-- CAMBIOS ITZAEL SE AGREGA UN NUEVO INPUT----------------------------- -->
                <div class="form-group col-md-3">
                  <div class="row g-0">
                    <div class="col-sm-3 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">Tarifa</p>
                    </div>
                    <div class="col-sm-9">
                      <p-dropdown [options]="lstTarifa" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="Tarifa" formControlName="clv_tarifa" optionLabel="desc_tarifa"
                        [showClear]="true" optionValue="clv_tarifa" tooltip="Tipo Tarifa"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <div class="row g-0">
                    <div class="col-sm-7 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">¿Devolución?</p>
                    </div>
                    <div class="col-sm-5">
                      <p-dropdown [options]="lstSituacion" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="Devolucion (SI/NO)*" formControlName="clv_devolucion" optionLabel="nombre"
                        [showClear]="true" optionValue="id" tooltip="Devolucion"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <div class="row g-0">
                    <div class="col-sm-2 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-10">
                      <p-dropdown [options]="lstConfUnidad" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="SELECCIONE TIPO DE CONF.UNIDAD*" formControlName="clave_configuracionUnidad"
                        optionLabel="nombre" [showClear]="true" optionValue="clave_configuracionUnidad"
                        tooltip="Configuración de Unidad"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 ">
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-book color-icon" style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <input type="text" class="form-control sinborde" placeholder="Complemento de Ruta*"
                        formControlName="complemento_ruta" style="text-transform:uppercase;"
                        pTooltip="Comentario Complementario">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-user color-icon" style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <input type="text" class="form-control sinborde" placeholder="Encargado*"
                        formControlName="personal_encargado" required style="text-transform:uppercase;">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <p-dropdown [options]="lstCiudades" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="Seleccione Ciudad*" formControlName="selectedCiudad" optionLabel="nombre"
                        [showClear]="true" optionValue="id"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <p-dropdown [options]="lstProyectos" optionLabel="nombre" formControlName="selectedProyecto"
                        autoWidth="false" [style]="{'width':'100%'}" placeholder="Seleccione Servicio*"
                        optionValue="id_proyecto"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-book color-icon" style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <input type="text" class="form-control sinborde" placeholder="Origen*" formControlName="origen"
                        required style="text-transform:uppercase;">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-book color-icon" style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <input type="text" class="form-control sinborde" placeholder="Destino*" formControlName="destino"
                        required style="text-transform:uppercase;">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <p-dropdown [options]="lstTransporte" optionLabel="nombre" formControlName="selectedTransporte"
                        placeholder="Seleccione Tipo Transporte*" autoWidth="false" [style]="{'width':'100%'}"
                        optionValue="clave_tipoTransporte"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <p-dropdown [options]="lstClientes" optionLabel="nombre" formControlName="selectedCliente"
                        placeholder="Seleccione Cliente*" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="Seleccione Cliente*" optionValue="id_cliente"></p-dropdown>

                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <!--Activa desde-->
              <div class="row mt-1">
                <div class="form-group col-md-4">
                  <div class="row g-0">
                    <div class="col-sm-6 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-calendar color-icon" style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">Inicio de Ruta
                        desde</p>
                    </div>
                    <div class="col-sm-6">
                      <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha"
                        tooltipPosition="top" formControlName="fec_registro">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <!--Tienda Adicional-->
                <div class="form-group col-md-4">
                  <div class="row g-0">
                    <div class="col-sm-6 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-dollar color-icon" style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">$TiendaAdicional
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <input type="number" class="form-control sinborde" pTooltip="Importe" tooltipPosition="top"
                        formControlName="imp_tienda_adicional">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <!--Numero de Folio de Proyecto-->
                <div class="form-group col-md-4">
                  <div class="row g-0">
                    <div class="col-sm-5 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-book color-icon" style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">Folio de
                        Proyecto</p>
                    </div>
                    <div class="col-sm-7">
                      <input type="string" class="form-control sinborde" pTooltip="Ultimo Folio del Mes"
                        tooltipPosition="top" formControlName="num_folio_proyecto" style="text-transform:uppercase;">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <!--RUTA FORANEA-->
              <div class="row">
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-4 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-check-square color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">¿Es Foranea?</p>
                    </div>
                    <div class="col-sm-8">
                      <p-dropdown [options]="lstSituacion" optionLabel="nombre" formControlName="clv_foranea"
                        placeholder="Seleccione SI O NO*" autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                        tooltip="Seleccionar si es Ruta Foranea o NO"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="dsb_foranea" class="mt-3">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <p class="text-center texto-valido">Viatico de Driver</p>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <p class="text-center texto-valido">Viatico de Hospedaje</p>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <p class="text-center texto-valido">Viatico de Auxiliar</p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                            style="font-size: 1.5rem"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <input type="number" class="form-control" style="box-shadow: 10px 5px 5px #7db2e1;"
                          placeholder="Viatico Driver*" formControlName="imp_viaticos_driver">
                        <hr class="my-0">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                            style="font-size: 1.5rem"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <input type="number" class="form-control" style="box-shadow: 10px 5px 5px #7db2e1;"
                          placeholder="Viatico Hospedaje*" formControlName="imp_viaticos_hospedaje">
                        <hr class="my-0">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                            style="font-size: 1.5rem"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <input type="number" class="form-control" style="box-shadow: 10px 5px 5px #7db2e1;"
                          placeholder="Viatico Auxiliar*" formControlName="imp_viaticos_auxiliar">
                        <hr class="my-0">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--FIN RUTA FORANEA-->
              <div class="row d-flex justify-content-center mt-3">
                <div class="col-4">
                  <p *ngIf="!rutaForm.valid" class="texto-invalido text-right">
                    <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario Proporcionar
                      La Informaci&oacute;n Obligatoria(*)</i>
                  </p>
                  <p *ngIf="rutaForm.valid" class="texto-valido text-right">
                    <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                      Completa</i>
                  </p>
                </div>
                <div class="col-2">
                  <button class="btn btn-primary form-control" type="submit" [disabled]="!rutaForm.valid">
                    <span class="text-white">Guardar</span>
                    <i class="pi pi-save text-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarMostrarAgregarRuta()"></button>
    </ng-template>
  </p-dialog>
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDesativarRuta"
    [style]="{width: '50vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
    <ng-template pTemplate="header">
      Edici&oacute;n de Ruta
    </ng-template>
    <ng-template pTemplate="content">
      <div class="mt-5">
        <div class="card-content card-shadow sinborde">
          <form class="form_ruta" [formGroup]="DesarutaForm" (ngSubmit)="guardarInformacionDesactivarRuta2()">
            <div class="ml-1">
              <span class="title-light">Informacion de la Ruta <i class="pi pi-cog" style="size: 2rem;"></i></span>
              <div class="row mt-3">
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <p-dropdown [options]="lstCiudades" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="Seleccione Ciudad*" formControlName="selectedCiudad" optionLabel="nombre"
                        [showClear]="true" optionValue="id"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center mt-3">
                <div class="col-2">
                  <button class="btn btn-primary form-control" type="submit" [disabled]="!DesarutaForm.valid">
                    <span class="text-white">Guardar</span>
                    <i class="pi pi-save text-white"></i>
                  </button>
                </div>
                <div class="col-4" *ngIf="!DesarutaForm.valid">
                  <span style="color:red">Es necesario llenar todos los campos..</span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarMostrarAgregarRuta()"></button>
    </ng-template>
  </p-dialog>

<!-- dialog para agregar folio  -->

  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbAgregarFolio"
  [style]="{width: '50vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
  (onHide)="cancelarMostrarAgregarRuta()">
  <ng-template pTemplate="header">
      Agregar Folio de Proyecto Mensual- Ruta
  </ng-template>
  <ng-template pTemplate="content">
      <div class="mt-5">
          <div class="row">
              <div class="col-md-12">
                  <p class="d-flex align-items-center texto-valido">Ruta:&nbsp;{{desc_ruta}}</p>
              </div>
          </div>
          <form [formGroup]="foliosForm" (ngSubmit)="guardarInformacionFolio()">
              <span class="title-light mt-3">Información del Folio<i class="pi pi-cog"
                      style="size: 2rem;"></i>
              </span>
              <div class="row mt-3">
                  <div class="form-group col-md-6">
                      <div class="row g-0">
                          <div class="col-sm-6 d-flex align-items-center">
                              <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                      style="font-size: 1.5rem"></i>
                                  <p class="ml-1 color-icon d-inline">Mes</p>
                              </span>
                          </div>
                          <div class="col-sm-6">
                              <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*"
                                  autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                                  formControlName="num_mes" tooltip="Es el Mes a registrar"
                                  tooltipPosition="left" optionValue="num_mes"></p-dropdown>
                              <hr class="my-0">
                          </div>
                      </div>
                  </div>
                  <div class="form-group col-md-6">
                      <div class="row g-0">
                          <div class="col-sm-6 d-flex align-items-center">
                              <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                      style="font-size: 1.5rem"></i>
                                  <p class="ml-1 color-icon d-inline">Año</p>
                              </span>
                          </div>
                          <div class="col-sm-6">
                              <p-calendar formControlName="num_anio" [showIcon]="true" view="year"
                                  dateFormat="yy" [style]="{'width':'100%'}" placeholder="Seleccione un año*"
                                  pTooltip="Seleccione o ingrese el año"></p-calendar>
                              <hr class="my-0">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row mt-3">
                  <div class="form-group col-md-6">
                      <div class="row g-0">
                          <div class="col-sm-6 d-flex align-items-center">
                              <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                      style="font-size: 1.5rem"></i>
                                  <p class="ml-1 color-icon d-inline">Folio del Proyecto</p>
                              </span>
                          </div>
                          <div class="col-sm-6">
                              <input type="string" class="form-control sinborde" pTooltip="Folio"
                                  tooltipPosition="top" formControlName="num_folio_proyecto"
                                  style="text-transform:uppercase;">
                              <hr class="my-0">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row d-flex justify-content-center mt-5">
                  <div class="col-4">
                      <p *ngIf="!foliosForm.valid" class="texto-invalido text-right">
                          <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario
                              Proporcionar La Informaci&oacute;n Obligatoria(*)</i>
                      </p>
                      <p *ngIf="foliosForm.valid" class="texto-valido text-right">
                          <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                              Completa</i>
                      </p>
                  </div>
                  <div class="col-2">
                      <button type="submit" class="btn btn-success w-100"
                          [disabled]="!foliosForm.valid ">
                          <span class="text-white">Aceptar</span>
                          <i class="pi pi-save text-white"></i>
                      </button>
                  </div>
              </div>
          </form>
          <div class="row d-flex justify-content-end mb-5">
              <div class="col-md-3">
                  <!-- <button class="btn btn-warning w-100" (click)="habilitarEdicionFolios();"
                      pTooltip="Habilitar campos para actualizar Información.">
                      <span>Habilitar Edición</span>
                      <i class="pi pi-eraser"></i>
                  </button> -->
              </div>
          </div>
      </div>
  </ng-template>
  <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarMostrarAgregarRuta();"></button>
  </ng-template>
</p-dialog>
</div>
