import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { NavigationCancel, NavigationEnd, NavigationStart,NavigationError, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SGDHM';
  displayLoading:boolean = false;
  constructor(private primengConfig: PrimeNGConfig, private router: Router){
    this.router.events.subscribe((event : any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.displayLoading = true;
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:  
        case event instanceof NavigationEnd: {
          this.displayLoading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }


  ngOnInit(){
    this.primengConfig.ripple = true;
  }
  
}
