<p-toast position="top-right" key="msj"></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000"
acceptLabel="Aceptar" rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-box mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">Registro de PAO
                            <div *ngIf="mes.length > 0" style="color:blueviolet; display: inline;">[{{mes}}]</div>
                        </header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-10">
                                <div class="form-group row mt-5">
                                    <div class="col-5">
                                        <label for="lblligero" class="col-sm-4 col-form-label color-font text-right" style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                            <span class="input-group-addon"> <i class="pi pi-calendar color-icon mr-3"
                                                    style="font-size: 2rem; color: #3B82F6;"></i>
                                            </span>
                                            Fecha Inicio</label>
                                        <div class="col-sm-8">
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha Inicio"
                                                tooltipPosition="top"
                                                [(ngModel)]="fecha_inicio_filtro">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <label for="lblligero" class="col-sm-4 col-form-label color-font text-right" style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                            <span class="input-group-addon"> <i class="pi pi-calendar color-icon mr-3"
                                                    style="font-size: 2rem;color: #3B82F6;"></i>
                                            </span>
                                            Fecha Fin</label>
                                        <div class="col-sm-8">
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha Fin"
                                                tooltipPosition="top"
                                                [(ngModel)]="fecha_fin_filtro">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <button pButton pRipple type="button" (click)="FiltrarFechasyRuta();"
                                         label="Filtrar"  icon="pi pi-filter"
                                         iconPos="left"
                                         ></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                    <button  *ngIf="mostrarbtnFiltro" pButton pRipple type="button" (click)="Limpiar();"
                                    label="Limpiar Filtro" class="p-button-raised p-button-warning mt-3" icon="pi pi-filter-slash"
                                    iconPos="left"
                                    pTooltip="Reiniciar Filtro"
                                    tooltipPosition="left"></button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="lstProyeccion" dataKey="id" editMode="row" 
                                responsiveLayout="scroll"
                                [scrollable]="true" scrollDirection="both"
                                scrollHeight="800px" [style]="{'min-width': '50rem'}" [columns]="cols"
                                styleClass="p-datatable-sm"
                                [paginator]="true" [rows]="150" [rowsPerPageOptions]="[150,200,300,500]"
                                [showCurrentPageReport]="true" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [globalFilterFields]="['nombre_ciudad','nombre_cliente','nombre_proyecto','nombre_ruta','nombre_tipo_transporte']"
                                [loading]="loading">
                                <ng-template pTemplate="caption">
                                    <!--<div class="flex flex-row-reverse flex-wrap">
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar" />
                                        </span>
                                    </div>-->
                                    <div class="row d-flex justify-content-end mt-5 mb-5">
                                        <div class="col-md-3">
                                            <p-columnFilter type="text" field="nombre_ciudad"  placeholder="Ciudad" class="form-control sinborde filtro"></p-columnFilter>
                                        </div>
                                        <div class="col-md-3">
                                            <p-columnFilter type="text" field="nombre_cliente" placeholder="Cliente" class="form-control sinborde filtro"></p-columnFilter>
                                        </div>
                                        <div class="col-md-3">
                                            <p-columnFilter type="text" field="nombre_proyecto" placeholder="Proyecto" class="form-control sinborde filtro"></p-columnFilter>
                                        </div>
                                        <div class="col-md-3">
                                            <p-columnFilter type="text" field="nombre_ruta" placeholder="Ruta" class="form-control sinborde filtro"></p-columnFilter>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                   <tr>
                                        <th pFrozenColumn  pSortableColumn="nombre_ciudad" style="min-width:200px ;max-width: 200px;"
                                        [ngClass]="{'texto_sm':true}">Ciudad
                                            <p-sortIcon
                                            field="nombre_ciudad"></p-sortIcon>
                                        </th>
                                        <th pFrozenColumn  pSortableColumn="nombre_cliente" style="min-width:200px ;max-width: 200px;"
                                        [ngClass]="{'texto_sm':true}">Cliente
                                            <p-sortIcon
                                            field="nombre_cliente"></p-sortIcon>
                                        </th>
                                        <th pFrozenColumn [frozen]="true" pSortableColumn="nombre_proyecto" style="min-width:200px ;max-width: 200px;"
                                        [ngClass]="{'texto_sm':true}">Proyecto
                                            <p-sortIcon
                                            field="nombre_proyecto"></p-sortIcon>
                                        </th>
                                        <th pFrozenColumn [frozen]="true" pSortableColumn="nombre_ruta" style="min-width:200px ;max-width:200px!important;"
                                        [ngClass]="{'texto_sm':true}">Ruta
                                            <p-sortIcon
                                            field="nombre_ruta"></p-sortIcon>
                                        </th>
                                        <th *ngFor="let col of cols" style="min-width:70px; max-width: 70px;" 
                                        [ngClass]="{'texto_sm':true}">
                                            {{ col.header }}
                                        </th>
                                        <th style="min-width:100px; max-width: 100px;"></th>
                                    </tr>
                                     <!--<tr>
                                        <th>
                                            <p-columnFilter type="text" field="nombre_ciudad"></p-columnFilter>
                                        </th>
                                        <th>
                                            <p-columnFilter type="text" field="nombre_cliente"></p-columnFilter>
                                        </th>
                                        <th>
                                            <p-columnFilter type="text" field="nombre_proyecto"></p-columnFilter>
                                        </th>
                                        <th>
                                            <p-columnFilter type="text" field="nombre_ruta"></p-columnFilter>
                                        </th>
                                        <th *ngFor="let col of cols" style="min-width:70px; max-width: 70px;" 
                                        [ngClass]="{'texto_sm':true}">
                                        </th>
                                        <th style="min-width:100px; max-width: 100px;"></th>
                                    </tr>-->
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr>
                                        <td  [ngClass]="{'col-header':true,'ajustarcelda':true,'texto_sm':true}" pFrozenColumn
                                        [frozen]="true" style="min-width:200px ;max-width: 200px;">
                                            {{rowData.nombre_ciudad}}
                                        </td>
                                        <td pFrozenColumn [ngClass]="{'ajustarcelda':true,'texto_sm':true}"
                                        [frozen]="true" style="min-width:200px ;max-width: 200px;">
                                            {{rowData.nombre_cliente}}
                                        </td>
                                        <td pFrozenColumn [ngClass]="{'ajustarcelda':true,'texto_sm':true}"
                                        [frozen]="true" style="min-width:200px ;max-width: 200px;">
                                            {{rowData.nombre_proyecto}}
                                        </td>
                                        <td  [ngClass]="{'col-header':true,'celdaAsignado':true,'texto_sm':true}" pFrozenColumn
                                        [frozen]="true" style="min-width:200px ;max-width: 200px !important;">
                                            {{rowData.nombre_ruta}} - <div style="color:rgb(218, 164, 3); display: inline;">{{rowData.nombre_tipo_transporte}}</div>
                                        </td>
                                        <td *ngFor="let col of columns" style="min-width:70px; max-width: 70px;" 
                                        [ngClass]="{'ajustarcelda':true,'texto_sm':true}"
                                        pTooltip="{{col.header}}" tooltipPosition="top"
                                            pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="number" [(ngModel)]="rowData[col.field]"
                                                    [ngClass]="{'input_edit':true}">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{rowData[col.field]}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align:center; min-width:100px;max-width: 100px;">
                                                <button pButton pRipple type="button" label="Guardar" class="p-button-sm p-button-success"
                                                icon="pi pi-save"
                                                (click)="onRowEditSave(rowData)"
                                                pTooltip="Guardar Informacion de Programado" tooltipPosition="top"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--EDICION DE INFORMACION-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspEditar" [style]="{width: '50vw'}"
            [transitionOptions]="'0ms'" styleClass="card-shadow">
            <ng-template pTemplate="header">
                Editar Informacion 
                <div *ngIf="dspmostrarFechaDia" style="color:black;">
                   {{nombre_ruta_Editable }}  | {{fecha_dia_editable}}
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <form (ngSubmit)="realizarActualizacion()" #uniForm="ngForm">
                    <div class="form-group row mt-5">
                        <label for="lblligero" class="col-sm-4 col-form-label color-font text-right">
                            <span class="input-group-addon"> <i class="pi pi-truck color-icon mr-3"
                                    style="font-size: 2rem"></i>
                            </span>
                            LIGERO</label>
                        <div class="col-sm-8">
                            <input type="number" class="form-control sinborde" id="num_unidades_ligero"
                                placeholder="Ingrese Numero de Unidades" [(ngModel)]="model.num_unidades_ligero"
                                #num_unidades_ligero="ngModel" name="num_unidades_ligero"
                                pTooltip="LIGERO: Ingrese Numero de Unidades" tooltipPosition="right" min="0" step="1"
                                required="">
                            <hr class="my-0">
                        </div>
                    </div>
                    <div class="form-group row mt-5">
                        <label for="lblpesado" class="col-sm-4 col-form-label color-font text-right">
                            <span class="input-group-addon"> <i class="pi pi-truck color-icon mr-3"
                                    style="font-size: 2rem"></i>
                            </span>
                            PESADO</label>
                        <div class="col-sm-8">
                            <input type="number" class="form-control sinborde" id="num_unidades_pesado"
                                placeholder="Ingrese Numero de Unidades" [(ngModel)]="model.num_unidades_pesado"
                                #num_unidades_pesado="ngModel" name="num_unidades_pesado"
                                pTooltip="PESADO: Ingrese Numero de Unidades" tooltipPosition="right" min="0" step="1">
                            <hr class="my-0">
                        </div>
                    </div>
                    <div class="mt-2 d-flex justify-content-center">
                        <button type="submit" class="btn btn-md btn-primary"
                            [disabled]="!uniForm.form.valid">Aceptar</button>
                    </div>
                </form>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="dspEditar=false"></button>
            </ng-template>

        </p-dialog>
    </div>
</div>