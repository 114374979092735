import { IChoferDisponibleManiobras } from './../models/choferdisponible_maniobras';
import { Component, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import ICredencialUsuario from '../models/credencial';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { AuthService } from '../services/auth.service';
import { MovFechaService } from '../services/movfecha.service';
import { ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import { ChoferService } from '../services/chofer.service';
import { IChoferManiobras } from '../models/chofer_maniobras';
import { IGuardarManiobras } from '../models/guardar_maniobras';
import { IEnviarTienda } from '../models/enviar_tienda';
import { IEnviarExtension } from '../models/enviar_extension';
import { ExtraService } from '../services/extra.service';
import { IMovimientosFechaExtra } from '../models/movimientos_extra';
import { IJsonManiobras } from '../models/json_maniobras';
import { ICalculoExtension } from '../models/calculoextension';
import { IEnviarRescate } from '../models/enviar_rescate';
import { ItiposValidacion } from '../models/tipos_validacion';
import { IDataEnviarGestion } from '../models/data_enviar_gestion';
import { IrptMovDiarioFechaGestion } from '../models/rpt_MovDiarioFechaGestion';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-movextras',
  templateUrl: './movextras.component.html',
  styleUrls: ['./movextras.component.scss']
})
export class MovextrasComponent {
  @ViewChild('dt1') dt!: Table;
  lstMovimientos: IMovimientosFechaExtra[] = [];
  // reportes
 lstMovimientosReporte : IMovimientosFechaExtra[] = [];
 lstMovimientosReporte_cast : IrptMovDiarioFechaGestion[]=[];
 fecha_inicio_r1: any = null;
 fecha_fin_r1: any = null;
 btngenerandoReporte:boolean=false;
  // fin reportes
  loading: boolean = false;
  fecha_dia: any = null;
  mostrarbtnLimpiarFiltro: boolean = false;
  user: ICredencialUsuario = {};
  dspManiobra: boolean = false;
  dspTiendaAdicional: boolean = false;
  dspExtension: boolean = false;
  extensionForm: FormGroup = new FormGroup({
    id_pao_diario_unidades: new FormControl(0, [
      Validators.required
    ]),
    imp_tab_total_extra_driver: new FormControl(0, [
      Validators.required
    ]),
    imp_tab_total_extra_auxiliar: new FormControl(0, [
      Validators.required
    ]),
    imp_viaticos_extra_driver: new FormControl(0, [
      Validators.required
    ]),
    imp_viaticos_extra_auxiliar: new FormControl(0, [
      Validators.required
    ]),
    imp_viaticos_extra_hospedaje: new FormControl(0, [
      Validators.required
    ]),
    num_dias_extra: new FormControl(0, [
      Validators.required
    ])
  });
  tiendaForm: FormGroup = new FormGroup({
    num_tiendas: new FormControl(0, [
      Validators.required
    ])
  });
  calculoForm: FormGroup = new FormGroup({
    id_ruta: new FormControl(0, [
      Validators.required
    ]),
    num_dias_extra: new FormControl(0, [
      Validators.required
    ]),

    num_dias_estadia: new FormControl(0, [
      Validators.required
    ])
  });
  maniobrasForm: FormGroup = new FormGroup({
    num_terceros: new FormControl(0, [
    ]),
    imp_terceros: new FormControl(0, [
    ]),
    SelectedColaborador: new FormControl()
  });
  rescateForm: FormGroup = new FormGroup({
    num_economico_rescate: new FormControl(0, [
      Validators.required
    ])
  });
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  listChoferes: any[] = [];
  clv_operacion: number = 0;
  //Fecha no se modifica
  fecha_final_dia!: Date;
  fecha_dia_real: any = null;
  texto_fecha_recurso: any = null;
  //Fecha para restar 5 dias de duracion de ruta
  fecha_minus5 !: Date;
  //colaboradores para maniobras
  lstChoferesDisponibles: IChoferDisponibleManiobras[] = [];
  lstChoferesDisponiblesAgregados: IChoferDisponibleManiobras[] = [];
  lstchofer: IChoferManiobras[] = [];
  lstchofer_eliminados: IChoferManiobras[] = [];
  btnHabilitar: boolean = false;
  btnHabilitarSpinner: boolean = false;
  data_enviar: IMovimientosFechaExtra = {
    idx: 0,
    idx_pao_diario_unidades: 0,
    id_ruta: 0,
    desc_ruta: '',
    clv_especial: 0,
    desc_tiporuta: '',
    desc_cliente: '',
    id_cliente: 0,
    desc_proyecto: '',
    id_proyecto: 0,
    desc_ciudad: '',
    id_ciudad: 0,
    num_economico: '',
    id_unidad_transporte: 0,
    datos_generales: '',
    cod_chofer: 0,
    nombre_chofer: '',
    fecha: '',
    clv_operacion: 0,
    clv_foranea: 0,
    clv_fin_ruta: 0,
    clv_cancelado: 0,
    cod_adicional1: 0,
    cod_adicional2: 0,
    id_puesto: 0,
    id_puesto1: 0,
    id_puesto2: 0,
    nombre_completo_adicional1: '',
    nombre_completo_adicional2: '',
    desc_puesto_principal: '',
    desc_puesto_adicional1: '',
    desc_puesto_adicional2: '',
    jsonManiobras: [],
    hora: '',
    num_tiendas_adicionales: 0,
    imp_tienda_adicional: 0,
    imp_tienda_adicional_total: 0,
    num_dias_extra: 0,
    num_terceros: 0,
    imp_terceros: 0,
    imp_tab_total_extra_driver: 0,
    imp_tab_total_extra_auxiliar: 0,
    imp_viaticos_extra_driver: 0,
    imp_viaticos_extra_auxiliar: 0,
    imp_viaticos_extra_hospedaje: 0,
    num_economico_rescate: '',
    num_folio: '',
    desc_folio: '',
    num_dias_estadia: 0,
    fec_salida: '',
    hora_salida: '',
    fec_llegada: '',
    hora_llegada: '',
    chk_cumple: 0,
    num_domicilios_visitados: 0,
    num_paquetes_entregados: 0,
    clv_fin_gestion: 0,
    clv_llegada: 0,
    clv_salida: 0,
    num_servicios_prestados:0,
    clv_cumple_llegada:0,
    clv_cumple_salida:0,
    num_domicilios_programados:0,
    num_paquetes_programados:0,
    desc_cumple_paquetes:'',
    desc_cumple_domicilios:'',
    desc_fin_gestion:'',
    desc_cumple_salida:'',
    desc_cumple_llegada:''
  }
  data_guardar_maniobras: IGuardarManiobras = {
    idx_pao_diario_unidades: 0,
    fecha: '',
    id_ruta: 0,
    fecha_registro: '',
    lstchofer: [],
    num_terceros: 0,
    imp_terceros: 0,
    cod_usuario: ''
  };
  //Tienda Adicional
  btnHabilitarTienda: boolean = false;
  //Extension de Ruta
  btnHabilitarExtension: boolean = false;
  //validaciones usuario normal
  habilitar_un_dia: boolean = true;
  //Rescate Unidad
  dspRescate: boolean = false;
  btnHabilitarRescate: boolean = false;
  //Menu 
  items: MenuItem[] = [
    /*{
    label: 'Maniobras',
    icon: 'pi pi-users',
    command: () => {
      this.mostrarModalManiobras(this.data_enviar);
    }
},
{
  label: 'Tienda Adicional',
  icon: 'pi pi-shopping-cart',
  command: () => {
      this.mostrarModalTienda(this.data_enviar);
  }
},*/
    {
      label: 'Extensión',
      icon: 'pi pi-plus',
      command: () => {
        this.mostrarModalExtension(this.data_enviar);
      }
    },
    {
      label: 'Rescate',
      icon: 'pi pi-truck',
      command: () => {
        this.mostrarModalRescate(this.data_enviar);
      }
    }];

  //reportes
  dbsReportes: boolean = false;
  dspGestion: boolean = false;
  btnHabilitarGestion: boolean = false;
  gestionForm: FormGroup = new FormGroup({
    idx_pao_diario_unidades: new FormControl(0, []),
    fec_salida: new FormControl('1900-01-01', []),
    hora_salida: new FormControl('00:00:00', []),
    fec_llegada: new FormControl('1900-01-01', []),
    hora_llegada: new FormControl('00:00:00', []),
    chk_cumple: new FormControl(0, []),
    num_domicilios_visitados: new FormControl(1, []),
    num_paquetes_entregados: new FormControl(1, []),
    clv_salida: new FormControl(null, []),
    clv_llegada: new FormControl(null, []),
    clv_fin_gestion: new FormControl(0, []),
    clv_cumple_salida: new FormControl(null, []),
    clv_cumple_llegada: new FormControl(null, []),
    num_domicilios_programados: new FormControl(1, []),
    num_paquetes_programados: new FormControl(1, [])
  });
  messageFin: string = '';
  messageFinalizado: string = '*Ruta finalizada por el usuario';
  messageSinFinalizar: string = '*Ruta "NO" finalizada';
  lstTiposValidacion: ItiposValidacion[] = [{ id: 1, nombre: 'Confirmado' },
  { id: 2, nombre: 'No Corresponde' },
  { id: 0, nombre: 'No se Realizo' }
  ];
  clv_salida_original: number = 0;
  clv_llegada_original: number = 0;
  texto_nombre_ruta:string='';
  constructor(private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private movfechaService: MovFechaService,
    private auth: AuthService,
    private choferService: ChoferService,
    private extraService: ExtraService) {
    this.cargarInformacionUsuario();
    this.gestionForm.controls['clv_salida'].valueChanges.subscribe(data => {
      if (data == true && this.clv_salida_original == 0) {
        //se mandan llenar la fecha y hora del momento
        this.cargarFechasYHoraFormulario(1);
      } else if(data == false /*&& this.clv_salida_original == 0*/){
        //si se desmarca y no se tiene informacion original se pone default
        this.gestionForm.controls['fec_salida'].setValue(null);
        this.gestionForm.controls['hora_salida'].setValue(null);
      }
    });
    this.gestionForm.controls['clv_llegada'].valueChanges.subscribe(data => {
      if (data == true && this.clv_llegada_original == 0) {
        //se mandan llenar la fecha y hora del momento
        this.cargarFechasYHoraFormulario(2);
      } else if(data == false /* && this.clv_salida_original == 0*/){
        //si se desmarca y no se tiene informacion original se pone default
        this.gestionForm.controls['fec_llegada'].setValue(null);
        this.gestionForm.controls['hora_llegada'].setValue(null);
      }
    });
  }

  cargarFechasYHoraFormulario(num_opcion: number) {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    var hourDate = new Date().toLocaleString('es-MX', { hour: 'numeric', minute: 'numeric', second: 'numeric' });
    switch (num_opcion) {
      case 1: {
        this.gestionForm.controls['fec_salida'].setValue(dateFormat);
        this.gestionForm.controls['hora_salida'].setValue(hourDate);
        break;
      }
      case 2: {
        this.gestionForm.controls['fec_llegada'].setValue(dateFormat);
        this.gestionForm.controls['hora_llegada'].setValue(hourDate);
        break;
      }
      default: {
        break;
      }
    }
  }


  ngOnInit(): void {
    this.inicializarFechasFormateadas();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  save(data: IMovimientosFechaExtra) {
    this.data_enviar = data;
  }

  inicializarFechasFormateadas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;
  }

  cargarInformacionPorFecha(fecha: string, fecha_fin: string) {
    let fecha_fin_cast = fecha_fin == null ? '1900-01-01' : fecha_fin;
    this.lstMovimientos = [];
    this.loading = true;
    if (fecha == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    } else {
      this.extraService.getListadoDeMovimientosFecha(fecha,'1900-01-01',this.user.cod).subscribe((resp) => {
        this.lstMovimientos = resp;
        if (!this.lstMovimientos.length || typeof this.lstMovimientos === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion con la Fecha Seleccionada de Operaciones');
        }
        this.mostrarbtnLimpiarFiltro = true;
        this.loading = false;
      },
        (error) => {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Informacion de Operacion Diaria');
        });
    }
  }

  cargarInformacionPorFechaReporte(fecha: string, fecha_fin: string) {
    this.btngenerandoReporte=true;
    let fecha_fin_cast = fecha_fin == null ? '1900-01-01' : fecha_fin;
    this.lstMovimientosReporte = [];
    if (fecha == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    } else {
      this.extraService.getListadoDeMovimientosFecha(fecha,fecha_fin,this.user.cod).subscribe((resp) => {
        this.lstMovimientosReporte = resp;
        if (!this.lstMovimientosReporte.length || typeof this.lstMovimientosReporte === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion con la Fecha Seleccionada para el Reporte');
          this.btngenerandoReporte=false;
        } else {
          this.exportarExcelMovimientos();
          this.btngenerandoReporte=false;
        }
      },
        (error) => {
          this.btngenerandoReporte=false;
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Informacion de Operacion Diaria');
        });
    }
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
  }
  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }

  mostrarModalManiobras(data: IMovimientosFechaExtra) {
    this.btnHabilitarSpinner = false;
    this.clv_operacion = data.clv_operacion;
    this.data_enviar = data;
    this.cargarPersonalDeManiobras(data.id_ciudad, data.fecha, data.idx_pao_diario_unidades);
    this.cargarManiobras(data.jsonManiobras);
    let fecha_ruta = new Date(data.fecha + 'T00:00:00');
    if (this.clv_operacion == 1) {
      if (this.fecha_final_dia.getTime() == fecha_ruta.getTime()) {
        this.habilitar_un_dia = false;
      } else {
        this.habilitar_un_dia = true;
      }
    }
    //deshabilitar boton agregar
    this.deshabilitarManiobras();
    //
    this.lstChoferesDisponibles = [...this.lstChoferesDisponibles];
    this.maniobrasForm.controls['num_terceros'].setValue(data.num_terceros);
    this.maniobrasForm.controls['imp_terceros'].setValue(data.imp_terceros);
    this.dspManiobra = true;
  }

  cargarManiobras(lst: IJsonManiobras[]) {
    this.lstChoferesDisponiblesAgregados = [];
    this.lstchofer = [];
    if (lst.length > 0) {
      lst.map((valor) => {
        this.lstChoferesDisponiblesAgregados.push({
          cod_chofer: valor.cod_chofer,
          nombre_completo: valor.nombre_completo,
          id_ciudad_hm: 0,
          id_puesto_chofer: valor.id_puesto,
          clave_tipoTransporte: 0,
          clv_guardado: 0
        });
      });
    }
  }

  cancelarModalManiobras() {
    this.clv_operacion = 0;
    this.btnHabilitar = false;
    this.btnHabilitarSpinner = false;
    this.lstChoferesDisponiblesAgregados = [];
    this.data_enviar = {
      idx: 0,
      idx_pao_diario_unidades: 0,
      id_ruta: 0,
      desc_ruta: '',
      clv_especial: 0,
      desc_tiporuta: '',
      desc_cliente: '',
      id_cliente: 0,
      desc_proyecto: '',
      id_proyecto: 0,
      desc_ciudad: '',
      id_ciudad: 0,
      num_economico: '',
      id_unidad_transporte: 0,
      datos_generales: '',
      cod_chofer: 0,
      nombre_chofer: '',
      fecha: '',
      clv_operacion: 0,
      clv_foranea: 0,
      clv_fin_ruta: 0,
      clv_cancelado: 0,
      cod_adicional1: 0,
      cod_adicional2: 0,
      id_puesto: 0,
      id_puesto1: 0,
      id_puesto2: 0,
      nombre_completo_adicional1: '',
      nombre_completo_adicional2: '',
      desc_puesto_principal: '',
      desc_puesto_adicional1: '',
      desc_puesto_adicional2: '',
      jsonManiobras: [],
      hora: '',
      num_tiendas_adicionales: 0,
      imp_tienda_adicional: 0,
      imp_tienda_adicional_total: 0,
      num_dias_extra: 0,
      num_terceros: 0,
      imp_terceros: 0,
      imp_tab_total_extra_driver: 0,
      imp_tab_total_extra_auxiliar: 0,
      imp_viaticos_extra_driver: 0,
      imp_viaticos_extra_auxiliar: 0,
      imp_viaticos_extra_hospedaje: 0,
      num_economico_rescate: '',
      num_folio: '',
      desc_folio: '',
      num_dias_estadia: 0,
      fec_salida: '',
      hora_salida: '',
      fec_llegada: '',
      hora_llegada: '',
      chk_cumple: 0,
      num_domicilios_visitados: 0,
      num_paquetes_entregados: 0,
      clv_fin_gestion: 0,
      clv_llegada: 0,
      clv_salida: 0,
      num_servicios_prestados:0,
      clv_cumple_llegada:0,
      clv_cumple_salida:0,
      num_domicilios_programados:0,
      num_paquetes_programados:0,
      desc_cumple_paquetes:'',
      desc_cumple_domicilios:'',
      desc_fin_gestion:'',
      desc_cumple_salida:'',
      desc_cumple_llegada:''
    }
    this.data_guardar_maniobras = {
      idx_pao_diario_unidades: 0,
      fecha: '',
      id_ruta: 0,
      fecha_registro: '',
      lstchofer: [],
      num_terceros: 0,
      imp_terceros: 0,
      cod_usuario: ''
    };
    this.dspManiobra = false;
    this.maniobrasForm.reset();
    this.lstchofer = [];
  }

  habilitarManiobras() {
    this.maniobrasForm.controls['num_terceros'].enable();
    this.maniobrasForm.controls['imp_terceros'].enable();
    this.maniobrasForm.controls['SelectedColaborador'].enable();
    this.btnHabilitar = false;
  }

  deshabilitarManiobras() {
    this.maniobrasForm.controls['num_terceros'].disable();
    this.maniobrasForm.controls['imp_terceros'].disable();
    this.maniobrasForm.controls['SelectedColaborador'].disable();
    this.btnHabilitar = true;
  }

  guardarInformacionManiobras() {
    this.btnHabilitar = true;
    this.btnHabilitarSpinner = true;
    if (this.lstChoferesDisponiblesAgregados.length > 0) {
      this.lstChoferesDisponiblesAgregados.map((data) => {
        //los que estan con clv_guardado=0 no se almacenan de nuevo
        if (data.clv_guardado != 0) {
          this.lstchofer.push({
            cod_chofer: data.cod_chofer,
            clv_guardado: data.clv_guardado!,
            imp_maniobras: 0
          });
        }
      });
    }
    //Hora en Formato al Guardar
    var formattedDate = new Date().toLocaleString('es-MX', { hour: 'numeric', minute: 'numeric', second: 'numeric' });
    var fecha_completa = this.fecha_dia_real + 'T' + formattedDate;
    this.data_guardar_maniobras = {
      idx_pao_diario_unidades: this.data_enviar.idx_pao_diario_unidades,
      fecha: this.data_enviar.fecha,
      id_ruta: this.data_enviar.id_ruta,
      fecha_registro: fecha_completa,
      lstchofer: this.lstchofer,
      num_terceros: this.maniobrasForm.get('num_terceros')?.value,
      imp_terceros: this.maniobrasForm.get('imp_terceros')?.value,
      cod_usuario: this.user.cod!
    };
    this.extraService.agregarManiobras(this.data_guardar_maniobras).subscribe((resp) => {
      this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
      this.btnHabilitar = false;
      this.btnHabilitarSpinner = false;
      this.cargarInformacionPorFecha(this.fecha_dia, this.user.cod!);
      this.cancelarModalManiobras();
    },
      (error) => {
        this.btnHabilitar = false;
        this.btnHabilitarSpinner = false;
        this.messageService.add({ severity: 'error', summary: 'Error en <Guardar Maniobras>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  mostrarModalTienda(data: IMovimientosFechaExtra) {
    this.data_enviar = data;
    this.clv_operacion = data.clv_operacion;
    this.btnHabilitarTienda = false;
    this.dspTiendaAdicional = true;
    let fecha_ruta = new Date(data.fecha + 'T00:00:00');
    if (this.clv_operacion == 1) {
      if (this.fecha_final_dia.getTime() == fecha_ruta.getTime()) {
        this.habilitar_un_dia = false;
      } else {
        this.habilitar_un_dia = true;
      }
    }
    //deshabilitar botones
    this.deshabilitarTiendas();
    //cargar informacion de tienda si tiene
    this.tiendaForm.controls['num_tiendas'].setValue(data.num_tiendas_adicionales);
  }

  cancelarModalTienda() {
    this.clv_operacion = 0;
    this.data_enviar = {
      idx: 0,
      idx_pao_diario_unidades: 0,
      id_ruta: 0,
      desc_ruta: '',
      clv_especial: 0,
      desc_tiporuta: '',
      desc_cliente: '',
      id_cliente: 0,
      desc_proyecto: '',
      id_proyecto: 0,
      desc_ciudad: '',
      id_ciudad: 0,
      num_economico: '',
      id_unidad_transporte: 0,
      datos_generales: '',
      cod_chofer: 0,
      nombre_chofer: '',
      fecha: '',
      clv_operacion: 0,
      clv_foranea: 0,
      clv_fin_ruta: 0,
      clv_cancelado: 0,
      cod_adicional1: 0,
      cod_adicional2: 0,
      id_puesto: 0,
      id_puesto1: 0,
      id_puesto2: 0,
      nombre_completo_adicional1: '',
      nombre_completo_adicional2: '',
      desc_puesto_principal: '',
      desc_puesto_adicional1: '',
      desc_puesto_adicional2: '',
      jsonManiobras: [],
      hora: '',
      num_tiendas_adicionales: 0,
      imp_tienda_adicional: 0,
      imp_tienda_adicional_total: 0,
      num_dias_extra: 0,
      num_terceros: 0,
      imp_terceros: 0,
      imp_tab_total_extra_driver: 0,
      imp_tab_total_extra_auxiliar: 0,
      imp_viaticos_extra_driver: 0,
      imp_viaticos_extra_auxiliar: 0,
      imp_viaticos_extra_hospedaje: 0,
      num_economico_rescate: '',
      num_folio: '',
      desc_folio: '',
      num_dias_estadia: 0,
      fec_salida: '',
      hora_salida: '',
      fec_llegada: '',
      hora_llegada: '',
      chk_cumple: 0,
      num_domicilios_visitados: 0,
      num_paquetes_entregados: 0,
      clv_fin_gestion: 0,
      clv_llegada: 0,
      clv_salida: 0,
      num_servicios_prestados:0,
      clv_cumple_llegada:0,
      clv_cumple_salida:0,
      num_domicilios_programados:0,
      num_paquetes_programados:0,
      desc_cumple_paquetes:'',
      desc_cumple_domicilios:'',
      desc_fin_gestion:'',
      desc_cumple_salida:'',
      desc_cumple_llegada:''
    }
    this.btnHabilitarTienda = false;
    this.dspTiendaAdicional = false;
    this.tiendaForm.reset();
  }

  habilitarTiendas() {
    this.tiendaForm.controls['num_tiendas'].enable();
    this.btnHabilitarTienda = false;
  }

  deshabilitarTiendas() {
    this.tiendaForm.controls['num_tiendas'].disable();
    this.btnHabilitarTienda = true;
  }

  guardarInformacionTiendaAdicional() {
    let data_enviar: IEnviarTienda = {
      id_pao_diario_unidades: this.data_enviar.idx_pao_diario_unidades,
      id_ruta: this.data_enviar.id_ruta,
      num_tiendas_adicionales: this.tiendaForm.get('num_tiendas')?.value,
      cod_usuario: this.user.cod!
    }
    this.extraService.agregarTiendasAdicionales(data_enviar).subscribe((resp) => {
      this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
      this.btnHabilitarTienda = false;
      this.cargarInformacionPorFecha(this.fecha_dia, this.user.cod!);
      this.cancelarModalTienda();
    },
      (error) => {
        this.btnHabilitarTienda = false;
        this.messageService.add({ severity: 'error', summary: 'Error en <Guardar Tienda Adicional>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  mostrarModalExtension(data: IMovimientosFechaExtra) {
    this.data_enviar = data;
    this.clv_operacion = data.clv_operacion;
    let fecha_ruta = new Date(data.fecha + 'T00:00:00');
    if (this.clv_operacion == 1) {
      if (this.fecha_final_dia.getTime() == fecha_ruta.getTime()) {
        this.habilitar_un_dia = false;
      } else {
        this.habilitar_un_dia = true;
      }
    }

    //cargar Información existente
    this.extensionForm.controls['id_pao_diario_unidades'].setValue(data.idx_pao_diario_unidades);
    this.extensionForm.controls['imp_tab_total_extra_driver'].setValue(data.imp_tab_total_extra_driver);
    this.extensionForm.controls['imp_tab_total_extra_auxiliar'].setValue(data.imp_tab_total_extra_auxiliar);
    this.extensionForm.controls['imp_viaticos_extra_driver'].setValue(data.imp_viaticos_extra_driver);
    this.extensionForm.controls['imp_viaticos_extra_auxiliar'].setValue(data.imp_viaticos_extra_auxiliar);
    this.extensionForm.controls['imp_viaticos_extra_hospedaje'].setValue(data.imp_viaticos_extra_hospedaje);
    //
    this.calculoForm.controls['num_dias_extra'].setValue(data.num_dias_extra);
    this.calculoForm.controls['num_dias_estadia'].setValue(data.num_dias_estadia);
    this.calculoForm.controls['id_ruta'].setValue(data.id_ruta);
    //
    this.deshabilitarExtension();
    this.dspExtension = true;
  }

  cancelarModalExtension() {
    this.clv_operacion = 0;
    this.data_enviar = {
      idx: 0,
      idx_pao_diario_unidades: 0,
      id_ruta: 0,
      desc_ruta: '',
      clv_especial: 0,
      desc_tiporuta: '',
      desc_cliente: '',
      id_cliente: 0,
      desc_proyecto: '',
      id_proyecto: 0,
      desc_ciudad: '',
      id_ciudad: 0,
      num_economico: '',
      id_unidad_transporte: 0,
      datos_generales: '',
      cod_chofer: 0,
      nombre_chofer: '',
      fecha: '',
      clv_operacion: 0,
      clv_foranea: 0,
      clv_fin_ruta: 0,
      clv_cancelado: 0,
      cod_adicional1: 0,
      cod_adicional2: 0,
      id_puesto: 0,
      id_puesto1: 0,
      id_puesto2: 0,
      nombre_completo_adicional1: '',
      nombre_completo_adicional2: '',
      desc_puesto_principal: '',
      desc_puesto_adicional1: '',
      desc_puesto_adicional2: '',
      jsonManiobras: [],
      hora: '',
      num_tiendas_adicionales: 0,
      imp_tienda_adicional: 0,
      imp_tienda_adicional_total: 0,
      num_dias_extra: 0,
      num_terceros: 0,
      imp_terceros: 0,
      imp_tab_total_extra_driver: 0,
      imp_tab_total_extra_auxiliar: 0,
      imp_viaticos_extra_driver: 0,
      imp_viaticos_extra_auxiliar: 0,
      imp_viaticos_extra_hospedaje: 0,
      num_economico_rescate: '',
      num_folio: '',
      desc_folio: '',
      num_dias_estadia: 0,
      fec_salida: '',
      hora_salida: '',
      fec_llegada: '',
      hora_llegada: '',
      chk_cumple: 0,
      num_domicilios_visitados: 0,
      num_paquetes_entregados: 0,
      clv_fin_gestion: 0,
      clv_llegada: 0,
      clv_salida: 0,
      num_servicios_prestados:0,
      clv_cumple_llegada:0,
      clv_cumple_salida:0,
      num_domicilios_programados:0,
      num_paquetes_programados:0,
      desc_cumple_paquetes:'',
      desc_cumple_domicilios:'',
      desc_fin_gestion:'',
      desc_cumple_salida:'',
      desc_cumple_llegada:''
    }
    this.dspExtension = false;
    this.btnHabilitarExtension = false;
    this.extensionForm.reset();
    this.calculoForm.reset();
  }

  habilitarExtension() {
    this.extensionForm.controls['id_pao_diario_unidades'].enable();
    this.extensionForm.controls['imp_tab_total_extra_driver'].enable();
    this.extensionForm.controls['imp_tab_total_extra_auxiliar'].enable();
    this.extensionForm.controls['imp_viaticos_extra_driver'].enable();
    this.extensionForm.controls['imp_viaticos_extra_auxiliar'].enable();
    this.extensionForm.controls['imp_viaticos_extra_hospedaje'].enable();
    this.extensionForm.controls['num_dias_extra'].enable();
    //
    this.calculoForm.controls['num_dias_extra'].enable();
    this.calculoForm.controls['num_dias_estadia'].enable();

    this.btnHabilitarExtension = false;
  }

  deshabilitarExtension() {
    this.extensionForm.controls['id_pao_diario_unidades'].disable();
    this.extensionForm.controls['imp_tab_total_extra_driver'].disable();
    this.extensionForm.controls['imp_tab_total_extra_auxiliar'].disable();
    this.extensionForm.controls['imp_viaticos_extra_driver'].disable();
    this.extensionForm.controls['imp_viaticos_extra_auxiliar'].disable();
    this.extensionForm.controls['imp_viaticos_extra_hospedaje'].disable();
    this.extensionForm.controls['num_dias_extra'].disable();
    //
    this.calculoForm.controls['num_dias_extra'].disable();
    this.calculoForm.controls['num_dias_estadia'].disable();

    this.btnHabilitarExtension = true;
  }

  guardarInformacionExtension() {
    this.btnHabilitarExtension = true;
    let data_enviar: IEnviarExtension = {
      id_pao_diario_unidades: this.data_enviar.idx_pao_diario_unidades,
      imp_tab_total_extra_driver: this.extensionForm.get('imp_tab_total_extra_driver')?.value,
      imp_tab_total_extra_auxiliar: this.extensionForm.get('imp_tab_total_extra_auxiliar')?.value,
      imp_viaticos_extra_driver: this.extensionForm.get('imp_viaticos_extra_driver')?.value,
      imp_viaticos_extra_auxiliar: this.extensionForm.get('imp_viaticos_extra_auxiliar')?.value,
      imp_viaticos_extra_hospedaje: this.extensionForm.get('imp_viaticos_extra_hospedaje')?.value,
      num_dias_extra: this.calculoForm.get('num_dias_extra')?.value,
      num_dias_estadia: this.calculoForm.get('num_dias_estadia')?.value,
      cod_usuario: this.user.cod!
    }
    this.extraService.agregarExtension(data_enviar).subscribe((resp) => {
      this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
      this.btnHabilitarExtension = false;
      this.cargarInformacionPorFecha(this.fecha_dia, this.user.cod!);
      this.cancelarModalExtension();
    },
      (error) => {
        this.btnHabilitarExtension = false;
        this.messageService.add({ severity: 'error', summary: 'Error en <Guardar Tienda Adicional>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  mostrarModalRescate(data: IMovimientosFechaExtra) {
    this.data_enviar = data;
    this.clv_operacion = data.clv_operacion;
    this.btnHabilitarRescate = false;
    this.dspRescate = true;
    let fecha_ruta = new Date(data.fecha + 'T00:00:00');
    if (this.clv_operacion == 1) {
      if (this.fecha_final_dia.getTime() == fecha_ruta.getTime()) {
        this.habilitar_un_dia = false;
      } else {
        this.habilitar_un_dia = true;
      }
    }
    //deshabilitar botones
    this.deshabilitarRescate();
    //cargar informacion de tienda si tiene
    this.rescateForm.controls['num_economico_rescate'].setValue(data.num_economico_rescate);
  }

  cancelarModalRescate() {
    this.clv_operacion = 0;
    this.data_enviar = {
      idx: 0,
      idx_pao_diario_unidades: 0,
      id_ruta: 0,
      desc_ruta: '',
      clv_especial: 0,
      desc_tiporuta: '',
      desc_cliente: '',
      id_cliente: 0,
      desc_proyecto: '',
      id_proyecto: 0,
      desc_ciudad: '',
      id_ciudad: 0,
      num_economico: '',
      id_unidad_transporte: 0,
      datos_generales: '',
      cod_chofer: 0,
      nombre_chofer: '',
      fecha: '',
      clv_operacion: 0,
      clv_foranea: 0,
      clv_fin_ruta: 0,
      clv_cancelado: 0,
      cod_adicional1: 0,
      cod_adicional2: 0,
      id_puesto: 0,
      id_puesto1: 0,
      id_puesto2: 0,
      nombre_completo_adicional1: '',
      nombre_completo_adicional2: '',
      desc_puesto_principal: '',
      desc_puesto_adicional1: '',
      desc_puesto_adicional2: '',
      jsonManiobras: [],
      hora: '',
      num_tiendas_adicionales: 0,
      imp_tienda_adicional: 0,
      imp_tienda_adicional_total: 0,
      num_dias_extra: 0,
      num_terceros: 0,
      imp_terceros: 0,
      imp_tab_total_extra_driver: 0,
      imp_tab_total_extra_auxiliar: 0,
      imp_viaticos_extra_driver: 0,
      imp_viaticos_extra_auxiliar: 0,
      imp_viaticos_extra_hospedaje: 0,
      num_economico_rescate: '',
      num_folio: '',
      desc_folio: '',
      num_dias_estadia: 0,
      fec_salida: '',
      hora_salida: '',
      fec_llegada: '',
      hora_llegada: '',
      chk_cumple: 0,
      num_domicilios_visitados: 0,
      num_paquetes_entregados: 0,
      clv_fin_gestion: 0,
      clv_llegada: 0,
      clv_salida: 0,
      num_servicios_prestados:0,
      clv_cumple_llegada:0,
      clv_cumple_salida:0,
      num_domicilios_programados:0,
      num_paquetes_programados:0,
      desc_cumple_paquetes:'',
      desc_cumple_domicilios:'',
      desc_fin_gestion:'',
      desc_cumple_salida:'',
      desc_cumple_llegada:''
    }
    this.btnHabilitarRescate = false;
    this.dspRescate = false;
    this.rescateForm.reset();
  }

  habilitarRescate() {
    this.rescateForm.controls['num_economico_rescate'].enable();
    this.btnHabilitarRescate = false;
  }

  deshabilitarRescate() {
    this.rescateForm.controls['num_economico_rescate'].disable();
    this.btnHabilitarRescate = true;
  }

  guardarInformacionRescate() {
    let data_enviar: IEnviarRescate = {
      idx_pao_diario_unidades: this.data_enviar.idx_pao_diario_unidades,
      num_economico_rescate: this.rescateForm.get('num_economico_rescate')?.value,
      cod_usuario: this.user.cod!
    }
    this.extraService.agregarRescateUnidad(data_enviar).subscribe((resp) => {
      this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
      this.btnHabilitarRescate = false;
      this.cargarInformacionPorFecha(this.fecha_dia, this.user.cod!);
      this.cancelarModalRescate();
    },
      (error) => {
        this.btnHabilitarRescate = false;
        this.messageService.add({ severity: 'error', summary: 'Error en <Guardar Rescate de Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  mostrarModalGestion(data: IMovimientosFechaExtra) {
    this.btnHabilitarSpinner=false;
    this.texto_nombre_ruta=data.desc_ruta;
    this.gestionForm.controls['idx_pao_diario_unidades'].setValue(data.idx_pao_diario_unidades);
    this.gestionForm.controls['num_domicilios_visitados'].setValue(data.num_domicilios_visitados);
    this.gestionForm.controls['num_paquetes_entregados'].setValue(data.num_paquetes_entregados);
    this.gestionForm.controls['num_domicilios_programados'].setValue(data.num_domicilios_programados);
    this.gestionForm.controls['num_paquetes_programados'].setValue(data.num_paquetes_programados);
    this.gestionForm.controls['chk_cumple'].setValue(data.chk_cumple);
    this.gestionForm.controls['clv_llegada'].setValue(data.clv_llegada == 0 ? false : true);
    this.gestionForm.controls['clv_salida'].setValue(data.clv_salida == 0 ? false : true);
    this.gestionForm.controls['clv_cumple_llegada'].setValue(data.clv_cumple_llegada == 0 ? false : true);
    this.gestionForm.controls['clv_cumple_salida'].setValue(data.clv_cumple_salida == 0 ? false : true);
    this.clv_salida_original = data.clv_salida;
    this.clv_llegada_original = data.clv_llegada;
    this.gestionForm.controls['clv_fin_gestion'].setValue(data.clv_fin_gestion);

    /*var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    var hourDate = new Date().toLocaleString('es-MX', { hour: 'numeric', minute: 'numeric', second: 'numeric' });*/

    if (data.clv_salida == 0) {
      this.gestionForm.controls['fec_salida'].setValue(null);
      this.gestionForm.controls['hora_salida'].setValue(null);
    } else if (data.clv_salida == 1) {
      //poner informacion que tiene almacenada
      this.gestionForm.controls['fec_salida'].setValue(data.fec_salida);
      this.gestionForm.controls['hora_salida'].setValue(data.hora_salida);
    }

    if (data.clv_llegada == 0) {
      this.gestionForm.controls['fec_llegada'].setValue(null);
      this.gestionForm.controls['hora_llegada'].setValue(null);
    } else if (data.clv_llegada == 1) {
      this.gestionForm.controls['fec_llegada'].setValue(data.fec_llegada);
      this.gestionForm.controls['hora_llegada'].setValue(data.hora_llegada);
    }



    if (data.clv_fin_gestion == 1) {
      this.messageFin = this.messageFinalizado;
    } else if (data.clv_fin_gestion == 0) {
      this.messageFin = this.messageSinFinalizar;
    } else {
      this.messageFin = '';
    }
    this.texto_fecha_recurso = data.fecha;
    this.data_enviar = data;
    this.clv_operacion = data.clv_operacion;
    this.btnHabilitarGestion = false;
    this.dspGestion = true;
    let fecha_ruta = new Date(data.fecha + 'T00:00:00');
    this.fecha_final_dia = new Date(this.obtenerFechaMomento()+'T00:00:00');
    this.fecha_minus5 = this.obtenerFechaDiferenciaDias(5);
    if (this.clv_operacion == 5) {
      /*if (this.fecha_final_dia.getTime() == fecha_ruta.getTime()) {
        this.habilitar_un_dia = false;
      } else {
        this.habilitar_un_dia = true;
      }*/
      if (fecha_ruta.getTime() >= this.fecha_minus5.getTime() && fecha_ruta.getTime() <= this.fecha_final_dia.getTime()) {
        this.habilitar_un_dia = false;
      } else {
        this.habilitar_un_dia = true;
      }
    }
    //deshabilitar botones
    this.deshabilitarGestion();
  }

  obtenerFechaDiferenciaDias(num_dias:number):any{
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let fecha_actual = new Date(dateFormat + 'T00:00:00');
    let fecha_minus = new Date(fecha_actual.setDate(new Date(fecha_actual).getDate() - num_dias));
    return fecha_minus;
  }

  cancelarModalGestion() {
    this.btnHabilitarSpinner=false;
    this.clv_operacion = 0;
    this.data_enviar = {
      idx: 0,
      idx_pao_diario_unidades: 0,
      id_ruta: 0,
      desc_ruta: '',
      clv_especial: 0,
      desc_tiporuta: '',
      desc_cliente: '',
      id_cliente: 0,
      desc_proyecto: '',
      id_proyecto: 0,
      desc_ciudad: '',
      id_ciudad: 0,
      num_economico: '',
      id_unidad_transporte: 0,
      datos_generales: '',
      cod_chofer: 0,
      nombre_chofer: '',
      fecha: '',
      clv_operacion: 0,
      clv_foranea: 0,
      clv_fin_ruta: 0,
      clv_cancelado: 0,
      cod_adicional1: 0,
      cod_adicional2: 0,
      id_puesto: 0,
      id_puesto1: 0,
      id_puesto2: 0,
      nombre_completo_adicional1: '',
      nombre_completo_adicional2: '',
      desc_puesto_principal: '',
      desc_puesto_adicional1: '',
      desc_puesto_adicional2: '',
      jsonManiobras: [],
      hora: '',
      num_tiendas_adicionales: 0,
      imp_tienda_adicional: 0,
      imp_tienda_adicional_total: 0,
      num_dias_extra: 0,
      num_terceros: 0,
      imp_terceros: 0,
      imp_tab_total_extra_driver: 0,
      imp_tab_total_extra_auxiliar: 0,
      imp_viaticos_extra_driver: 0,
      imp_viaticos_extra_auxiliar: 0,
      imp_viaticos_extra_hospedaje: 0,
      num_economico_rescate: '',
      num_folio: '',
      desc_folio: '',
      num_dias_estadia: 0,
      fec_salida: '',
      hora_salida: '',
      fec_llegada: '',
      hora_llegada: '',
      chk_cumple: 0,
      num_domicilios_visitados: 0,
      num_paquetes_entregados: 0,
      clv_fin_gestion: 0,
      clv_llegada: 0,
      clv_salida: 0,
      num_servicios_prestados:0,
      clv_cumple_llegada:0,
      clv_cumple_salida:0,
      num_domicilios_programados:0,
      num_paquetes_programados:0,
      desc_cumple_paquetes:'',
      desc_cumple_domicilios:'',
      desc_fin_gestion:'',
      desc_cumple_salida:'',
      desc_cumple_llegada:''
    }
    this.btnHabilitarGestion = false;
    this.btnHabilitar = false;
    this.dspGestion = false;
    this.clv_salida_original = 0;
    this.clv_llegada_original = 0;
    this.texto_nombre_ruta='';
    //falta reiniciar el form
    this.gestionForm.reset();
  }

  habilitarGestion() {
    this.gestionForm.controls['fec_salida'].enable();
    this.gestionForm.controls['hora_salida'].enable();
    this.gestionForm.controls['clv_salida'].enable();
    this.gestionForm.controls['fec_llegada'].enable();
    this.gestionForm.controls['hora_llegada'].enable();
    this.gestionForm.controls['clv_llegada'].enable();
    this.gestionForm.controls['chk_cumple'].enable();
    this.gestionForm.controls['num_domicilios_visitados'].enable();
    this.gestionForm.controls['num_paquetes_entregados'].enable();
    //
    this.gestionForm.controls['clv_cumple_llegada'].enable();
    this.gestionForm.controls['clv_cumple_salida'].enable();
    this.gestionForm.controls['num_domicilios_programados'].enable();
    this.gestionForm.controls['num_paquetes_programados'].enable();
    //
    this.btnHabilitarGestion = false;
  }

  deshabilitarGestion() {
    this.gestionForm.controls['fec_salida'].disable();
    this.gestionForm.controls['hora_salida'].disable();
    this.gestionForm.controls['clv_salida'].disable();
    this.gestionForm.controls['fec_llegada'].disable();
    this.gestionForm.controls['hora_llegada'].disable();
    this.gestionForm.controls['clv_llegada'].disable();
    this.gestionForm.controls['chk_cumple'].disable();
    this.gestionForm.controls['num_domicilios_visitados'].disable();
    this.gestionForm.controls['num_paquetes_entregados'].disable();
    //
    this.gestionForm.controls['clv_cumple_llegada'].disable();
    this.gestionForm.controls['clv_cumple_salida'].disable();
    this.gestionForm.controls['num_domicilios_programados'].disable();
    this.gestionForm.controls['num_paquetes_programados'].disable();
    //
    this.btnHabilitarGestion = true;
  }

  validarInformacionGestion() {
    
    if (this.gestionForm.get('fec_salida')?.value == null || this.gestionForm.get('hora_salida')?.value == null
      || this.gestionForm.get('fec_llegada')?.value == null || this.gestionForm.get('hora_salida')?.value == null) {
      this.mostrarDialogoInformativo('La Información debe estar completa para finalizar gestión de Ruta. Favor de Verificar.');
      this.gestionForm.controls['clv_fin_gestion'].setValue(0);
      this.messageFin = this.messageSinFinalizar;
    } else if(this.data_enviar.clv_fin_gestion==1 && (this.clv_operacion==3 || this.clv_operacion==6)){
      //El administrador cambia el estatus de la Ruta ya finalizada a sin finalizar
      this.gestionForm.controls['clv_fin_gestion'].setValue(0);
      this.messageFin = this.messageSinFinalizar;
    }
    else {
      //se finaliza ruta al guardar
      this.gestionForm.controls['clv_fin_gestion'].setValue(1);
      this.messageFin = this.messageFinalizado;
    }
  }

  guardarGestion() {
    this.btnHabilitar = true;
    this.btnHabilitarSpinner = true;
    let clv_salida = this.gestionForm.get('clv_salida')?.value == true ? 1 : 0;
    let clv_llegada = this.gestionForm.get('clv_llegada')?.value == true ? 1 : 0;
    let clv_cumple_salida = this.gestionForm.get('clv_cumple_salida')?.value == true ? 1 : 0;
    let clv_cumple_llegada = this.gestionForm.get('clv_cumple_llegada')?.value == true ? 1 : 0;
    let fec_salida: any, hora_salida: any;
    let fec_llegada: any, hora_llegada: any;

    if (clv_salida == 1) {
      fec_salida = this.gestionForm.get('fec_salida')?.value == null || this.gestionForm.get('fec_salida')?.value=='' 
      ? '1900-01-01' : this.gestionForm.get('fec_salida')?.value;
      hora_salida = this.gestionForm.get('hora_salida')?.value == null || this.gestionForm.get('hora_salida')?.value == ''
       ? '00:00:00' : this.gestionForm.get('hora_salida')?.value;
    } else {
      fec_salida = '1900-01-01';
      hora_salida = '00:00:00';
      this.gestionForm.get('clv_fin_gestion')?.setValue(0);
    }

    if (clv_llegada == 1) {
      fec_llegada = this.gestionForm.get('fec_llegada')?.value == null || this.gestionForm.get('fec_llegada')?.value ==''
       ? '1900-01-01' : this.gestionForm.get('fec_llegada')?.value;
      hora_llegada = this.gestionForm.get('hora_llegada')?.value == null || this.gestionForm.get('hora_llegada')?.value == ''
       ? '00:00:00' : this.gestionForm.get('hora_llegada')?.value;
    } else {
      fec_llegada = '1900-01-01';
      hora_llegada = '00:00:00';
      this.gestionForm.get('clv_fin_gestion')?.setValue(0);
    }

    //validar que fecha de salida no sea diferente a fecha de ruta
    let fecha_ruta = new Date(this.data_enviar.fecha + 'T00:00:00');
    let fecha_salida_ruta = new Date(fec_salida + 'T00:00:00');
    if (fecha_salida_ruta.getTime() != fecha_ruta.getTime() && fec_salida != '1900-01-01') {
      //Si la fecha de salida es menor a la ruta manda, error
      this.btnHabilitar = false;
      this.btnHabilitarSpinner = false;
      this.mostrarDialogoInformativo('Verifique las Fechas. <b>Fecha de Salida</b> no puede ser menor o mayor a la Fecha de Ruta.');
    } else {
      let data_enviar: IDataEnviarGestion = {
        idx_pao_diario_unidades: this.gestionForm.get('idx_pao_diario_unidades')?.value,
        fec_salida: fec_salida,
        hora_salida: hora_salida,
        fec_llegada: fec_llegada,
        hora_llegada: hora_llegada,
        chk_cumple: this.gestionForm.get('chk_cumple')?.value,
        num_domicilios_visitados: this.gestionForm.get('num_domicilios_visitados')?.value,
        num_paquetes_entregados: this.gestionForm.get('num_paquetes_entregados')?.value,
        clv_fin_gestion: this.gestionForm.get('clv_fin_gestion')?.value,
        cod_usuario: this.user.cod!,
        clv_cumple_salida: clv_cumple_salida,
        clv_cumple_llegada: clv_cumple_llegada,
        num_domicilios_programados: this.gestionForm.get('num_domicilios_programados')?.value,
        num_paquetes_programados: this.gestionForm.get('num_paquetes_programados')?.value
      };

      this.extraService.agregarGestion(data_enviar).subscribe((resp) => {
        this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
        this.cargarInformacionPorFecha(this.fecha_dia, this.user.cod!);
        this.cancelarModalGestion();
        setTimeout(() => {
          this.btnHabilitar = false;
          this.btnHabilitarSpinner = false;
        }, 2000);
      },
        (error) => {
          this.btnHabilitar = false;
          this.btnHabilitarSpinner = false;
          this.messageService.add({ severity: 'error', summary: 'Error en <Guardar Gestión>', detail: 'Contacte al Administrador del Sitio' });
        });



    } //fin else
  }


  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  LimpiarFiltro() {
    this.lstMovimientos = [];
    this.fecha_dia = null;
    this.limpiarFiltrosTabla();
    this.mostrarbtnLimpiarFiltro = false;
  }

  limpiarFiltrosTabla() {
    this.dt.clear();
  }

  exportarExcel() {

  }



  agregarColaborador() {
    let chofer: IChoferDisponibleManiobras = this.maniobrasForm.get('SelectedColaborador')?.value == null ||
      this.maniobrasForm.get('SelectedColaborador')?.value == 0 ? null : this.maniobrasForm.get('SelectedColaborador')?.value;
    if (chofer != null) {
      chofer.clv_guardado = 1;
      this.lstChoferesDisponiblesAgregados.push(chofer);
      this.maniobrasForm.controls['SelectedColaborador'].setValue(null);
      this.deshabilitarObjetoArray(this.lstChoferesDisponibles, chofer);
    }
  }

  eliminarColaborador(data: IChoferDisponibleManiobras) {
    if (this.btnHabilitar == false) {
      this.eliminarObjetoArray(this.lstChoferesDisponiblesAgregados, data);
    }
  }

  public eliminarObjetoArray(arr: any, item: any) {
    var i = arr.indexOf(item);
    if (i !== -1) {
      // eliminar en informacion de ruta bd. 
      if (item.clv_guardado == 0) {
        //si existia en base de datos se manda a eliminar
        this.lstchofer.push({
          cod_chofer: item.cod_chofer,
          clv_guardado: 3,
          imp_maniobras: 0
        });
      }
      arr.splice(i, 1);
    }
  }

  public deshabilitarObjetoArray(arr: IChoferDisponibleManiobras[], item: IChoferDisponibleManiobras) {
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr[i].disabled = true;
    }
  }

  cargarPersonalDeManiobras(id_ciudad_hm: number, fecha: string, idx: number) {
    this.choferService.getListadoChoferesDisponiblesAlDiaManiobras(3, idx, id_ciudad_hm, fecha).subscribe((resp) => {
      this.lstChoferesDisponibles = resp;
      if (this.lstChoferesDisponibles.length == 0) {
        this.mostrarDialogoInformativoSinInformacion('No hay Colaboradores Disponibles para la Ruta.');
      } else {
      }
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Colaborador Disponible>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  calcularExtension() {
    if (this.btnHabilitarExtension == false) {
      let id_ruta = this.calculoForm.get('id_ruta')?.value;
      let num_dias_extra = this.calculoForm.get('num_dias_extra')?.value;
      let num_dias_estadia = this.calculoForm.get('num_dias_estadia')?.value;
      this.extraService.getcalculoRuta(id_ruta, num_dias_extra, num_dias_estadia).subscribe((resp) => {
        let data: ICalculoExtension = resp;
        this.extensionForm.controls['imp_tab_total_extra_driver'].setValue(data.imp_tab_total_extra_driver);
        this.extensionForm.controls['imp_tab_total_extra_auxiliar'].setValue(data.imp_tab_total_extra_auxiliar);
        this.extensionForm.controls['imp_viaticos_extra_driver'].setValue(data.imp_viaticos_driver);
        this.extensionForm.controls['imp_viaticos_extra_auxiliar'].setValue(data.imp_viaticos_auxiliar);
        this.extensionForm.controls['imp_viaticos_extra_hospedaje'].setValue(data.imp_viaticos_hospedaje);
      },
        (error) => {
          this.btnHabilitarExtension = false;
          this.messageService.add({ severity: 'error', summary: 'Error en <Guardar Tienda Adicional>', detail: 'Contacte al Administrador del Sitio' });
        });
    } else {
      this.mostrarDialogoInformativo('No esta disponible el calculo por la Fecha y/o permiso de Habilitar Edición');
    }
  }

  abrirModalReportes() {
    this.dbsReportes = true;
    this.btngenerandoReporte=false;
    let fecha = this.obtenerFechaMomento();
    //asignar fechas de reportes
    this.fecha_inicio_r1=fecha;
    this.fecha_fin_r1=fecha;
  }

  cancelarModalReportes() {
    this.dbsReportes = false;
    this.btngenerandoReporte=false;
    //reiniciar todas las variables

  }

  obtenerFechaMomento():string{
    let fecha:any;
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    fecha = getYear + "-" + getMonth + "-" + getDay;
    return fecha;
  }


  exportar(num_opcion: number) {
    let fecha= this.obtenerFechaMomento();
    switch (num_opcion) {
      case 1: {
        //statements; 
        this.cargarInformacionPorFechaReporte(this.fecha_inicio_r1,this.fecha_fin_r1);
        break;
      }
      case 2: {
        //statements; 
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
  }

  exportarExcelMovimientos() {
    this.lstMovimientosReporte_cast=[];
    if (!this.lstMovimientosReporte.length || typeof this.lstMovimientosReporte === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    } else {
      this.asignarArregloParaExportar1(this.lstMovimientosReporte);
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.lstMovimientosReporte_cast);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        let excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        let texto: string = this.fecha_fin_r1 == '1900-01-01' || this.fecha_fin_r1 == null ? '' : '_Al_' + this.fecha_fin_r1;
        this.saveAsExcelFile(excelBuffer, "MovimientosOperacionGestionDeRuta" + '_' + this.fecha_inicio_r1 + texto);
      });
    }
  }

  asignarArregloParaExportar1(data: IMovimientosFechaExtra[]) {
    data.map((valor) => {
      this.lstMovimientosReporte_cast.push({
        NombreRuta: valor.desc_ruta,
        Folio: valor.num_folio,
        TipoDeRuta: valor.desc_tiporuta,
        Cliente: valor.desc_cliente,
        Proyecto: valor.desc_proyecto,
        Ciudad: valor.desc_ciudad,
        NumeroEconomico: valor.num_economico,
        DatosUnidad: valor.datos_generales,
        NombreChofer: valor.nombre_chofer,
        Coordinador: valor.nombre_coordinadores!,
        Supervisor: valor.nombre_supervisores!,
        FechaRuta: valor.fecha,
        ImporteCombustible: valor.imp_combustible!,
        ImporteCasetas: valor.imp_caseta!,
        KilometroInicial: valor.kim_inicial!,
        KilometroFinal: valor.kim_final!,
        EstatusRecursos: valor.clv_fin_ruta == 0 ? 'NO FINALIZADA' : 'FINALIZADA',
        EstatusRuta: valor.clv_cancelado == 0 ? '' : 'CANCELADA',
        ServiciosPrestados: valor.num_servicios_prestados, //ver si se muestra en otra hoja
        Docking:  valor.fec_salida +' '+valor.hora_salida,
        CumpleDocking:valor.desc_cumple_salida,
        Arrival: valor.fec_llegada +' '+valor.hora_llegada,
        CumpleArrival:valor.desc_cumple_llegada,
        Rostering: valor.desc_cumple!,  
        DomiciliosProgramados:valor.num_domicilios_programados,
        DomiciliosVisitados: valor.num_domicilios_visitados,
        CumpleDomicilios:valor.desc_cumple_domicilios,
        PaquetesProgramados:valor.num_paquetes_programados,
        PaquetesEntregados: valor.num_paquetes_entregados,
        CumplePaquetes:valor.desc_cumple_paquetes,
        EstatusGestion:valor.desc_fin_gestion
      });
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName /*+ "_export_" + dateFormat */ + EXCEL_EXTENSION);

  }


}
