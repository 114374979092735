import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { saveAs } from 'file-saver';

import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import ICredencialUsuario from '../models/credencial';
import { IUsuarios } from '../models/usuarios';
import { UsuariosService } from '../services/usuarios.service';
import { CiudadService } from '../services/ciudad.service';
import { ICiudad } from '../models/ciudad';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IResultadoGeneral } from '../models/resultadogeneral';

@Component({
  selector: 'app-lsusuarios',
  templateUrl: './lsusuarios.component.html',
  styleUrls: ['./lsusuarios.component.scss']
})
export class LsusuariosComponent {
  @ViewChild('dt1') dt!: Table;
  lsUsuarios: IUsuarios[] = [];
  usuario_editar: IUsuarios ={
    cod_Empleado: '',
    cod_Usuario: '',
    nom_usuario: '',
    clv_activo: 0,
    nombre: '',
    apellido_Paterno: '',
    contrasenia: '',
    id_ciudad: 0,
    desc_ciudad: '',
    nom_puesto: '',
    nom_departamento: '',
    correo_laboral: ''
  };
  loading: boolean = false;
  num_operacion: number = 0;
  lstCiudades: ICiudad[] = [];
  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };
  ciudadForm!: FormGroup;
  texto_operacion:string = '';
  dspAgregarYEditarUsuario:boolean=false;
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  }
  //validaciones RXJS
  REGEX_NAMES = /^[a-zA-Z\s]{2,50}$/;
  REGEX_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{7,15}$/;

  //Registro
  user: ICredencialUsuario = {};
  constructor(private auth: AuthService, private usuariosService: UsuariosService,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private ciudadService: CiudadService) {
    this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.crearFormularioUsuario();
    this.cargarInformacionDeUsuariosExistentes();
    this.getListaDeCiudades();
  }

  crearFormularioUsuario() {
    this.ciudadForm = new FormGroup({
      nombre: new FormControl('', [
        Validators.required
      ]),
      apellido_Paterno: new FormControl('', [
        Validators.required
      ]),
      nom_usuario: new FormControl('', [
        Validators.required,Validators.pattern(this.REGEX_NAMES)
      ]),
      contrasenia: new FormControl('', [
        Validators.required,Validators.pattern(this.REGEX_PASSWORD)
      ]),
      id_ciudad: new FormControl(0, [
        Validators.required
      ]),
      nom_puesto: new FormControl('', [
        Validators.required
      ]),
      nom_departamento: new FormControl('', [
        Validators.required
      ]),
      correo_laboral: new FormControl('', [
        Validators.required
      ]),
    });
  }

  cancelarMostrarAgregarCiudad(){
    this.reiniciar();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  getListaDeCiudades() {
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudades = resp;
      if (!this.lstCiudades.length) {
        setTimeout(() => {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b>-No existe informacion de ciudades');
        }, 1000);
      }
      let ciudad_todos: ICiudad = {
        id: -1,
        nombre: 'TODAS LAS CIUDADES',
        clave_Estado: '-1',
        id_ciudad_principal: -1
      };
      this.lstCiudades.push(ciudad_todos);
    });
  }

  cargarInformacionDeUsuariosExistentes() {
    this.loading = true;
    this.usuariosService.getListadoUsuarios(1).subscribe((resp) => {
      this.lsUsuarios = resp;
      if (!this.lsUsuarios.length) {
        setTimeout(() => {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b>-No existe informacion de Usuarios');
        }, 1000);
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Usuarios');
      });
  }

  mostrarNuevoUsuario(num_operacion: number) {
    this.ciudadForm.get('nom_usuario')!.enable();
    this.ciudadForm.get('contrasenia')!.enable();
    this.num_operacion = num_operacion;
    this.texto_operacion = 'Agregar Usuario';
    this.dspAgregarYEditarUsuario=true;
  }


  editarInformacion(num_operacion: number,data:IUsuarios) {
    this.usuario_editar=data;
    this.ciudadForm.controls['nom_usuario'].setValue(data.nom_usuario);
    this.ciudadForm.controls['contrasenia'].setValue(data.contrasenia);
    this.ciudadForm.controls['nombre'].setValue(data.nombre);
    this.ciudadForm.controls['apellido_Paterno'].setValue(data.apellido_Paterno);
    this.ciudadForm.controls['nom_puesto'].setValue(data.nom_puesto);
    this.ciudadForm.controls['nom_departamento'].setValue(data.nom_departamento);
    this.ciudadForm.controls['correo_laboral'].setValue(data.correo_laboral);
    this.ciudadForm.controls['id_ciudad'].setValue(this.asignarCiudadFormulario(data.id_ciudad));
    this.ciudadForm.get('nom_usuario')!.disable();
    this.ciudadForm.get('contrasenia')!.disable();
    this.num_operacion = num_operacion;
    this.texto_operacion = 'Editar Información de Usuario';
    this.dspAgregarYEditarUsuario=true;
  }

  asignarCiudadFormulario(id_ciudad:number):ICiudad{
    let selectedCiudad: ICiudad = {
      id: 0,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.lstCiudades.map((ciudad)=>{
      if(ciudad.id==id_ciudad){
        selectedCiudad=ciudad;
      }
    });
    return selectedCiudad;
  }

  guardarInformacion(){
    if(this.num_operacion==1){
      //Nuevo
      this.selectedCiudad = this.ciudadForm.get('id_ciudad')?.value;
      let usuario_enviar: IUsuarios= {
        cod_Empleado: '0000',
        cod_Usuario: '0000',
        nom_usuario: this.ciudadForm.get('nom_usuario')?.value,
        clv_activo: 1,
        nombre: this.ciudadForm.get('nombre')?.value,
        apellido_Paterno: this.ciudadForm.get('apellido_Paterno')?.value,
        contrasenia: this.ciudadForm.get('contrasenia')?.value,
        id_ciudad: this.selectedCiudad.id,
        desc_ciudad: '',
        nom_puesto: this.ciudadForm.get('nom_puesto')?.value,
        nom_departamento: this.ciudadForm.get('nom_departamento')?.value,
        correo_laboral: this.ciudadForm.get('correo_laboral')?.value
      };
      this.usuariosService.addUsuario(1,usuario_enviar).subscribe((resp) => {
        this.resultado = resp;
        setTimeout(() => {
          this.cargarInformacionDeUsuariosExistentes();
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.reiniciar();
        }, 800);
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Crear Usuario>', detail: 'Contacte al Administrador del Sitio' });
      });


    } else if(this.num_operacion==2){
      //Edicion
      this.selectedCiudad = this.ciudadForm.get('id_ciudad')?.value;
      let usuario_enviar_editar: IUsuarios= {
        cod_Empleado: this.usuario_editar.cod_Empleado,
        cod_Usuario: this.usuario_editar.cod_Usuario,
        nom_usuario: this.ciudadForm.get('nom_usuario')?.value,
        clv_activo: this.usuario_editar.clv_activo,
        nombre: this.ciudadForm.get('nombre')?.value,
        apellido_Paterno: this.ciudadForm.get('apellido_Paterno')?.value,
        contrasenia: this.ciudadForm.get('contrasenia')?.value,
        id_ciudad: this.selectedCiudad.id,
        desc_ciudad: '',
        nom_puesto: this.ciudadForm.get('nom_puesto')?.value,
        nom_departamento: this.ciudadForm.get('nom_departamento')?.value,
        correo_laboral: this.ciudadForm.get('correo_laboral')?.value
      };
      this.usuariosService.editUsuario(2,usuario_enviar_editar).subscribe((resp) => {
        this.resultado = resp;
        setTimeout(() => {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.cargarInformacionDeUsuariosExistentes();
          this.reiniciar();
        }, 800);
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Crear Usuario>', detail: 'Contacte al Administrador del Sitio' });
      });
    }
  }

  reiniciar(){
    this.num_operacion=0;
    this.texto_operacion='';
    this.dspAgregarYEditarUsuario=false;
    this.usuario_editar={
      cod_Empleado: '',
      cod_Usuario: '',
      nom_usuario: '',
      clv_activo: 0,
      nombre: '',
      apellido_Paterno: '',
      contrasenia: '',
      id_ciudad: 0,
      desc_ciudad: '',
      nom_puesto: '',
      nom_departamento: '',
      correo_laboral: ''
    };
    this.ciudadForm.get('nom_usuario')!.enable();
    this.ciudadForm.get('contrasenia')!.enable();
    this.ciudadForm.reset();
  }

  editarEstatus(num_operacion: number,data:IUsuarios) {
    this.num_operacion = num_operacion;
    let estatus_original = data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea ' + texto_desactivar + ' el Usuario <b>' + data.nom_usuario + '</b> ?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivarUsuario(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivarUsuario(data:IUsuarios){
    this.usuariosService.desaUsuario(3, data).subscribe((resp) => {
      this.resultado = resp;
      setTimeout(() => {
        this.cargarInformacionDeUsuariosExistentes();
        this.messageService.add({ key: 'msj',severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
      }, 800);
    }, (error) => {
      this.messageService.add({ key: 'msj',severity: 'error', summary: 'Error en <Desactivar Usuario>', detail: 'Contacte al Administrador del Sitio' });
    });
  }


  exportarExcel() {
    if (!this.lsUsuarios.length || typeof this.lsUsuarios === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    } else {
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.lsUsuarios);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        this.saveAsExcelFile(excelBuffer, "usuarios");
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);

  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
}
