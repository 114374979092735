import { IResultadoGeneral } from './../models/resultadogeneral';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { ICliente } from '../models/cliente';
import { ClienteService } from '../services/cliente.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
/*import xlsx from 'xlsx/xlsx';*/
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-lstcliente',
  templateUrl: './lstcliente.component.html',
  styleUrls: ['./lstcliente.component.scss']
})
export class LstclienteComponent {
  @ViewChild('dt1') dt!: Table;
  lstCliente: ICliente[] = [];
  loading: boolean = false;
  dspCliente: boolean = false;
  ClienteForm!: FormGroup;
  texto_operacion: string = '';
  num_operacion: number = 0;
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  color_gris_deshabilitadas: string = '#D9D9D9';
  fecha_dia: string = new Date().toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' });

  //Permisos
  user: ICredencialUsuario = {};
  constructor(private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private clienteService: ClienteService,private auth: AuthService) {
      this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.getListaDeClientes();
    this.crearFormularioCliente();
  }

  cargarInformacionUsuario(){
    this.user = this.auth.user;
  }

  getListaDeClientes() {
    this.loading=true;
    this.clienteService.getListadoClientes().subscribe((resp) => {
      this.lstCliente = resp;
      this.loading=false;
    },
    (error)=> {
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Clientes');
    });
  }

  redireccionarNueva() {
    alert('agregar nuevo cliente');
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  crearFormularioCliente() {
    this.ClienteForm = new FormGroup({
      nombre: new FormControl('', [
        Validators.required
      ],
      ),
      id_cliente: new FormControl({ value: 0, disabled: true }, [],),
      fec_registro: new FormControl(),
      clv_activo: new FormControl()
    });
  }

  guardarInformacionDeCliente() {
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let data_enviar: ICliente = {
      id_cliente: this.num_operacion == 1 ? 0 : this.ClienteForm.get('id_cliente')?.value,
      nombre: this.ClienteForm.get('nombre')?.value.toUpperCase(),
      fec_registro: this.num_operacion == 1 ? dateFormat : this.ClienteForm.get('fec_registro')?.value,
      clv_activo: this.num_operacion == 1 ? 1 : this.ClienteForm.get('clv_activo')?.value
    };
    if (this.num_operacion == 1) {
      this.clienteService.saveCliente(data_enviar).subscribe((resp) => {
        var resultado = resp;
        if (resultado == 1) {
          setTimeout(() => {
            this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
            this.ClienteForm.reset();
            this.cancelarVentanaModal();
            this.getListaDeClientes();
          }, 800);
        }
      },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Cliente>', detail: 'Contacte al Administrador del Sitio' });
        });
    } else
      if (this.num_operacion == 2) {
        this.clienteService.editClienteCompleto(data_enviar).subscribe((resp) => {
          var resultado = resp;
          setTimeout(() => {
            this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
            this.ClienteForm.reset();
            this.cancelarVentanaModal();
            this.getListaDeClientes();
          }, 800);
        },
          (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error en <Editar Cliente>', detail: 'Contacte al Administrador del Sitio' });
          });
      }
  }

  mostrarModalAgregar() {
    this.cancelarVentanaModal();
    this.num_operacion = 1;
    this.dspCliente = true;
    this.texto_operacion = 'Agregar Nuevo Cliente';
  }

  mostrarModalEditar(cliente: ICliente) {
    this.ClienteForm.controls['nombre'].setValue(cliente.nombre);
    this.ClienteForm.controls['id_cliente'].setValue(cliente.id_cliente);
    this.ClienteForm.controls['fec_registro'].setValue(cliente.fec_registro);
    this.ClienteForm.controls['clv_activo'].setValue(cliente.clv_activo);
    this.num_operacion = 2;
    this.dspCliente = true;
    this.texto_operacion = 'Editar Cliente';
  }

  cancelarVentanaModal() {
    this.num_operacion = 0;
    this.dspCliente = false;
    this.texto_operacion = '';
    this.ClienteForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }

  confirmarEliminar(id: number, data: ICliente) {
   let estatus_original= data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea ' + texto_desactivar + ' el registro del Cliente ' + data.nombre + '?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo=estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo=estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: ICliente) {
    this.clienteService.editClienteCompleto(data).subscribe((resp) => {
      var resultado = resp;
      if (resultado == 1) {
        setTimeout(() => {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.ClienteForm.reset();
          this.cancelarVentanaModal();
          this.getListaDeClientes();
        }, 800);
      }
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Editar Cliente>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  exportarExcel() {
    if(!this.lstCliente.length || typeof this.lstCliente ==='undefined'){
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    } else {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.lstCliente);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });
      this.saveAsExcelFile(excelBuffer, "clientes");
    });
  }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);

  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario:string){
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
      },
      key: "sinInformacionDialog"
  });
  }

}
