<!--<p-blockUI [target]="pnl" [blocked]="blockedPanel">
    <i class="pi pi-lock" style="font-size: 3rem"></i>
</p-blockUI>-->
<p-sidebar [(visible)]="displaySidebar" styleClass="side-bar-menu">
    <div class="profile-companie">
        <div class="companie-image">
        </div>
    </div>
    <hr class="style-two">
    <div class="sidebar-profile-block">
        <div class="profile-image">
            <img src="../../../assets/images/user-icon.png" alt="usuario">
        </div>
        <div class="profile-info">
            <div class="info-name t-subtitle2 tc-w">{{user.name}}</div>
        </div>
    </div>
    <hr class="style-two">
    <p-menu [model]="menuItems" styleClass="menu" (click)="stop($event)">
    </p-menu>
</p-sidebar>


<div class="nav-bar p-shadow-4 pd-flex bgc-main">
    <div class="nav-bar-inside-left">
        <p-button icon="pi pi-bars" styleClass="p-button-rounded p-button-text p-button-white nav-bar-toggle-button text-white" (click)="tgSidebar()"></p-button>
        <div class="nav-bar-title t-h5 tc-w text-white">
            <div class="logo mt-2 ml-2">
                <img src="../../../assets/images/hm_logo_header-trans.svg" alt="LogoHM">
            </div>
        </div>
    </div>
    <div class="nav-bar-inside-right">
        <div class="profile-block">
            <div class="profile-image"></div>
            <div class="profile-info">
                <div class="info-name t-subtitle2 tc-w">{{user.name}}</div>
            </div>
        </div>
        <p-button icon="pi pi-sign-out" styleClass="p-button-rounded p-button-text p-button-white text-white" pTooltip="Cerrar sesión" tooltipPosition="left" (click)="salir()"></p-button>
    </div>
</div>
<div class="content mt-0">
    <router-outlet></router-outlet>
</div>
