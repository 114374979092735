import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUsuarios } from '../models/usuarios';
@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) { 
    this.apiUrl = environment.SUrlgApi + 'Usuarios';
  }

  getListadoUsuarios(num_opcion:number): Observable<IUsuarios[]> {
    return this.http
      .get<IUsuarios[]>(this.apiUrl+'/GetUsuarios/'+num_opcion)
      .pipe(retry(1), catchError(this.handleError));
  }

  public addUsuario(num_opcion:number,data: IUsuarios ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.post<any>(this.apiUrl + '/addUsuario/'+num_opcion,body,{'headers':headers});
   }

  public editUsuario(num_opcion:number,data: IUsuarios ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.post<any>(this.apiUrl + '/editUsuario/'+num_opcion,body,{'headers':headers});
   }

   public desaUsuario(num_opcion:number,data: IUsuarios ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.post<any>(this.apiUrl + '/desactivarUsuario/'+num_opcion,body,{'headers':headers});
   }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
