import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ICliente } from '../models/cliente';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.SUrlgApi + 'Clientes';
  }


  getListadoClientes(): Observable<ICliente[]> {
    return this.http
      .get<ICliente[]>(this.apiUrl)
      .pipe(retry(1), catchError(this.handleError));
  }

  public editClienteCompleto(data: ICliente ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/editClienteCompleto/',body,{'headers':headers});
   }

   public saveCliente(data: ICliente ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl + '/saveCliente/',body,{'headers':headers});
   }

   /*
   public editCliente(num_opcion:number,id_cliente:number,nombre:string):Observable<any>{

    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const body = {};
    let params = new HttpParams().
      set('id_cliente', id_cliente).
      set('nombre', nombre);
    return this.http.put<any>(this.apiUrl + '/updCliente/'+num_opcion, body, {
      headers: headers,
      params: params,
    });
   }

  public addCliente(num_opcion:number,id_cliente:number,nombre:string):Observable<any>{
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const body = {};
    let params = new HttpParams().
      set('id_cliente', id_cliente).
      set('nombre', nombre);
    return this.http.post<any>(this.apiUrl + '/addCliente/'+num_opcion, body, {
      headers: headers,
      params: params,
    });
   }

   public editEstatusCliente(num_opcion:number,id_cliente:number,nombre:string):Observable<any>{
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const body = {};
    let params = new HttpParams().
      set('id_cliente', id_cliente).
      set('nombre', nombre);
    return this.http.post<any>(this.apiUrl + '/updStdCliente/'+num_opcion, body, {
      headers: headers,
      params: params,
    });
   }*/

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
