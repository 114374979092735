<p-toast position="top-right" key="msj"></p-toast>
<p-toast position="top-center" key="confirm" (onClose)="cancelarMensajeConfirmacion()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-6">
                    <button type="button" pButton (click)="cancelarMensajeConfirmacion()" label="Cancelar" class="p-button-secondary"></button>
                </div>
                <div class="col-6">
                    <button type="button" pButton (click)="confirmarMensaje()" label="Aceptar" class="p-button-success"></button>
                </div>
            </div>
        </div>
    </ng-template>
    </p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-box mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">Consulta de
                            <div style="color:blueviolet; display: inline;">PAO</div>
                        </header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-10">
                                <div class="form-group row mt-5">
                                    <div class="col-5">
                                        <label for="lblligero" class="col-sm-4 col-form-label color-font text-right" style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                            <span class="input-group-addon"> <i class="pi pi-calendar color-icon mr-3"
                                                    style="font-size: 2rem; color: #3B82F6;"></i>
                                            </span>
                                            Mes</label>
                                        <div class="col-sm-8">
                                            <p-dropdown [options]="meses" optionLabel="nombre"
                                                placeholder="Seleccione Mes*" autoWidth="false"
                                                [style]="{'width':'100%'}"
                                                [showClear]="true"
                                                [(ngModel)]="selectedMes"
                                                tooltip="Es el Mes a consultar" tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <label for="lblligero" class="col-sm-4 col-form-label color-font text-right" style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                            <span class="input-group-addon"> <i class="pi pi-calendar color-icon mr-3"
                                                    style="font-size: 2rem;color: #3B82F6;"></i>
                                            </span>
                                            A&ntilde;o</label>
                                        <div class="col-sm-8">
                                            <p-calendar [(ngModel)]="anio" [showIcon]="true" view="year" dateFormat="yy"
                                             [style]="{'width':'100%'}"
                                             placeholder="Seleccione un año*"></p-calendar>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <button pButton pRipple type="button" (click)="cargarInformacionMes();"
                                         label="Filtrar"  icon="pi pi-filter" [style]="{'width':'100%'}"
                                         iconPos="left" class="info"
                                         ></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 d-flex align-items-end">
                                    <button  *ngIf="mostrarbtnLimpiarFiltro"  pButton pRipple type="button" (click)="LimpiarFiltro();"
                                    label="Limpiar Filtro" class="p-button-raised p-button-warning mt-3" icon="pi pi-filter-slash"
                                    iconPos="left"
                                    pTooltip="Reiniciar Filtro"
                                    tooltipPosition="left"></button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3 mb-5" style="height: calc(100vh - 149px)">
                            <p-table #dt1 [value]="lstProyeccion" dataKey="id"  id="tablaconsultapao" 
                            responsiveLayout="scroll"
                            [scrollable]="true" scrollDirection="both"
                            scrollHeight="flex" [style]="{'min-width': '50rem'}" [columns]="cols" 
                            styleClass="p-datatable-sm"
                            [paginator]="true" [rows]="150" [rowsPerPageOptions]="[150,200,300,500]"
                            [showCurrentPageReport]="true" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                            [globalFilterFields]="['nombre_ciudad','nombre_cliente','nombre_proyecto','nombre_ruta','nombre_tipo_transporte']"
                            [loading]="loading"
                            sortField="nombre_ciudad">
                            <ng-template pTemplate="caption">
                                <div class="flex justify-content-between">
                                    <button type="button"
                                    pButton
                                    pRipple class="p-button-success ml-5" pTooltip="Exportar Catalogo a EXCEL"
                                        tooltipPosition="right" (click)="exportarExcel();">
                                        <i class="pi pi-file-excel">Exportar</i>
                                    </button>
                                    <span class="p-input-icon-left p-ml-auto">
                                        <!--<i class="pi pi-search"></i>
                                        <input pInputText type="text" size="40"
                                            (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar" />-->
                                    </span>
                                </div>
                                <div class="row d-flex justify-content-end mt-5 mb-5">
                                    <div class="col-md-3">
                                        <p-columnFilter type="text" field="nombre_ciudad"  placeholder="Ciudad" class="form-control sinborde filtro"></p-columnFilter>
                                    </div>
                                    <div class="col-md-3">
                                        <p-columnFilter type="text" field="nombre_cliente" placeholder="Cliente" class="form-control sinborde filtro"></p-columnFilter>
                                    </div>
                                    <div class="col-md-3">
                                        <p-columnFilter type="text" field="nombre_proyecto" placeholder="Proyecto" class="form-control sinborde filtro"></p-columnFilter>
                                    </div>
                                    <div class="col-md-3">
                                        <p-columnFilter type="text" field="nombre_ruta" placeholder="Ruta" class="form-control sinborde filtro"></p-columnFilter>
                                    </div>
                                </div>
                            </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pFrozenColumn  pSortableColumn="nombre_ciudad" style="min-width:200px ;max-width: 200px;"
                                        [ngClass]="{'texto_sm':true}">Ciudad
                                            <p-sortIcon
                                            field="nombre_ciudad"></p-sortIcon>
                                        </th>
                                        <th pFrozenColumn  pSortableColumn="nombre_cliente" style="min-width:200px ;max-width: 200px;"
                                        [ngClass]="{'texto_sm':true}">Cliente
                                            <p-sortIcon
                                            field="nombre_cliente"></p-sortIcon>
                                        </th>
                                        <th pFrozenColumn [frozen]="true" pSortableColumn="nombre_proyecto" style="min-width:200px ;max-width: 200px;"
                                        [ngClass]="{'texto_sm':true}">Proyecto
                                            <p-sortIcon
                                            field="nombre_proyecto"></p-sortIcon>
                                        </th>
                                        <th pFrozenColumn [frozen]="true" pSortableColumn="nombre_ruta" style="min-width:200px ;max-width:200px!important;"
                                        [ngClass]="{'texto_sm':true}">Ruta
                                            <p-sortIcon
                                            field="nombre_ruta"></p-sortIcon>
                                        </th>
                                        <th *ngFor="let col of cols" style="min-width:60px; width: 60px;" [ngClass]="{'texto_sm':true}">
                                            {{ col.header }}
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr>
                                        <td  [ngClass]="{'col-header':true,'ajustarcelda':true,'texto_sm':true}" pFrozenColumn
                                        [frozen]="true" style="min-width:200px ;max-width: 200px;">
                                            {{rowData.nombre_ciudad}}
                                        </td>
                                        <td pFrozenColumn [ngClass]="{'ajustarcelda':true,'texto_sm':true}"
                                        [frozen]="true" style="min-width:200px ;max-width: 200px;">
                                            {{rowData.nombre_cliente}}
                                        </td>
                                        <td pFrozenColumn [ngClass]="{'ajustarcelda':true,'texto_sm':true}"
                                        [frozen]="true" style="min-width:200px ;max-width: 200px;">
                                            {{rowData.nombre_proyecto}}
                                        </td>
                                        <td  [ngClass]="{'col-header':true,'celdaAsignado':true,'texto_sm':true}" pFrozenColumn
                                        [frozen]="true" style="min-width:200px ;max-width: 200px !important;">
                                            {{rowData.nombre_ruta}} - <div style="color:rgb(218, 164, 3); display: inline;">{{rowData.nombre_tipo_transporte}}</div>
                                        </td>
                                        <td *ngFor="let col of columns" style="min-width:60px; width: 60px;" pTooltip="{{col.header}}" tooltipPosition="top"
                                        [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                                             {{rowData[col.field]}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>