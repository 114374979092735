import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IUnidadTransporte } from '../models/unidadTransporte';
import { IUnidadDisponible } from '../models/unidaddisponible';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { InumEconomico } from '../models/numEconomico';
@Injectable({
  providedIn: 'root'
})
export class UnidadtransporteService {
  private apiUrl: string = '';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.SUrlgApi + 'Unidad';
  }

  getListadoUnidades() {
    return this.http.get<any>('assets/json/unidadesTransporte.json')
      .toPromise()
      .then(res => <IUnidadTransporte[]>res.data)
      .then(data => { return data; });
  }

  getListadoUnidadesDisponiblesAlDia(num_opcion: number, id_tipo_transporte: number, id_ciudad: number, fecha: string): Observable<IUnidadDisponible[]> {
    const valores = {
      params: new HttpParams().
        set('id_tipo_transporte', id_tipo_transporte).
        set('id_ciudad', id_ciudad).
        set('fecha', fecha)
    };
    return this.http
      .get<IUnidadDisponible[]>(this.apiUrl + '/GetListadoUniDisp/' + num_opcion, valores)
      .pipe(retry(1), catchError(this.handleError));

  }


  getListadoChoferes_Filtro(cod_usuario?:string): Observable<InumEconomico[]> {
    return this.http
      .get<InumEconomico[]>(this.apiUrl+'/getUnidadesSimplified')
      .pipe(retry(1), catchError(this.handleError));
  }

  postGuardarUnidadAlDia(
    num_opcion: number,
    idx_pao_unidades: number,
    idx_pao_diario: number,
    id_ruta: number,
    fecha: string,
    cod_chofer: number,
    id_unidad_transporte: number,
    cod_adicional1: number,
    cod_adicional2: number,
    id_puesto: number,
    id_puesto1: number,
    id_puesto2: number,
    clv_retardo: number,
    clv_retardo1: number,
    clv_retardo2: number,
    cod_usuario: string,
    clv_especial: number,
    clv_apoyo_driver: number,
    clv_confirma_combustible: number,
    clv_confirma_casetas : number,
    id_ruta_travel:  string,
):
    Observable<IResultadoGeneral> {
    let cod_usuario_cast: any = cod_usuario == 'undefined' ? '0999' : cod_usuario;
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const body = {};
    let params = new HttpParams().
      set('idx_pao_unidades', idx_pao_unidades).
      set('idx_pao_diario', idx_pao_diario).
      set('id_ruta', id_ruta).
      set('fecha', fecha).
      set('cod_chofer', cod_chofer).
      set('id_unidad_transporte', id_unidad_transporte)
      .set('cod_usuario', cod_usuario_cast)
      .set('cod_adicional1', cod_adicional1)
      .set('cod_adicional2', cod_adicional2)
      .set('id_puesto', id_puesto)
      .set('id_puesto1', id_puesto1)
      .set('id_puesto2', id_puesto2)
      .set('clv_retardo', clv_retardo)
      .set('clv_retardo1',clv_retardo1)
      .set('clv_retardo2', clv_retardo2)
      .set('clv_especial', clv_especial)
      .set('clv_apoyo_driver', clv_apoyo_driver)
      .set('clv_confirma_combustible',clv_confirma_combustible)
      .set('clv_confirma_casetas',clv_confirma_casetas)
      .set('id_ruta_travel',id_ruta_travel);
    return this.http.post<IResultadoGeneral>(this.apiUrl + '/GetGuardarUniDiario/' + num_opcion, body, {
      headers: headers,
      params: params,
    });
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
