import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { IRuta } from '../models/ruta';
import { RutaService } from '../services/ruta.service';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
import { IEstatus } from '../models/estatus';
import { IMes } from '../models/mes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IHistoricoFoliosDeProyecto } from '../models/historico_folios';
import { IDataGuardarFolio } from '../models/data_folio';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IFolioMensual } from '../models/FolioMensual';
import { IrptFolioRutaMensual } from '../models/rptFolioMensual';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-folios',
  templateUrl: './folios.component.html',
  styleUrls: ['./folios.component.scss']
})
export class FoliosComponent {
  @ViewChild('dt1') dt!: Table;
  lstRutas: IRuta[] = [];
  loading: boolean = false;
  /*Colores*/
  color_rojo_especial: string = '#FF0000';
  color_gris_deshabilitadas: string = '#D9D9D9';
  //Registro
  user: ICredencialUsuario = {};
  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'ACTIVA'
  },
  {
    id: 0,
    nombre: 'INACTIVA'
  }];

  //PARA EL Agregar Folio
  dsbAgregarHerramientas: boolean = false;
  desc_ruta: string = '';
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];
  selectedMes: IMes = {
    num_mes: -1,
    nombre: ""
  }
  REGEX_CARACTERES: any =/^(?=(?:.*[A-Za-z]){2})(?=(?:.*[0-9]){2})(?=(?:.*[@$?¡\-_]){1})\S{6,9}$/;
  foliosForm: FormGroup = new FormGroup({
    idx: new FormControl(0, [Validators.required]),
    id_ruta: new FormControl({ value: 0, disabled: true }, [Validators.required]),
    num_folio_proyecto: new FormControl('',[Validators.required, Validators.pattern(this.REGEX_CARACTERES)]),
    num_mes: new FormControl(0, [Validators.required]),
    num_anio: new FormControl(0, [Validators.required])
  });
  habilitar_edicion_costo: boolean = true;
  //
  dsbgestionHerramientas: boolean = false;
  anio: any;
  clv_operacion:number=0;
  lstHistorico: IHistoricoFoliosDeProyecto[]=[];
  loadinglst:boolean=false;
  //
  dsbAgregarHerramientasEditar:boolean=false;
  //reporte 1
  dbsReportes:boolean=false;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }
  anioReporte1: any;
  lstFolioMensual:IFolioMensual[]=[];
  lstRptFolioMensual: IrptFolioRutaMensual[]=[];
  loading_reporte:boolean=false;
  constructor(private rutaService: RutaService, private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private auth: AuthService) {
    this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.getListaDeRutas();
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;

    //Reportes

    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMes.nombre = mes.nombre;
        this.selectedMes.num_mes = mes.num_mes;
        //
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anio = getYear;
    this.anioReporte1 = getYear;
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  mostrarModalAgregarFolio(data: IRuta) {
    this.inicializarFechas();
    this.foliosForm.controls['idx'].setValue(0);
    this.foliosForm.controls['num_mes'].setValue(this.selectedMes.num_mes);
    this.foliosForm.controls['num_anio'].setValue(this.anio);
    this.foliosForm.controls['id_ruta'].setValue(data.clave_ruta);
    this.foliosForm.controls['num_folio_proyecto'].setValue('');
    this.deshabilitarTodos();
    this.desc_ruta = data.desc_ruta;
    this.dsbAgregarHerramientas = true;
  }

  cancelarModalAgregarFolio() {
    this.resetearFormulario();
    this.desc_ruta = '';
    this.dsbAgregarHerramientas = false;
    this.habilitar_edicion_costo = true;
  }

  habilitarEdicionFolios() {
    this.habilitarTodos();
    this.habilitar_edicion_costo = false;
  }

  habilitarEdicionFolios_historico() {
    this.foliosForm.get('num_folio_proyecto')!.enable();
    this.habilitar_edicion_costo = false;
  }

  deshabilitarTodos(){
    this.foliosForm.get('num_mes')!.disable();
    this.foliosForm.get('num_anio')!.disable();
    this.foliosForm.get('num_folio_proyecto')!.disable();
  }

  habilitarTodos(){
    this.foliosForm.get('num_mes')!.enable();
    this.foliosForm.get('num_anio')!.enable();
    this.foliosForm.get('num_folio_proyecto')!.enable();
  }



  gestionarFoliosDeRuta(data: IRuta) {
    this.loadinglst=true;
    this.lstHistorico = data.lstRutas!;
    this.desc_ruta = data.desc_ruta;
    this.dsbgestionHerramientas = true;
    setTimeout(() => {
      this.loadinglst=false;
    },
      300);
  }

  cancelarModalHistoricoFolios() {
    this.lstHistorico=[];
    this.desc_ruta = '';
    this.dsbgestionHerramientas = false;
  }



  guardarInformacionFolio() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var fecha_registro_momento = getYear + "-" + getMonth + "-" + getDay;
    let data_enviar : IDataGuardarFolio ={
      idx: 0,
      num_folio_proyecto: this.foliosForm.get('num_folio_proyecto')?.value,
      id_ruta: this.foliosForm.get('id_ruta')?.value,
      cod_usuario: this.user.cod!,
      num_mes: this.foliosForm.get('num_mes')?.value,
      num_anio: this.foliosForm.get('num_anio')?.value,
      fecha_registro: fecha_registro_momento
    };
    this.rutaService.guardarFolioRuta(data_enviar).subscribe((resp)=> {
      let rst_estatus: IResultadoGeneral = {
        clv_estatus: 0,
        messageDetails: ''
      };
      rst_estatus = resp;
      if(rst_estatus.clv_estatus==208){
        this.mostrarDialogoInformativo(rst_estatus.messageDetails);
      } else {
        setTimeout(() => {
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
        },
          300);
        this.cancelarModalAgregarFolio();
        this.getListaDeRutas();
      }
    },
    (error)=> {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Guardar Folio del Mes>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  mostrarModalEditarFolio(data:IHistoricoFoliosDeProyecto){
    this.habilitar_edicion_costo=true;
    this.foliosForm.controls['idx'].setValue(data.idx);
    this.foliosForm.controls['id_ruta'].setValue(data.id_ruta);
    this.foliosForm.controls['num_mes'].setValue(data.num_mes);
    this.foliosForm.controls['num_anio'].setValue(data.num_anio);
    this.foliosForm.controls['num_folio_proyecto'].setValue(data.num_folio_proyecto);
    //
    this.foliosForm.get('num_mes')!.disable();
    this.foliosForm.get('num_anio')!.disable();
    this.foliosForm.get('num_folio_proyecto')!.disable();
    //
    this.dsbAgregarHerramientasEditar = true;
  }

  cancelarModalEditarFolio(){
    this.dsbAgregarHerramientasEditar = false;
    this.habilitar_edicion_costo=true;
    this.resetearFormulario();
    //
    this.foliosForm.get('num_mes')!.enable();
    this.foliosForm.get('num_anio')!.enable();
  }

  resetearFormulario(){
    this.foliosForm.controls['idx'].setValue(0);
    this.foliosForm.controls['id_ruta'].setValue(0);
    this.foliosForm.controls['num_mes'].setValue(0);
    this.foliosForm.controls['num_anio'].setValue(0);
    this.foliosForm.controls['num_folio_proyecto'].setValue('');
  }

  guardarInformacionFolioEditar(){
    this.loadinglst=true;
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var fecha_registro_momento = getYear + "-" + getMonth + "-" + getDay;
    let data_enviar : IDataGuardarFolio ={
      idx: this.foliosForm.get('idx')?.value,
      num_folio_proyecto: this.foliosForm.get('num_folio_proyecto')?.value,
      id_ruta: this.foliosForm.get('id_ruta')?.value,
      cod_usuario: this.user.cod!,
      num_mes: this.foliosForm.get('num_mes')?.value,
      num_anio: this.foliosForm.get('num_anio')?.value,
      fecha_registro: fecha_registro_momento
    };
    this.rutaService.editarFolioRuta(data_enviar).subscribe((resp)=> {
      setTimeout(() => {
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
      },
        300);
      this.lstHistorico=resp;
      this.cancelarModalEditarFolio();
      this.getListaDeRutas();
      this.loadinglst=false;
    },
    (error)=> {
      this.loadinglst=false;
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Editar Folio de Ruta>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  getListaDeRutas() {
    this.loading = true;
    this.rutaService.getListadoFolios(this.user.cod!).subscribe((resp) => {
      this.lstRutas = resp;
      if(this.lstRutas.length>0){
        this.clv_operacion = this.lstRutas[0].clv_operacion!;
      }
      setTimeout(() => {
        this.loading = false;
      },
        800);

    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de RUTAS');
        this.loading = false;
      });
  }

  generarReporte1() {
   this.loading_reporte = true;
    this.lstFolioMensual = [];
    this.lstRptFolioMensual = [];
    if ((this.selectedMesReporte1 == null || this.selectedMesReporte1.num_mes == -1) || (this.anioReporte1 === 'undefined' || this.anioReporte1 == null)) {
      this.mostrarMensajeDeAlerta();
      //this.mostrarDialogoInformativoSinInformacion('Mes y Año deben seleccionarse para consultar informacion.');
      this.loading_reporte = false;
    } else {
      let mes = this.selectedMesReporte1.num_mes;
      let anio_cast: any = 0;
      if (this.anioReporte1 instanceof Date) {
        // will execute
        anio_cast = this.anioReporte1.getFullYear();
      } else {
        anio_cast = this.anioReporte1;
      }
      this.cargarInformacionDelMes(1, mes, anio_cast);
    }
  }

  mostrarMensajeDeAlerta() {
    this.loading = false;
    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'info',
      summary: 'Falta Mes y/o Año',
      detail: 'Mes y Año deben seleccionarse para consultar informacion.'
    })
  }

  cancelarMensajeConfirmacion(){
    this.messageService.clear('confirm');
  }

  confirmarMensaje(){
    this.messageService.clear('confirm');
  }

  cargarInformacionDelMes(num_opcion: number, num_mes: number, num_anio: number) {
    // aqui se manda el servicio a llamar se llena rpt_ListadoUnidades
    this.rutaService.getListadoFolioMensual(num_opcion, num_mes, num_anio).subscribe((resp) => {
      this.lstFolioMensual = resp;
      this.asignarArregloParaExportarReporte1(this.lstFolioMensual);
      if (this.lstRptFolioMensual.length == 0 || typeof this.lstRptFolioMensual === 'undefined') {
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
      } else {
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.lstRptFolioMensual);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array"
          });
          this.saveAsExcelFile(excelBuffer, "ReporteFoliosProyectoMensual" + '_' + this.selectedMesReporte1.nombre + '_' + num_anio);
        });
      }
      this.loading_reporte = false;
    },
      (error) => {
        this.loading_reporte = false;
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <ReporteFoliosProyectoMensual>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  asignarArregloParaExportarReporte1(data: IFolioMensual[]) {
    data.map((valor) => {
      this.lstRptFolioMensual.push({
        ClavedeRuta: valor.clave_ruta,
        NombredeRuta: valor.desc_ruta,
        CiudaddeOperacion: valor.desc_ciudad,
        Servicio: valor.desc_proyecto,
        Cliente: valor.desc_cliente,
        Responsable: valor.nombre_responsable,
        FolioProyecto: valor.num_folio_proyecto,
        Mes: valor.nom_mes,
        Anio: valor.num_anio,
        desc_estatus: valor.desc_estatus
      });
    });
  }

  abrirModalReportes() {
    this.inicializarFechas();
    this.dbsReportes = true;
  }


  cancelarModalReportes() {
    this.dbsReportes = false;
    this.selectedMes = {
      num_mes: 0,
      nombre: ''
    }
    this.anio = null;

    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
  }



  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName /*+ "_export_" + dateFormat */ + EXCEL_EXTENSION);

  }



  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
}
