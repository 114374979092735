<p-toast [style]="{marginTop: '80px'}" position="top-right" key="msj"></p-toast>

<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-shadow sinborde">
        <div class="card-header sinborde" style="background: none; ">
          <div class="cardicono rounded">
            <i class="pi pi-map-marker mt-3" style="font-size: 2rem; color: #fff;"></i>
          </div>
          <header style="left: 11%; top: 5%;">Ruta</header>
          <div class="row d-flex justify-content-end">
            <div class="col-2">
              <button type="button" class="btn btn-success form-control" (click)="redireccionarTodas();"
                pTooltip="ir Listado de Rutas" tooltipPosition="top">
                Ver Todas <i class="pi pi-align-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card-content card-shadow sinborde">
          <form class="form_ruta" [formGroup]="formularioRuta" (ngSubmit)="submit()">
            <div class="mx-2">
              <span class="title-light">Informacion de la Ruta <i class="pi pi-cog" style="size: 2rem;"></i></span>
              <div class="row mt-3">
                <div class="form-group col-md-4">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <p-dropdown [options]="lstTipoRuta" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="SELECCIONE TIPO DE RUTA*" formControlName="clv_especial" optionLabel="nombre"
                        [showClear]="true" optionValue="id" tooltip="Ruta Normal o Especial"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <!-- CAMBIOS ITZAEL SE AGREGA UN NUEVO INPUT----------------------------- -->
                <div class="form-group col-md-4">
                  <div class="row g-0">
                    <div class="col-sm-3 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">Tarifa</p>
                    </div>
                    <div class="col-sm-9">
                      <p-dropdown [options]="lstTarifa" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="Tarifa" formControlName="clv_tarifa" optionLabel="desc_tarifa" [showClear]="true"
                        optionValue="clv_tarifa" tooltip="Tipo Tarifa"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="form-group col-md-4">
                  <div class="row g-0">
                    <div class="col-sm-7 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">¿Devolución?</p>
                    </div>
                    <div class="col-sm-5">
                      <p-dropdown [options]="lstSituacion" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="Devolucion (SI/NO)*" formControlName="clv_devolucion" optionLabel="nombre"
                        [showClear]="true" optionValue="id" tooltip="Devolucion"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <div class="row g-0">
                    <div class="col-sm-2 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-10">
                      <p-dropdown [options]="lstConfUnidad" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="SELECCIONE TIPO DE CONF.UNIDAD*" formControlName="clave_configuracionUnidad"
                        optionLabel="nombre" [showClear]="true" optionValue="clave_configuracionUnidad"
                        tooltip="Configuración de Unidad"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-book color-icon" style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <input type="text" class="form-control sinborde" placeholder="Complemento de Ruta*"
                        formControlName="complemento_ruta" style="text-transform:uppercase;"
                        pTooltip="Comentario Complementario">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-user color-icon" style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <input type="text" class="form-control sinborde" placeholder="Encargado*"
                        formControlName="personal_encargado" style="text-transform:uppercase;">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <p-dropdown [options]="lstCiudades" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="SELECCIONE CIUDAD*" formControlName="selectedCiudad" optionLabel="nombre"
                        [showClear]="true" optionValue="id"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <p-dropdown [options]="lstProyectos" optionLabel="nombre" formControlName="selectedProyecto"
                        autoWidth="false" [style]="{'width':'100%'}" placeholder="SELECCIONE PROYECTO(SERVICIO)*"
                        optionValue="id_proyecto"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-book color-icon" style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <input type="text" class="form-control sinborde" placeholder="Origen*" formControlName="origen"
                        style="text-transform:uppercase;">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-book color-icon" style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <input type="text" class="form-control sinborde" placeholder="Destino*" formControlName="destino"
                        style="text-transform:uppercase;">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <p-dropdown [options]="lstTransporte" optionLabel="nombre" formControlName="selectedTransporte"
                        autoWidth="false" placeholder="SELECCIONE TIPO TRANSPORTE*" [style]="{'width':'100%'}"
                        optionValue="clave_tipoTransporte">
                      </p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-1 mx-0">
                      <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                    </div>
                    <div class="col-sm-11">
                      <p-dropdown [options]="lstClientes" optionLabel="nombre" formControlName="selectedCliente"
                        placeholder="SELECCIONE CLIENTE*" autoWidth="false" [style]="{'width':'100%'}"
                        optionValue="id_cliente"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <!--Activa Desde-->
              <div class="row mt-1">
                <div class="form-group col-md-4">
                  <div class="row g-0">
                    <div class="col-sm-6 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-calendar color-icon" style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">Inicio de Ruta
                        desde</p>
                    </div>
                    <div class="col-sm-6">
                      <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha"
                        tooltipPosition="top" formControlName="fec_registro">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <!--Tienda Adicional-->
                <div class="form-group col-md-4">
                  <div class="row g-0">
                    <div class="col-sm-6 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-dollar color-icon" style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">$TiendaAdicional
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <input type="number" class="form-control sinborde" pTooltip="Importe" tooltipPosition="top"
                        formControlName="imp_tienda_adicional">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
                <!--Numero de Folio de Proyecto-->
                <div class="form-group col-md-4">
                  <div class="row g-0">
                    <div class="col-sm-5 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-book color-icon" style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">Folio de
                        Proyecto</p>
                    </div>
                    <div class="col-sm-7">
                      <input type="string" class="form-control sinborde" pTooltip="Folio" tooltipPosition="top"
                        formControlName="num_folio_proyecto" style="text-transform:uppercase;">
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <!--RUTA FORANEA-->
              <div class="row">
                <div class="form-group col-md-6">
                  <div class="row g-0">
                    <div class="col-sm-4 mx-0 d-flex align-items-center">
                      <span class="input-group-addon"> <i class="pi pi-check-square color-icon"
                          style="font-size: 2rem"></i>
                      </span>
                      <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">¿Es Foranea?</p>
                    </div>
                    <div class="col-sm-8">
                      <p-dropdown [options]="lstSituacion" optionLabel="nombre" formControlName="clv_foranea"
                        placeholder="Seleccione SI O NO*" autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                        tooltip="Seleccionar si es Ruta Foranea o NO"></p-dropdown>
                      <hr class="my-0">
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="dsb_foranea" class="mt-3">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <p class="text-center texto-valido">Viatico de Driver</p>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <p class="text-center texto-valido">Viatico de Hospedaje</p>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <p class="text-center texto-valido">Viatico de Auxiliar</p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <div class="col-sm-1 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                            style="font-size: 1.5rem"></i>
                        </span>
                      </div>
                      <div class="col-sm-11">
                        <input type="number" class="form-control" style="box-shadow: 10px 5px 5px #7db2e1;"
                          placeholder="Viatico Driver*" formControlName="imp_viaticos_driver">
                        <hr class="my-0">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <div class="col-sm-1 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                            style="font-size: 1.5rem"></i>
                        </span>
                      </div>
                      <div class="col-sm-11">
                        <input type="number" class="form-control" style="box-shadow: 10px 5px 5px #7db2e1;"
                          placeholder="Viatico Hospedaje*" formControlName="imp_viaticos_hospedaje">
                        <hr class="my-0">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="row g-0">
                      <div class="col-sm-1 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                            style="font-size: 1.5rem"></i>
                        </span>
                      </div>
                      <div class="col-sm-11">
                        <input type="number" class="form-control" style="box-shadow: 10px 5px 5px #7db2e1;"
                          placeholder="Viatico Auxiliar*" formControlName="imp_viaticos_auxiliar">
                        <hr class="my-0">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--FIN RUTA FORANEA-->
              <div class="row d-flex justify-content-center mt-5">
                <div class="col-6">
                  <p *ngIf="!formularioRuta.valid" class="texto-invalido text-right">
                    <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario Proporcionar
                      La Informaci&oacute;n Obligatoria(*)</i>
                  </p>
                  <p *ngIf="formularioRuta.valid" class="texto-valido text-right">
                    <i class="pi pi-check-circle" style="font-size: 1;">Informaci&oacute;n
                      Completa</i>
                  </p>
                </div>
                <div class="col-2">
                  <button class="btn btn-primary" style="width: 100%;" type="submit" [disabled]="!formularioRuta.valid">
                    <span class="text-white">Guardar</span>
                    <i class="pi pi-save text-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div>
            <div class="row d-flex justify-content-end mt-2">
              <div class="col-2">
                <button class="btn btn-warning" style="width: 100%;" (click)="limpiar()">
                  <span class="text-white">Limpiar</span>
                  <i class="pi pi-pencil text-white"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
