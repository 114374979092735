
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ICiudad } from '../models/ciudad';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IChofer } from '../models/chofer';
import { IChoferDisponible } from '../models/choferdisponible';
import { IChoferDisponibleManiobras } from '../models/choferdisponible_maniobras';
import { Ipuestos } from '../models/Ipuestos';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
@Injectable({
  providedIn: 'root'
})
export class ChoferService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.SUrlgApi + 'Chofer';
  }

  getListadoChoferes(): Observable<IChofer[]> {
    return this.http
      .get<IChofer[]>(this.apiUrl)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoChoferes_Filtro(cod_usuario?:string): Observable<IChofer[]> {
    return this.http
      .get<IChofer[]>(this.apiUrl+'/GetListadoChoferRH/'+cod_usuario)
      .pipe(retry(1), catchError(this.handleError));
  }
  getPuestos ( ):Observable<Ipuestos[]>{
return this.http
      .get<Ipuestos[]>(this.apiUrl+'/getPuestos/')
      .pipe(retry(1), catchError(this.handleError));
  }

  public editChofer(data: IChofer ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/editChofer/',body,{'headers':headers});
   }

   public saveChofer(data: IChofer ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl + '/saveChofer/',body,{'headers':headers});
   }

  getListadoChoferesDisponiblesAlDia(num_opcion:number,id_tipo_transporte:number,id_ciudad:number,fecha:string): Observable<IChoferDisponible[]> {
    const valores = {
      params: new HttpParams().
         set('id_tipo_transporte',id_tipo_transporte).
         set('id_ciudad',id_ciudad).
         set('fecha',fecha)
    };
    return this.http
      .get<IChoferDisponible[]>(this.apiUrl+'/GetListadoChoferDisp/'+num_opcion,valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoChoferesDisponiblesAlDiaManiobras(num_opcion: number,
    idx: number,
     id_ciudad: number,
    fecha:string): Observable<IChoferDisponibleManiobras[]> {
    const valores = {
      params: new HttpParams().
         set('idx',idx).
         set('id_ciudad',id_ciudad).
         set('fecha',fecha)
    };
    return this.http
      .get<IChoferDisponibleManiobras[]>(this.apiUrl+'/GetListadoChoferDispManiobras/'+num_opcion,valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getvalidarRFC(rfc:string): Observable<boolean> {
    const valores = {
      params: new HttpParams().
         set('rfc',rfc)
    };
    return this.http
      .get<boolean>(this.apiUrl+'/GetConfirmaRFC/'+rfc)
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
  getListadoChoferesGeneral(): Observable<InombreCompletoChofer[]> {
    return this.http.get<InombreCompletoChofer[]>(`${this.apiUrl}/getChoferesGenerales`)
    .pipe(retry(1), catchError(this.handleError));
  }

}
