import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ICiudad } from '../models/ciudad';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IConfiguracionUnidad } from '../models/confUnidad';
import { IProyeccion } from '../models/proyeccion';
import { IColumnas } from '../models/columnas';
import { IOperacionDia } from '../models/operaciondia';
import { IMovDiarioUnidad } from '../models/movdiariounidad';
import { IProyeccionOriginal } from '../models/proyeccionoriginal';
@Injectable({
  providedIn: 'root'
})
export class ProyeccionService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.SUrlgApi + 'Proyeccion';
  }

  getListadoProyeccionpao(num_opcion:number,mes:number,anio?:number): Observable<IProyeccion[]> {
    return this.http
      .get<IProyeccion[]>(this.apiUrl+'/Getpao/'+num_opcion+'/'+mes+'/'+anio)
      .pipe(retry(1), catchError(this.handleError));
  }

  public editPaoPorRegistro(id:number,pao: IProyeccionOriginal):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(pao);
    return this.http.put<any>(this.apiUrl + '/guardarpaofila/'+id,body,{'headers':headers});
   }


  getListadoColumnasRangoFecha(num_opcion:number,fecha_inicio:string,fecha_fin:string): Observable<IColumnas[]> {
    const valores = {
      params: new HttpParams().
         set('fecha_inicio',fecha_inicio)
        .set('fecha_fin',fecha_fin)
    };
    return this.http
      .get<IColumnas[]>(this.apiUrl+'/GetListadoColFecha/'+num_opcion,valores)
      .pipe(retry(1), catchError(this.handleError));
  }


  getListadoOpDia(num_opcion:number,fecha:string,cod_usuario?:string): Observable<IOperacionDia[]> {
    let cod_usuario_cast: any = cod_usuario=='undefined'?'0999':cod_usuario;
    const valores = {
      params: new HttpParams().
         set('fecha',fecha)
         .set('cod_usuario',cod_usuario_cast)
    };
    return this.http
      .get<IOperacionDia[]>(this.apiUrl+'/GetMovOpe/'+num_opcion,valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoUnidadesDia(num_opcion:number,idx_pao_diario:number,id_ruta:number,fecha:string): Observable<IMovDiarioUnidad[]> {
    const valores = {
      params: new HttpParams().
         set('idx_pao_diario',idx_pao_diario).
         set('id_ruta',id_ruta).
         set('fecha',fecha)
    };
    return this.http
      .get<IMovDiarioUnidad[]>(this.apiUrl+'/GetMovUniDiario/'+num_opcion,valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoRendimientoMensual(id_ciudad_hm: number,num_mes: number,num_anio:number): Observable<any[]> {
    const valores = {
      params: new HttpParams().
        set('id_ciudad_hm', id_ciudad_hm).
        set('num_mes', num_mes).
        set('num_anio', num_anio)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/GetPromTotalRendimiento',valores)
      .pipe(retry(1), catchError(this.handleError));
  }


  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}



