<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000"
acceptLabel="Aceptar" rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 90%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-book mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">Manual de Usuario</header>
                    </div>
                    <div class="card-content">
                        <div class="row mt-5">
                            <div class="col-md-6">
                                <p class="text-justify pl-3">
                                    El proposito del Manual de Usuario es proporcionar una guia para entender el funcionamiento del Sistema 
                                    <b style="color: blueviolet;">TDO</b>(Tasa de Ocupación). 
                                    En este documento se explican el usuo y funcionamiento de los modulos y las opciones de cada apartado del Sistema. 
                                    En caso de cualquier duda, favor de contactar al area de Sistemas.  
                                </p>
                            </div>
                            <div class="col-md-6 d-flex justify-content-center">
                                <p-button label="Descargar Manual" styleClass="p-button-raised p-button-text p-button-danger" icon="pi pi-file-pdf"
                                (onClick)="downloadMyFile();">
                                </p-button>
                                <!--<a href="assets/files/Manual_TDO.pdf"
                                class="btn btn-default"
                                target="_blank"
                                download="Manual_TDO.pdf"
                                >Descargar Manual PDF</a>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--pi-file-pdf-->