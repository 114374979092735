import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ICiudad } from '../models/ciudad';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IMovDiarioUnidadCompleto } from '../models/movdiariocompleto';
import { IResultadoGeneral } from '../models/resultadogeneral';

@Injectable({
  providedIn: 'root'
})
export class AdminpaounidadesService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) { 
    this.apiUrl = environment.SUrlgApi + 'AdminPaoUnidades';
  }

  public eliminarMovimiento(data:IMovDiarioUnidadCompleto):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.put<any>(this.apiUrl + '/AdminEarseByConfirm/',body,{'headers':headers});
   }

   public cancelarMovimiento(idx:number):Observable<IResultadoGeneral>{
    return this.http.get<IResultadoGeneral>(this.apiUrl + '/cancelarRuta/'+idx);
   }
}
