import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NoordinariosService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.SUrlgApi + 'NoOrdinarios';
  }

  getChoferesActivos(): Observable<any[]> {
    return this.http
      .get<any[]>(this.apiUrl + '/GetChoferesActivos')
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoNoOrdinarios(): Observable<any[]> {
    return this.http
      .get<any[]>(this.apiUrl + '/GetTipoNoOrdinario')
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoTipoAsistencia(): Observable<any[]> {
    return this.http
      .get<any[]>(this.apiUrl + '/GetTipoAsistenciaPagada')
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoHorariosAsistenciaPagada(): Observable<any[]> {
    return this.http
      .get<any[]>(this.apiUrl + '/GetHorarioAsistencia')
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoTipoServicioAP(): Observable<any[]> {
    return this.http
      .get<any[]>(this.apiUrl + '/GetServicioAsistencia')
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoDeMovimientosFecha(fecha: string,cod_usuario?:string): Observable<any[]> {
    let cod_usuario_cast = cod_usuario=='undefined' ? '': cod_usuario!;
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('cod_usuario', cod_usuario_cast)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/GetListadoNoOrdinarios', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  public agregarAsistenciaPagada(data:any):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl + '/SaveAsistenciaPagada/',body,{'headers':headers});
   }

  public agregarTrasladoUnidad(data:any):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl + '/SaveTrasladoUnidad/',body,{'headers':headers});
   }

  public agregarCertificacion(data:any):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl + '/SaveCertificacion/',body,{'headers':headers});
   }

   public agregarCapacitacion(data:any):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl + '/SaveCapacitacion/',body,{'headers':headers});
   }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
