import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IGuardarManiobras } from '../models/guardar_maniobras';
import { IEnviarTienda } from '../models/enviar_tienda';
import { IEnviarExtension } from '../models/enviar_extension';
import { IEnviarRescate } from '../models/enviar_rescate';
import { IDataEnviarGestion } from '../models/data_enviar_gestion';

@Injectable({
  providedIn: 'root'
})
export class ExtraService {

  private apiUrl: string ='';
  constructor(private http: HttpClient) { 
    this.apiUrl = environment.SUrlgApi + 'ServiciosExtra';
  }

  getListadoDeMovimientosFecha(fecha: string,fecha_fin: string,cod_usuario?:string): Observable<any[]> {
    let cod_usuario_cast = cod_usuario=='undefined' ? '': cod_usuario!; 
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('fecha_fin', fecha_fin).
        set('cod_usuario', cod_usuario_cast)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/GetListadoServiciosExtra', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getcalculoRuta(id_ruta:number,num_dias_extra:number,num_dias_estadia:number): Observable<any> {
    const valores = {
      params: new HttpParams().
        set('id_ruta', id_ruta).
        set('num_dias_extra', num_dias_extra).
        set('num_dias_estadia', num_dias_estadia)
    };
    return this.http
      .get<any>(this.apiUrl + '/calculoTabuladoExtRuta', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  public agregarManiobras(data:IGuardarManiobras):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.post<any>(this.apiUrl + '/SaveManiobras/',body,{'headers':headers});
   }

   public agregarTiendasAdicionales(data:IEnviarTienda):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.post<any>(this.apiUrl + '/SaveTiendaAdiconal/',body,{'headers':headers});
   }

   public agregarRescateUnidad(data:IEnviarRescate):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.post<any>(this.apiUrl + '/SaveRescateUnidad/',body,{'headers':headers});
   }

   public agregarExtension(data:IEnviarExtension):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.post<any>(this.apiUrl + '/SaveExtensionRuta/',body,{'headers':headers});
   }

   public agregarGestion(data:IDataEnviarGestion):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.post<any>(this.apiUrl + '/SaveGestionRuta/',body,{'headers':headers});
   }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }


}
