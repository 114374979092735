<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 90%;">
        <div class="row mt-5 mb-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-cog mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">Listado de Usuarios</header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <button type="button" class="btn btn-primary form-control"
                                    (click)="mostrarNuevoUsuario(1);" pTooltip="Agregar un Nuevo Usuario"
                                    tooltipPosition="top">
                                    Agregar <i class="pi pi-plus-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="lsUsuarios" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="480px"
                                responsiveLayout="scroll" [globalFilterFields]="['desc_ciudad','nombre','nom_usuario']"
                                [paginator]="true" [rows]="20" [rowsPerPageOptions]="[20,40,50]"
                                [showCurrentPageReport]="true" [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [loading]="loading" sortField="nom_usuario">
                                <ng-template pTemplate="caption">
                                    <div class="flex justify-content-between">
                                        <button type="button" pButton pRipple class="p-button-success ml-5"
                                            pTooltip="Exportar Catalogo a EXCEL" tooltipPosition="right"
                                            (click)="exportarExcel();">
                                            <i class="pi pi-file-excel">Exportar</i>
                                        </button>
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="nom_usuario" class="text-center">Usuario <p-sortIcon
                                                field="nom_usuario"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombre">Nombre<p-sortIcon field="nombre"></p-sortIcon></th>
                                        <th>Apellido</th>
                                        <th>Puesto</th>
                                        <th>Departamento</th>
                                        <th>Correo</th>
                                        <th pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                                                field="desc_ciudad"></p-sortIcon></th>
                                        <th pSortableColumn="clv_activo">Estatus<p-sortIcon
                                            field="clv_activo"></p-sortIcon></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr>
                                        <td class="text-center" [ngClass]="{'color-usuario':true}">
                                            {{data.nom_usuario}}
                                        </td>
                                        <td>
                                            {{data.nombre}}
                                        </td>
                                        <td>
                                            {{data.apellido_Paterno}}
                                        </td>
                                        <td [ngClass]="{'texto_sm':true,'col-header':true}">
                                            {{data.nom_puesto}}
                                        </td>
                                        <td [ngClass]="{'texto_sm':true,'col-header':true}">
                                            {{data.nom_departamento}}
                                        </td>
                                        <td>{{data.correo_laboral}}</td>
                                        <td [ngClass]="{'col-all-ciudades':data.id_ciudad==-1}">{{data.desc_ciudad}}</td>
                                        <td>
                                            <p-tag *ngIf="data.clv_activo==1" severity="success" value="Activo"></p-tag>
                                            <p-tag *ngIf="data.clv_activo==0" severity="danger"
                                                value="Inactivo"></p-tag>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-warning" pTooltip="Editar Informacion de Usuario"
                                                    tooltipPosition="left" (click)="editarInformacion(2,data)">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                                <button class="btn btn-info" pTooltip="Cambiar Estatus de Usuario"
                                                    (click)="editarEstatus(3,data)">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="row mt-3 d-flex justify-content-center">
                            <div class="col-6">
                                <div class="mt-3">
                                </div>
                                <div class="mt-3 mb-2">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspAgregarYEditarUsuario"
        [style]="{width: '60vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
        <ng-template pTemplate="header">
            {{ texto_operacion }}
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-5">
                <div class="card-content card-shadow sinborde">
                    <form class="form_user" [formGroup]="ciudadForm" (ngSubmit)="guardarInformacion()">
                        <div class="ml-1">
                            <span class="title-light">Informacion del Usuario <i class="pi pi-cog"
                                    style="size: 2rem;"></i></span>
                            <div class="row mt-3">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="Nombre de Usuario*" formControlName="nom_usuario"
                                                pTooltip="Nombre de Usuario">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-user color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde" placeholder="Contraseña*"
                                                formControlName="contrasenia" required
                                                pTooltip="Contraseña de Acceso">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde" placeholder="Nombre Empleado*"
                                                formControlName="nombre" required style="text-transform:uppercase;"
                                                pTooltip="Nombre de Empleado">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde" placeholder="Apellido Paterno*"
                                                formControlName="apellido_Paterno" required style="text-transform:uppercase;"
                                                pTooltip="Apellido Paterno">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde" placeholder="Puesto*"
                                                formControlName="nom_puesto" required style="text-transform:uppercase;"
                                                pTooltip="Puesto de Empleado">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde" placeholder="Departamento*"
                                                formControlName="nom_departamento" required style="text-transform:uppercase;"
                                                pTooltip="Departamento">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstCiudades" autoWidth="false"
                                                [style]="{'width':'100%'}" placeholder="Seleccione Ciudad*"
                                                formControlName="id_ciudad" optionLabel="nombre" [showClear]="true"
                                                pTooltip="Ciudad"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-at color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde" placeholder="Correo*"
                                            formControlName="correo_laboral" required style="text-transform:uppercase;"
                                            pTooltip="Correo">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mt-3">
                                <div class="col-4">
                                    <p *ngIf="!ciudadForm.valid" class="texto-invalido text-right">
                                        <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario Proporcionar La Informaci&oacute;n Obligatoria(*)</i>
                                    </p>
                                    <p *ngIf="ciudadForm.valid" class="texto-valido text-right">
                                        <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n Completa</i>
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary form-control" type="submit"
                                        [disabled]="!ciudadForm.valid">
                                        <span class="text-white">Guardar</span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarMostrarAgregarCiudad()"></button>
        </ng-template>
    </p-dialog>
</div>
