import { Component } from '@angular/core';
import { ITotalServicios } from '../models/totalservicios';
import { IUnidadesUtilizadas } from '../models/unidadesdia';
import { IChoferesDia } from '../models/choferesdia';
import { IChoferesNoAsignados } from '../models/choferesnoasignados';

import { ConfirmationService, MessageService } from 'primeng/api';
import { IColumnas } from '../models/columnas';
import { IChoferesNoAsignadosRango } from '../models/choferesnoasignadosrango';
import { ReporteService } from '../services/reporte.service';
import { saveAs } from 'file-saver';
import { ITotalServiciosrpt } from '../models/rpt_totalservicios';
import { IUnidadesUtilizadas_rpt } from '../models/unidadesdia_rpt';
import { IMovimientosFecha } from '../models/movfecha';
import { MovFechaService } from '../services/movfecha.service';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';

import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { RecursosService } from '../services/recursos.service';
import { IRecursosMaster } from '../models/recursos_master';
import { IRecursoCasetas } from '../models/recurso_casetas';
import { IRecursoCombustible } from '../models/recurso_combustible';

interface IColumn {
  field: string;
  header: string;
  customExportHeader?: string;
}

interface ExportColumn {
  title: string;
  dataKey: string;
}

@Component({
  selector: 'app-rptservicios',
  templateUrl: './rptservicios.component.html',
  styleUrls: ['./rptservicios.component.scss']
})
export class RptserviciosComponent {
  lstTotalServicios: ITotalServicios[] = [];
  lstUnidadesAlDiaNormal: IUnidadesUtilizadas[] = [];
  lstUnidadesAlDiaEspecial: IUnidadesUtilizadas[] = [];
  lstChoferesAlDiaNormal: IChoferesDia[] = [];
  lstChoferesAlDiaEspecial: IChoferesDia[] = [];
  lstChoferesNoAsignados: IChoferesNoAsignados[] = [];
  lstChoferesNoAsignadosRango: IChoferesNoAsignadosRango[] = [];
  loading: boolean = false;
  fecha_dia: any = null;
  fecha_inicio_filtro: any = null;
  fecha_fin_filtro: any = null;
  mostrarbtnLimpiarFiltro: boolean = false;
  texto_totalServicios: string = '';
  cols: IColumnas[] = [{
    field: 'dia01',
    header: '1',
    dia: 1,
    mes: 0
  },
  {
    field: 'dia02',
    header: '2',
    dia: 2,
    mes: 0
  },
  {
    field: 'dia03',
    header: '3',
    dia: 3,
    mes: 0
  },
  {
    field: 'dia04',
    header: '4',
    dia: 4,
    mes: 0
  },
  {
    field: 'dia05',
    header: '5',
    dia: 5,
    mes: 0
  },
  {
    field: 'dia06',
    header: '6',
    dia: 6,
    mes: 0
  },
  {
    field: 'dia07',
    header: '7',
    dia: 7,
    mes: 0
  },
  {
    field: 'dia08',
    header: '8',
    dia: 8,
    mes: 0
  },
  {
    field: 'dia09',
    header: '9',
    dia: 9,
    mes: 0
  },
  {
    field: 'dia10',
    header: '10',
    dia: 10,
    mes: 0
  },
  {
    field: 'dia11',
    header: '11',
    dia: 11,
    mes: 0
  },
  {
    field: 'dia12',
    header: '12',
    dia: 12,
    mes: 0
  },
  {
    field: 'dia13',
    header: '13',
    dia: 13,
    mes: 0
  },
  {
    field: 'dia14',
    header: '14',
    dia: 14,
    mes: 0
  },
  {
    field: 'dia15',
    header: '15',
    dia: 15,
    mes: 0
  },
  {
    field: 'dia16',
    header: '16',
    dia: 16,
    mes: 0
  },
  {
    field: 'dia17',
    header: '17',
    dia: 17,
    mes: 0
  },
  {
    field: 'dia18',
    header: '18',
    dia: 18,
    mes: 0
  },
  {
    field: 'dia19',
    header: '19',
    dia: 19,
    mes: 0
  },
  {
    field: 'dia20',
    header: '20',
    dia: 20,
    mes: 0
  },
  {
    field: 'dia21',
    header: '21',
    dia: 21,
    mes: 0
  },
  {
    field: 'dia22',
    header: '22',
    dia: 22,
    mes: 0
  },
  {
    field: 'dia23',
    header: '23',
    dia: 23,
    mes: 0
  },
  {
    field: 'dia24',
    header: '24',
    dia: 24,
    mes: 0
  },
  {
    field: 'dia25',
    header: '25',
    dia: 25,
    mes: 0
  },
  {
    field: 'dia26',
    header: '26',
    dia: 26,
    mes: 0
  },
  {
    field: 'dia27',
    header: '27',
    dia: 27,
    mes: 0
  },
  {
    field: 'dia28',
    header: '28',
    dia: 28,
    mes: 0
  },
  {
    field: 'dia29',
    header: '29',
    dia: 29,
    mes: 0
  },
  {
    field: 'dia30',
    header: '30',
    dia: 30,
    mes: 0
  },
  {
    field: 'dia31',
    header: '31',
    dia: 31,
    mes: 0
  }];

  /*Reportes*/
  lstTotalServicios_rpt: ITotalServiciosrpt[] = [];
  lstUnidadesAlDiaNormal_rpt: IUnidadesUtilizadas_rpt[] = [];
  lstUnidadesAlDiaEspecial_rpt: IUnidadesUtilizadas_rpt[] = [];
  /*Textos de ERROR INFORMACION*/
  texto_total_servicios: string = '';

  //Reporte PDF
  lstMovimientos: IMovimientosFecha[] = [];
  user: ICredencialUsuario = {};
  cols2!: IColumn[];
  exportColumns!: ExportColumn[];
  //Gestor de Recursos
  recurso: IRecursosMaster = {
    idx_pao_diario_unidades: 0,
    id_unidad_transporte: 0,
    num_economico: '',
    listCasetas: [],
    listCombustible: [],
    kim_inicial: 0,
    kim_final: 0,
    cod_usuario_registro_combustible: '',
    clv_fin_ruta: 0,
    total_casetas: 0,
    total_combustible: 0,
    clv_fin_casetas: 0,
    clv_fin_combustible: 0
  };
  listCasetas: IRecursoCasetas[] = [];
  listCombustible: IRecursoCombustible[] = [];
  constructor(private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private reporteService: ReporteService,
    private movfechaService: MovFechaService,
    private auth: AuthService,
    private recursosService:RecursosService) {
    this.cargarInformacionUsuario();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  cargarInformacionPorFecha(fecha: string) {
    if (fecha == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    } else {
      this.cargarTotalServicios(1, fecha);
      this.cargarInformacionServicios(fecha, '1900-01-01');
      this.cargarRecursosPrueba();
    }
  }

  cargarRecursosPrueba(){
    this.recursosService.getListadoRecursos(11604, 115, '007-001').subscribe((resp) => {
      this.recurso = resp[0];
      this.listCasetas = this.recurso.listCasetas.filter((data) => data.cod_usuario_registro != '0000');
      this.listCombustible = this.recurso.listCombustible.filter((data) => data.cod_usuario_registro != '0000');
    },
    (error)=>{
    });
  }

  cargarColumnas() {
    this.cols2 = [
      { field: 'desc_ruta', header: 'Ruta' },
      { field: 'desc_ciudad', header: 'Ciudad' },
      { field: 'num_economico', header: 'Num.Economico' },
      { field: 'num_litros_total', header: 'Total de Litros' },
      { field: 'num_kim_total', header: 'Kim.Recorridos' },
      { field: 'num_rendimiento_total', header: 'Rendimiento Total' }
    ];

    this.exportColumns = this.cols2.map((col) => ({ title: col.header, dataKey: col.field }));
  }

  cargarTotalServicios(num_opcion: number, fecha: string) {
    this.reporteService.GetTotalDeServicios(num_opcion, fecha).subscribe((resp) => {
      this.lstTotalServicios = resp;
    },
      (error) => {
        this.texto_total_servicios = 'Ocurrio un error al mostrar Total de Servicios. Contacte al Administrador'
      });
  }

  cargarTotalServiciosRango(num_opcion: number, fecha_inicio: string, fecha_fin: string) {

  }

  cargarInformacionServicios(fecha: string, fecha_fin: string) {
    let fecha_fin_cast = fecha_fin == null ? '1900-01-01' : fecha_fin;
    this.lstMovimientos = [];
    this.loading = true;
    if (fecha == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    } else {
      this.movfechaService.getListadoDeMovimientosFecha(fecha, fecha_fin_cast, this.user.cod).subscribe((resp) => {
        this.lstMovimientos = resp;
        if (!this.lstMovimientos.length || typeof this.lstMovimientos === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion con la Fecha Seleccionada de Operaciones');
        }
        this.mostrarbtnLimpiarFiltro = true;
        this.loading = false;
      },
        (error) => {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Informacion de Operacion Diaria');
        });
    }
  }


  LimpiarFiltro() {
    this.fecha_dia = null;
    this.fecha_fin_filtro = null;
    this.fecha_inicio_filtro = null;
    this.texto_total_servicios = '';

    //Inicializar Arreglos
    this.lstTotalServicios = [];

    //Inicializar Reportes
    this.lstTotalServicios_rpt = [];
    this.lstUnidadesAlDiaNormal_rpt = [];
    this.lstUnidadesAlDiaEspecial_rpt = [];
  }

  FiltrarFechasyRuta() {
    if (this.fecha_inicio_filtro == null || this.fecha_fin_filtro == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha Inicio y Fin</b> a Filtrar');
    } else {

    }
  }

  exportarExcel(num_opcion: number) {
    switch (num_opcion) {
      case 1: {
        alert('total de Servicios' + num_opcion);
        this.exportarExcelFecha(this.lstTotalServicios, this.lstTotalServicios_rpt, 'TotaldeServicios');
        break;
      }
      case 2: {
        alert('Unidades Asignadas Normal' + num_opcion);
        break;
      }
      case 3: {
        alert('Unidades Asignadas Especial' + num_opcion);
        break;
      }
      case 4: {
        alert('Choferes Asignados Normal' + num_opcion);
        break;
      }
      case 5: {
        alert('Choferes Asignados Especial' + num_opcion);
        break;
      }
      case 6: {
        alert('Choferes NO asignados' + num_opcion);
        break;
      }
      default: alert('default');
        break;
    }
  }

  exportarExcelFecha(arreglo_normal: any[], arreglo_reporte: any[], nombre_archivo: string) {
    arreglo_reporte = [];
    if (!arreglo_normal.length || typeof arreglo_normal === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    } else {
      this.asignarArregloParaExportarTotalServicios(arreglo_normal);
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.lstTotalServicios_rpt);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        this.saveAsExcelFile(excelBuffer, nombre_archivo + '_' + this.fecha_dia);
      });
    }
  }

  asignarArregloParaExportarTotalServicios(arreglo_normal: ITotalServicios[]) {
    arreglo_normal.map(valor => {
      this.lstTotalServicios_rpt.push({
        idx: valor.idx,
        Ciudad: valor.desc_ciudad,
        TotalServicios: valor.total_Servicios,
        Fecha: valor.fecha.substring(0, 10)
      });
    });
  }

  asignarArregloParaExportarUnidadesAsignadas(arreglo_normal: ITotalServicios[]) {
    arreglo_normal.map(valor => {
      this.lstTotalServicios_rpt.push({
        idx: valor.idx,
        Ciudad: valor.desc_ciudad,
        TotalServicios: valor.total_Servicios,
        Fecha: valor.fecha.substring(0, 10)
      });
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + EXCEL_EXTENSION);

  }

  exportarPDF() {
    let data: IMovimientosFecha[] = this.lstMovimientos;
    let data_casetas: any=this.obtenerFilasCasetasPDF(this.listCasetas);
    let data_combustible: any=this.obtenerFilasCombustiblePDF(this.listCombustible);
    let nombre_encabezado = 'Pantalla De Movimientos de Operación al dia (' + this.fecha_dia + ')';
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        let doc = new jsPDF.default('l', 'pt', 'a4');
        var img = new Image();
        img.src = 'assets/images/hm_logo.png';
        doc.addImage(img, 'png', 40, 30, 175, 40);
        doc.setFontSize(14);
        var xOffnombreencabezado = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(nombre_encabezado) * doc.getFontSize() / 2);
        doc.setTextColor('blue');
        doc.text(nombre_encabezado, xOffnombreencabezado, 40);
        doc.setTextColor('black');
        doc.setFontSize(12);
        //Espacios antes del AutoTable
        doc.text('', 0, 55);
        doc.text('', 0, 75);
        //Tabla para el encabezado

        let numero_pagina = 1;
        //Tabla para el contenido
        autoTable(doc, {
          startY: 80,
          columnStyles: {
            desc_ruta: {
              halign: 'left',
              cellWidth: 180,
              textColor: 'black'
            },
            nombre_chofer: {
              halign: 'left',
              cellWidth: 130,
              textColor: 'black'
            },
            desc_ciudad: { halign: 'left', textColor: 'black', cellWidth: 80},
            num_economico: { halign: 'center', textColor: 'green', cellWidth: 40 },
            num_litros_total: { halign: 'center', cellWidth: 'auto', textColor: 'black' },
            num_kim_total: { halign: 'center', textColor: 'black', cellWidth: 'auto' },
            num_rendimiento_total: { halign: 'center', cellWidth: 'auto', textColor: 'black' },
            rendimiento: { halign: 'center', cellWidth: 270, textColor: 'black' }
          },
          bodyStyles: {
            //alto de la fila
            minCellHeight: 80
          },
          headStyles: { halign: 'center', cellWidth: 'auto', fillColor: [125, 178, 225], textColor: 'white' },
          styles: {
            fontSize: 8,
            cellPadding: 1
          },
          body: this.obtenerFilasPDF(this.lstMovimientos),
          columns: [
            { header: 'Ruta', dataKey: 'desc_ruta' },
            { header: 'Ciudad', dataKey: 'desc_ciudad' },
            { header: '#Eco', dataKey: 'num_economico' },
            { header: 'Chofer', dataKey: 'nombre_chofer' },
            { header: 'Rendimiento', dataKey: 'rendimiento' }
          ],
          didDrawPage: function (data) {
            let pageSize = doc.internal.pageSize;
            let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            //Pie de Pagina
            doc.setFontSize(10);
            let page = "Pagina " + numero_pagina + ' de ' + doc.getNumberOfPages();
            doc.text(page, (pageWidth - data.settings.margin.right) - (page.length + 50), pageHeight - 15);
            numero_pagina++;
          },
          showHead: 'everyPage',
          didParseCell: function (info) {
            /*if (info.row.section === 'body') {
              if (info.row.index === (data.length) && (info.column.dataKey === 'num_rendimiento_total'
                || info.column.dataKey === 'desc_ruta')) {
                info.cell.styles.fontStyle = 'bold';
                info.cell.styles.halign = info.column.dataKey === 'desc_ruta' ? 'center' : 'right';
              }

            }*/
          },
          didDrawCell: function (data) {
            if (data.column.dataKey === 'rendimiento' && data.cell.section === 'body') {
              /*var doc1 = new jsPDF.default('p', 'pt', 'letter');
              autoTable(doc1,{
                columns: [
                  { header: 'Ruta', dataKey: 'desc_ruta' },
                  { header: 'Ciudad', dataKey: 'desc_ciudad' },
                  { header: 'Numero Economico', dataKey: 'num_economico' },
                  { header: 'Chofer', dataKey: 'nombre_chofer' },
                  { header: 'Rendimiento', dataKey: 'rendimiento' }
                ],
                  body: [
                      ["1", "2", "3", "4","3"],
                      ["1", "2", "3", "4","3"],
                      ["1", "2", "3", "4","3"],
                      ["1", "2", "3", "4","3"]
                  ],
                  startY: data.cell.y + 2,
                  margin: {left: data.cell.x + data.cell.padding('left')},
                  tableWidth: 'wrap',
                  theme: 'grid',
                  styles: {
                      fontSize: 4,
                      cellPadding: 1,
                  }
              });*/
              //CASETAS
              autoTable(doc, {
                columns: [
                  { header: '#', dataKey: 'id_caseta' },
                  { header: 'Pago', dataKey: 'desc_pago' },
                  { header: 'Importe', dataKey: 'imp_caseta' },
                  { header: 'Registro', dataKey: 'fec_registro' }
                ],
                body:data_casetas,
                startY: data.cell.y + 2,
                margin: { left: data.cell.x + data.cell.padding('left') },
                tableWidth: 'wrap',
                theme: 'grid',
                styles: {
                  fontSize: 6,
                  cellPadding: 1,
                }
              });

              //COMBUSTIBLE
              autoTable(doc, {
                columns: [
                  { header: 'Pago', dataKey: 'desc_pago' },
                  { header: 'Litros', dataKey: 'num_litros_combustible' },
                  { header: 'Precio Litro', dataKey: 'imp_litro' },
                  { header: 'Total', dataKey: 'imp_total_combustible' },
                  { header: 'Kim.Ini', dataKey: 'kim_inicial_carga' },
                  { header: 'Kim.Fin', dataKey: 'kim_final_carga' },
                  { header: 'Recorridos', dataKey: 'kim_recorridos' },
                  { header: 'Rendimiento', dataKey: 'num_rendimiento' },
                  { header: 'Fecha', dataKey: 'fec_registro' }
                ],
                body:data_combustible,
                //startY: data.cell.y + 2,
                margin: { left: data.cell.x + data.cell.padding('left') },
                tableWidth: 'wrap',
                theme: 'grid',
                styles: {
                  fontSize: 6,
                  cellPadding: 1,
                }
              });
            }
          }
        }); //fin didDrawCell
        doc.save('ReporteDeServiciosdeRutas_' + this.fecha_dia + '.pdf');
      });
    });
  }

  obtenerFilasPDF(data: IMovimientosFecha[]): any {
    // Create a NumberFormat type object
    var formatter = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2
    });
    let data_casteo: any = [];
    let cargos_general = 0;
    let abonos_general = 0;
    var options = { style: 'currency', currency: 'USD' };
    var numberFormat = new Intl.NumberFormat('en-US', options);
    data.forEach((valor) => {
      data_casteo.push({
        desc_ruta: valor.desc_ruta,
        desc_ciudad: valor.desc_ciudad,
        num_economico: valor.num_economico,
        num_litros_total: formatter.format(Number(valor.num_litros_total)),
        num_kim_total: formatter.format(Number(valor.num_kim_total)),
        num_rendimiento_total: formatter.format(Number(valor.num_rendimiento_total)),
        nombre_chofer: valor.nombre_chofer
      });
      /*cargos_general += Number(valor.cargos);
      abonos_general += Number(valor.abonos);*/
    });

    /*data_casteo.push({
      cuenta_nombre: 'Total General',
      concepto: '',
      cargos: numberFormat.format(cargos_general).toString(),
      abonos: numberFormat.format(abonos_general).toString()
    });*/

    return data_casteo;
  }

  obtenerFilasCasetasPDF(data: IRecursoCasetas[]): any {
    // Create a NumberFormat type object
    var formatter = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2
    });
    let data_casteo: any = [];
    var options = { style: 'currency', currency: 'USD' };
    var numberFormat = new Intl.NumberFormat('en-US', options);
    data.forEach((valor) => {
      data_casteo.push({
        id_caseta: valor.id_caseta,
        desc_pago: valor.desc_pago,
        fec_registro: valor.fec_registro.substring(0,10),
        imp_caseta: formatter.format(Number(valor.imp_caseta))
      });
    });

    return data_casteo;
  }

  obtenerFilasCombustiblePDF(data: IRecursoCombustible[]): any {
    // Create a NumberFormat type object
    var formatter = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2
    });
    let data_casteo: any = [];
    var options = { style: 'currency', currency: 'USD' };
    var numberFormat = new Intl.NumberFormat('en-US', options);
    data.forEach((valor) => {
      data_casteo.push({
        id_combustible: valor.id_combustible,
        desc_pago: valor.desc_pago,
        num_litros_combustible: valor.num_litros_combustible,
        imp_litro: formatter.format(Number(valor.imp_litro)),
        imp_total_combustible: formatter.format(Number(valor.imp_total_combustible)),
        kim_inicial_carga: valor.kim_inicial_carga,
        kim_final_carga: valor.kim_final_carga,
        kim_recorridos: valor.kim_recorridos,
        num_rendimiento: valor.num_rendimiento,
        fec_registro: valor.fec_registro.substring(0,10)
      });
    });

    return data_casteo;
  }

  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
  }
}
