import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
//services
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate,CanActivateChild {
  constructor(private router: Router,private auth: AuthService){}
  
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    
    return new Promise((resolve,reject)=>{
      if(this.auth.validateToken())
        resolve(true);
      else{
        this.router.navigateByUrl("/login");
        resolve(false);
      }
    })
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      return new Promise<boolean>(async (resolve,reject)=>{
        if(this.auth.validateToken())
        resolve(true);
        else{
          this.router.navigateByUrl("/login");
          resolve(false);
        }
        //resolve(true); //TEST
      });
  }
  
}
