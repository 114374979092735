import { Component, OnInit } from '@angular/core';
import { IProyeccion } from '../models/proyeccion';
import { ProyeccionService } from '../services/proyeccion.service';

import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { saveAs } from 'file-saver';
import { IMes } from '../models/mes';
import { IColumnas } from '../models/columnas';
@Component({
  selector: 'app-consultapao',
  templateUrl: './consultapao.component.html',
  styleUrls: ['./consultapao.component.scss'],
  providers: [MessageService]
})
export class ConsultapaoComponent implements OnInit{
  lstProyeccion: IProyeccion[] = [];
  loading:boolean=false;
  mes:number=0;
  anio:any;
  anio_reporte:number=0;
  meses: IMes[] = [{num_mes:1 , nombre:"Enero"},
  {num_mes: 2, nombre:"Febrero"},
  {num_mes: 3, nombre:"Marzo"},
  {num_mes: 4, nombre:"Abril"},
  {num_mes: 5, nombre:"Mayo"},
  {num_mes: 6, nombre:"Junio"},
  {num_mes: 7, nombre:"Julio"},
  {num_mes: 8, nombre:"Agosto"},
  {num_mes: 9, nombre:"Septiembre"},
  {num_mes: 10, nombre:"Octubre"},
  {num_mes: 11, nombre:"Noviembre"},
  {num_mes: 12, nombre:"Diciembre"}];
  selectedMes: IMes = {
    num_mes:0,
    nombre:""
  }
  /*Columnas Dinamicas*/
  cols: IColumnas[] =[{
    field: '',
    header: '',
    dia: 1,
    mes:0
},
{
  field: '',
  header: '',
  dia: 2,
  mes:0
},
{
  field: '',
  header: '',
  dia: 3,
  mes:0
},
{
  field: '',
  header: '',
  dia: 4,
  mes:0
},
{
  field: '',
  header: '',
  dia: 5,
  mes:0
},
{
  field: '',
  header: '',
  dia: 6,
  mes:0
},
{
  field: '',
  header: '',
  dia: 7,
  mes:0
},
{
  field: '',
  header: '',
  dia: 8,
  mes:0
},
{
  field: '',
  header: '',
  dia: 9,
  mes:0
},
{
  field: '',
  header: '',
  dia: 10,
  mes:0
},
{
  field: '',
  header: '',
  dia: 11,
  mes:0
},
{
  field: '',
  header: '',
  dia: 12,
  mes:0
},
{
  field: '',
  header: '',
  dia: 13,
  mes:0
},
{
  field: '',
  header: '',
  dia: 14,
  mes:0
},
{
  field: '',
  header: '',
  dia: 15,
  mes:0
},
{
  field: '',
  header: '',
  dia: 16,
  mes:0
},
{
  field: '',
  header: '',
  dia: 17,
  mes:0
},
{
  field: '',
  header: '',
  dia: 18,
  mes:0
},
{
  field: '',
  header: '',
  dia: 19,
  mes:0
},
{
  field: '',
  header: '',
  dia: 20,
  mes:0
},
{
  field: '',
  header: '',
  dia: 21,
  mes:0
},
{
  field: '',
  header: '',
  dia: 22,
  mes:0
},
{
  field: '',
  header: '',
  dia: 23,
  mes:0
},
{
  field: '',
  header: '',
  dia: 24,
  mes:0
},
{
  field: '',
  header: '',
  dia: 25,
  mes:0
},
{
  field: '',
  header: '',
  dia: 26,
  mes:0
},
{
  field: '',
  header: '',
  dia: 27,
  mes:0
},
{
  field: '',
  header: '',
  dia: 28,
  mes:0
},
{
  field: '',
  header: '',
  dia: 29,
  mes:0
},
{
  field: '',
  header: '',
  dia: 30,
  mes:0
}];
  mostrarbtnLimpiarFiltro:boolean=false;
  @ViewChild('dt1') dt!: Table;
  constructor(private proyeccionService: ProyeccionService,
    private confirmationService: ConfirmationService, private messageService: MessageService){
  }

  ngOnInit(): void {
    this.inicializarFechasFormateadas();
  }

  inicializarFechasFormateadas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;

    let num_mes:number = +getMonth;

    this.meses.map((mes)=> {
      if(mes.num_mes==num_mes){
        this.selectedMes.nombre=mes.nombre;
        this.selectedMes.num_mes=mes.num_mes;
      }
    });

    //this.anio=dateFormat;
  }

  

  cargarInformacionMes() {
    this.mes=this.selectedMes==null ? 0: this.selectedMes.num_mes;
    if((this.mes==0 || this.mes==null || this.selectedMes.num_mes===null) || (this.anio===undefined || this.anio==null)){
      this.mostrarMensajeDeAlerta();
    } else {
    this.loading=true;
    this.mes=this.selectedMes.num_mes;
    let anio_cast: number = this.anio.getFullYear();
    let num_opcion: number = 1, mes: number = this.mes, anio: number = anio_cast;
    this.anio_reporte = anio_cast;
    let primerDia = new Date(anio_cast, (mes-1), 1);
    let ultimoDia = new Date(anio_cast, (mes-1) + 1, 0); 
    let fecha_inicio=this.formatDate(primerDia);
    let fecha_fin=this.formatDate(ultimoDia);
    //FORMATO 2023-04-14 YYYY-MM-DD
    this.cargarInformaciondeColumnasFechaFiltro(1,fecha_inicio,fecha_fin,mes,anio);
  }
  }

  cargarInformaciondeColumnasFechaFiltro(num_opcion:number,fecha_inicio_filtro:any,fecha_fin_filtro:any,
    mes:number,anio:number){
    this.loading=true;
    this.proyeccionService.getListadoColumnasRangoFecha(num_opcion,fecha_inicio_filtro,fecha_fin_filtro).subscribe((resp) => {
      this.cols=resp;
      this.cols = [...this.cols];
      //Llenar Informacion de la Tabla 
      this.cargarInformacionPaoFiltro(1,mes,anio);
    },
    (error)=>{
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Informacion de Columnas');
    });
  }

  formatDate(date:any) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

  cargarInformacionPaoFiltro(num_opcion:number,mes:number,anio:number){
    this.proyeccionService.getListadoProyeccionpao(num_opcion, mes,anio).subscribe((resp) => {
      this.lstProyeccion = resp;
      if(!this.lstProyeccion.length){
        setTimeout(() => {
          this.loading=false;
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b>-No existe informacion del mes y año proporcionados');
        }, 1000);
      } else {
        setTimeout(() => {
          this.loading=false;
        }, 1000);
      }
      this.mostrarbtnLimpiarFiltro = true;
    },
    (error)=> {
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Informacion de Rutas y Programado(PAO)');
    });
  }

  LimpiarFiltro(){
    this.lstProyeccion=[];
    this.cols =[];
    this.loading=false;
    this.mostrarbtnLimpiarFiltro = false;
    this.mes=0;
    this.anio=undefined;
    //Se limpian los filtros de las tablas
    this.limpiarFiltrosTabla();
  }

  limpiarFiltrosTabla(){
    this.dt.clear();
  }


  mostrarDialogoInformativoSinInformacion(mensaje_usuario:string){
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
      },
      key: "sinInformacionDialog"
  });
  this.loading=false;
  }

  mostrarMensajeDeAlerta(){
    this.loading=false;
    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'info',
      summary: 'Falta Mes y/o Año',
      detail: 'Mes y Año deben seleccionarse para consultar informacion.'
  });
  }

  cancelarMensajeConfirmacion(){
    this.messageService.clear('confirm');
    this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
     
  }

  confirmarMensaje(){
    this.messageService.clear('confirm');
    this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
  }

   /*Filtro General para la informacion de tabla*/
   applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }


  exportarExcel() {
    if(!this.lstProyeccion.length || typeof this.lstProyeccion ==='undefined'){
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    } else {
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.lstProyeccion);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        this.saveAsExcelFile(excelBuffer, "ConsultaPAO"+'_'+this.selectedMes.nombre+this.anio_reporte);
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);

  }
  
}
