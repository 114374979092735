export const EMAIL_ERR = {
    'required': "Nombre de Usuario requerido",
    'pattern': "Nombre de Usuario inválido, verifique."
  }
  
  export const PASSWORDS_ERR = {
    'required': "Contraseña requerida",
    'pattern': "Contraseña inválida (7 a 15 letras o números)"
  }
  
  export const CAPTCHA_ERR = {
    'required': "Favor de completar el captcha",
  }