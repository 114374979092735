import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IRecursosMaster } from '../models/recursos_master';
import { IRecursosMovimientos } from '../models/recursos';

@Injectable({
  providedIn: 'root'
})
export class RecursosService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) { 
    this.apiUrl = environment.SUrlgApi + 'Recursos';
  }


  getListadoRecursos(idx_pao_diario:number,id_unidad_transporte:number,num_economico:string): Observable<any> {
    const valores = {
      params: new HttpParams().
        set('idx_pao_diario_unidades', idx_pao_diario).
        set('id_unidad_transporte', id_unidad_transporte).
        set('num_economico', num_economico)
    };
    return this.http
      .get<any>(this.apiUrl + '/GetRecursos', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  postRecursos(data: IRecursosMaster ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.post<any>(this.apiUrl + '/',body,{'headers':headers});
   }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
