<p-toast position="top-right" key="msj"></p-toast>
<p-toast position="top-center" key="confirm" (onClose)="cancelarMensajeConfirmacion()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-6">
                    <button type="button" pButton (click)="cancelarMensajeConfirmacion()" label="Cancelar" class="p-button-secondary"></button>
                </div>
                <div class="col-6">
                    <button type="button" pButton (click)="confirmarMensaje()" label="Aceptar" class="p-button-success"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 98%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-calendar-plus mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">
                            <div style="display:inline">Listado de</div>
                            <div style="color:blueviolet;display:inline;" class="ml-3">Tabulado Sueldo por Ciudad</div>
                        </header>
                        <div class="row d-flex justify-content-around mt-4">
                            <div class="col-md-6">
                                <div class="row d-flex justify-content-around">
                                    <div class="col-md-5">
                                        <p-calendar [(ngModel)]="anio" view="year" [showIcon]="true" dateFormat="yy"
                                            inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                                            pTooltip="Seleccione Año"></p-calendar>
                                    </div>
                                    <div class="col-md-3">
                                        <button pButton pRipple label="Filtrar" class="p-button-success"
                                            icon="pi pi-filter" (click)="cargarInformacionTabulado(anio);"
                                            pTooltip="Cargar Información"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 d-flex justify-content-end">
                                <div>
                                    <button type="button" class="btn btn-success" (click)="abrirModalReportes()"
                                        pTooltip="Abrir Ventana" tooltipPosition="left">
                                        Reportes <i class="pi pi-file-excel"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="lstTabuladoN" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                                responsiveLayout="scroll" [globalFilterFields]="['desc_ruta','clave_ruta','id_ruta']"
                                [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]"
                                [showCurrentPageReport]="true" [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [loading]="loading">
                                <ng-template pTemplate="caption">
                                    <div class="flex justify-content-end">
                                        <p-columnFilter field="id_servicio" matchMode="equals" [showMenu]="false"
                                            tooltip="Filtrar por Cliente" tooltipPosition="top">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="lstServicioBrindado"
                                                    optionLabel="nom_servicio" optionValue="id_servicio"
                                                    (onChange)="filter($event.value)"
                                                    placeholder="Filtrar por ServicioBrindado" [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <p-columnFilter field="id_ciudad_HM" matchMode="equals" [showMenu]="false"
                                            tooltip="Filtrar por Ciudad" tooltipPosition="top">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="lstCiudad" optionLabel="nombre"
                                                    optionValue="id" (onChange)="filter($event.value)"
                                                    placeholder="Filtrar por Ciudad" [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')"
                                                placeholder="Buscar Clave,Nombre de Ruta" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="idx" class="text-center">#<p-sortIcon
                                                field="idx"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="desc_servicio">Servicio<p-sortIcon
                                                field="desc_servicio"></p-sortIcon></th>
                                        <th pSortableColumn="desc_ruta">Ruta<p-sortIcon field="desc_ruta"></p-sortIcon>
                                        </th>
                                        <th>Dias</th>
                                        <th>SueldoDriver</th>
                                        <th>SueldoAux</th>
                                        <th>AlimentoDriver</th>
                                        <th>AlimentoAux</th>
                                        <th class="text-center texto_principal">Info(?)</th>
                                        <th pSortableColumn="desc_ciudad_HM">Ciudad<p-sortIcon
                                                field="desc_ciudad_HM"></p-sortIcon></th>
                                        <th pSortableColumn="num_anio" class="text-center">Año<p-sortIcon
                                                field="num_anio"></p-sortIcon>
                                        </th>
                                        <th>Estatus</th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index>
                                    <tr>
                                        <td>
                                            <p class="font-weight-bold text-center">{{data.idx}}</p>
                                        </td>
                                        <td class="texto_azul"
                                            [ngClass]="{'texto_principal': data.id_servicio==8,
                                            'texto_azul': data.id_servicio==1,'texto_resaltado': data.id_servicio==2,
                                            'color-operacion': data.id_servicio==3,'texto_tinto': data.id_servicio==4}">
                                            {{data.desc_servicio }}
                                        </td>
                                        <td class="texto_sm text-justify texto_resaltado">
                                            {{data.desc_ruta}}
                                        </td>
                                        <td>
                                            <p *ngIf="data.id_servicio==8" class="my-0 texto_tinto">{{
                                                data.num_dias_mas}}</p>
                                        </td>
                                        <td>
                                            <p *ngIf="data.num_dias_mas<=1" class="my-0">{{data.imp_sueldo_diver |
                                                currency}}</p>
                                            <p *ngIf="data.num_dias_mas>1" class="my-0">{{data.imp_ext_est_sueldo_diver
                                                | currency}}</p>

                                        </td>
                                        <td>
                                            <p *ngIf="data.num_dias_mas<=1" class="my-0">{{data.imp_sueldo_auxiliar |
                                                currency}}</p>
                                            <p *ngIf="data.num_dias_mas>1" class="my-0">
                                                {{data.imp_ext_est_sueldo_auxiliar | currency}}</p>
                                        </td>
                                        <td>
                                            <p *ngIf="data.id_servicio==8 && data.num_dias_mas<=1" class="my-0">{{
                                                data.imp_alimento_diver |
                                                currency}}</p>
                                            <p *ngIf="data.id_servicio==8 && data.num_dias_mas>1" class="my-0">{{
                                                data.imp_ext_est_alimento_diver |
                                                currency}}</p>
                                        </td>
                                        <td>
                                            <p *ngIf="data.id_servicio==8 && data.num_dias_mas<=1" class="my-0">{{
                                                data.imp_alimento_auxiliar |
                                                currency}}</p>
                                            <p *ngIf="data.id_servicio==8 && data.num_dias_mas>1" class="my-0">{{
                                                data.imp_ext_est_alimento_auxiliar |
                                                currency}}</p>
                                        </td>
                                        <td class="text-center texto_principal">
                                            <div *ngIf="data.id_servicio==8 && data.num_dias_mas>1">
                                                <button class="btn btn-warning" pTooltip="Extensión de Ruta"
                                                    tooltipPosition="left" (click)="mostrarModalExtension(data);">
                                                    <i class="pi pi-info-circle"></i>
                                                </button>
                                            </div>
                                            <div
                                                *ngIf="data.id_servicio==1 || data.id_servicio==2 || data.id_servicio==3 ||data.id_servicio==4">
                                                {{data.imp_no_ordinario | currency}}
                                            </div>
                                        </td>
                                        <td>
                                            {{data.desc_ciudad_HM}}
                                        </td>
                                        <td class="texto_principal">
                                            {{data.num_anio}}
                                        </td>
                                        <td><p-tag *ngIf="data.clv_activo==1" severity="success" value="Activo"></p-tag>
                                            <p-tag *ngIf="data.clv_activo==0" [style]="{ 'background': '#D9D9D9'}"
                                                value="Inactivo"></p-tag>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--VENTANA DE EXTENSION DE RUTA-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoExtra"
            [style]="{width: '55vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarMostrarExtension()">
            <ng-template pTemplate="header">
                Información del Tabulador
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-1">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th colspan="2" class="texto_azul">Detalle de la Extensión de Ruta</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="texto_resaltado">Ruta</td>
                                <td class="text-justify" style="color: blue; font-weight: bold;">{{
                                    data_extension.desc_ruta}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Numero Dias</td>
                                <td style="color: orangered; font-weight: bold;">{{ data_extension.num_dias_mas}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Sueldo Ext.Driver</td>
                                <td>{{ data_extension.imp_ext_est_sueldo_diver | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Sueldo Ext.Auxiliar</td>
                                <td>{{ data_extension.imp_ext_est_sueldo_auxiliar | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Alimento Ext.Driver</td>
                                <td>{{ data_extension.imp_ext_est_alimento_diver | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Alimento Ext.Auxiliar</td>
                                <td>{{ data_extension.imp_ext_est_alimento_auxiliar | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Apoyo Entregas</td>
                                <td>{{ data_extension.imp_apoyo_entregas | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Apoyo Maniobras</td>
                                <td>{{ data_extension.imp_apoyo_maniobra | currency}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Dias de Hospedaje</td>
                                <td class="color-operacion">{{ data_extension.num_dias_hospedaje | number}}</td>
                            </tr>
                            <tr>
                                <td class="texto_resaltado">Hospedaje</td>
                                <td>{{ data_extension.imp_hospedaje | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarMostrarExtension();"></button>
            </ng-template>
        </p-dialog>
        <!--REPORTES-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
    [style]="{width: '70vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarModalReportes()">
    <ng-template pTemplate="header">
        Reportes
    </ng-template>
    <ng-template pTemplate="content">
        <div class="mt-5">
            <hr class="style-two">
            <div class="row">
                <div class="col-md-3">
                    <p class="texto_principal">Tabulados Autorizados(Año)</p>
                </div>
                <div class="col-md-2">
                    <p-calendar [(ngModel)]="anioReporte1" [showIcon]="true" view="year" dateFormat="yy"
                        [style]="{'width':'100%'}" placeholder="Seleccione un año*"></p-calendar>
                    <hr class="my-0">
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-outline-success" style="width: 100%;"
                        (click)="generarReporte1()">
                        Exportar <i class="pi pi-file-export"></i>
                    </button>
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div class="col-2 text-center">
                    <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarModalReportes();"></button>
    </ng-template>
</p-dialog>
    </div>
</div>