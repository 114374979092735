import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ICliente } from '../models/cliente';
import { environment } from 'src/environments/environment';
import { ITipoTransporte } from '../models/tipotransporte';

@Injectable({
  providedIn: 'root'
})
export class TipotransporteService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) { 
    this.apiUrl = environment.SUrlgApi + 'Transporte';
  }

  public getListadoTipoTransporte(): Observable<ITipoTransporte[]> {
    return this.http
      .get<ITipoTransporte[]>(this.apiUrl)
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
