<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="container-fluid" style="border-color: blue; border: 5px ; width: 90%;">
    <div class="row">
        <div class="col-md-12">
            <div class="card card-shadow sinborde">
                <div class="card-header sinborde" style="background: none; ">
                    <div class="cardicono rounded">
                        <i class="pi pi-history mt-3" style="font-size: 2rem; color: #fff;"></i>
                    </div>
                    <header style="left: 11%; top: 5%;">Consulta de Reportes
                        <div style="color:blueviolet; display: inline;">Operación Diaria</div>
                    </header>
                    <div class="row d-flex justify-content-end">
                        <div class="col-10">
                            <div class="form-group row mt-5">
                                <div class="col-5 flex align-items-center justify-content-center">
                                    <div class="p-fluid">
                                        <div class="field flex justify-content-center">
                                            <label for="lblligero" class="col-sm-4 col-form-label color-font text-right"
                                                style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                                <span class="input-group-addon"> <i
                                                        class="pi pi-calendar color-icon mr-3"
                                                        style="font-size: 2rem; color: #3B82F6;"></i>
                                                </span>
                                                Fecha</label>
                                        </div>
                                        <div class="field">
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                                                [(ngModel)]="fecha_dia">
                                            <hr class="my-0">
                                        </div>
                                        <div class="field">
                                            <button pButton pRipple type="button"
                                                (click)="cargarInformacionPorFecha(fecha_dia);" label="Filtrar Fecha"
                                                icon="pi pi-filter" [style]="{'width':'100%'}" iconPos="left"
                                                class="info"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <p-divider layout="vertical">

                                    </p-divider>
                                </div>
                                <div class="col-5">
                                    <div class="form-group row mt-5">
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <span class="input-group-addon"> <i
                                                            class="pi pi-calendar color-icon mr-3"
                                                            style="font-size: 2rem; color: #3B82F6;"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label for="lblligero" class="color-font text-right"
                                                        style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                                        Inicio</label>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-sm-12">
                                                    <input type="date" class="form-control sinborde"
                                                        pTooltip="Seleccione o teclee fecha Inicio"
                                                        tooltipPosition="top" [(ngModel)]="fecha_inicio_filtro">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <span class="input-group-addon"> <i
                                                            class="pi pi-calendar color-icon mr-3"
                                                            style="font-size: 2rem; color: #3B82F6;"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label for="lblligero" class="color-font text-right"
                                                        style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                                        Fin</label>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-sm-12">
                                                    <input type="date" class="form-control sinborde"
                                                        pTooltip="Seleccione o teclee fecha Fin" tooltipPosition="top"
                                                        [(ngModel)]="fecha_fin_filtro">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex justify-content-center">
                                        <div class="col-4">
                                            <button pButton pRipple type="button" (click)="FiltrarFechasyRuta();"
                                                label="Filtrar Rango" icon="pi pi-filter" iconPos="left"
                                                pTooltip="Filtrar informacion por rango de Fechas"
                                                tooltipPosition="left"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-flex align-items-start">
                            <button pButton pRipple type="button" (click)="LimpiarFiltro();" label="Limpiar Filtro"
                                class="p-button-raised p-button-warning mt-3" icon="pi pi-filter-slash" iconPos="left"
                                pTooltip="Reiniciar Filtro" tooltipPosition="left"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <p-tabView>
            <p-tabPanel header="Total de Servicios">
                <div>
                    <p-table #dt1 [value]="lstTotalServicios" [scrollable]="true" scrollHeight="flex"
                        scrollDirection="both" [paginator]="true" [rows]="15" [rowsPerPageOptions]="[15,20,30,50]"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading"
                        styleClass="p-datatable-sm">
                        <ng-template pTemplate="caption">
                            <div class="flex justify-content-between">
                                <label for="">{{ texto_totalServicios}}</label>
                                <button type="button" pButton pRipple class="p-button-success ml-5"
                                    pTooltip="Exportar Total de Servicios" tooltipPosition="right"
                                    (click)="exportarExcel(1);">
                                    <i class="pi pi-file-excel">Exportar</i>
                                </button>
                                <button type="button" pButton pRipple class="p-button-danger ml-5"
                                    pTooltip="Exportar PDF" tooltipPosition="right"
                                    (click)="exportarPDF();">
                                    <i class="pi pi-file-pdf">PDF</i>
                                </button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px">#</th>
                                <th style="width: 300px" pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                                        field="desc_ciudad"></p-sortIcon></th>
                                <th style="width: 300px" pSortableColumn="total_Servicios">Total de Servicios<p-sortIcon
                                        field="total_Servicios"></p-sortIcon></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                            <tr>
                                <td style="width: 100px">{{ (rowIndex+1) }}</td>
                                <td style="width: 300px">{{data.desc_ciudad}}</td>
                                <td style="width: 300px; color: green; font-weight: bold;">{{data.total_Servicios}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Unidades Asignadas-Normal">
                <div>
                    <p-table [value]="lstUnidadesAlDiaNormal" [scrollable]="true" scrollHeight="flex"
                        scrollDirection="both" [paginator]="true" [rows]="15" [rowsPerPageOptions]="[15,20,30,50]"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading">
                        <ng-template pTemplate="caption">
                            <div class="flex justify-content-between">
                                <label for="">{{ texto_totalServicios}}</label>
                                <button type="button" pButton pRipple class="p-button-success ml-5"
                                    pTooltip="Exportar Unidades Asignadas-Normal" tooltipPosition="right"
                                    (click)="exportarExcel(2);">
                                    <i class="pi pi-file-excel">Exportar</i>
                                </button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px">#</th>
                                <th style="width: 100px">Numero Economico</th>
                                <th style="width: 300px">Datos Generales</th>
                                <th style="width: 300px" pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                                        field="desc_ciudad"></p-sortIcon></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                            <tr>
                                <td style="width: 100px">{{ (rowIndex+1) }}</td>
                                <td style="width: 100px">{{ data.num_economico }}</td>
                                <td style="width: 300px; color: green; font-weight: bold;">{{data.datos_generales}}</td>
                                <td style="width: 300px">{{data.desc_ciudad}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Unidades Asignadas-Especial">
                <div>
                    <p-table [value]="lstUnidadesAlDiaEspecial" [scrollable]="true" scrollHeight="flex"
                        scrollDirection="both" [paginator]="true" [rows]="15" [rowsPerPageOptions]="[15,20,30,50]"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading">
                        <ng-template pTemplate="caption">
                            <div class="flex justify-content-between">
                                <label for="">{{ texto_totalServicios}}</label>
                                <button type="button" pButton pRipple class="p-button-success ml-5"
                                    pTooltip="Exportar Unidades Asignadas-Especial" tooltipPosition="right"
                                    (click)="exportarExcel(3);">
                                    <i class="pi pi-file-excel">Exportar</i>
                                </button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px">#</th>
                                <th style="width: 100px">Numero Economico</th>
                                <th style="width: 300px">Datos Generales</th>
                                <th style="width: 300px" pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                                        field="desc_ciudad"></p-sortIcon></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                            <tr>
                                <td style="width: 100px">{{ (rowIndex+1) }}</td>
                                <td style="width: 100px">{{ data.num_economico }}</td>
                                <td style="width: 300px; color: green; font-weight: bold;">{{data.datos_generales}}</td>
                                <td style="width: 300px">{{data.desc_ciudad}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Choferes Asignados-Normal">
                <div>
                    <p-table [value]="lstChoferesAlDiaNormal" [scrollable]="true" scrollHeight="flex"
                        scrollDirection="both" [paginator]="true" [rows]="15" [rowsPerPageOptions]="[15,20,30,50]"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading">
                        <ng-template pTemplate="caption">
                            <div class="flex justify-content-between">
                                <label for="">{{ texto_totalServicios}}</label>
                                <button type="button" pButton pRipple class="p-button-success ml-5"
                                    pTooltip="Exportar Choferes Asignados-Normal" tooltipPosition="right"
                                    (click)="exportarExcel(4);">
                                    <i class="pi pi-file-excel">Exportar</i>
                                </button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px">#</th>
                                <th style="width: 100px">Codigo de Chofer</th>
                                <th style="width: 300px" pSortableColumn="nombre_chofer">Nombre de Chofer<p-sortIcon
                                        field="nombre_chofer"></p-sortIcon></th>
                                <th style="width: 300px" pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                                        field="desc_ciudad"></p-sortIcon></th>
                                <th style="width: 100px">Fecha</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                            <tr>
                                <td style="width: 100px">{{ (rowIndex+1) }}</td>
                                <td style="width: 100px">{{ data.cod_chofer }}</td>
                                <td style="width: 300px; color: green; font-weight: bold;">{{data.nombre_chofer}}</td>
                                <td style="width: 300px">{{data.desc_ciudad}}</td>
                                <td style="width: 100px">{{data.fecha}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Choferes Asignados-Especial">
                <div>
                    <p-table [value]="lstChoferesAlDiaEspecial" [scrollable]="true" scrollHeight="flex"
                        scrollDirection="both" [paginator]="true" [rows]="15" [rowsPerPageOptions]="[15,20,30,50]"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading">
                        <ng-template pTemplate="caption">
                            <div class="flex justify-content-between">
                                <label for="">{{ texto_totalServicios}}</label>
                                <button type="button" pButton pRipple class="p-button-success ml-5"
                                    pTooltip="Exportar Choferes Asignados-Especial" tooltipPosition="right"
                                    (click)="exportarExcel(5);">
                                    <i class="pi pi-file-excel">Exportar</i>
                                </button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px">#</th>
                                <th style="width: 100px">Codigo de Chofer</th>
                                <th style="width: 300px" pSortableColumn="nombre_chofer">Nombre de Chofer<p-sortIcon
                                        field="nombre_chofer"></p-sortIcon></th>
                                <th style="width: 300px" pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                                        field="desc_ciudad"></p-sortIcon></th>
                                <th style="width: 100px">Fecha</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                            <tr>
                                <td style="width: 100px">{{ (rowIndex+1) }}</td>
                                <td style="width: 100px">{{ data.cod_chofer }}</td>
                                <td style="width: 300px; color: green; font-weight: bold;">{{data.nombre_chofer}}</td>
                                <td style="width: 300px">{{data.desc_ciudad}}</td>
                                <td style="width: 100px">{{data.fecha}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Choferes NO Asignados">
                <div>
                    <p-table [value]="lstChoferesNoAsignadosRango" responsiveLayout="scroll"
                    [scrollable]="true" scrollDirection="both"
                    scrollHeight="flex" [paginator]="true" [rows]="15"
                        [rowsPerPageOptions]="[15,20,30,50]" [showCurrentPageReport]="true"
                        currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading"
                        [columns]="cols">
                        <ng-template pTemplate="caption">
                            <div class="flex justify-content-between">
                                <label for="">{{ texto_totalServicios}}</label>
                                <button type="button" pButton pRipple class="p-button-success ml-5"
                                    pTooltip="Exportar Choferes NO asignados" tooltipPosition="right"
                                    (click)="exportarExcel(6);">
                                    <i class="pi pi-file-excel">Exportar</i>
                                </button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pFrozenColumn
                                    style="width: 200px" [ngClass]="{'texto_sm':true}">Codigo de
                                    Chofer
                                </th>
                                <th pFrozenColumn style="width: 300px" pSortableColumn="nombre_chofer"
                                [ngClass]="{'texto_sm':true}">Nombre<p-sortIcon
                                        field="nombre_chofer" ></p-sortIcon></th>
                                <th pFrozenColumn style="width: 300px" pSortableColumn="desc_ciudad" [ngClass]="{'texto_sm':true}">Ciudad<p-sortIcon
                                        field="desc_ciudad"></p-sortIcon></th>
                                <th *ngFor="let col of cols" style="min-width:60px; width: 60px;"
                                    [ngClass]="{'texto_sm':true}">
                                    {{ col.header }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data let-rowIndex="rowIndex" let-columns="columns">
                            <tr>
                                
                                <td style="width: 200px" pFrozenColumn
                                [frozen]="true">{{ data.cod_chofer }}</td>
                                <td style="width: 300px; color: green; font-weight: bold;" pFrozenColumn
                                [frozen]="true">{{data.nombre_chofer}}</td>
                                <td style="width: 300px" pFrozenColumn
                                [frozen]="true">{{data.desc_ciudad}}</td>
                                <td *ngFor="let col of columns" style="min-width:60px; width: 60px;"
                                    pTooltip="{{col.header}}" tooltipPosition="top"
                                    [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                                    {{data[col.field]}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>