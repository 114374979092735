<p-toast [style]="{marginTop: '80px'}" position="top-right" key="msj"></p-toast>
<p-toast position="top-center" key="confirm" (onClose)="cancelarMensajeConfirmacion()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-6">
                    <button type="button" pButton (click)="cancelarMensajeConfirmacion()" label="Cancelar" class="p-button-secondary"></button>
                </div>
                <div class="col-6">
                    <button type="button" pButton (click)="confirmarMensaje()" label="Aceptar" class="p-button-success"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 98%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-car mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <!--<header style="left: 5%; top: 5%;">Listado de Unidades</header>-->
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <button type="button" class="btn btn-success" style="width: 100%;"
                                    (click)="abrirModalReportes()" pTooltip="Abrir Ventana" tooltipPosition="left">
                                    Reportes <i class="pi pi-file-excel"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="lstUnidades" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="580px"
                                [globalFilterFields]="['num_economico','desc_placaUnidad','nombre_tipounidad','nombre_tipotransporte','nombre_configuracionunidad','nombre_ciudaddestino','desc_marca',
                                'desc_modelo','vin']" [paginator]="true" [rows]="50"
                                [rowsPerPageOptions]="[25,50,100,150,200]" [loading]="loading"
                                [showCurrentPageReport]="true" [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros">
                                <ng-template pTemplate="caption">
                                    <div class="flex">
                                        <p class="mr-2 fs-2 color-header">Costo de Unidades de Transporte</p>
                                        <p-columnFilter field="clv_estatus" matchMode="equals" [showMenu]="false">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="lstEstatus"
                                                    optionLabel="nombre" optionValue="id"
                                                    (onChange)="filter($event.value)" placeholder="Filtrar por Estatus"
                                                    [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <span class="p-input-icon-left ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')"
                                                placeholder="Buscar Economico,Transporte,Ciudad,Unidad" />
                                        </span>
                                    </div>
                                    <!--<div class="flex flex-row-reverse flex-wrap">
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar" />
                                        </span>
                                    </div>-->
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="num_economico" class="text-center"
                                            [ngClass]="{'texto_sm':true}">#Economico
                                            <p-sortIcon field="num_economico"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="desc_placaUnidad" [ngClass]="{'texto_sm':true}">
                                            Placa<p-sortIcon field="desc_placaUnidad"></p-sortIcon>
                                        </th>
                                        <th style="min-width:150px ;max-width: 150px;" [ngClass]="{'texto_sm':true}">VIN
                                        </th>
                                        <th style="min-width:180px ;max-width: 180px;" [ngClass]="{'texto_sm':true}">
                                            Descripci&oacute;n</th>
                                        <th pSortableColumn="nombre_tipotransporte" class="text-center"
                                            style="min-width:150px ;max-width: 150px;" [ngClass]="{'texto_sm':true}">
                                            Transporte<p-sortIcon field="nombre_tipotransporte"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombre_configuracionunidad" class="text-center"
                                            [ngClass]="{'texto_sm':true}">
                                            Tipo
                                            <p-sortIcon field="nombre_configuracionunidad"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombre_tipounidad" class="text-center"
                                            style="min-width:160px ;max-width: 160px;" [ngClass]="{'texto_sm':true}">
                                            Unidad<p-sortIcon field="nombre_tipounidad"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombre_ciudaddestino" class="text-center"
                                            style="min-width:160px ;max-width: 160px;" [ngClass]="{'texto_sm':true}">
                                            Ciudad
                                            Operaci&oacute;n<p-sortIcon field="nombre_ciudaddestino"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="desc_estatus" class="text-center"
                                            style="min-width:230px ;max-width: 230px;" [ngClass]="{'texto_sm':true}">
                                            Estatus/Sub-Estatus
                                            <p-sortIcon field="desc_estatus"></p-sortIcon>
                                        </th>
                                        <th style="min-width:180px ;max-width: 180px;"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-unidad>
                                    <tr
                                        [ngClass]="{'row-accessories': unidad.clv_estatus===0,'row-baja': unidad.clv_estatus==3,'texto_sm':true}">
                                        <td [ngClass]="{'color-numeconomico':true}">
                                            <p class="text-center">{{unidad.num_economico}}</p>
                                        </td>
                                        <td [ngClass]="{'color-sombreado':true}">{{unidad.desc_placaUnidad}}</td>
                                        <td>{{unidad.vin}}</td>
                                        <td [ngClass]="{'ajustarcelda':true}">{{ unidad.desc_marca +' '+
                                            unidad.desc_modelo + ' '+unidad.desc_color}}<p
                                                [ngClass]="{'color-sombreado':true}" style="display: inline;">
                                                -{{unidad.num_anio}}</p>
                                        </td>
                                        <td class="text-center">{{unidad.nombre_tipotransporte}}</td>
                                        <td class="text-center">{{unidad.nombre_configuracionunidad}}</td>
                                        <td class="text-center">{{unidad.nombre_tipounidad}}</td>
                                        <td class="text-center">{{unidad.nombre_ciudaddestino}}</td>
                                        <td class="text-center">{{unidad.desc_estatus +' -'+ unidad.desc_sub_estatus}}
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-around">
                                                <p-button styleClass="p-button-sm" icon="pi pi-plus"
                                                    pTooltip="Agregar Mensual" tooltipPosition="left"
                                                    (click)="mostrarModalAgregarCosto(unidad);"></p-button>
                                                <p-button styleClass="p-button-help p-button-sm" icon="pi pi-cog"
                                                    pTooltip="Costo Mensual" tooltipPosition="left"
                                                    (click)="gestionarCostoUnidad(unidad);"></p-button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <!--<ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-between">
                                        Un total de {{lstUnidadesTransporte ? lstUnidadesTransporte.length : 0 }}
                                        Registros.
                                    </div>
                                </ng-template>-->
                            </p-table>
                        </div>
                        <div class="row mt-3 d-flex justify-content-center">
                            <div class="col-6">
                                <div class="text-center">
                                    <p-colorPicker [(ngModel)]="color_gris_deshabilitadas"
                                        [disabled]="true"></p-colorPicker>
                                    <p class="text-left informativo" style="display: inline;">
                                        Inactiva</p>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-6">
                                <div class="text-center">
                                    <p-colorPicker [(ngModel)]="color_naranja_baja" [disabled]="true"></p-colorPicker>
                                    <p class="text-left informativo" style="display: inline;">
                                        Baja de Operación</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--REPORTES-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
            [style]="{width: '60vw','height':'820px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModalReportes()">
            <ng-template pTemplate="header">
                Reportes
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Aprovechamiento de Unidades para Costo de Venta (Inicio y Fin)</p>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha inicio"
                                tooltipPosition="top" [(ngModel)]="fecha_dia">
                            <hr class="my-0">
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha fin"
                                tooltipPosition="top" [(ngModel)]="fecha_fin">
                            <hr class="my-0">
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="generarEstructuraReporte(fecha_dia,fecha_fin)">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-2 text-center">
                            <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte"></i>
                        </div>
                    </div>
                    <hr class="style-two">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Costo Mensual de Unidad (Mes y Año)</p>
                        </div>
                        <div class="col-md-3">
                            <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*" autoWidth="false"
                                [style]="{'width':'100%'}" [showClear]="true" [(ngModel)]="selectedMesReporte1"
                                tooltip="Es el Mes a consultar" tooltipPosition="left"></p-dropdown>
                            <hr class="my-0">
                        </div>
                        <div class="col-md-3">
                            <p-calendar [(ngModel)]="anioReporte1" [showIcon]="true" view="year" dateFormat="yy"
                                [style]="{'width':'100%'}" placeholder="Seleccione un año*"></p-calendar>
                            <hr class="my-0">
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="generarReporte1()">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-2 text-center">
                            <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
                        </div>
                    </div>
                    <hr class="style-two">
                    <!--INICIO CONCENTRADO-->
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Costo de Unidades Concentrado por Proyecto (Inicio y Fin)</p>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha inicio"
                                tooltipPosition="top" [(ngModel)]="fecha_diaconcentrado">
                            <hr class="my-0">
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha fin"
                                tooltipPosition="top" [(ngModel)]="fecha_finconcentrado">
                            <hr class="my-0">
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="generarReporteConcentradoProyecto(fecha_diaconcentrado,fecha_finconcentrado)">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-2 text-center">
                            <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte_concentrado"></i>
                        </div>
                    </div>
                    <!--FIN CONCENTRADO-->
                    <hr class="style-two">
                    <!--INICIO DIFERENCIA-->
                    <div class="row" style="height: 200px;">
                        <div class="col-md-4">
                            <p class="texto-pesado">Diferencia de Costo de Operación (Mes y Año)</p>
                        </div>
                        <div class="col-md-3">
                            <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*" autoWidth="false"
                                [style]="{'width':'100%'}" [showClear]="true" [(ngModel)]="selectedMesReporteDiferencia"
                                tooltip="Es el Mes a consultar" tooltipPosition="left"></p-dropdown>
                            <hr class="my-0">
                        </div>
                        <div class="col-md-3">
                            <p-calendar [(ngModel)]="anioReporteDiferencia" [showIcon]="true" view="year" dateFormat="yy"
                                [style]="{'width':'100%'}" placeholder="Seleccione un año*"></p-calendar>
                            <hr class="my-0">
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="generarReporteDiferenciaCosto()">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center mb-5">
                        <div class="col-2 text-center">
                            <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte_diferencia"></i>
                        </div>
                    </div>
                    <!--FIN DIFERENCIA-->
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModalReportes();"></button>
            </ng-template>
        </p-dialog>
        <!--Herramientas de Costo de Unidades-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbHerramientas"
            [style]="{width: '85vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModalCosto()">
            <ng-template pTemplate="header">
                Historico Mensual de Unidad
            </ng-template>
            <ng-template pTemplate="content">
                <!--TABLA HISORICO DE LA UNIDAD-->
                <div class="row mt-3">
                    <div class="col-md-12">
                        <p-table dataKey="id"  [value]="lstHistoricoPorUnidad" [tableStyle]="{ 'min-width': '50rem' }"
                            styleClass="p-datatable-sm" [loading]="loadinglst" [scrollable]="true" scrollHeight="380px">
                            <ng-template pTemplate="caption">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <p class="d-flex align-items-center texto-valido">Numero
                                                    Economico:&nbsp;{{num_economico}}&nbsp;({{nombre_tipounidad}})</p>
                                            </div>
                                        </div>
                                        <div class="row texto-black">
                                            <div class="col-md-6">
                                                <p class="d-flex align-items-center">Datos de Unidad:&nbsp;{{
                                                    datos_generales}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Mes</th>
                                    <th>Año</th>
                                    <th>Depreciación</th>
                                    <th>Interes</th>
                                    <th>Seguro</th>
                                    <th>Arrendamiento</th>
                                    <th>Renta</th>
                                    <th>Sub-Contratación</th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data let-index="rowIndex">
                                <tr [pEditableRow]="data">
                                    <td class="color-numeconomico">{{ data.nom_mes }}</td>
                                    <td>{{ data.num_anio }}</td>
                                    <td>
                                        {{ data.imp_depreciacion | currency }}
                                    <td>
                                        {{ data.imp_interes | currency }}
                                    </td>
                                    <td>
                                        {{ data.imp_seguro | currency }}
                                    </td>
                                    <td>
                                        {{ data.imp_arrendamiento | currency }}
                                    </td>
                                    <td>
                                        {{ data.imp_renta | currency }}
                                    </td>
                                    <td>
                                        {{ data.imp_sub_contratacion | currency }}
                                    </td>
                                    <td>
                                        <div class="flex align-items-center justify-content-center gap-2">
                                            <button *ngIf="clv_operacion==3" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                                                (click)="mostrarModalEditarCosto(data)" class="p-button-rounded"
                                                pTooltip="Admin-Editar Costo Mensual"></button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModalCosto();"></button>
            </ng-template>
        </p-dialog>
        <!--Agregar Mensual Costo de Unidades-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbAgregarHerramientas"
            [style]="{width: '85vw','height':'680px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModalAgregarCosto()">
            <ng-template pTemplate="header">
                Costo Mensual de Unidad
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="d-flex align-items-center texto-valido">Numero
                                        Economico:&nbsp;{{num_economico}}&nbsp;({{nombre_tipounidad}})</p>
                                </div>
                            </div>
                            <div class="row texto-black">
                                <div class="col-md-6">
                                    <p class="d-flex align-items-center">Datos de Unidad:&nbsp;{{
                                        datos_generales}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form [formGroup]="herramientasForm" (ngSubmit)="guardarInformacionAgregarCosto()">
                        <span class="title-light mt-3">Información de la Unidad<i class="pi pi-cog"
                                style="size: 2rem;"></i>
                        </span>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Mes</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*"
                                            autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                                            formControlName="num_mes" tooltip="Es el Mes a registrar"
                                            tooltipPosition="left" optionValue="num_mes"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Año</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <p-calendar formControlName="num_anio" [showIcon]="true" view="year"
                                            dateFormat="yy" [style]="{'width':'100%'}" placeholder="Seleccione un año*"
                                            pTooltip="Seleccione o ingrese el año"></p-calendar>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Depreciación</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_depreciacion"
                                            style="text-transform:uppercase; font-weight: bold;"
                                            pTooltip="Depreciación">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Interes</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_interes"
                                            style="text-transform:uppercase; font-weight: bold;" pTooltip="Interes">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Seguro</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_seguro"
                                            style="text-transform:uppercase; font-weight: bold;" pTooltip="Seguro">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Arrendamiento</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_arrendamiento"
                                            style="text-transform:uppercase; font-weight: bold;"
                                            pTooltip="Arrendamiento">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Renta</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_renta"
                                            style="text-transform:uppercase; font-weight: bold;" pTooltip="Renta">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Sub-Contratación</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_sub_contratacion"
                                            style="text-transform:uppercase; font-weight: bold;"
                                            pTooltip="Sub-Contratación">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-4">
                                <p *ngIf="!herramientasForm.valid" class="texto-invalido text-right">
                                    <i class="pi pi-info-circle" style="font-size: 1.5rem;">Es Necesario
                                        Proporcionar La Informaci&oacute;n Obligatoria(*)</i>
                                </p>
                                <p *ngIf="herramientasForm.valid" class="texto-valido text-right">
                                    <i class="pi pi-check-circle" style="font-size: 1.5rem;">Informaci&oacute;n
                                        Completa</i>
                                </p>
                            </div>
                            <div class="col-2">
                                <button type="submit" class="btn btn-success w-100"
                                    [disabled]="!herramientasForm.valid || habilitar_edicion_costo">
                                    <span class="text-white">Aceptar</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-md-3">
                            <button class="btn btn-warning w-100" (click)="habilitarEdicionCosto(clave_tipoUnidad);"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModalAgregarCosto();"></button>
            </ng-template>
        </p-dialog>
        <!--Editar Mensual Costo de Unidades-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbAgregarHerramientasEditar"
            [style]="{width: '50vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModalEditarCosto()">
            <ng-template pTemplate="header">
                Editar Costo Mensual de Unidad
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="d-flex align-items-center texto-valido">Numero
                                        Economico:&nbsp;{{num_economico}}&nbsp;({{nombre_tipounidad}})</p>
                                </div>
                            </div>
                            <div class="row texto-black">
                                <div class="col-md-6">
                                    <p class="d-flex align-items-center">Datos de Unidad:&nbsp;{{
                                        datos_generales}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form [formGroup]="herramientasForm" (ngSubmit)="guardarInformacionEditarCosto()">
                        <span class="title-light mt-3">Información de la Unidad<i class="pi pi-cog"
                                style="size: 2rem;"></i>
                        </span>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Mes</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*"
                                            autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                                            formControlName="num_mes" tooltip="Es el Mes a registrar"
                                            tooltipPosition="left" optionValue="num_mes"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Año</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="num_anio"
                                            style="text-transform:uppercase; font-weight: bold; color: brown;"
                                            pTooltip="Año">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Depreciación</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_depreciacion"
                                            style="text-transform:uppercase; font-weight: bold;"
                                            pTooltip="Depreciación">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Interes</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_interes"
                                            style="text-transform:uppercase; font-weight: bold;" pTooltip="Interes">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Seguro</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_seguro"
                                            style="text-transform:uppercase; font-weight: bold;" pTooltip="Seguro">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Arrendamiento</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_arrendamiento"
                                            style="text-transform:uppercase; font-weight: bold;"
                                            pTooltip="Arrendamiento">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Renta</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_renta"
                                            style="text-transform:uppercase; font-weight: bold;" pTooltip="Renta">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                            <p class="ml-1 color-icon d-inline">Sub-Contratación</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" min="0" class="form-control sinborde"
                                            formControlName="imp_sub_contratacion"
                                            style="text-transform:uppercase; font-weight: bold;"
                                            pTooltip="Sub-Contratación">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-4">
                                <p *ngIf="!herramientasForm.valid" class="texto-invalido text-right">
                                    <i class="pi pi-info-circle" style="font-size: 1.5rem;">Es Necesario
                                        Proporcionar La Informaci&oacute;n Obligatoria(*)</i>
                                </p>
                                <p *ngIf="herramientasForm.valid" class="texto-valido text-right">
                                    <i class="pi pi-check-circle" style="font-size: 1.5rem;">Informaci&oacute;n
                                        Completa</i>
                                </p>
                            </div>
                            <div class="col-2">
                                <button type="submit" class="btn btn-success w-100"
                                    [disabled]="!herramientasForm.valid || habilitar_edicion_costo">
                                    <span class="text-white">Aceptar</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-md-3">
                            <button class="btn btn-warning w-100"
                                (click)="habilitarEditarCostoMensual(clave_tipoUnidad);"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModalEditarCosto();"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>