import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IDescansoFaltas } from '../models/descansosFaltas';
import { IpostDescansoFaltas } from '../models/postDescansoFatltas';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IdeleteDescansoFaltas } from '../models/deleteDescansoFaltas';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';

@Injectable({
  providedIn: 'root'
})
export class ProgramacionDescansosService {

  private apiUrl: string = environment.SUrlgApi + 'ProgDescansoFalta';

  constructor(private http: HttpClient) { }

 getListadoDescasoFalta(fec_inicio: string, fec_fin: string,  id_ciudad_hm: number, cod_usuario : string): Observable<IDescansoFaltas[]> {
  const valores = {
    params: new HttpParams().
       set('fec_inicio',fec_inicio).
       set('fec_fin',fec_fin).
       set('id_ciudad_hm',id_ciudad_hm).
       set('cod_usuario',cod_usuario)
  }
  return this.http
      .get<IDescansoFaltas[]>(this.apiUrl,valores)
      .pipe(retry(1), catchError(this.handleError));

 }

  // Utiliza el modelo IRecursosMaster
  postRecursos(data: IpostDescansoFaltas): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar =JSON.stringify(data);
    return this.http.post<IResultadoGeneral>(this.apiUrl +'/AgregaProgDescFalt', data_enviar, { headers: headers })
      .pipe(catchError(this.handleError));
  }

 deleteRecursos(data: IdeleteDescansoFaltas): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar={headers:headers, body : JSON.stringify(data)};

    return this.http.delete<IResultadoGeneral>(this.apiUrl +'/EliminarProgDescFalta', data_enviar)
      .pipe(catchError(this.handleError));
  }

  getListadoChoferesGeneral(Date: any , ciudad:number  ): Observable<InombreCompletoChofer[]> {
    const valores = {
      params: new HttpParams().
         set('fecha', Date).
         set('id_ciudad_hm', ciudad)

        }
    return this.http.get<InombreCompletoChofer[]>(this.apiUrl+'/getChoferesGenerales' , valores)
    .pipe(retry(1), catchError(this.handleError));
  }


  // const insuranceData = {
  //   id : insuranceId
  // }
  // var reqHeader = new HttpHeaders({
  //         "Content-Type": "application/json",
  //     });
  //     const httpOptions = {
  //         headers: reqHeader,
  //         body: insuranceData,
  //     };
  // return this.http.delete<any>(this.url + "users/insurance", httpOptions);
  // }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

}
