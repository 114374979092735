
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
//dependencies
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from "jwt-decode";
//models 
import Module from '../models/Module';
import User from '../models/user';
//import { Permission } from 'src/app/utils/models/Permission.model';


import { skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userBehavior = new BehaviorSubject<User | null>(null);
  $user = this.userBehavior.asObservable().pipe(skip(1));

  private tokenTag: string = "ase-auth-token";
  constructor(private cookies: CookieService) { 
    this.getUser();
  }

  setToken(token: string  | null): void {
    let user: any;
    try {
      user = jwt_decode(token as string);
      if (user['nameid']) this.userBehavior.next(this.parseUser(user));
    } catch (err) {
      user = null;
    }
    this.cookies.set(this.tokenTag, token as string);
  }

  getUser(){
    let token = this.token;
    try{
      let user : any = jwt_decode(token);
      if (user['nameid']) this.userBehavior.next(this.parseUser(user));
      return true;
    }catch(err){
      return false;
    }
  }

  get token(): string {
    return this.cookies.get(this.tokenTag);
  }

  get user(): User {
    return this.userBehavior.value as User;
  }

  get permissions():Module[]{
    let user : User = this.userBehavior.value as User;
    if(user){
      if(user.permissions) return user.permissions as Module[];
    }
    return [];
  }

  set permissions(permissions: Array<Module>){
    let user : User = this.user;
    user.permissions = permissions;
    this.userBehavior.next(user);
  }

  clearCredentials() {
    this.setToken(null);
  }

  validateToken(): boolean {
    try {
      let user: User = jwt_decode(this.cookies.get(this.tokenTag));
      if (user) {
        let expDate = new Date(+(user.exp ?? 0)  * 1000);
        if (new Date() < expDate) {
          return true;
        } else
          return false;
      }
      else
        return false;
    } catch(err) {
      return false;
    }
  }

  parseUser(userFromToken: any): User {
    return {
      name: userFromToken['unique_name'],
      profession: userFromToken['role'],
      cod:userFromToken['nameid']
      
      
    } as User;
  }

}
