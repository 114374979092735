import { Component } from '@angular/core';

@Component({
  selector: 'app-operaciondiaria',
  templateUrl: './operaciondiaria.component.html',
  styleUrls: ['./operaciondiaria.component.scss']
})
export class OperaciondiariaComponent {

}
