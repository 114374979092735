import { IChofer } from './../models/chofer';
import { IResultadoGeneral } from './../models/resultadogeneral';
import { ICiudad } from './../models/ciudad';
import { CiudadService } from './../services/ciudad.service';
import { IPuestochofer } from './../models/puestochofer';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { ChoferService } from '../services/chofer.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ITipoTransporte } from '../models/tipotransporte';
import { TipotransporteService } from '../services/tipotransporte.service';

import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
import { IEstatus } from '../models/estatus';
@Component({
  selector: 'app-lstchofer',
  templateUrl: './lstchofer.component.html',
  styleUrls: ['./lstchofer.component.scss']
})
export class LstchoferComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  lstChofer: IChofer[] = [];
  loading: boolean = false;
  //rfc
  REGEX_RFC: any ='^[A-Za-z0-9]{13}$';
  ChoferForm: FormGroup = new FormGroup({
    cod_Chofer: new FormControl({ value: 0, disabled: true }, []),
    nombres: new FormControl('', [ 
      Validators.required
    ]),
    apellido_Paterno: new FormControl('', [
      Validators.required
    ]),
    apellido_Materno: new FormControl('', [
      Validators.required
    ]),
    id_puesto_chofer: new FormControl(0, [
      Validators.required
    ]),
    id_ciudad_hm: new FormControl(0, [
      Validators.required
    ]),
    clave_tipoTransporte: new FormControl(0, [
      Validators.required
    ]),
    id_ciudad_principal: new FormControl(0, []),
    desc_puesto: new FormControl('', []),
    desc_tipo_transporte: new FormControl('', []),
    clv_activo: new FormControl(0, []),
    fec_alta: new FormControl('', [Validators.required]),
    rfc: new FormControl('', [Validators.required, Validators.pattern(this.REGEX_RFC)]),
    id_ciudad_principal_prestada: new FormControl(0, []),
    id_ciudad_hm_prestada: new FormControl(0, []),
  });
  puestos: IPuestochofer[] = [{
    id: 1,
    nombre: 'DRIVER',
    descripcion_Puesto: ''
  },
  {
    id: 2,
    nombre: 'AUXILIAR',
    descripcion_Puesto: ''
  },
  {
    id: 3,
    nombre: 'BACK UP',
    descripcion_Puesto: ''
  }];
  lstciudades: ICiudad[] = [];
  lstciudades_prestadas: ICiudad[] = [];
  dspChofer: boolean = false;
  texto_operacion: string = '';
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  id_ciudad_principal_general: number = 0;
  desc_puesto_general: string = '';
  desc_tipo_transporte: string = '';
  lstTransporte: ITipoTransporte[] = [];
  valor_ciudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  }
  color_gris_deshabilitadas:string = '#D9D9D9';
  numero_operacion:number=0;
  //Registro
  user: ICredencialUsuario = {};
  data_editar_chofer: IChofer={
    cod_Chofer: 0,
    nombres: '',
    apellido_Paterno: '',
    apellido_Materno: '',
    id_puesto_chofer: 0,
    clave_tipoTransporte: 0,
    id_ciudad_principal: 0,
    id_ciudad_hm: 0,
    desc_puesto: '',
    desc_tipo_transporte: '',
    clv_activo: 0,
    cod_usuario_registro: '',
    cod_usuario_ult_mov: '',
    desc_ciudad: '',
    fec_baja: '',
    fec_alta: '',
    rfc: '',
    id_ciudad_principal_prestada: 0,
    id_ciudad_hm_prestada: 0
  };
  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'ACTIVO'
  },
  {
    id: 0,
    nombre: 'INACTIVO'
  }];
  fecha_dia: any = null;
  existe_rfc: boolean=false;
  //ciudad prestada
  id_ciudad_principal_prestada: number = 0;
  constructor(private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private choferService: ChoferService,
    private ciudadService: CiudadService,
    private tipoTransporteService: TipotransporteService,
    private auth: AuthService) {
    this.cargarInformacionUsuario();
    this.ChoferForm.controls['id_ciudad_hm'].valueChanges.subscribe(data => {
      if (data !== null) {
        this.asignarCiudadPrincipal(data);
      }
    });
    this.ChoferForm.controls['id_ciudad_hm_prestada'].valueChanges.subscribe(data => {
      if (data !== null) {
        this.asignarCiudadPrincipalPrestada(data);
      }
    });
    this.ChoferForm.controls['clave_tipoTransporte'].valueChanges.subscribe(data => {
      if (data !== null) {
        this.asignarTransportePrincipal(data);
      }
    });
    this.ChoferForm.controls['id_puesto_chofer'].valueChanges.subscribe(data => {
      if (data !== null) {
        this.asignarPuestoPrincipal(data);
      }
    });
  }

  ngOnInit(): void {
    this.getListaDeChoferes_Filtro(this.user.cod);
    this.getListaDeCIudadHM();
    this.getListaDeTipoTransporte();
    this.inicializarFechas();
  }

  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia=dateFormat;
    //Formatear fecha de Inicio
    this.ChoferForm.controls['fec_alta'].setValue(this.fecha_dia);
  }

  cargarInformacionUsuario(){
    this.user = this.auth.user;
  }

  validarInformacionRFC(){
    let valor_numero: string = '';
    valor_numero = valor_numero + this.ChoferForm.get('rfc')?.value;
    if (valor_numero !== null) {
      if (valor_numero.length==13 && this.numero_operacion==1){
        this.choferService.getvalidarRFC(valor_numero).subscribe((resp) => {
          this.existe_rfc = resp;
          if (this.existe_rfc == true) {
            this.confirmationService.confirm({
              message: 'RFC ingresado <b>YA EXISTE</b>.Revise información o verifique existencia del Colaborador.',
              header: 'RFC Invalido',
              icon: 'pi pi-info-circle',
              accept: () => {
                //limpiar
                this.ChoferForm.controls['rfc'].setValue('');
              },
              reject: (type: any) => {
                switch (type) {
                  case ConfirmEventType.REJECT:
                    this.ChoferForm.controls['rfc'].setValue('');
                    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                    break;
                  case ConfirmEventType.CANCEL:
                    this.ChoferForm.controls['rfc'].setValue('');
                    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                    break;
                }
              }
            });
          }
        });
      } else if(valor_numero.length==13 && this.numero_operacion==2 && valor_numero!=this.data_editar_chofer.rfc){
        this.choferService.getvalidarRFC(valor_numero).subscribe((resp) => {
          this.existe_rfc = resp;
          if (this.existe_rfc == true) {
            this.confirmationService.confirm({
              message: 'RFC ingresado <b>Ya Existe</b>.Revise información o verifique existencia del Colaborador.',
              header: 'RFC Invalido',
              icon: 'pi pi-info-circle',
              accept: () => {
                //limpiar
                this.ChoferForm.controls['rfc'].setValue('');
              },
              reject: (type: any) => {
                switch (type) {
                  case ConfirmEventType.REJECT:
                    this.ChoferForm.controls['rfc'].setValue('');
                    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                    break;
                  case ConfirmEventType.CANCEL:
                    this.ChoferForm.controls['rfc'].setValue('');
                    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                    break;
                }
              }
            });
          }
        });
      }
    }
  }

  validarInformacionRFCAlTeclear(){
    let valor_numero: string = '';
    valor_numero = valor_numero + (this.ChoferForm.get('rfc')?.value);
    if (valor_numero.length==13 && this.numero_operacion==1){
      this.choferService.getvalidarRFC(valor_numero).subscribe((resp) => {
        this.existe_rfc = resp;
        if (this.existe_rfc == true) {
          this.confirmationService.confirm({
            message: 'RFC ingresado <b>Ya Existe</b>.Revise información o verifique existencia del Colaborador.',
            header: 'RFC Invalido',
            icon: 'pi pi-info-circle',
            accept: () => {
              //limpiar
              this.ChoferForm.controls['rfc'].setValue('');
            },
            reject: (type: any) => {
              switch (type) {
                case ConfirmEventType.REJECT:
                  this.ChoferForm.controls['rfc'].setValue('');
                  this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                  break;
                case ConfirmEventType.CANCEL:
                  this.ChoferForm.controls['rfc'].setValue('');
                  this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                  break;
              }
            }
          });
        }
      });
    } else if(valor_numero.length==13 && this.numero_operacion==2 && valor_numero!=this.data_editar_chofer.rfc){
      this.choferService.getvalidarRFC(valor_numero).subscribe((resp) => {
        this.existe_rfc = resp;
        if (this.existe_rfc == true) {
          this.confirmationService.confirm({
            message: 'RFC ingresado <b>Ya Existe</b>.Revise información o verifique existencia del Colaborador.',
            header: 'RFC Invalido',
            icon: 'pi pi-info-circle',
            accept: () => {
              //limpiar
              this.ChoferForm.controls['rfc'].setValue('');
            },
            reject: (type: any) => {
              switch (type) {
                case ConfirmEventType.REJECT:
                  this.ChoferForm.controls['rfc'].setValue('');
                  this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                  break;
                case ConfirmEventType.CANCEL:
                  this.ChoferForm.controls['rfc'].setValue('');
                  this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                  break;
              }
            }
          });
        }
      });
    }
  }


  mostrarModalEditar(data: IChofer,num_operacion:number) {
    this.data_editar_chofer=data;
    this.numero_operacion=num_operacion;
    this.id_ciudad_principal_general = data.id_ciudad_principal;
    this.ChoferForm.controls['cod_Chofer'].setValue(data.cod_Chofer);
    this.ChoferForm.controls['nombres'].setValue(data.nombres);
    this.ChoferForm.controls['apellido_Paterno'].setValue(data.apellido_Paterno);
    this.ChoferForm.controls['apellido_Materno'].setValue(data.apellido_Materno);
    this.ChoferForm.controls['id_puesto_chofer'].setValue(data.id_puesto_chofer);
    this.ChoferForm.controls['id_ciudad_hm'].setValue(data.id_ciudad_hm);
    this.ChoferForm.controls['id_ciudad_principal'].setValue(data.id_ciudad_principal);
    this.ChoferForm.controls['clave_tipoTransporte'].setValue(data.clave_tipoTransporte);
    this.ChoferForm.controls['desc_puesto'].setValue(data.desc_puesto);
    this.ChoferForm.controls['desc_tipo_transporte'].setValue(data.desc_tipo_transporte);
    this.ChoferForm.controls['clv_activo'].setValue(data.clv_activo);
    this.ChoferForm.controls['fec_alta'].setValue(data.fec_alta.substring(0,10));
    this.ChoferForm.controls['fec_alta'].enable();
    //
    this.ChoferForm.controls['rfc'].setValue(data.rfc);
    //
    this.id_ciudad_principal_prestada = data.id_ciudad_principal_prestada;
    this.ChoferForm.controls['id_ciudad_hm_prestada'].setValue(data.id_ciudad_hm_prestada);
    this.ChoferForm.controls['id_ciudad_principal_prestada'].setValue(data.id_ciudad_principal_prestada);
    //
    this.dspChofer = true;
    this.texto_operacion = 'Editar Colaborador';
  }

  asignarCiudadPrincipal(id_ciudad_hm: number) {
    if (this.lstciudades.length > 0) {
      this.lstciudades.map((ciudad) => {
        if (ciudad.id == id_ciudad_hm) {
          this.id_ciudad_principal_general = ciudad.id_ciudad_principal;
        }
      });
    }
  }

  asignarCiudadPrincipalPrestada(id_ciudad_hm: number) {
    if (this.lstciudades_prestadas.length > 0) {
      if(id_ciudad_hm==0){
        this.id_ciudad_principal_prestada = 0;
      } else {
        this.lstciudades_prestadas.map((ciudad) => {
          if (ciudad.id == id_ciudad_hm) {
            this.id_ciudad_principal_prestada = ciudad.id_ciudad_principal;
          }
        });
      }
    }
  }

  asignarPuestoPrincipal(id: number) {
    if (this.puestos.length > 0) {
      this.puestos.map((puesto) => {
        if (puesto.id == id) {
          this.desc_puesto_general = puesto.nombre.toUpperCase();
        }
      });
    }
  }

  asignarTransportePrincipal(id: number) {
    if (this.lstTransporte.length > 0) {
      this.lstTransporte.map((data) => {
        if (data.clave_tipoTransporte == id) {
          this.desc_tipo_transporte = data.nombre.toUpperCase();
        }
      });
    }
  }

  cancelarVentanaModal() {
    this.id_ciudad_principal_general = 0;
    this.id_ciudad_principal_prestada = 0;
    this.texto_operacion = '';
    //this.num_operacion=0;
    this.dspChofer = false;
    this.ChoferForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
    this.ChoferForm.controls['fec_alta'].enable();
  }

  guardarInformacion() {
    /*
    Numero de Operacion: 1 Nuevo. 2 Editar. 
    */
    let data_enviar: IChofer = {
      cod_Chofer: this.ChoferForm.get('cod_Chofer')?.value,
      nombres: this.ChoferForm.get('nombres')?.value.toUpperCase(),
      apellido_Paterno: this.ChoferForm.get('apellido_Paterno')?.value.toUpperCase(),
      apellido_Materno: this.ChoferForm.get('apellido_Materno')?.value.toUpperCase(),
      id_puesto_chofer: this.ChoferForm.get('id_puesto_chofer')?.value,
      clave_tipoTransporte: this.ChoferForm.get('clave_tipoTransporte')?.value,
      id_ciudad_principal: this.id_ciudad_principal_general,
      id_ciudad_hm: this.ChoferForm.get('id_ciudad_hm')?.value,
      desc_puesto: this.desc_puesto_general.toUpperCase(),
      desc_tipo_transporte: this.desc_tipo_transporte.toUpperCase(),
      clv_activo: typeof this.ChoferForm.get('clv_activo')?.value === 'object' ? 1 : this.ChoferForm.get('clv_activo')?.value,
      cod_usuario_registro: this.numero_operacion == 1 ? this.user.cod : this.data_editar_chofer.cod_usuario_registro,
      cod_usuario_ult_mov: this.numero_operacion == 2 ? this.user.cod : this.data_editar_chofer.cod_usuario_ult_mov,
      fec_baja: this.numero_operacion == 1 ? '1900-01-01T00:00:00' : this.data_editar_chofer.fec_baja,
      fec_alta: this.ChoferForm.get('fec_alta')?.value,
      rfc: this.ChoferForm.get('rfc')?.value,
      id_ciudad_principal_prestada: this.ChoferForm.get('id_ciudad_hm_prestada')?.value==0 ? 0 : this.id_ciudad_principal_prestada,
      id_ciudad_hm_prestada: this.ChoferForm.get('id_ciudad_hm_prestada')?.value==null ? 0: this.ChoferForm.get('id_ciudad_hm_prestada')?.value
    };
    if(this.numero_operacion==1){
      data_enviar.cod_usuario_ult_mov=this.user.cod;
      data_enviar.rfc = data_enviar.rfc.toUpperCase();
      this.choferService.saveChofer(data_enviar).subscribe((resp)=> {
        var resultado=resp;
          setTimeout(() => {
            this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
            this.ChoferForm.reset();
            this.cancelarVentanaModal();
            this.getListaDeChoferes_Filtro(this.user.cod);
          }, 800);
      },
      (error)=> {
        this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Chofer>', detail: 'Contacte al Administrador del Sitio' });
      });
     } else 
     if(this.numero_operacion==2){
      data_enviar.rfc = data_enviar.rfc.toUpperCase();
      this.choferService.editChofer(data_enviar).subscribe((resp)=> {
        var resultado=resp;
          setTimeout(() => {
            this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
            this.ChoferForm.reset();
            this.cancelarVentanaModal();
            this.getListaDeChoferes_Filtro(this.user.cod);
          }, 800);
      },
      (error)=> {
        this.messageService.add({ severity: 'error', summary: 'Error en <Editar Chofer>', detail: 'Contacte al Administrador del Sitio' });
      });
     }
  }

  getValorNombreCiudadPorid(id: number) {
    if (this.lstciudades.length > 0) {
      this.lstciudades.map((ciudad) => {
        if (ciudad.id == id) {
          this.valor_ciudad = ciudad;
        }
      });
    }
  }

  getListaDeChoferes() {
    this.loading=true;
    this.choferService.getListadoChoferes().subscribe((resp) => {
      this.lstChofer = resp;
      setTimeout(() => {
        this.loading=false;
      }, 1000);
    },
    (error)=> {
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Choferes');
    });
  }

  getListaDeChoferes_Filtro(cod_usuario?:string) {
    this.loading=true;
    this.choferService.getListadoChoferes_Filtro(cod_usuario).subscribe((resp) => {
      this.lstChofer = resp;
      setTimeout(() => {
        this.loading=false;
      }, 1000);
    },
    (error)=> {
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Choferes');
    });
  }

  getListaDeCIudadHM() {
    this.ChoferForm.controls['id_ciudad_hm'].disable();
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstciudades = resp;
      this.lstciudades_prestadas=resp;
      this.lstciudades_prestadas.unshift({
        id: 0,
        nombre: 'SIN ASIGNAR',
        clave_Estado: '',
        id_ciudad_principal: 0
      });
      //llenar Informacion
      this.ChoferForm.controls['id_ciudad_hm'].enable();
    },
    (error)=> {
      this.ChoferForm.controls['id_ciudad_hm'].disable();
      this.messageService.add({ severity: 'error', summary: 'Error en cargar <Listado de Ciudades>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  getListaDeTipoTransporte() {
    this.ChoferForm.controls['clave_tipoTransporte'].disable();
    this.tipoTransporteService.getListadoTipoTransporte().subscribe((resp) => {
      this.lstTransporte = resp;
      this.ChoferForm.controls['clave_tipoTransporte'].enable();
    },
    (error)=> {
      this.ChoferForm.controls['clave_tipoTransporte'].disable();
      this.messageService.add({ severity: 'error', summary: 'Error en cargar <Listado de Tipo Transporte>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  confirmarEliminar(id: number, data: IChofer) {
    let estatus_original= data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> el registro del Colaborador <b>'+data.nombres+' '+data.apellido_Paterno+'</b>?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo=estatus_original;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo=estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: IChofer) {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    data.cod_usuario_ult_mov=this.user.cod==null? '0999': this.user.cod;
    data.fec_baja = data.clv_activo==1 ? '1900-01-01T00:00:00': dateFormat;
    this.choferService.editChofer(data).subscribe((resp)=> {
      var resultado=resp;
      if(resultado==1){
        setTimeout(() => {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.ChoferForm.reset();
          this.cancelarVentanaModal();
          this.getListaDeChoferes_Filtro(this.user.cod);
        }, 800);
      }
    },
    (error)=> {
      this.messageService.add({ severity: 'error', summary: 'Error en <Editar Chofer>', detail: 'Contacte al Administrador del Sitio' });
    });
  }


  redireccionarNueva(num_operacion:number) {
    this.numero_operacion=num_operacion;
    this.id_ciudad_principal_general = 0;
    this.id_ciudad_principal_prestada=0;
    this.texto_operacion = '';
    this.ChoferForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
    // this.router.navigateByUrl("/principal/drive");
    this.texto_operacion = 'Nuevo Colaborador';
    this.ChoferForm.controls['cod_Chofer'].setValue(0);
    this.ChoferForm.controls['fec_alta'].setValue(this.fecha_dia);
    this.ChoferForm.controls['fec_alta'].enable();
    //
    this.ChoferForm.controls['id_ciudad_hm_prestada'].setValue(0);
    //
    this.dspChofer = true;
  }

  exportarExcel() {
    if(!this.lstChofer.length || typeof this.lstChofer ==='undefined'){
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    } else {
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.lstChofer);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        this.saveAsExcelFile(excelBuffer, "choferes");
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);

  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario:string){
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
      },
      key: "sinInformacionDialog"
  });
  }





}
