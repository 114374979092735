/*import Module from "src/app/utils/models/Module.model";
import Permission from "src/app/utils/models/Permission.model";*/

import Module from "../models/Module";
import Permission from "../models/Permiso";

export function rand() {
    return Math.floor(Math.random() * (5000 - 1)) + 1;
}

export function orderBy(property: string) {
    return function (a: any, b: any) {
        if (+a[property] < +b[property])
            return -1;
        else if (+a[property] > +b[property])
            return 1;
        else
            return 0;
    }
}

export function orderByDescending(property: string) {
    return function (a: any, b: any) {
        if (+a[property] > +b[property])
            return -1;
        else if (+a[property] < +b[property])
            return 1;
        else
            return 0;
    }
}

export function currencyFormat(value: string): string {
    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function numberFormat(value: string): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function hexToRGBA(hex: any, alpha: any) {
    hex = ("" + hex).trim().replace(/#/g, ""); //trim and remove any leading # if there (supports number values as well)
    if (!/^(?:[0-9a-fA-F]{3}){1,2}$/.test(hex)) throw ("not a valid hex string"); //Regex Validator
    if (hex.length == 3) { hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2] } //support short form
    var b_int = parseInt(hex, 16);
    return "rgba(" + [
        (b_int >> 16) & 255, //R
        (b_int >> 8) & 255, //G
        b_int & 255, //B
        alpha || 1  //add alpha if is set
    ].join(",") + ")";
}


export function getTotal(list: any[], prop: string) {
    let tot = 0;
    for (let g of list) {
        tot += + g[prop];
    }
    return tot;
}


export function groupBy(list: Array<any>, key: string, mapper?: Function) {
    let groups:any = {};
    for (var i = 0; i < list.length; i++) {

        let groupName : string = list[i][key];
        if (!groups[groupName]) {
            groups[groupName] = [];
        }
        if (mapper) groups[groupName].push(mapper(list[i]));
        else groups[groupName].push(list[i]);
    }
    list = [];
    for (let groupName in groups) {
        if (mapper) list.push({ description: groupName, options: groups[groupName] } as Module);
        else list.push({ father: groupName, children: groups[groupName] });
    }
    return list;
}

export function permissionMapper(obj: any): Permission {
    return {
        description: obj['nom_Opcion'],
        icon: obj['icono'],
        id: obj['cod_Opcion'],
        route: obj['ruta']
    } as Permission;
}