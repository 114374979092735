import { subscribeOn } from 'rxjs';
import { EstatusunidadService } from './../services/estatusunidad.service';
import { IUnidadTransporteCompleto } from './../models/unidadtransportecompleto';
import { UnidadService } from './../services/unidad.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUnidadTransporte } from '../models/unidadTransporte';
import { UnidadtransporteService } from '../services/unidadtransporte.service';
import { MessageService } from "primeng/api";
import { IConfiguracionUnidad } from '../models/confUnidad';
import { IVerificacion } from '../models/verificacion';
import { ITipoVerificacion } from '../models/tipoVerificacion';
import { ITipoUnidad } from '../models/tipoUnidad';
import { ITipoTransporte } from '../models/tipotransporte';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { IProveedor } from '../models/proveedor';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ICombustible } from '../models/combustible';
import { ICiudad } from '../models/ciudad';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
import { IEstado } from '../models/estado';
import { IverificacionHumo } from '../models/humo';
import { IverificacionFisico } from '../models/fisico';
import { IEstatus } from '../models/estatus';
import { IEstatusUnidad } from '../models/estatusunidad';
import { ISubEstatusUnidad } from '../models/subestatusunidad';
import { IDataEstatus } from '../models/dataEstatus';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IUnidadesEstatus } from '../models/rpt_unidades_estatus';
import { IUnidadesEstatusCasteado } from '../models/rpt_unidades_estatus_casteado';

import { saveAs } from 'file-saver';
import { IMes } from '../models/mes';
import { IUnidadesEstatusServicios } from '../models/rpt_unidades_estatus_servicios';
import { ConfunidadService } from '../services/confunidad.service';
import { IDataHerramientas } from '../models/data_herramientas';
import { CiudadService } from '../services/ciudad.service';
import { IUnidadesEstatusHistorico } from '../models/rpt_unidades_estatus_historico';
import { IUnidadTransporteHistorico } from '../models/unidad_transporte_historico';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ListadoComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  fecha_principal: any = new Date().toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }).substring(0, 10);
  fecha_dia: any;
  lstUnidadesTransporte: IUnidadTransporte[] = [];
  unidadTransporte: IUnidadTransporte = {
    id_unidad_transporte: 0,
    idx_respaldo_unidad: 0,
    clave_tipoTransporte: 0,
    clave_tipoUnidad: 0,
    clave_configuracionUnidad: 0,
    num_economico: '',
    clave_proveedor: 0,
    fecha_inicioRenta: '',
    fecha_finRenta: '',
    desc_placaUnidad: '',
    vin: '',
    num_tarjetaCirculacion: '',
    clave_tipoverificacion: 0,
    clave_verificacion: 0,
    des_verificacion: '',
    clave_tipo_combustible: 0,
    desc_marca: '',
    desc_modelo: '',
    num_anio: '',
    desc_color: '',
    num_polizaSeguro: '',
    fecha_inicioPoliza: '',
    fecha_finPoliza: '',
    desc_aseguradora: '',
    desc_permisoCarga: '',
    desc_tipoCaja: '',
    num_caja: 0,
    clave_ciudadOrigen: 0,
    clave_ciudadDestino: 0,
    num_gps: '',
    nombre_gps: '',
    num_camara: '',
    num_boton_panico: '',
    num_inmobilizador: '',
    num_kilometraje: 0,
    num_kilometraje_ultmantenimiento: 0,
    fecha_ult_mantenimiento: '',
    num_kilometraje_proxmantenimiento: 0,
    fecha_prox_mantenimiento: '',
    desc_tipo_mantenimiento: '',
    fecha_siniestro: '',
    desc_vidallantas: '',
    desc_siniestros: '',
    desc_fallasMecanicas: '',
    fecha_alta: '',
    fecha_registro: '',
    clv_estatus: 0,
    cod_usuario_registro: '',
    cod_usuario_ult_mov: '',
    fec_vigencia_tarjeta: '',
    num_estado_tarjeta: '',
    fec_inicio_humo: '',
    fec_fin_humo: '',
    fec_inicio_mecanica: '',
    fec_fin_mecanica: '',
    fec_inicio_estatal: '',
    fec_fin_estatal: '',
    clv_humo: 0,
    clv_mecanica: 0,
    fec_baja: '',
    num_tag_fijo: '',
    num_tag_emergente: '',
    num_tarjeta_sodexo: '',
    num_rendimiento_objetivo: 0
  };
  loading: boolean = false;
  dspEditar: boolean = false;

  /*Listados*/
  lstTransporte: ITipoTransporte[] = [{ clave_tipoTransporte: 1, nombre: "LIGERO" }, { clave_tipoTransporte: 2, nombre: "PESADO" }];
  lstTipoUnidad: ITipoUnidad[] = [];
  lstTipoVerificacion: ITipoVerificacion[] = [];
  lstVerificaciones: IVerificacion[] = [];
  lstConfUnidad: IConfiguracionUnidad[] = [];
  lstproveedor: IProveedor[] = [];
  lstEstado: IEstado[] = [];
  lstVerificacionHumo: IverificacionHumo[] = [];
  lstVerificacionFisico: IverificacionFisico[] = [];
  /*Dialogo*/


  /*Informacion Completa de Unidades de Transporte*/
  lstUnidades: IUnidadTransporteCompleto[] = [];
  texto_operacion: string = '';
  /*Informacion de Catalogos y Formulario*/
  formularioUnidad: FormGroup = new FormGroup({
    clave_tipoTransporte: new FormControl(null, [Validators.required]),
    clave_configuracionUnidad: new FormControl(null, [Validators.required]),
    clave_tipoUnidad: new FormControl(null, [Validators.required]),
    num_economico: new FormControl('', [Validators.required]),
    desc_placaUnidad: new FormControl('', [Validators.required]),
    clave_tipoverificacion: new FormControl(null, [Validators.required]),
    clave_verificacion: new FormControl(0),
    fec_inicio_estatal: new FormControl(''),
    fec_fin_estatal: new FormControl(''),
    clave_proveedor: new FormControl(),
    fecha_inicioRenta: new FormControl(),
    fecha_finRenta: new FormControl(),
    num_tarjetaCirculacion: new FormControl('', [Validators.required]),
    fec_vigencia_tarjeta: new FormControl('', [Validators.required]),
    vin: new FormControl(''),
    desc_marca: new FormControl('', [Validators.required]),
    desc_modelo: new FormControl('', [Validators.required]),
    num_anio: new FormControl('', [Validators.required]),
    desc_color: new FormControl(''),
    num_polizaSeguro: new FormControl('', [Validators.required]),
    fecha_inicioPoliza: new FormControl('', [Validators.required]),
    fecha_finPoliza: new FormControl('', [Validators.required]),
    desc_aseguradora: new FormControl(''),
    desc_permisoCarga: new FormControl(''),
    desc_tipoCaja: new FormControl(''),
    num_caja: new FormControl(),
    clave_tipo_combustible: new FormControl(),
    clave_ciudadOrigen: new FormControl(),
    clave_ciudadDestino: new FormControl(null, [Validators.required]),
    num_gps: new FormControl(),
    nombre_gps: new FormControl(''),
    num_camara: new FormControl(),
    num_boton_panico: new FormControl(),
    num_inmobilizador: new FormControl(),
    clave_Estado: new FormControl(null, [Validators.required]),
    clv_humo: new FormControl(0),
    fec_inicio_humo: new FormControl(''),
    fec_fin_humo: new FormControl(''),
    clv_fisico: new FormControl(0),
    fec_inicio_mecanica: new FormControl(''),
    fec_fin_mecanica: new FormControl(''),
    num_rendimiento_objetivo: new FormControl(0, [Validators.required])
  });
  visualizarFechasProveedor: boolean = false;
  lstCombustible: ICombustible[] = [];
  lstCiudades: ICiudad[] = [];
  existe_numeroeconomico: boolean = false;
  data: IUnidadTransporte = {
    id_unidad_transporte: 0,
    idx_respaldo_unidad: 0,
    clave_tipoTransporte: 0,
    clave_tipoUnidad: 0,
    clave_configuracionUnidad: 0,
    num_economico: '',
    clave_proveedor: 0,
    fecha_inicioRenta: '',
    fecha_finRenta: '',
    desc_placaUnidad: '',
    vin: '',
    num_tarjetaCirculacion: '',
    clave_tipoverificacion: 0,
    clave_verificacion: 0,
    des_verificacion: '',
    clave_tipo_combustible: 0,
    desc_marca: '',
    desc_modelo: '',
    num_anio: '',
    desc_color: '',
    num_polizaSeguro: '',
    fecha_inicioPoliza: '',
    fecha_finPoliza: '',
    desc_aseguradora: '',
    desc_permisoCarga: '',
    desc_tipoCaja: '',
    num_caja: 0,
    clave_ciudadOrigen: 0,
    clave_ciudadDestino: 0,
    num_gps: '',
    nombre_gps: '',
    num_camara: '',
    num_boton_panico: '',
    num_inmobilizador: '',
    num_kilometraje: 0,
    num_kilometraje_ultmantenimiento: 0,
    fecha_ult_mantenimiento: '',
    num_kilometraje_proxmantenimiento: 0,
    fecha_prox_mantenimiento: '',
    desc_tipo_mantenimiento: '',
    fecha_siniestro: '',
    desc_vidallantas: '',
    desc_siniestros: '',
    desc_fallasMecanicas: '',
    fecha_alta: '',
    fecha_registro: '',
    clv_estatus: 0,
    cod_usuario_registro: '',
    cod_usuario_ult_mov: '',
    fec_vigencia_tarjeta: '',
    num_estado_tarjeta: '',
    fec_inicio_humo: '',
    fec_fin_humo: '',
    fec_inicio_mecanica: '',
    fec_fin_mecanica: '',
    fec_inicio_estatal: '',
    fec_fin_estatal: '',
    clv_humo: 0,
    clv_mecanica: 0,
    fec_baja: '',
    num_tag_fijo: '',
    num_tag_emergente: '',
    num_tarjeta_sodexo: '',
    num_rendimiento_objetivo: 0
  };
  data_editar: IUnidadTransporte = {
    id_unidad_transporte: 0,
    idx_respaldo_unidad: 0,
    clave_tipoTransporte: 0,
    clave_tipoUnidad: 0,
    clave_configuracionUnidad: 0,
    num_economico: '',
    clave_proveedor: 0,
    fecha_inicioRenta: '',
    fecha_finRenta: '',
    desc_placaUnidad: '',
    vin: '',
    num_tarjetaCirculacion: '',
    clave_tipoverificacion: 0,
    clave_verificacion: 0,
    des_verificacion: '',
    clave_tipo_combustible: 0,
    desc_marca: '',
    desc_modelo: '',
    num_anio: '',
    desc_color: '',
    num_polizaSeguro: '',
    fecha_inicioPoliza: '',
    fecha_finPoliza: '',
    desc_aseguradora: '',
    desc_permisoCarga: '',
    desc_tipoCaja: '',
    num_caja: 0,
    clave_ciudadOrigen: 0,
    clave_ciudadDestino: 0,
    num_gps: '',
    nombre_gps: '',
    num_camara: '',
    num_boton_panico: '',
    num_inmobilizador: '',
    num_kilometraje: 0,
    num_kilometraje_ultmantenimiento: 0,
    fecha_ult_mantenimiento: '',
    num_kilometraje_proxmantenimiento: 0,
    fecha_prox_mantenimiento: '',
    desc_tipo_mantenimiento: '',
    fecha_siniestro: '',
    desc_vidallantas: '',
    desc_siniestros: '',
    desc_fallasMecanicas: '',
    fecha_alta: '',
    fecha_registro: '',
    clv_estatus: 0,
    cod_usuario_registro: '',
    cod_usuario_ult_mov: '',
    fec_vigencia_tarjeta: '',
    num_estado_tarjeta: '',
    fec_inicio_humo: '',
    fec_fin_humo: '',
    fec_inicio_mecanica: '',
    fec_fin_mecanica: '',
    fec_inicio_estatal: '',
    fec_fin_estatal: '',
    clv_humo: 0,
    clv_mecanica: 0,
    fec_baja: '',
    num_tag_fijo: '',
    num_tag_emergente: '',
    num_tarjeta_sodexo: '',
    num_rendimiento_objetivo: 0
  };
  numero_operacion: number = 0;
  dsb_numeroeconomico: boolean = true;
  texto_verificacion: string = '';
  color_gris_deshabilitadas: string = '#D9D9D9';
  color_naranja_baja: string = '#FFDAB7';
  //Registro
  user: ICredencialUsuario = {};
  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'ACTIVAS'
  },
  {
    id: 4,
    nombre: 'ACTIVAS CON REPORTE'
  },

  {
    id: 3,
    nombre: 'BAJA DE OPERACIÓN'
  },
  {
    id: 0,
    nombre: 'INACTIVAS'
  }];
  //Estatus
  lstEstatusUnidad: IEstatusUnidad[] = [];
  lstSubEstatusUnidad: ISubEstatusUnidad[] = [];
  dspEstatus: boolean = false;
  dataForm: FormGroup = new FormGroup({
    id_unidad_transporte: new FormControl(0, [Validators.required]),
    clv_estatus: new FormControl(0, [Validators.required]),
    clv_sub_estatus: new FormControl(0)
  });
  rst_estatus: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  habilitar_edicion: boolean = true;

  //Emision de Reportes
  rpt_ListadoUnidades: IUnidadesEstatus[] = [];
  rpt_ListadoUnidades_Servicios: IUnidadesEstatusServicios[] = [];
  rpt_ListadoUnidades_cast: IUnidadesEstatusCasteado[] = [];
  lstUnidadesHistorico: IUnidadTransporteHistorico[] = [];
  rpt_ListadoUnidades_historico: IUnidadesEstatusHistorico[] = [];
  num_economico_general: string = '';
  dbsReportes: boolean = false;
  meses: IMes[] = [
    { num_mes: 0, nombre: "Todos" },
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];
  selectedMes: IMes = {
    num_mes: -1,
    nombre: ""
  }
  selectedMesHistorico: IMes = {
    num_mes: -1,
    nombre: ""
  }
  selectedMesHistoricoIna: IMes = {
    num_mes: -1,
    nombre: ""
  }
  anio: any;
  anio_historico: any;
  anio_historico_ina: any;
  //apartado de Herramientas de Recursos
  //REGEX_NUMBER: any = /^[0-9]{4}$/;
  //REGEX_NUMBER: any ='^[0-9]*$'; Solo numeros sin limite
  REGEX_NUMBER: any = '^[0-9]{4}$';
  dsbHerramientas: boolean = false;
  herramientasForm: FormGroup = new FormGroup({
    num_economico: new FormControl({ value: '', disabled: true }, [Validators.required]),
    id_unidad_transporte: new FormControl(0, [Validators.required]),
    num_tag_fijo: new FormControl(null, [Validators.required, Validators.pattern(this.REGEX_NUMBER)]),
    num_tag_emergente: new FormControl(null, [Validators.pattern(this.REGEX_NUMBER)]),
    num_tarjeta_sodexo: new FormControl(null, [Validators.required, Validators.pattern(this.REGEX_NUMBER)])
  });
  habilitar_edicion_herramientas: boolean = false;
  constructor(private unidadTransporteService: UnidadtransporteService,
    private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private _unidadService: UnidadService,
    private auth: AuthService,
    private estatusunidadService: EstatusunidadService,
    private confUnidadService: ConfunidadService,
    private ciudadService: CiudadService) {
    this.cargarInformacionUsuario();
    this.dataForm.controls['clv_estatus'].valueChanges.subscribe(data => {
      if (data == 1 || data == 4) {
        this.dataForm.controls['clv_sub_estatus'].setValue(0);
        this.dataForm.get('clv_sub_estatus')!.clearValidators();
      } else if (data == 0 || data == 3) {
        if (this.dataForm.get('clv_sub_estatus')?.value == 0) {
          this.dataForm.controls['clv_sub_estatus'].setValue(null);
        }
        this.dataForm.get('clv_sub_estatus')!.setValidators(Validators.required);
      }
      this.filtrarListadoSubEstatus(data);
    });
  }

  filtrarListadoSubEstatus(clv_estatus: number) {
    if (this.lstSubEstatusUnidad.length > 0) {
      this.lstSubEstatusUnidad.map((valor) => {
        if (valor.clv_estatus == clv_estatus) {
          valor.disabled = false;
        } else {
          valor.disabled = true;
        }
      });
    }
  }


  ngOnInit(): void {
    this.cargarListadoTodasLasUnidades();
    //this.crearFormulario();

    //CATALOGOS
    this.cargarListadoProveedores();
    this.cargarListadoTipoUnidad();
    this.cargarListadoTipoVerificacion();
    this.cargarListadoVerificaciones();
    this.cargarListadoCiudades();
    this.cargarListadoConfUnidad();
    this.cargarListadoCombustible();
    this.cargarListadoDeEstados();
    this.cargarVerificacionesHumo();
    this.cargarVerificacionesFisico();

    //Estatus
    this.cargarListadoDeEstatus();
    this.cargarListadoDeSubEstatus();
    this.inicializarFechas();
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;


    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMes.nombre = mes.nombre;
        this.selectedMes.num_mes = mes.num_mes;
        //
        this.selectedMesHistorico.nombre = mes.nombre;
        this.selectedMesHistorico.num_mes = mes.num_mes;
        //
        this.selectedMesHistoricoIna.nombre = mes.nombre;
        this.selectedMesHistoricoIna.num_mes = mes.num_mes;
      }
    });
    this.anio = getYear;
    this.anio_historico = getYear;
    this.anio_historico_ina = getYear;
  }

  cargarListadoDeEstatus() {
    this.estatusunidadService.getListadoEstatusUnidad().subscribe((resp) => {
      this.lstEstatusUnidad = resp;
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Estatus Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarListadoDeSubEstatus() {
    this.estatusunidadService.getListadoSubEstatusUnidad().subscribe((resp) => {
      this.lstSubEstatusUnidad = resp;
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <SubEstatus Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarVerificacionesHumo() {
    this.lstVerificacionHumo = [{ id: 0, nombre: 'Verificacion Humo(NO)' }, { id: 1, nombre: 'Verificacion Humo(SI)' }];
  }

  cargarVerificacionesFisico() {
    this.lstVerificacionFisico = [{ id: 0, nombre: 'Verificacion Fisico-Mecanica(NO)' }, { id: 1, nombre: 'Fisico-Mecanica(SI)' }];
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  reiniciarFormulario() {
    this.formularioUnidad.reset();
  }

  crearFormulario() {
    this.formularioUnidad = new FormGroup({
      clave_tipoTransporte: new FormControl(null, [Validators.required]),
      clave_configuracionUnidad: new FormControl(null, [Validators.required]),
      clave_tipoUnidad: new FormControl(null, [Validators.required]),
      num_economico: new FormControl('', [Validators.required]),
      desc_placaUnidad: new FormControl('', [Validators.required]),
      clave_tipoverificacion: new FormControl(null, [Validators.required]),
      clave_verificacion: new FormControl(null, [Validators.required]),
      clave_proveedor: new FormControl(null),
      fecha_inicioRenta: new FormControl(),
      fecha_finRenta: new FormControl(),
      num_tarjetaCirculacion: new FormControl(),
      vin: new FormControl(),
      desc_marca: new FormControl('', [Validators.required]),
      desc_modelo: new FormControl('', [Validators.required]),
      num_anio: new FormControl('', [Validators.required]),
      desc_color: new FormControl(),
      num_polizaSeguro: new FormControl('', [Validators.required]),
      fecha_inicioPoliza: new FormControl([Validators.required]),
      fecha_finPoliza: new FormControl([Validators.required]),
      desc_aseguradora: new FormControl(),
      desc_permisoCarga: new FormControl(),
      desc_tipoCaja: new FormControl(),
      num_caja: new FormControl(),
      clave_tipo_combustible: new FormControl(),
      clave_ciudadOrigen: new FormControl(),
      clave_ciudadDestino: new FormControl(),
      num_gps: new FormControl(),
      nombre_gps: new FormControl(),
      num_camara: new FormControl(),
      num_boton_panico: new FormControl(),
      num_inmobilizador: new FormControl()
    });
  }

  validarCambioTipoUnidad() {
    //Reiniciar valores de Proveedor
    /*this.fecha_fin_proveedor = new Date();
    this.fecha_inicio_proveedor = new Date();*/
    let valor_tipo_unidad = (this.formularioUnidad.get('clave_tipoUnidad')?.value);
    if (valor_tipo_unidad == 2) {
      this.formularioUnidad.get('clave_proveedor')!.setValidators(Validators.required);
      this.formularioUnidad.get('fecha_inicioRenta')!.setValidators(Validators.required);
      this.formularioUnidad.get('fecha_finRenta')!.setValidators(Validators.required);
    } else {
      this.formularioUnidad.get('clave_proveedor')!.clearValidators();
      this.formularioUnidad.get('fecha_inicioRenta')!.clearValidators();
      this.formularioUnidad.get('fecha_finRenta')!.clearValidators();
    }
  }

  validarCambioHumo() {
    let valor_humo = (this.formularioUnidad.get('clv_humo')?.value);
    if (valor_humo == null || valor_humo == 0) {
      this.formularioUnidad.get('fec_inicio_humo')!.disable();
      this.formularioUnidad.get('fec_fin_humo')!.disable();
      this.formularioUnidad.get('fec_inicio_humo')!.clearValidators();
      this.formularioUnidad.get('fec_fin_humo')!.clearValidators();
      this.formularioUnidad.controls['fec_inicio_humo'].setValue('');
      this.formularioUnidad.controls['fec_fin_humo'].setValue('');
    } else {
      this.formularioUnidad.get('fec_inicio_humo')!.setValidators(Validators.required);
      this.formularioUnidad.get('fec_fin_humo')!.setValidators(Validators.required);
      this.formularioUnidad.controls['fec_inicio_humo'].setValue('');
      this.formularioUnidad.controls['fec_fin_humo'].setValue('');
      this.formularioUnidad.get('fec_inicio_humo')!.enable();
      this.formularioUnidad.get('fec_fin_humo')!.enable();
    }
  }

  validarCambioFisico() {
    let valor_fisico = (this.formularioUnidad.get('clv_fisico')?.value);
    if (valor_fisico == null || valor_fisico == 0) {
      this.formularioUnidad.get('fec_inicio_mecanica')!.disable();
      this.formularioUnidad.get('fec_fin_mecanica')!.disable();
      this.formularioUnidad.get('fec_inicio_mecanica')!.clearValidators();
      this.formularioUnidad.get('fec_fin_mecanica')!.clearValidators();
      this.formularioUnidad.controls['fec_inicio_mecanica'].setValue('');
      this.formularioUnidad.controls['fec_fin_mecanica'].setValue('');
    } else {
      this.formularioUnidad.get('fec_inicio_mecanica')!.setValidators(Validators.required);
      this.formularioUnidad.get('fec_fin_mecanica')!.setValidators(Validators.required);
      this.formularioUnidad.controls['fec_inicio_mecanica'].setValue('');
      this.formularioUnidad.controls['fec_fin_mecanica'].setValue('');
      this.formularioUnidad.get('fec_inicio_mecanica')!.enable();
      this.formularioUnidad.get('fec_fin_mecanica')!.enable();
    }
  }

  validarCambioEstado() {
    let valor_estado = (this.formularioUnidad.get('clave_verificacion')?.value);
    if (valor_estado == null || valor_estado == 0) {
      this.formularioUnidad.get('fec_inicio_estatal')!.disable();
      this.formularioUnidad.get('fec_fin_estatal')!.disable();
      this.formularioUnidad.get('fec_inicio_estatal')!.clearValidators();
      this.formularioUnidad.get('fec_fin_estatal')!.clearValidators();
      this.formularioUnidad.controls['fec_inicio_estatal'].setValue('');
      this.formularioUnidad.controls['fec_fin_estatal'].setValue('');
    } else {
      this.formularioUnidad.get('fec_inicio_estatal')!.setValidators(Validators.required);
      this.formularioUnidad.get('fec_fin_estatal')!.setValidators(Validators.required);
      this.formularioUnidad.controls['fec_inicio_estatal'].setValue('');
      this.formularioUnidad.controls['fec_fin_estatal'].setValue('');
      this.formularioUnidad.get('fec_inicio_estatal')!.enable();
      this.formularioUnidad.get('fec_fin_estatal')!.enable();
    }
  }

  validarCambioVerificacion() {
    let valor_verificacion = (this.formularioUnidad.get('clave_verificacion')?.value);
  }

  seleccionarProveedor() {
    this.visualizarFechasProveedor = true;
  }

  cargarListadoTodasLasUnidades() {
    this.loading = true;
    this._unidadService.getListadoTodasLasUnidades(1).subscribe((resp) => {
      this.lstUnidades = resp;
      setTimeout(() => {
        this.loading = false;
      },
        800);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En -Listar Unidades-. No existe informacion del Catalogo para mostrar');
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Unidades>', detail: 'Contacte al Administrador del Sitio' });
        this.loading = false;
      });
  }

  /*cargarListadoUnidadTransporte(){
    this.unidadTransporteService.getListadoUnidades().then(unidades => {
      this.lstUnidadesTransporte = unidades;
      setTimeout(() => {
        this.loading = false;
      },
        1200);
    });
  }*/

  redireccionarNueva(numero_operacion: number) {
    this.numero_operacion = 1;
    //this.router.navigateByUrl("/principal/unidad");
    this.texto_operacion = 'Nueva Unidad(+)';
    this.dspEditar = true;
    this.formularioUnidad.reset();
    this.formularioUnidad.get('num_economico')!.enable();
    this.formularioUnidad.get('desc_placaUnidad')!.enable();
    //DeshabilitarFechas
    this.formularioUnidad.get('fec_inicio_humo')!.disable();
    this.formularioUnidad.get('fec_fin_humo')!.disable();
    this.formularioUnidad.get('fec_inicio_mecanica')!.disable();
    this.formularioUnidad.get('fec_fin_mecanica')!.disable();
    this.formularioUnidad.get('fec_inicio_estatal')!.disable();
    this.formularioUnidad.get('fec_fin_estatal')!.disable();
    //rendimiento objetivo
    //this.formularioUnidad.get('num_rendimiento_objetivo')!.setValue(0);
    this.dsb_numeroeconomico = false;
  }

  applyFilter($event: any, field: string, matchMode: string) {
    let value = ($event.target as HTMLInputElement)?.value;
    this.dt.filter(value, field, matchMode);
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  confirmarEliminar(data: IUnidadTransporte) {
    let clv_estatus_original: number = data.clv_estatus;
    let texto_desactivar: string = data.clv_estatus == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_estatus == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> el registro de Unidad Transporte <b>' + data.num_economico + '</b>- Placa: <b>' + data.desc_placaUnidad + '</b>?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_estatus = data.clv_estatus == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_estatus = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado la operación' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_estatus = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado la operación' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: IUnidadTransporte) {
    this._unidadService.editUnidadEstatus(data).subscribe((resp) => {
      setTimeout(() => {
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
      },
        300);
      this.cargarListadoTodasLasUnidades();
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Editar Estatus Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }


  realizarEdicion() {
    alert('editar informacion');
    this.dspEditar = false;
  }

  editarInformacion(informacion: IUnidadTransporte, numero_operacion: number) {
    this.formularioUnidad.get('num_economico')!.disable();
    //this.formularioUnidad.get('desc_placaUnidad')!.disable();


    this.numero_operacion = numero_operacion;
    this.texto_operacion = 'Editar Información';
    this.data_editar = informacion;
    if (this.data_editar.clv_humo != 0) {
      this.formularioUnidad.get('fec_inicio_humo')!.enable();
      this.formularioUnidad.get('fec_fin_humo')!.enable();
    } else {
      this.formularioUnidad.get('fec_inicio_humo')!.disable();
      this.formularioUnidad.get('fec_fin_humo')!.disable();
    }

    if (this.data_editar.clv_mecanica != 0) {
      this.formularioUnidad.get('fec_inicio_mecanica')!.enable();
      this.formularioUnidad.get('fec_fin_mecanica')!.enable();
    } else {
      this.formularioUnidad.get('fec_inicio_mecanica')!.disable();
      this.formularioUnidad.get('fec_fin_mecanica')!.disable();
    }

    if (this.data_editar.clave_verificacion != 0) {
      this.formularioUnidad.get('fec_inicio_estatal')!.enable();
      this.formularioUnidad.get('fec_fin_estatal')!.enable();
    } else {
      this.formularioUnidad.get('fec_inicio_estatal')!.disable();
      this.formularioUnidad.get('fec_fin_estatal')!.disable();
    }

    this.dsb_numeroeconomico = true;
    this.formularioUnidad.controls['clave_tipoTransporte'].setValue(this.data_editar.clave_tipoTransporte);
    this.formularioUnidad.controls['clave_configuracionUnidad'].setValue(this.data_editar.clave_configuracionUnidad);
    this.formularioUnidad.controls['clave_tipoUnidad'].setValue(this.data_editar.clave_tipoUnidad);
    this.formularioUnidad.controls['num_economico'].setValue(this.data_editar.num_economico);
    this.formularioUnidad.controls['desc_placaUnidad'].setValue(this.data_editar.desc_placaUnidad);
    let clave_tipoverificacion: any = this.data_editar.clave_tipoverificacion == 0 ? 0 : this.data_editar.clave_tipoverificacion;
    this.formularioUnidad.controls['clave_tipoverificacion'].setValue(clave_tipoverificacion);
    let clave_verificacion: any = this.data_editar.clave_verificacion == 0 ? 0 : this.data_editar.clave_verificacion;
    this.formularioUnidad.controls['clave_verificacion'].setValue(clave_verificacion);
    this.formularioUnidad.controls['clave_proveedor'].setValue(this.data_editar.clave_proveedor);
    this.formularioUnidad.controls['num_tarjetaCirculacion'].setValue(this.data_editar.num_tarjetaCirculacion);
    this.formularioUnidad.controls['vin'].setValue(this.data_editar.vin);
    this.formularioUnidad.controls['desc_marca'].setValue(this.data_editar.desc_marca);
    this.formularioUnidad.controls['desc_modelo'].setValue(this.data_editar.desc_modelo);
    this.formularioUnidad.controls['num_anio'].setValue(this.data_editar.num_anio);
    this.formularioUnidad.controls['desc_color'].setValue(this.data_editar.desc_color);
    this.formularioUnidad.controls['num_polizaSeguro'].setValue(this.data_editar.num_polizaSeguro);
    this.formularioUnidad.controls['desc_aseguradora'].setValue(this.data_editar.desc_aseguradora);
    this.formularioUnidad.controls['desc_permisoCarga'].setValue(this.data_editar.desc_permisoCarga);
    this.formularioUnidad.controls['desc_tipoCaja'].setValue(this.data_editar.desc_tipoCaja);
    this.formularioUnidad.controls['num_caja'].setValue(this.data_editar.num_caja);
    this.formularioUnidad.controls['clave_tipo_combustible'].setValue(this.data_editar.clave_tipo_combustible);
    this.formularioUnidad.controls['clave_ciudadOrigen'].setValue(this.data_editar.clave_ciudadOrigen);
    this.formularioUnidad.controls['clave_ciudadDestino'].setValue(this.data_editar.clave_ciudadDestino);
    this.formularioUnidad.controls['num_gps'].setValue(this.data_editar.num_gps);
    this.formularioUnidad.controls['nombre_gps'].setValue(this.data_editar.nombre_gps);
    this.formularioUnidad.controls['num_camara'].setValue(this.data_editar.num_camara);
    this.formularioUnidad.controls['num_boton_panico'].setValue(this.data_editar.num_boton_panico);
    this.formularioUnidad.controls['num_inmobilizador'].setValue(this.data_editar.num_inmobilizador);

    //Formatear Fechas
    let fec_inicio_renta: string = this.data_editar.fecha_inicioRenta == '1900-01-01T00:00:00' ? '' : this.data_editar.fecha_inicioRenta.substring(0, 10);
    this.formularioUnidad.controls['fecha_inicioRenta'].setValue(fec_inicio_renta);
    let fec_fin_renta: string = this.data_editar.fecha_finRenta == '1900-01-01T00:00:00' ? '' : this.data_editar.fecha_finRenta.substring(0, 10);
    this.formularioUnidad.controls['fecha_finRenta'].setValue(fec_fin_renta);
    let fec_inicio_poliza: string = this.data_editar.fecha_inicioPoliza == '1900-01-01T00:00:00' ? '' : this.data_editar.fecha_inicioPoliza.substring(0, 10);
    this.formularioUnidad.controls['fecha_inicioPoliza'].setValue(fec_inicio_poliza);
    let fec_fin_poliza: string = this.data_editar.fecha_finPoliza == '1900-01-01T00:00:00' ? '' : this.data_editar.fecha_finPoliza.substring(0, 10);
    this.formularioUnidad.controls['fecha_finPoliza'].setValue(fec_fin_poliza);

    //Nuevos Campos
    this.formularioUnidad.controls['clave_Estado'].setValue(this.data_editar.num_estado_tarjeta);
    let fec_vigencia_tarjeta: string = this.data_editar.fec_vigencia_tarjeta == '1900-01-01T00:00:00' ? '' : this.data_editar.fec_vigencia_tarjeta.substring(0, 10);
    this.formularioUnidad.controls['fec_vigencia_tarjeta'].setValue(fec_vigencia_tarjeta);
    this.formularioUnidad.controls['clv_humo'].setValue(this.data_editar.clv_humo);
    let fec_inicio_humo: string = this.data_editar.fec_inicio_humo == '1900-01-01T00:00:00' ? '' : this.data_editar.fec_inicio_humo.substring(0, 10);
    this.formularioUnidad.controls['fec_inicio_humo'].setValue(fec_inicio_humo);
    let fec_fin_humo: string = this.data_editar.fec_fin_humo == '1900-01-01T00:00:00' ? '' : this.data_editar.fec_fin_humo.substring(0, 10);
    this.formularioUnidad.controls['fec_fin_humo'].setValue(fec_fin_humo);
    this.formularioUnidad.controls['clv_fisico'].setValue(this.data_editar.clv_mecanica);
    let fec_inicio_mecanica: string = this.data_editar.fec_inicio_mecanica == '1900-01-01T00:00:00' ? '' : this.data_editar.fec_inicio_mecanica.substring(0, 10);
    this.formularioUnidad.controls['fec_inicio_mecanica'].setValue(fec_inicio_mecanica);
    let fec_fin_mecanica: string = this.data_editar.fec_fin_mecanica == '1900-01-01T00:00:00' ? '' : this.data_editar.fec_fin_mecanica.substring(0, 10);
    this.formularioUnidad.controls['fec_fin_mecanica'].setValue(fec_fin_mecanica);
    let fec_inicio_estatal: string = this.data_editar.fec_inicio_estatal == '1900-01-01T00:00:00' ? '' : this.data_editar.fec_inicio_estatal.substring(0, 10);
    this.formularioUnidad.controls['fec_inicio_estatal'].setValue(fec_inicio_estatal);
    let fec_fin_estatal: string = this.data_editar.fec_fin_estatal == '1900-01-01T00:00:00' ? '' : this.data_editar.fec_fin_estatal.substring(0, 10);
    this.formularioUnidad.controls['fec_fin_estatal'].setValue(fec_fin_estatal);
    //rendimiento objetivo
    this.formularioUnidad.controls['num_rendimiento_objetivo'].setValue(this.data_editar.num_rendimiento_objetivo);
    this.dspEditar = true;
  }

  submit() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.data.id_unidad_transporte = this.numero_operacion == 1 ? 0 : this.data_editar.id_unidad_transporte;
    this.data.idx_respaldo_unidad = this.numero_operacion == 1 ? 0 : this.data_editar.idx_respaldo_unidad;
    this.data.clave_tipoTransporte = this.formularioUnidad.get('clave_tipoTransporte')?.value;
    this.data.clave_tipoUnidad = this.formularioUnidad.get('clave_tipoUnidad')?.value;
    this.data.clave_configuracionUnidad = this.formularioUnidad.get('clave_configuracionUnidad')?.value;
    this.data.num_economico = this.formularioUnidad.get('num_economico')?.value;
    this.data.clave_proveedor = typeof this.formularioUnidad.get('clave_proveedor')?.value === 'object' ? 0 : this.formularioUnidad.get('clave_proveedor')?.value;
    //
    this.data.fecha_inicioRenta = typeof this.formularioUnidad.get('fecha_inicioRenta')?.value === 'object' || this.formularioUnidad.get('fecha_inicioRenta')?.value == '' ? '1900-01-01T00:00:00' : this.formularioUnidad.get('fecha_inicioRenta')?.value;
    this.data.fecha_finRenta = typeof this.formularioUnidad.get('fecha_finRenta')?.value === 'object' || this.formularioUnidad.get('fecha_finRenta')?.value == '' ? '1900-01-01T00:00:00' : this.formularioUnidad.get('fecha_finRenta')?.value;
    //
    this.data.desc_placaUnidad = this.formularioUnidad.get('desc_placaUnidad')?.value.toUpperCase();
    this.data.vin = (typeof this.formularioUnidad.get('vin')?.value === 'object') ? '' : this.formularioUnidad.get('vin')?.value.toUpperCase();
    this.data.num_tarjetaCirculacion = typeof this.formularioUnidad.get('num_tarjetaCirculacion')?.value === 'object' ? 0 : this.formularioUnidad.get('num_tarjetaCirculacion')?.value;
    this.data.clave_tipoverificacion = typeof this.formularioUnidad.get('clave_tipoverificacion')?.value === 'object' ? 0 : this.formularioUnidad.get('clave_tipoverificacion')?.value;
    this.data.clave_verificacion = typeof this.formularioUnidad.get('clave_verificacion')?.value === 'object' ? 0 : this.formularioUnidad.get('clave_verificacion')?.value;
    this.data.des_verificacion = this.numero_operacion == 1 ? '' : this.data_editar.des_verificacion;
    this.data.clave_tipo_combustible = typeof this.formularioUnidad.get('clave_tipo_combustible')?.value === 'object' ? 0 : this.formularioUnidad.get('clave_tipo_combustible')?.value;
    this.data.desc_marca = this.formularioUnidad.get('desc_marca')?.value.toUpperCase();
    this.data.desc_modelo = this.formularioUnidad.get('desc_modelo')?.value.toUpperCase();
    this.data.num_anio = this.formularioUnidad.get('num_anio')?.value;
    this.data.desc_color = typeof this.formularioUnidad.get('desc_color')?.value === 'object' ? '' : this.formularioUnidad.get('desc_color')?.value.toUpperCase();
    this.data.num_polizaSeguro = this.formularioUnidad.get('num_polizaSeguro')?.value.toUpperCase();
    //
    this.data.fecha_inicioPoliza = typeof this.formularioUnidad.get('fecha_inicioPoliza')?.value === 'object' || this.formularioUnidad.get('fecha_inicioPoliza')?.value == '' ? '1900-01-01T00:00:00' : this.formularioUnidad.get('fecha_inicioPoliza')?.value;
    this.data.fecha_finPoliza = typeof this.formularioUnidad.get('fecha_finPoliza')?.value === 'object' || this.formularioUnidad.get('fecha_finPoliza')?.value == '' ? '1900-01-01T00:00:00' : this.formularioUnidad.get('fecha_finPoliza')?.value;
    //
    this.data.desc_aseguradora = typeof this.formularioUnidad.get('desc_aseguradora')?.value === 'object' ? '' : this.formularioUnidad.get('desc_aseguradora')?.value.toUpperCase();
    this.data.desc_permisoCarga = typeof this.formularioUnidad.get('desc_permisoCarga')?.value === 'object' ? '' : this.formularioUnidad.get('desc_permisoCarga')?.value.toUpperCase();
    this.data.desc_tipoCaja = typeof this.formularioUnidad.get('desc_tipoCaja')?.value === 'object' ? '' : this.formularioUnidad.get('desc_tipoCaja')?.value.toUpperCase();
    this.data.num_caja = typeof this.formularioUnidad.get('num_caja')?.value === 'object' ? 0 : this.formularioUnidad.get('num_caja')?.value;
    this.data.clave_ciudadOrigen = typeof this.formularioUnidad.get('clave_ciudadOrigen')?.value === 'object' ? 0 : this.formularioUnidad.get('clave_ciudadOrigen')?.value;
    this.data.clave_ciudadDestino = typeof this.formularioUnidad.get('clave_ciudadDestino')?.value === 'object' ? 0 : this.formularioUnidad.get('clave_ciudadDestino')?.value;
    this.data.num_gps = typeof this.formularioUnidad.get('num_gps')?.value === 'object' ? '' : this.formularioUnidad.get('num_gps')?.value;
    this.data.nombre_gps = typeof this.formularioUnidad.get('nombre_gps')?.value === 'object' ? '' : this.formularioUnidad.get('nombre_gps')?.value;
    this.data.num_camara = typeof this.formularioUnidad.get('num_camara')?.value === 'object' ? '' : this.formularioUnidad.get('num_camara')?.value;
    this.data.num_boton_panico = typeof this.formularioUnidad.get('num_boton_panico')?.value === 'object' ? '' : this.formularioUnidad.get('num_boton_panico')?.value;
    this.data.num_inmobilizador = typeof this.formularioUnidad.get('num_inmobilizador')?.value === 'object' ? '' : this.formularioUnidad.get('num_inmobilizador')?.value;
    //Ultimos Campos
    this.data.num_estado_tarjeta = typeof this.formularioUnidad.get('clave_Estado')?.value === 'object' ? '25' : this.formularioUnidad.get('clave_Estado')?.value;
    this.data.fec_vigencia_tarjeta = this.formularioUnidad.get('fec_vigencia_tarjeta')?.value == '' ? '1900-01-01T00:00:00' : this.formularioUnidad.get('fec_vigencia_tarjeta')?.value;
    let clv_humo: number = this.formularioUnidad.get('clv_humo')?.value === 'object' ? 0 : this.formularioUnidad.get('clv_humo')?.value;
    this.data.clv_humo = clv_humo;
    this.data.fec_inicio_humo = this.formularioUnidad.get('fec_inicio_humo')?.value == '' ? '1900-01-01T00:00:00' : this.formularioUnidad.get('fec_inicio_humo')?.value;
    this.data.fec_fin_humo = this.formularioUnidad.get('fec_fin_humo')?.value == '' ? '1900-01-01T00:00:00' : this.formularioUnidad.get('fec_fin_humo')?.value;
    let clv_mecanica: number = this.formularioUnidad.get('clv_fisico')?.value === 'object' ? 0 : this.formularioUnidad.get('clv_fisico')?.value;
    this.data.clv_mecanica = clv_mecanica;
    this.data.fec_inicio_mecanica = this.formularioUnidad.get('fec_inicio_mecanica')?.value == '' ? '1900-01-01T00:00:00' : this.formularioUnidad.get('fec_inicio_mecanica')?.value;
    this.data.fec_fin_mecanica = this.formularioUnidad.get('fec_fin_mecanica')?.value == '' ? '1900-01-01T00:00:00' : this.formularioUnidad.get('fec_fin_mecanica')?.value;
    this.data.fec_inicio_estatal = this.formularioUnidad.get('fec_inicio_estatal')?.value == '' ? '1900-01-01T00:00:00' : this.formularioUnidad.get('fec_inicio_estatal')?.value;
    this.data.fec_fin_estatal = this.formularioUnidad.get('fec_fin_estatal')?.value == '' ? '1900-01-01T00:00:00' : this.formularioUnidad.get('fec_fin_estatal')?.value;

    //verificacion de cvl
    this.data.clv_humo = this.data.clv_humo == null ? 0 : this.data.clv_humo;
    this.data.clv_mecanica = this.data.clv_mecanica == null ? 0 : this.data.clv_mecanica;
    this.data.fec_vigencia_tarjeta = this.data.fec_vigencia_tarjeta == null ? '1900-01-01T00:00:00' : this.data.fec_vigencia_tarjeta;
    this.data.fec_inicio_estatal = this.data.fec_inicio_estatal == null ? '1900-01-01T00:00:00' : this.data.fec_inicio_estatal;
    this.data.fec_fin_estatal = this.data.fec_fin_estatal == null ? '1900-01-01T00:00:00' : this.data.fec_fin_estatal;
    this.data.fec_inicio_humo = this.data.fec_inicio_humo == null ? '1900-01-01T00:00:00' : this.data.fec_inicio_humo;
    this.data.fec_fin_humo = this.data.fec_fin_humo == null ? '1900-01-01T00:00:00' : this.data.fec_fin_humo;
    this.data.fec_inicio_mecanica = this.data.fec_inicio_mecanica == null ? '1900-01-01T00:00:00' : this.data.fec_inicio_mecanica;
    this.data.fec_fin_mecanica = this.data.fec_fin_mecanica == null ? '1900-01-01T00:00:00' : this.data.fec_fin_mecanica;
    //Agregar Nuevos Campos
    //Fecha Inicio Renta, Poliza
    this.data.fecha_inicioRenta == null ? '1900-01-01T00:00:00' : this.data.fecha_inicioRenta;
    this.data.fecha_finRenta == null ? '1900-01-01T00:00:00' : this.data.fecha_finRenta;
    this.data.fecha_inicioPoliza == null ? '1900-01-01T00:00:00' : this.data.fecha_inicioPoliza;
    this.data.fecha_finPoliza == null ? '1900-01-01T00:00:00' : this.data.fecha_finPoliza;
    //Fecha Fin Renta, Poliza

    //Fecha de Baja
    this.data.fec_baja = this.numero_operacion == 1 ? '1900-01-01T00:00:00' : this.data_editar.fec_baja;

    /*Informacion de Mantenimiento-Abastecimiento*/
    this.data.num_kilometraje = this.numero_operacion == 1 ? 0 : this.data_editar.num_kilometraje;
    this.data.num_kilometraje_ultmantenimiento = this.numero_operacion == 1 ? 0 : this.data_editar.num_kilometraje_ultmantenimiento;
    this.data.fecha_ult_mantenimiento = this.numero_operacion == 1 ? '1900-01-01T00:00:00' : this.data_editar.fecha_ult_mantenimiento;
    this.data.num_kilometraje_proxmantenimiento = this.numero_operacion == 1 ? 0 : this.data_editar.num_kilometraje_proxmantenimiento;
    this.data.fecha_prox_mantenimiento = this.numero_operacion == 1 ? '1900-01-01T00:00:00' : this.data_editar.fecha_prox_mantenimiento;
    this.data.desc_tipo_mantenimiento = this.numero_operacion == 1 ? '' : this.data_editar.desc_tipo_mantenimiento;
    this.data.fecha_siniestro = this.numero_operacion == 1 ? '1900-01-01T00:00:00' : this.data_editar.fecha_siniestro;
    this.data.desc_vidallantas = this.numero_operacion == 1 ? '' : this.data_editar.desc_vidallantas;
    this.data.desc_siniestros = this.numero_operacion == 1 ? '' : this.data_editar.desc_siniestros;
    this.data.desc_fallasMecanicas = this.numero_operacion == 1 ? '' : this.data_editar.desc_fallasMecanicas;
    /*this.data.fecha_alta = this.numero_operacion == 1 ? this.fecha_principal : this.data_editar.fecha_alta;
    this.data.fecha_registro = this.numero_operacion == 1 ? this.fecha_principal : this.data_editar.fecha_registro;*/
    this.data.fecha_alta = this.numero_operacion == 1 ? dateFormat : this.data_editar.fecha_alta;
    this.data.fecha_registro = this.numero_operacion == 1 ? dateFormat : this.data_editar.fecha_registro;
    this.data.clv_estatus = this.numero_operacion == 1 ? 1 : this.data_editar.clv_estatus;

    //Rendimiento Objetivo
    this.data.num_rendimiento_objetivo = typeof this.formularioUnidad.get('num_rendimiento_objetivo')?.value === 'object' ? 0 : this.formularioUnidad.get('num_rendimiento_objetivo')?.value;


    //Usuario
    this.data.cod_usuario_registro = this.numero_operacion == 1 ? this.user.cod : this.data_editar.cod_usuario_registro;
    this.data.cod_usuario_ult_mov = this.user.cod;
    if (this.numero_operacion == 1) {
      this._unidadService.saveUnidad(this.data).subscribe((resp) => {
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
        /*if (resp == 1) {
          this.cancelarVentanaOperacion();
          setTimeout(() => {
            this.cargarListadoTodasLasUnidades();
          },
            1000);
        }*/
        this.cancelarVentanaOperacion();
          setTimeout(() => {
            this.cargarListadoTodasLasUnidades();
          },
            1000);
      },
        (error) => {
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Agregar Unidad>', detail: 'Contacte al Administrador del Sitio' });
        });

    } else if (this.numero_operacion == 2) {
      this._unidadService.editUnidad(this.data).subscribe((resp) => {
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Edición Exitosa', detail: 'La informacion se actualizo de forma correcta' });
        /*if (resp == 1) {
          this.cancelarVentanaOperacion();
          setTimeout(() => {
            this.cargarListadoTodasLasUnidades();
          },
            1000);
        }*/
        this.cancelarVentanaOperacion();
          setTimeout(() => {
            this.cargarListadoTodasLasUnidades();
          },
            1000);
      },
        (error) => {
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Editar Unidad>', detail: 'Contacte al Administrador del Sitio' });
        });
    }
  }

  validarFormulario() {

  }

  ejemplo() {
    alert('ejemplo');
  }

  validarNumeroEconomico() {
    let valor_numero: string = '';
    valor_numero = valor_numero + this.formularioUnidad.get('num_economico')?.value;
    if (valor_numero !== null) {
      if (valor_numero.length >= 5 && this.numero_operacion == 1) {
        this._unidadService.getExisteNumeroEconomico(valor_numero).subscribe((resp) => {
          this.existe_numeroeconomico = resp;
          if (this.existe_numeroeconomico == true) {
            this.confirmationService.confirm({
              message: 'Numero Economico ingresado <b>YA EXISTE</b>. Revise información. ',
              header: 'Numero Economico Invalido',
              icon: 'pi pi-info-circle',
              accept: () => {
                //limpiar Numero Economico
                this.formularioUnidad.controls['num_economico'].setValue('');
              },
              reject: (type: any) => {
                switch (type) {
                  case ConfirmEventType.REJECT:
                    this.formularioUnidad.controls['num_economico'].setValue('');
                    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                    break;
                  case ConfirmEventType.CANCEL:
                    this.formularioUnidad.controls['num_economico'].setValue('');
                    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                    break;
                }
              }
            });
          }
        },
          (error) => {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Revisar Numero Economico>', detail: 'Contacte al Administrador del Sitio' });
          });
      }
    }
  }

  validarNumeroEconomicoAlTeclear() {
    let valor_numero: string = '';
    valor_numero = valor_numero + (this.formularioUnidad.get('num_economico')?.value);
    if (valor_numero.length >= 5 && this.numero_operacion == 1) {
      this._unidadService.getExisteNumeroEconomico(valor_numero).subscribe((resp) => {
        this.existe_numeroeconomico = resp;
        if (this.existe_numeroeconomico == true) {
          this.confirmationService.confirm({
            message: 'Numero Economico ingresado <b>YA EXISTE</b>. Revise información. ',
            header: 'Numero Economico Invalido',
            icon: 'pi pi-info-circle',
            accept: () => {
              //limpiar Numero Economico
              this.formularioUnidad.controls['num_economico'].setValue('');
            },
            reject: (type: any) => {
              switch (type) {
                case ConfirmEventType.REJECT:
                  this.formularioUnidad.controls['num_economico'].setValue('');
                  this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                  break;
                case ConfirmEventType.CANCEL:
                  this.formularioUnidad.controls['num_economico'].setValue('');
                  this.messageService.add({ key: 'msj', severity: 'info', summary: 'Informativo', detail: 'El usuario fue notificado' });
                  break;
              }
            }
          });
        }
      });
    }
  }



  cancelarVentanaOperacion() {
    this.dspEditar = false;
    this.formularioUnidad.reset();
    this.texto_operacion = '';
    this.data = {
      id_unidad_transporte: 0,
      idx_respaldo_unidad: 0,
      clave_tipoTransporte: 0,
      clave_tipoUnidad: 0,
      clave_configuracionUnidad: 0,
      num_economico: '',
      clave_proveedor: 0,
      fecha_inicioRenta: '',
      fecha_finRenta: '',
      desc_placaUnidad: '',
      vin: '',
      num_tarjetaCirculacion: '',
      clave_tipoverificacion: 0,
      clave_verificacion: 0,
      des_verificacion: '',
      clave_tipo_combustible: 0,
      desc_marca: '',
      desc_modelo: '',
      num_anio: '',
      desc_color: '',
      num_polizaSeguro: '',
      fecha_inicioPoliza: '',
      fecha_finPoliza: '',
      desc_aseguradora: '',
      desc_permisoCarga: '',
      desc_tipoCaja: '',
      num_caja: 0,
      clave_ciudadOrigen: 0,
      clave_ciudadDestino: 0,
      num_gps: '',
      nombre_gps: '',
      num_camara: '',
      num_boton_panico: '',
      num_inmobilizador: '',
      num_kilometraje: 0,
      num_kilometraje_ultmantenimiento: 0,
      fecha_ult_mantenimiento: '',
      num_kilometraje_proxmantenimiento: 0,
      fecha_prox_mantenimiento: '',
      desc_tipo_mantenimiento: '',
      fecha_siniestro: '',
      desc_vidallantas: '',
      desc_siniestros: '',
      desc_fallasMecanicas: '',
      fecha_alta: '',
      fecha_registro: '',
      clv_estatus: 0,
      cod_usuario_registro: '',
      cod_usuario_ult_mov: '',
      fec_vigencia_tarjeta: '',
      num_estado_tarjeta: '',
      fec_inicio_humo: '',
      fec_fin_humo: '',
      fec_inicio_mecanica: '',
      fec_fin_mecanica: '',
      fec_inicio_estatal: '',
      fec_fin_estatal: '',
      clv_humo: 0,
      clv_mecanica: 0,
      fec_baja: '',
      num_tag_fijo: '',
      num_tag_emergente: '',
      num_tarjeta_sodexo: '',
      num_rendimiento_objetivo:0
    }
  }



  /*CATALOGOS*/

  cargarListadoProveedores() {
    this.lstproveedor = [{ clave_Proveedor: 1, nombre: 'VAMONOS EXPREES, SA DE CV', fk_clave_Estado: '19' },
    { clave_Proveedor: 2, nombre: 'CAMIONES PREMIER', fk_clave_Estado: '25' },
    { clave_Proveedor: 3, nombre: 'VALORES MOVILES SA DE CV', fk_clave_Estado: '19' },
    { clave_Proveedor: 4, nombre: 'TEOSA', fk_clave_Estado: '19' },
    { clave_Proveedor: 5, nombre: 'RIVERO MOTORS', fk_clave_Estado: '19' },
    { clave_Proveedor: 6, nombre: 'KERSI', fk_clave_Estado: '09' },
    { clave_Proveedor: 7, nombre: 'IDEALEASE', fk_clave_Estado: '14' },
    { clave_Proveedor: 8, nombre: 'REYNOSO', fk_clave_Estado: '14' },
    { clave_Proveedor: 9, nombre: 'GLEZMO', fk_clave_Estado: '14' },
    { clave_Proveedor: 10, nombre: 'GANZO', fk_clave_Estado: '25' },
    { clave_Proveedor: 11, nombre: 'RAFAEL GIL', fk_clave_Estado: '19' },
    { clave_Proveedor: 12, nombre: 'TRANSGAR', fk_clave_Estado: '14' },
    { clave_Proveedor: 13, nombre: 'TAPATIOS', fk_clave_Estado: '14' },
    { clave_Proveedor: 14, nombre: 'FRANCISCO JESUS GUEVARA VARGAS', fk_clave_Estado: '25' }];
  }

  cargarListadoTipoUnidad() {
    this.lstTipoUnidad = [
      {
        clave_tipoUnidad: 1,
        nombre: 'PROPIA',
        fecha_Registro: ''
      },
      {
        clave_tipoUnidad: 2,
        nombre: 'ARRENDADA-RENTA',
        fecha_Registro: ''
      },
      {
        clave_tipoUnidad: 3,
        nombre: 'CREDITO',
        fecha_Registro: ''
      },
      {
        clave_tipoUnidad: 4,
        nombre: 'SUBCONTRATADA',
        fecha_Registro: ''
      }];
  }

  cargarListadoTipoVerificacion() {
    this.lstTipoVerificacion = [{
      clave_tipoverificacion: 1,
      nombre: 'ESTATAL',
      fecha_Registro: ''
    },
    {
      clave_tipoverificacion: 2,
      nombre: 'FEDERAL',
      fecha_Registro: ''
    }];
  }

  cargarListadoVerificaciones() {
    this.lstVerificaciones = [{
      clave_verificacion: 1,
      nombre: 'VERIFICACION VEHICULAR DEL ESTADO DE MEXICO',
      fecha_Registro: ''
    }/*,
    {
      clave_verificacion: 2,
      nombre: 'VERIFICACION FEDERAL HUMO',
      fecha_Registro: ''
    },
    {
      clave_verificacion: 3,
      nombre: 'VERIFICACION FEDERAL FISICO-MECANICA',
      fecha_Registro: ''
    }*/,
    {
      clave_verificacion: 4,
      nombre: 'VERIFICACION VEHICULAR DEL ESTADO DE QUERETARO',
      fecha_Registro: ''
    },
    {
      clave_verificacion: 5,
      nombre: 'VERIFICACION VEHICULAR DEL ESTADO DE GUADALAJARA',
      fecha_Registro: ''
    }];
  }

  cargarListadoCiudades() {
    /*this.lstCiudades = [
      {
        id: 9,
        nombre: "CDMX TL",
        clave_Estado: "09",
        id_ciudad_principal: 1,
        clave_tipoTransporte: 1
      },
      {
        id: 14,
        nombre: "CDMX TP",
        clave_Estado: "09",
        id_ciudad_principal: 1,
        clave_tipoTransporte: 2
      },
      {
        id: 10,
        nombre: "CULIACAN TL",
        clave_Estado: "25",
        id_ciudad_principal: 2,
        clave_tipoTransporte: 1
      },
      {
        id: 17,
        nombre: "CULIACAN TP",
        clave_Estado: "25",
        id_ciudad_principal: 2,
        clave_tipoTransporte: 2
      },
      {
        id: 11,
        nombre: "GUADALAJARA TL",
        clave_Estado: "14",
        id_ciudad_principal: 3,
        clave_tipoTransporte: 1
      },
      {
        id: 8,
        nombre: "GUADALAJARA TP",
        clave_Estado: "14",
        id_ciudad_principal: 3,
        clave_tipoTransporte: 2
      },
      {
        id: 4,
        nombre: "LA PAZ TL",
        clave_Estado: "03",
        id_ciudad_principal: 4,
        clave_tipoTransporte: 3
      },
      {
        id: 12,
        nombre: "MONTERREY TL",
        clave_Estado: "19",
        id_ciudad_principal: 5,
        clave_tipoTransporte: 1
      },
      {
        id: 5,
        nombre: "MONTERREY TP",
        clave_Estado: "19",
        id_ciudad_principal: 5,
        clave_tipoTransporte: 2
      },
      {
        id: 15,
        nombre: "QUERETARO TL",
        clave_Estado: "22",
        id_ciudad_principal: 6,
        clave_tipoTransporte: 3
      },
      {
        id: 16,
        nombre: "TIJUANA TL",
        clave_Estado: "02",
        id_ciudad_principal: 7,
        clave_tipoTransporte: 3
      }
    ];*/
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudades = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Ciudades>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarListadoConfUnidad() {
    this.confUnidadService.getListadoConfUnidades().subscribe((resp) => {
      this.lstConfUnidad = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Conf. de Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarListadoDeEstados() {
    this.lstEstado = [
      {
        clave_Estado: '01',
        nombre: 'Aguascalientes',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '02',
        nombre: 'Baja California',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '03',
        nombre: 'Baja California Sur',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '04',
        nombre: 'Campeche',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '05',
        nombre: 'Coahuila de Zaragoza',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '06',
        nombre: 'Colima',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '07',
        nombre: 'Chiapas',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '08',
        nombre: 'Chihuahua',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '09',
        nombre: 'Distrito Federal',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '10',
        nombre: 'Durango',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '11',
        nombre: 'Guanajuato',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '12',
        nombre: 'Guerrero',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '13',
        nombre: 'Hidalgo',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '14',
        nombre: 'Jalisco',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '15',
        nombre: 'México',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '16',
        nombre: 'Michoacán de Ocampo',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '17',
        nombre: 'Morelos',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '18',
        nombre: 'Nayarit',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '19',
        nombre: 'Nuevo León',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '20',
        nombre: 'Oaxaca',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '21',
        nombre: 'Puebla',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '22',
        nombre: 'Querétaro',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '23',
        nombre: 'Quintana Roo',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '24',
        nombre: 'San Luis Potosí',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '25',
        nombre: 'Sinaloa',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '26',
        nombre: 'Sonora',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '27',
        nombre: 'Tabasco',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '28',
        nombre: 'Tamaulipas',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '29',
        nombre: 'Tlaxcala',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '30',
        nombre: 'Veracruz de Ignacio de la Llave',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '31',
        nombre: 'Yucatán',
        fk_clave_Pais: '260'
      },
      {
        clave_Estado: '32',
        nombre: 'Zacatecas',
        fk_clave_Pais: '260'
      }];
  }

  cargarListadoCombustible() {
    this.lstCombustible = [{
      id: 1,
      nombre: 'GASOLINA'
    }, {
      id: 2,
      nombre: 'DIESEL'
    },
    { id: 4, nombre: 'UREA' }
    ];
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  mostrarDialogoEstatus(data: IUnidadTransporteCompleto) {
    this.num_economico_general = data.num_economico.toUpperCase();
    this.dspEstatus = true;
    this.dataForm.controls['id_unidad_transporte'].setValue(data.id_unidad_transporte);
    this.dataForm.controls['clv_estatus'].setValue(data.clv_estatus);
    this.dataForm.controls['clv_sub_estatus'].setValue(data.clv_sub_estatus);
    this.deshabilitarEdicion();
    this.habilitar_edicion = true;
  }

  deshabilitarEdicion() {
    this.dataForm.controls['clv_estatus'].disable();
    this.dataForm.controls['clv_sub_estatus'].disable();
  }

  habilitarEdicion() {
    this.dataForm.controls['clv_estatus'].enable();
    this.dataForm.controls['clv_sub_estatus'].enable();
    this.habilitar_edicion = false;
  }

  habilitarEdicionHerramientas() {
    this.habilitar_edicion_herramientas = false;
    this.habilitarRecursos();
  }
  exportarExcelGeneralUnidades() {
    let rep: any = []
    this._unidadService.getrptListadoTodasLasUnidadesGeneral().subscribe((resp) => {
      rep = resp
      if (rep.length == 0) {
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
      } else {
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(rep);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array"
          });
          this.saveAsExcelFile(excelBuffer, "ReporteGeneralDeUnidadesCompleto" + '_' + this.fecha_dia);
        });
      }
    });
  }
  guardarInformacionEstatus() {
    //fecha_dia
    let data_enviar_Estatus: IDataEstatus = {
      id_unidad_transporte: this.dataForm.get('id_unidad_transporte')?.value,
      clv_estatus: this.dataForm.get('clv_estatus')?.value,
      clv_sub_estatus: this.dataForm.get('clv_estatus')?.value == 1 ? 0 : this.dataForm.get('clv_sub_estatus')?.value,
      fec_cambiaEstatus: this.fecha_dia,
      cod_usuario: this.user.cod!
    };
    data_enviar_Estatus.cod_usuario = data_enviar_Estatus.cod_usuario == null || data_enviar_Estatus.cod_usuario == typeof undefined ? '0999' : data_enviar_Estatus.cod_usuario;
    let validar_pertenencia = (this.verificarPertenenciaEstatus(data_enviar_Estatus.clv_estatus, data_enviar_Estatus.clv_sub_estatus));
    if ((data_enviar_Estatus.clv_estatus == 0 || data_enviar_Estatus.clv_estatus == 3) && (data_enviar_Estatus.clv_sub_estatus == null ||
      data_enviar_Estatus.clv_sub_estatus == 0)) {
      this.mostrarDialogoInformativo('<b>Seleccione Sub-Estatus.</b>');
    } else if (validar_pertenencia == 0) {
      this.mostrarDialogoInformativo('Debe Pertener a la Clasificación Correcta.<b>Verifique Sub-Estatus.</b>');
    }
    else {
      this._unidadService.editUnidadGestionEstatus(data_enviar_Estatus).subscribe((resp) => {
        this.rst_estatus = resp;
        if (this.rst_estatus.clv_estatus == 1) {
          this.cargarListadoTodasLasUnidades();
          this.cancelarModal();
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Completado', detail: 'La información se almaceno de forma correcta.' });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error en <Editar Estatus Unidad>', detail: 'Contacte al Administrador del Sitio' });
        }
      },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error en <Editar Estatus Unidad>', detail: 'Contacte al Administrador del Sitio' });
        });
    }
  }

  gestionarHerramientas(data: IUnidadTransporteCompleto) {
    this.herramientasForm.controls['num_economico'].setValue(data.num_economico);
    this.herramientasForm.controls['id_unidad_transporte'].setValue(data.id_unidad_transporte);
    this.herramientasForm.controls['num_tag_fijo'].setValue(data.num_tag_fijo);
    this.herramientasForm.controls['num_tag_emergente'].setValue(data.num_tag_emergente);
    this.herramientasForm.controls['num_tarjeta_sodexo'].setValue(data.num_tarjeta_sodexo);
    this.dsbHerramientas = true;
    this.deshabilitarRecursos();
  }

  deshabilitarRecursos() {
    this.herramientasForm.controls['num_tag_fijo'].disable();
    this.herramientasForm.controls['num_tag_emergente'].disable();
    this.herramientasForm.controls['num_tarjeta_sodexo'].disable();
  }

  habilitarRecursos() {
    this.herramientasForm.controls['num_tag_fijo'].enable();
    this.herramientasForm.controls['num_tag_emergente'].enable();
    this.herramientasForm.controls['num_tarjeta_sodexo'].enable();
  }

  guardarInformacionHerramientas() {

    let num_tag_fijo_cast: string = this.herramientasForm.get('num_tag_fijo')?.value == null ? '' : this.herramientasForm.get('num_tag_fijo')?.value;
    let num_tag_emergente_cast: string = this.herramientasForm.get('num_tag_emergente')?.value == null ? '' : this.herramientasForm.get('num_tag_emergente')?.value;
    let num_tarjeta_sodexo_cast: string = this.herramientasForm.get('num_tarjeta_sodexo')?.value == null ? '' : this.herramientasForm.get('num_tarjeta_sodexo')?.value;


    let data_enviar: IDataHerramientas = {
      id_unidad_transporte: this.herramientasForm.get('id_unidad_transporte')?.value,
      num_tag_fijo: num_tag_fijo_cast,
      num_tag_emergente: num_tag_emergente_cast,
      num_tarjeta_sodexo: num_tarjeta_sodexo_cast
    };
    this._unidadService.editUnidadGestionHerramientas(data_enviar).subscribe((resp) => {
      if (resp == 1) {
        this.cargarListadoTodasLasUnidades();
        this.cancelarModalHerramientas();
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Completado', detail: 'La información se almaceno de forma correcta.' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error en <Actualizar Recursos>', detail: 'Contacte al Administrador del Sitio' });
      }
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Actualizar Recursos>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  verificarPertenenciaEstatus(clv_estatus: number, sub_estatus: number): number {
    let pertenencia = 0;
    if (this.lstSubEstatusUnidad.length > 0) {
      this.lstSubEstatusUnidad.map((valor) => {
        if (valor.clv_estatus == clv_estatus && valor.clv_sub_estatus == sub_estatus) {
          pertenencia = 1;
        } else if ((clv_estatus == 1 || clv_estatus == 4) && sub_estatus == 0) {
          pertenencia = 1;
        }
      });
    }
    return pertenencia;
  }

  cancelarModal() {
    this.dspEstatus = false;
    this.habilitar_edicion = false;
    this.num_economico_general = '';
  }

  cancelarModalHerramientas() {
    this.dsbHerramientas = false;
    this.habilitar_edicion_herramientas = false;
    this.num_economico_general = '';
  }

  exportarExcel() {
    this.rpt_ListadoUnidades_cast = [];
    if (!this.lstUnidades.length || typeof this.lstUnidades === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    } else {
      this.asignarArregloParaExportar(this.lstUnidades);
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.rpt_ListadoUnidades_cast);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        this.saveAsExcelFile(excelBuffer, "ReporteGeneralDeUnidades" + '_' + this.fecha_dia);
      });
    }
  }

  exportarExcelTDO() {
    this.rpt_ListadoUnidades = [];
    this.rpt_ListadoUnidades_Servicios = [];
    if ((this.selectedMes == null || this.selectedMes.num_mes == -1) || (this.anio === 'undefined' || this.anio == null)) {
      this.mostrarMensajeDeAlerta();
    } else {
      let mes = this.selectedMes.num_mes;
      let anio_cast: any = 0;
      if (this.anio instanceof Date) {
        // will execute
        anio_cast = this.anio.getFullYear();
      } else {
        anio_cast = this.anio;
      }
      this.cargarInformacionDelMes(1, mes, anio_cast);
    }
  }

  cargarInformacionDelMes(num_opcion: number, num_mes: number, num_anio: number) {
    // aqui se manda el servicio a llamar se llena rpt_ListadoUnidades
    this._unidadService.getListadoTodasLasUnidadesTDO(num_opcion, num_mes, num_anio).subscribe((resp) => {
      this.rpt_ListadoUnidades = resp;
      this.asignarArregloParaExportarTDO(this.rpt_ListadoUnidades);
      if (this.rpt_ListadoUnidades_Servicios.length == 0 || typeof this.rpt_ListadoUnidades_Servicios === 'undefined') {
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
      } else {
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.rpt_ListadoUnidades_Servicios);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array"
          });
          this.saveAsExcelFile(excelBuffer, "ReporteUnidadesDiasyTotalServicios" + '_' + this.selectedMes.nombre + '_' + num_anio);
        });
      }
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listado Estatus Unidad TDO>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  exportarExcelHistorico() {
    this.lstUnidadesHistorico = [];
    this.rpt_ListadoUnidades_historico = [];
    if ((this.anio_historico === 'undefined' || this.anio_historico == null) || (this.selectedMesHistorico == null || this.selectedMesHistorico.num_mes == -1)) {
      this.mostrarMensajeDeAlerta();
    } else {
      let anio_cast: any = 0;
      if (this.anio_historico instanceof Date) {
        // will execute
        anio_cast = this.anio_historico.getFullYear();
      } else {
        anio_cast = this.anio_historico;
      }
      this.cargarInformacionHistorico(1, anio_cast, this.selectedMesHistorico.num_mes);
    }
  }

  exportarExcelHistoricoIna() {
    this.lstUnidadesHistorico = [];
    this.rpt_ListadoUnidades_historico = [];
    if ((this.anio_historico_ina === 'undefined' || this.anio_historico_ina == null) || (this.selectedMesHistoricoIna == null || this.selectedMesHistoricoIna.num_mes == -1)) {
      this.mostrarMensajeDeAlerta();
    } else {
      let anio_cast: any = 0;
      if (this.anio_historico instanceof Date) {
        // will execute
        anio_cast = this.anio_historico.getFullYear();
      } else {
        anio_cast = this.anio_historico;
      }
      this.cargarInformacionHistoricoInactivas(2, anio_cast, this.selectedMesHistoricoIna.num_mes);
    }
  }

  cargarInformacionHistoricoInactivas(num_opcion: number, num_anio: number, num_mes: number) {
    //
    this._unidadService.getListadoHistorico(num_opcion, num_anio, num_mes).subscribe((resp) => {
      this.lstUnidadesHistorico = resp;
      this.asignarArregloParaExportarHistoricoInactivas(this.lstUnidadesHistorico);
      if (this.lstUnidadesHistorico.length == 0 || typeof this.lstUnidadesHistorico === 'undefined') {
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
      } else {
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.rpt_ListadoUnidades_historico);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array"
          });
          let nombre_documento = 'ContabilizarUnidadesInactivas_' + this.meses.filter((r) => r.num_mes == num_mes)[0].nombre.toUpperCase() + '_' + num_anio;
          this.saveAsExcelFile(excelBuffer, nombre_documento);
        });
      }
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listado Historico de Estatus>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarInformacionHistorico(num_opcion: number, num_anio: number, num_mes: number) {
    // aqui se manda el servicio a llamar se llena rpt_ListadoUnidades_historico
    this._unidadService.getListadoHistorico(num_opcion, num_anio, num_mes).subscribe((resp) => {
      this.lstUnidadesHistorico = resp;
      this.asignarArregloParaExportarHistorico(this.lstUnidadesHistorico);
      if (this.lstUnidadesHistorico.length == 0 || typeof this.lstUnidadesHistorico === 'undefined') {
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
      } else {
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.rpt_ListadoUnidades_historico);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array"
          });
          let nombre_documento = 'ReporteHistoricoDeEstatus_' + this.meses.filter((r) => r.num_mes == num_mes)[0].nombre.toUpperCase() + '_' + num_anio;
          this.saveAsExcelFile(excelBuffer, nombre_documento);
        });
      }
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listado Historico de Estatus>', detail: 'Contacte al Administrador del Sitio' });
      });
  }





  mostrarMensajeDeAlerta() {
    this.loading = false;
    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'info',
      summary: 'Falta Mes y/o Año',
      detail: 'Mes y Año deben seleccionarse para consultar informacion.'
    });
  }

  cancelarMensajeConfirmacion() {
    this.messageService.clear('confirm');
  }

  confirmarMensaje() {
    this.messageService.clear('confirm');
  }

  asignarArregloParaExportar(data: IUnidadTransporteCompleto[]) {
    data.map((valor) => {
      this.rpt_ListadoUnidades_cast.push({
        IdUnidad: valor.id_unidad_transporte,
        NumeroEconomico: valor.num_economico,
        Placa: valor.desc_placaUnidad,
        VIN: valor.vin,
        DatosGenerales: (valor.desc_marca + ' ' + valor.desc_modelo + ' ' + valor.desc_color),
        TipoTransporte: valor.nombre_tipotransporte,
        TipoUnidad: valor.nombre_tipounidad,
        ConfiguracionUnidad: valor.nombre_configuracionunidad,
        Ciudad: valor.nombre_ciudaddestino,
        Estatus: valor.desc_estatus!,
        SubEstatus: valor.desc_sub_estatus!
      });
    });
  }

  asignarArregloParaExportarHistorico(data: IUnidadTransporteHistorico[]) {
    data.map((valor) => {
      this.rpt_ListadoUnidades_historico.push({
        IdUnidad: valor.id_unidad_transporte,
        NumeroEconomico: valor.num_economico,
        Placa: valor.desc_placaUnidad,
        VIN: valor.vin,
        DatosGenerales: valor.datos_generales,
        TipoTransporte: valor.nombre_tipotransporte,
        TipoUnidad: valor.nombre_tipounidad,
        ConfiguracionUnidad: valor.nombre_configuracionunidad,
        Ciudad: valor.nombre_ciudaddestino,
        Estatus: valor.desc_estatus!,
        SubEstatus: valor.desc_sub_estatus!,
        FechaRegistro: valor.fecha_registro,
        NumeroDias: valor.num_dias
      });
    });
  }

  asignarArregloParaExportarHistoricoInactivas(data: IUnidadTransporteHistorico[]) {
    data.map((valor) => {
      this.rpt_ListadoUnidades_historico.push({
        IdUnidad: valor.id_unidad_transporte,
        NumeroEconomico: valor.num_economico,
        Placa: valor.desc_placaUnidad,
        VIN: valor.vin,
        DatosGenerales: valor.datos_generales,
        TipoTransporte: valor.nombre_tipotransporte,
        TipoUnidad: valor.nombre_tipounidad,
        ConfiguracionUnidad: valor.nombre_configuracionunidad,
        Ciudad: valor.nombre_ciudaddestino,
        /*Estatus: valor.desc_estatus!,
        SubEstatus: valor.desc_sub_estatus!,
        FechaRegistro: valor.fecha_registro,*/
        NumeroVecesInactiva: valor.num_veces_inactiva
      });
    });
  }

  asignarArregloParaExportarTDO(data: IUnidadesEstatus[]) {
    data.map((valor) => {
      this.rpt_ListadoUnidades_Servicios.push({
        IdUnidad: valor.id_unidad_transporte,
        NumeroEconomico: valor.num_economico,
        Placa: valor.desc_placaUnidad,
        VIN: valor.vin,
        DatosGenerales: valor.desc_general,
        TipoTransporte: valor.nombre_tipotransporte,
        TipoUnidad: valor.nombre_tipounidad,
        ConfiguracionUnidad: valor.nombre_configuracionunidad,
        Ciudad: valor.nombre_ciudaddestino,
        Estatus: valor.desc_estatus!,
        SubEstatus: valor.desc_sub_estatus!,
        DiasDeAsignacion: valor.num_dias_asignacion,
        TotalDeServicios: valor.num_total_servicios
      });
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + EXCEL_EXTENSION);

  }

  abrirModalReportes() {
    this.dbsReportes = true;
  }

  cancelarModalReportes() {
    this.dbsReportes = false;
    this.selectedMes = {
      num_mes: 0,
      nombre: ''
    }
    this.anio = null;
  }

}
