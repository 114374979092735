import { IRuta } from './../models/ruta';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IDataGuardarFolio } from '../models/data_folio';
import { IFolioMensual } from '../models/FolioMensual';

@Injectable({
  providedIn: 'root'
})
export class RutaService {
  private apiUrl: string ='';
  private apiUrl1: string ='';
  constructor(private _http: HttpClient) { 
    this.apiUrl = environment.SUrlgApi + 'Rutas';
    this.apiUrl1 = environment.SUrlgApi + 'GestionFolioRutas';
  }

  public listarRutas():Observable<any>{
    return this._http.get(this.apiUrl);
   }

   public listarRutasNormales():Observable<any>{
    return this._http.get(this.apiUrl+'/getListNormal');
   }

   getListadoFolios(cod_usuario:string): Observable<any[]> {
    let cod_usuario_cast = cod_usuario=='undefined' ? '': cod_usuario!; 
    const valores = {
      params: new HttpParams().
        set('cod_usuario', cod_usuario_cast)
    };
    return this._http
      .get<any[]>(this.apiUrl1 + '/getRutasYFolios', valores);
  }

  getListadoFolioMensual(num_opcion: number,num_mes: number,num_anio:number): Observable<IFolioMensual[]> {
    const valores = {
      params: new HttpParams().
        set('num_mes', num_mes).
        set('num_anio', num_anio)
    };
    return this._http
      .get<IFolioMensual[]>(this.apiUrl1 + '/GetListadoFolioMensual',valores);
  }

   public saveRutaEspecial(num_opcion:number,ruta: IRuta ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(ruta); 
    return this._http.post<any>(this.apiUrl + '/addRutaEspecial/'+num_opcion,body,{'headers':headers});
   }

   public saveRutaNormal(num_opcion:number,ruta: IRuta ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(ruta); 
    return this._http.post<any>(this.apiUrl + '/addRutaNormal/'+num_opcion,body,{'headers':headers});
   }

   public editRutaNormal(num_opcion:number,ruta: IRuta ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(ruta); 
    return this._http.post<any>(this.apiUrl + '/ediRutaNormal/'+num_opcion,body,{'headers':headers});
   }

   public DesaRutaNormal(num_opcion:number,ruta: IRuta ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(ruta); 
    return this._http.post<any>(this.apiUrl + '/desactivarRutaNormal/'+num_opcion,body,{'headers':headers});
   }

   public guardarFolioRuta(data:IDataGuardarFolio):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.post<any>(this.apiUrl1 + '/guardarFolioRutaMes/',body,{'headers':headers});
   }

   public editarFolioRuta(data:IDataGuardarFolio):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.post<any>(this.apiUrl1 + '/editarFolioRutaMes/',body,{'headers':headers});
   }

}
