
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import {ConfirmationService, ConfirmEventType,MessageService} from 'primeng/api';
import { IProyecto } from '../models/proyecto';
import { ProyectoService } from '../services/proyecto.service';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { subscribeOn } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
@Component({
  selector: 'app-lstproyecto',
  templateUrl: './lstproyecto.component.html',
  styleUrls: ['./lstproyecto.component.scss']
})
export class LstproyectoComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  lstProyecto: IProyecto[]=[];
  loading:boolean=false;
  color_gris_deshabilitadas:string = '#D9D9D9';
  numero_operacion:number=0;
  proyectoForm: FormGroup = new FormGroup({
    id_proyecto: new FormControl({ value: 0, disabled: true }, []),
    nombre: new FormControl('', [
      Validators.required
    ]),
    clv_activo: new FormControl(),
    fec_registro:new FormControl()
  });
  dspProyecto:boolean=false;
  texto_operacion:string='';
  fecha_dia : string = new Date().toLocaleDateString('es-MX',{ year: 'numeric', month: '2-digit', day: '2-digit' });
  //Registro
  user: ICredencialUsuario = {};
  constructor(private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private proyectoService: ProyectoService,
    private auth: AuthService){
      this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.getListaDeProyectos();
  }

  cargarInformacionUsuario(){
    this.user = this.auth.user;
  }

  getListaDeProyectos(){
    this.loading=true;
    this.proyectoService.getListadoProyectos().subscribe((resp) => {
      this.lstProyecto = resp;
      this.loading=false;
    },
    (error)=> {
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Servicios');
    });
  }

  redireccionarNueva(){
    this.cancelarVentanaModal();
    this.dspProyecto=true;
    this.numero_operacion=1;
    this.texto_operacion = 'Agregar Nuevo Servicio';
  }

   /*Filtro General para la informacion de tabla*/
   applyFilterGlobal($event:any, stringVal:any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  confirmarEliminar(id: number,data:IProyecto){
    let estatus_original = data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea ' + texto_desactivar + ' el registro del Servicio '+data.nombre+'?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo=estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo=estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }

  editarInformacion(data:IProyecto){
  this.dspProyecto=true;
  this.numero_operacion=2;
  this.texto_operacion = 'Edicion de Informacion';
  this.proyectoForm.controls['id_proyecto'].setValue(data.id_proyecto);
  this.proyectoForm.controls['nombre'].setValue(data.nombre);
  this.proyectoForm.controls['clv_activo'].setValue(data.clv_activo);
  this.proyectoForm.controls['fec_registro'].setValue(data.fec_registro);
  }

  guardarInformacion(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let data_enviar : IProyecto = {
      id_proyecto: this.numero_operacion==1 ? 0: this.proyectoForm.get('id_proyecto')?.value,
      nombre: this.proyectoForm.get('nombre')?.value,
      fec_registro: this.numero_operacion==1 ? dateFormat: this.proyectoForm.get('fec_registro')?.value,
      clv_activo: this.numero_operacion==1 ? 1: this.proyectoForm.get('clv_activo')?.value
    };
    if(this.numero_operacion==1){
      this.proyectoService.saveProyecto(data_enviar).subscribe((resp)=>{
        let valor = resp;
        if(valor==1){
          this.cancelarVentanaModal();
          this.getListaDeProyectos();
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Informacion almacenada correctamente.' });
        }
      },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en Nuevo Servicio. Contacte al Administrador' });
      });
    } else if(this.numero_operacion==2){
      this.proyectoService.editProyecto(data_enviar).subscribe((resp)=>{
        let valor = resp;
        if(valor==1){
          this.cancelarVentanaModal();
          this.getListaDeProyectos();
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Informacion actualizada correctamente.' });
        }
      },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en Nuevo Servicio. Contacte al Administrador' });
      });
    }
  }

  cancelarVentanaModal(){
    this.dspProyecto=false;
    this.texto_operacion = '';
    this.numero_operacion=0;
    this.proyectoForm.reset();
  }

  guardarInformacionDesactivar(data:IProyecto){
    this.proyectoService.editProyecto(data).subscribe((resp)=>{
      let valor = resp;
      if(valor==1){
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Informacion actualizada correctamente.' });
      }
    },
    (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en Editar Estatus Servicio. Contacte al Administrador' });
    });
  }

  exportarExcel() {
    if(!this.lstProyecto.length || typeof this.lstProyecto ==='undefined'){
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    } else {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.lstProyecto);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });
      this.saveAsExcelFile(excelBuffer, "servicios");
    });
  }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);

  }

  
  mostrarDialogoInformativoSinInformacion(mensaje_usuario:string){
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
      },
      key: "sinInformacionDialog"
  });
  }

}
