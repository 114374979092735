import { IResultadoGeneral } from './../models/resultadogeneral';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { ClienteService } from '../services/cliente.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { CiudadService } from '../services/ciudad.service';
import { ICiudad } from '../models/ciudad';
import { IDescansoFaltas } from '../models/descansosFaltas';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { ProgramacionDescansosService } from '../services/programacionDescansos.service';
import { ChoferService } from '../services/chofer.service';
import { IpostDescansoFaltas } from '../models/postDescansoFatltas';
import { IdeleteDescansoFaltas } from '../models/deleteDescansoFaltas';
import { IMes } from '../models/mes';
import { IrptDescansoFaltas } from '../models/rptDescansoFaltas';
import { ICiudadDescFalta } from '../models/ciudadDescFalta';

@Component({
  selector: 'app-registro-personal',
  templateUrl: './registro-personal.component.html',
  styleUrls: ['./registro-personal.component.scss']
})
export class RegistroPersonalComponent {
  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudad[] = [];
  lstChoferes: InombreCompletoChofer[] = [];
  lstchofCopy: InombreCompletoChofer[] = [];
  lstDescansos: IDescansoFaltas[] = [];
  lstCiudades: ICiudad[] = [];
  mssgListaCiudades: string = '';


  lstrptDescansoFaltas: IrptDescansoFaltas[] = [];
  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };


  loading: boolean = false;
  //variables usadas para la fecha, fueron casteadas ya que presentaban errorees
  fecha_hoy: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_filtrada: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  id_ciudad_hm: number = 0;
  dspCliente: boolean = false;
  texto_operacion: string = '';
  num_operacion: number = 0;
  clv_operacion: number = 0;
  habilitar_agregar: boolean = false;

  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  // variables de reportes--------------------------
  dbsReportes: boolean = false;
  fecha_fin: any = null;
  ButtonExportExcel: boolean = false;
  //Fecha de Dias Atras
  fecha_dos_dias_anterior!: Date;
  fecha_del_dia: any = null;
  btnHabilitar: boolean = false;
  mostrar_texto_fechas: number = 0;
  fecha_final_dia!: Date;
  //Fecha para restar 3 dias
  fecha_minus3 !: Date;
  habilitar_tres_dias: boolean = true;
  fecha_dia_real: any = null;
  checkboxValue: boolean = false;
  checked:  boolean = false;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }

  anioReporte1: any;
  loading_reporte1: boolean = false;
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];

  reactiveForm: FormGroup = new FormGroup({
    idx: new FormControl(0, [Validators.required]),
    fecha: new FormControl(this.fecha_dia, [Validators.required]),
    chofer: new FormControl(0, [Validators.required ]),
    ciudad: new FormControl(0, [Validators.required]),
    evento: new FormControl(0, [Validators.required]),
    nom_usuario_mod: new FormControl('')

  });

  //Permisos
  user: ICredencialUsuario = {};
  //Validaciones de Tiempo
  dsblstCiudades: boolean=true;
  dsblstColaboradores: boolean=true;
  dsblstCiudadesAdscritas: boolean=true;
  constructor(private router: Router,
    private programacionDescansosService: ProgramacionDescansosService,

    private ciudadService: CiudadService,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private clienteService: ClienteService, private auth: AuthService) {
    this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.cargaInicial();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  limpiarfiltrosTabla() {
    this.dt.clear();
  }

  cargaListaChoferes(){
    this.dsblstColaboradores=true;
    this.lstChoferes = [];
    this.lstchofCopy = [];
    this.programacionDescansosService.getListadoChoferesGeneral(this.fecha_filtrada , this.id_ciudad_hm).subscribe((resp) => {
      this.lstChoferes = resp;
      this.lstchofCopy = resp;
      setTimeout(() => {
        this.dsblstColaboradores=false;
      }, 1000);
    },
      (error) => {
        this.dsblstColaboradores=true;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Colaboradores');
      });
  }
  // -----------filtra y carga la informacion --------
  FiltrarFechaDiariaRuta() {
    this.loading = true;
    this.dia = this.fecha_filtrada;
    this.fecha_dia = this.fecha_filtrada;
    if(this.id_ciudad_hm==null || typeof this.id_ciudad_hm==='undefined'){
      this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> La fecha y Ciudad de Operación o Todas las Ciudades deben seleccionarse');
      this.loading = false;
    } else {
      this.cargaListaChoferes();
      //let cod_usuario
      this.programacionDescansosService.getListadoDescasoFalta(this.fecha_dia, this.fecha_dia, this.id_ciudad_hm, this.user.cod == undefined ? '0999' : this.user.cod).subscribe((resp) => {
        this.lstDescansos = [];
        this.lstDescansos = resp;
        this.clv_operacion = this.clv_operacion !=0 ? this.clv_operacion: this.lstDescansos.length==0? 0: this.lstDescansos[0].clv_operacion ;

        this.habilitar_agregar = true;
        if (this.lstDescansos.length == 0) {
            this.loading = false;
            this.mostrarDialogoInformativoSinInformacion('<b>No Existe</b> información de Rutas para la Fecha ' + this.fecha_filtrada + ' seleccionada');
        }
        setTimeout(() => {
        this.loading = false;
        }, 800);
      },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>ERROR</b> en Cargar Movimientos del Dia(Gestion de Descanso y Faltas)');
          this.loading = false;
        });
    }
  }

  asignarValorFecha() {
    this.fecha_dia = this.fecha_filtrada;
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  //   guarda la informacion del colaborador
  guardarInformacionDeColaborador() {
    this.fecha_dia = this.fecha_filtrada;
    let data_enviar: IpostDescansoFaltas = {
      idx: this.reactiveForm.get('idx')?.value,
      id_ciudad_hm: this.reactiveForm.get('ciudad')?.value,
      fec_evento: this.reactiveForm.get('fecha')?.value,
      cod_chofer: this.reactiveForm.get('chofer')?.value,
      id_evento: this.reactiveForm.get('evento')?.value,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod
    };
    this.programacionDescansosService.postRecursos(data_enviar).subscribe((resp) => {
      var resultado = resp;
      if (resultado.clv_estatus == 1) {
        setTimeout(() => {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.reactiveForm.reset();
          this.cancelarVentanaModal();
          this.FiltrarFechaDiariaRuta();
        }, 800);
      } else if (resultado.clv_estatus == -2) {
        setTimeout(() => {
          this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Cliente>', detail: resultado.messageDetails });
          this.reactiveForm.reset();
          this.cancelarVentanaModal();
          this.FiltrarFechaDiariaRuta();
        }, 800);
      }
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Cliente>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  changedp(event: any) {
    let eve = event.value;
    this.loading = true;
    this.lstCiudades = [];
    this.reactiveForm.controls['ciudad'].setValue(0);
    this.reactiveForm.controls['ciudad'].enable();

    if (this.lstchofCopy.length > 0) {
      this.lstchofCopy.forEach(resp => {
        if (resp.cod_chofer == eve) {
          if (resp.id_ciudad_hm != 0) {
            this.lstCiudades.push({
              id: resp.id_ciudad_hm,
              nombre: resp.desc_ciudad_hm,
              clave_Estado: '',
              id_ciudad_principal: 0,
              clave_tipoTransporte: 0
            });
          }

          if (resp.id_ciudad_hm_prestada != 0 && resp.id_ciudad_hm != resp.id_ciudad_hm_prestada) {
            this.lstCiudades.push({
              id: resp.id_ciudad_hm_prestada,
              nombre: resp.desc_ciudad_hm_prestada,
              clave_Estado: '',
              id_ciudad_principal: 0,
              clave_tipoTransporte: 0
            });
          }
        }
      });

      if (this.lstCiudades.length == 1) {
        this.reactiveForm.controls['ciudad'].setValue(this.lstCiudades[0].id);
        this.reactiveForm.controls['ciudad'].disable();
      }

      switch (this.lstCiudades.length) {
        case 0:
          this.mssgListaCiudades = 'El colaborador no cuenta con ciudad. Favor de verificar.';
          break;
        case 1:
          this.mssgListaCiudades = 'El colaborador solo cuenta con ciudad origen.';
          break;
        case 2:
        default:
          this.mssgListaCiudades = 'Seleccione ciudad a registrar.';
          break;
      }
    }

    this.loading = false;
  }

  // cargar lista inicial para los dropdown  get
  cargaInicial(){
    this.dsblstCiudades=true;
    this.loading = true;
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp;
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_Estado: '', id_ciudad_principal: 0, clave_tipoTransporte: 0 });
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades=false;
      }, 1000);

    },
      (error) => {
        this.loading = false;

        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
      });
  }

  //botones de agregar
  mostrarModalAgregar() {
    this.cancelarVentanaModal();
    this.num_operacion = 1;
    this.dspCliente = true;
    this.texto_operacion = 'Agregar Nuevo Descanso';
    this.reactiveForm.controls['idx'].setValue(0);
    this.reactiveForm.controls['evento'].setValue(2);
    this.reactiveForm.controls['fecha'].setValue(this.dia);
    this.reactiveForm.controls['ciudad'].disable();
  }

  mostrarModalAgregarFalta() {
    this.cancelarVentanaModal();
    this.num_operacion = 1;
    this.dspCliente = true;
    this.texto_operacion = 'Agregar Nueva Falta';
    this.reactiveForm.controls['idx'].setValue(0);
    this.reactiveForm.controls['evento'].setValue(1);
    this.reactiveForm.controls['fecha'].setValue(this.dia);
    this.reactiveForm.controls['ciudad'].disable();
  }

  cancelarVentanaModal() {
    this.num_operacion = 0;
    this.dspCliente = false;
    this.texto_operacion = '';
    this.reactiveForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }
  //botones de editar
  mostrarModalEditarFalta(descansoFaltas: IDescansoFaltas) {

    this.lstChoferes.push({

      cod_chofer: descansoFaltas.cod_chofer,
      nombre_completo: descansoFaltas.nom_chofer,
      id_ciudad_hm: descansoFaltas.id_ciudad_origen,
      desc_ciudad_hm:descansoFaltas.desc_ciudad_origen,
      id_ciudad_hm_prestada:descansoFaltas.id_ciudad_prestada,
      desc_ciudad_hm_prestada:descansoFaltas.desc_ciudad_prestada
    })

    this.reactiveForm.controls['idx'].setValue(descansoFaltas.idx);
    this.reactiveForm.controls['ciudad'].setValue(descansoFaltas.id_ciudad_hm);
    this.reactiveForm.controls['fecha'].setValue(descansoFaltas.fec_evento);
    this.reactiveForm.controls['chofer'].setValue(descansoFaltas.cod_chofer);
    this.reactiveForm.controls['evento'].setValue(descansoFaltas.id_evento);
    this.reactiveForm.controls['nom_usuario_mod'].setValue(descansoFaltas.nom_usuario_mod);

    if(this.clv_operacion == 1 ){
      this.lstCiudades = [];
      if(descansoFaltas.id_ciudad_origen != 0){
        this.lstCiudades.push({
          id: descansoFaltas.id_ciudad_origen,
          nombre: descansoFaltas.desc_ciudad_origen,
          clave_Estado:'',
          id_ciudad_principal:0,
          clave_tipoTransporte:0
        });
      }

      if(descansoFaltas.id_ciudad_prestada != 0 && descansoFaltas.id_ciudad_origen != descansoFaltas.id_ciudad_prestada){
        this.lstCiudades.push({
          id: descansoFaltas.id_ciudad_prestada,
          nombre: descansoFaltas.desc_ciudad_prestada,
          clave_Estado:'',
          id_ciudad_principal:0,
          clave_tipoTransporte:0
        });
      }

      if(descansoFaltas.id_ciudad_hm != descansoFaltas.id_ciudad_origen && descansoFaltas.id_ciudad_hm != descansoFaltas.id_ciudad_prestada){
        this.lstCiudades.push({
          id: descansoFaltas.id_ciudad_hm,
          nombre: descansoFaltas.desc_ciudad_hm,
          clave_Estado:'',
          id_ciudad_principal:0,
          clave_tipoTransporte:0
        });
      }
      this.reactiveForm.controls['fecha'].disable();
    }else if(this.clv_operacion == 3){
      this.lstCiudades = [];
      this.lstCiudades = this.lstCiudad;
      this.reactiveForm.controls['fecha'].enable();
      this.reactiveForm.controls['ciudad'].enable();
    }

    this.num_operacion = 2;
    this.dspCliente = true;
    this.texto_operacion = 'Editar Registro Falta';

  }

  mostrarModalEditarDescanso(descansoFaltas: IDescansoFaltas) {
    this.lstChoferes.push({
      cod_chofer: descansoFaltas.cod_chofer,
      nombre_completo: descansoFaltas.nom_chofer,
      id_ciudad_hm: descansoFaltas.id_ciudad_origen,
      desc_ciudad_hm:descansoFaltas.desc_ciudad_origen,
      id_ciudad_hm_prestada:descansoFaltas.id_ciudad_prestada,
      desc_ciudad_hm_prestada:descansoFaltas.desc_ciudad_prestada
    })

    this.reactiveForm.controls['idx'].setValue(descansoFaltas.idx);
    this.reactiveForm.controls['ciudad'].setValue(descansoFaltas.id_ciudad_hm);
    this.reactiveForm.controls['fecha'].setValue(descansoFaltas.fec_evento);
    this.reactiveForm.controls['chofer'].setValue(descansoFaltas.cod_chofer);
    this.reactiveForm.controls['evento'].setValue(descansoFaltas.id_evento);
    this.reactiveForm.controls['nom_usuario_mod'].setValue(descansoFaltas.nom_usuario_mod);

    if(this.clv_operacion == 1 ){
      this.lstCiudades = [];
      if(descansoFaltas.id_ciudad_origen != 0){
        this.lstCiudades.push({
          id: descansoFaltas.id_ciudad_origen,
          nombre: descansoFaltas.desc_ciudad_origen,
          clave_Estado:'',
          id_ciudad_principal:0,
          clave_tipoTransporte:0
        });
      }

      if(descansoFaltas.id_ciudad_prestada != 0 && descansoFaltas.id_ciudad_origen != descansoFaltas.id_ciudad_prestada){
        this.lstCiudades.push({
          id: descansoFaltas.id_ciudad_prestada,
          nombre: descansoFaltas.desc_ciudad_prestada,
          clave_Estado:'',
          id_ciudad_principal:0,
          clave_tipoTransporte:0
        });
      }

      if(descansoFaltas.id_ciudad_hm != descansoFaltas.id_ciudad_origen && descansoFaltas.id_ciudad_hm != descansoFaltas.id_ciudad_prestada){
        this.lstCiudades.push({
          id: descansoFaltas.id_ciudad_hm,
          nombre: descansoFaltas.desc_ciudad_hm,
          clave_Estado:'',
          id_ciudad_principal:0,
          clave_tipoTransporte:0
        });
      }
      this.reactiveForm.controls['fecha'].disable();
    }else if(this.clv_operacion == 3){
      this.lstCiudades = [];
      this.lstCiudades = this.lstCiudad;
      this.reactiveForm.controls['fecha'].enable();
      this.reactiveForm.controls['ciudad'].enable();
    }



    this.num_operacion = 2;
    this.dspCliente = true;
    this.texto_operacion = 'Editar Registro Descanso';
  }

  confirmarEliminar(data: IDescansoFaltas) {
    let resultado: IResultadoGeneral;
    let texto_desactivar: string = data.desc_evento;
    let data_enviar: IdeleteDescansoFaltas = { cod_chofer: data.cod_chofer, fec_evento: data.fec_evento };
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar el/la <b>' + texto_desactivar + '</b> del Colaborador ' + data.nom_chofer + '?',
      header: 'Confirmación de Eliminación',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.programacionDescansosService.deleteRecursos(data_enviar).subscribe((resp) => {
          resultado = resp;
          if (resultado.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Eliminado', detail: 'Se eliminó correctamente el servicio' });
            this.FiltrarFechaDiariaRuta();
          } else if (resultado.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }


  // ----------------reportes  ------------
  abrirModalReportes() {
    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }

  cancelarModalReportes() {
    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
    this.selectedCiudad = {
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.fecha_fin = null;
  }

  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;

    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));

    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;

    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }

  // metodo para exportar a excel
  exportarExcel(fecha_dia: string, fecha_fin: string) {
    let fecha_fin_cast = fecha_fin == null ? '1900-01-01' : fecha_fin;
    this.loading_reporte1 = true;
    if (fecha_dia == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    } else {
      this.programacionDescansosService.getListadoDescasoFalta(fecha_dia, fecha_fin_cast, 0, this.user.cod == undefined ? '0999' : this.user.cod)
        .subscribe((resp: IDescansoFaltas[]) => {
          if (resp.length === 0 || typeof resp === 'undefined') {
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
            this.loading_reporte1 = false;
          } else {
            const rptDescansosFaltas: IrptDescansoFaltas[] = resp.map(descansoFalta => ({
              Colaborador: descansoFalta.nom_chofer,
              Puesto: descansoFalta.desc_puesto,
              Ciudad: descansoFalta.desc_ciudad_hm,
              Evento: descansoFalta.desc_evento,
              FechaRegistro: descansoFalta.fec_evento,
              UsuarioQueModifico: descansoFalta.nom_usuario_mod
            }));
            import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(rptDescansosFaltas);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
              const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
              });
              let texto: string = fecha_fin == '1900-01-01' || fecha_fin == null ? '' : '_Al_' + fecha_fin;
              this.saveAsExcelFile(excelBuffer, "GestiónDeEstatusDeRuta" + '_' + fecha_dia + texto);
            });
            setTimeout(() => {
              this.ButtonExportExcel = false;
              this.loading_reporte1 = false;
            }, 1000);
          }
        },
          (error) => {
            this.ButtonExportExcel = false;
            this.loading_reporte1 = false;
            this.fecha_fin = null;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
          });
    }
  }


  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }
  //metodo para ahcer las validaciones referentes a clv_operacion que es donde se autoriza o no al usuario a modificar
  validarClvOperacion(): boolean {

    if (this.clv_operacion == 1) {
      return this.fecha_hoy == this.fecha_filtrada ? true : false
    } else if (this.clv_operacion == 3) {
      return true;
    }
    return false;
  }

}
